<!--
Presentational component for displaying a percentage in a tire chart
-->
<template>
	<figure>
		<figcaption
			class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-sans text-xs font-bold text-green-300"
			data-test-id="progress-circle-percentage"
		>
			{{ percentString }}%
		</figcaption>
		<svg class="h-16 w-16">
			<circle
				id="progress"
				cx="32"
				cy="32"
				r="21"
				class="fill-transparent stroke-green-300 stroke-[4px]"
				pathLength="100"
				:stroke-dasharray="dashArray"
			></circle>
		</svg>
	</figure>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
	/** The progress value from 0 to 1 */
	progress: Number,
})

/** Progress clamped from 0 to 1 */
const clampedProgress = computed(() =>
	props.progress === undefined ? 0 : Math.min(1, Math.max(0, props.progress))
)

/** Get human-readable string for percentage */
const percentString = computed(() => {
	return Math.floor(clampedProgress.value * 100).toString()
})

/** Convert progress to stroke-dasharray.
 * The percentage will the the first value, and the rest of the circle will be the second value */
const dashArray = computed(() => {
	const percent = clampedProgress.value * 100
	return `${percent} ${100 - percent}`
})
</script>

<style scoped>
#progress {
	stroke-linecap: round;
	stroke-dashoffset: 25; /* Shifts progress bar 25/100 to start at the top */
	transition: stroke-dasharray 0.5s ease-out;
}
</style>
