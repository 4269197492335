<!-- Layout organism for a selection card group with a heading above it -->
<template>
	<div class="grid auto-rows-max grid-cols-1">
		<HeadingMedium
			:richTextHtml="richTextHtml"
			class="pb-8"
		/>

		<SbSelectionCards :blok="selectionCards" />
	</div>
</template>

<script setup lang="ts">
import SbSelectionCards from './SelectionCards/SbSelectionCards.vue'
import HeadingMedium from '@/components/presentation/HeadingMedium.vue'
import type {
	HeadingAndSelectionCardsStoryblok,
	SelectionCardsStoryblok,
} from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<HeadingAndSelectionCardsStoryblok>,
})

/** Generated HTML for the medium heading rich text */
const richTextHtml = computed(() => {
	return renderRichText(props.blok?.heading)
})

/** Map the parameters for the selection cards and cast to SelectionCardsStoryblok */
const selectionCards = computed(
	() =>
		({
			...props.blok,
			component: 'selection-cards',
		}) as SelectionCardsStoryblok
)
</script>
