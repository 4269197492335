<!-- Component to display a card with a label and an image -->
<template>
	<RadioGroupOption
		:value="props.funeralHome.id"
		v-slot="{ checked }"
	>
		<div
			class="min-h-42 h-full cursor-pointer rounded-lg bg-white px-2 py-5"
			:class="
				checked
					? 'border-2 border-primary-green-400 shadow-xl'
					: 'border-2 border-transparent shadow'
			"
		>
			<img
				:src="imageUrl"
				class="ml-auto mr-auto max-h-20 max-w-20 object-contain"
			/>
			<div class="mt-3 text-center">{{ name }}</div>
		</div>
	</RadioGroupOption>
</template>

<script setup lang="ts">
import { useAssetStore } from '@/stores/asset'
import type { CombinedFhLocation } from '@/types'
import { RadioGroupOption } from '@headlessui/vue'
import { computedAsync } from '@vueuse/core'
import { computed } from 'vue'

const assetStore = useAssetStore()

const props = defineProps<{
	funeralHome: CombinedFhLocation
}>()

const name = computed(() => props.funeralHome.name)

const imageUrl = computedAsync(
	async () =>
		props.funeralHome.logo && assetStore.getImageBlobUrl(props.funeralHome.logo)
)
</script>
