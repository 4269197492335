<!--
	Component for simple text, lists, headers, etc.
	This is named Rich Text "Block" to differentiate from SB's
	built-in rich text field type.
-->
<template>
	<div
		v-editable="blok"
		v-html="richTextHtml"
		class="prose lg:prose-xl"
	></div>
</template>

<script setup lang="ts">
import type { RichTextBlockStoryblok } from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<RichTextBlockStoryblok>,
})

const richTextHtml = computed(() => renderRichText(props.blok?.content))
</script>
