import type { FormKitNode, FormKitSectionsSchema } from '@formkit/core'

/**
 * Disables autofill for specific input types.
 *
 * @param {FormKitNode} node - The node object to disable autofill plugin for.
 * @return {void}
 */
export default function disableAutofillPlugin(node: FormKitNode) {
	node.on('created', () => {
		// Exclude specific input types where disableAutofillPlugin should not be applied
		const excludedTypes = [
			'form',
			'group',
			'repeater',
			'mask',
			'dropdown',
			'autocomplete',
		]
		if (excludedTypes.includes(node.props.type)) return

		// Check if the parent form has the ID 'loginForm'
		const formId = node.parent?.props?.id
		if (
			formId === 'loginForm' &&
			['email', 'password'].includes(node.props.type)
		) {
			// Do not disable autofill for email and password in the login form
			return
		}

		// Ensure definition is defined before proceeding
		if (!node.props.definition) return

		// Modify the schemaMemoKey to ensure the schema is recomputed
		node.props.definition.schemaMemoKey = `data-disableAutocomplete_${node.props.definition.schemaMemoKey}`

		// Save the original schema function and ensure it is a function
		// This allows us to maintain the existing functionality and apply additional modifications as needed
		const originalSchema = node.props.definition.schema
		if (typeof originalSchema !== 'function') return

		// Overwrite the schema function to modify the input attributes
		node.props.definition.schema = (
			sectionsSchema: FormKitSectionsSchema = {}
		) => {
			const schema = sectionsSchema as {
				input?: {
					attrs: Record<string, string>
				}
			}

			// Set the attributes to disable autocomplete
			schema.input = {
				attrs: {
					...(schema.input?.attrs || {}), // Preserve existing attributes and don't override them
					autocomplete: 'off', // For Chrome and other non-safari browsers
					role: 'presentation', // For Chrome and other non-safari browsers
					'data-lpignore': 'true', // For LastPass password manager plugin
					'data-1p-ignore': 'true', // For 1Password password manager plugin
				},
			}
			return originalSchema(schema)
		}
	})
}
