<!-- 
Component to select 'funeralHome' in a SelectionCards group.
The drop-down can be used to change the plan's funeral home location. 
-->
<template>
	<div
		class="w-full"
		as="div"
		:value="value"
		:aria-label="label"
		v-editable="blok"
	>
		<div
			@click="handleClick"
			:class="[
				'relative flex cursor-pointer justify-between rounded-lg border bg-white px-5 py-3 shadow-lg focus:outline-none',
			]"
		>
			<span
				:class="[
					checked ? 'border-green-500' : 'border-transparent',
					'pointer-events-none absolute -inset-px rounded-lg border-2',
				]"
				aria-hidden="true"
			></span>

			<span class="flex min-h-10 w-full flex-col justify-between">
				<div>
					<img
						v-if="selectedFHLogo"
						:src="selectedFHLogo"
						class="h-12"
					/>
				</div>
				<span class="text-base font-extrabold">{{ label }}</span>
			</span>

			<span
				class="mt-2 flex items-center justify-between text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
			>
				<PlanAvatar
					:plan="plan"
					:index="index"
					:filled="checked"
				/>
				<PlayIcon
					class="h-4 w-4 rotate-90 text-xs text-green-500"
					@click.stop="clickEdit"
				/>
			</span>

			<precoa-modal
				v-model:open="modalOpen"
				is-modal-for-value-input
			>
				<template #default>
					<div
						class="grid h-full w-full grid-cols-1 grid-rows-[max-content_1fr] divide-y divide-slate-200 overflow-hidden"
					>
						<header
							v-html="labelHtml"
							class="text-xl font-normal leading-7 tracking-wide text-slate-900 [&_i]:font-bold [&_i]:text-precoa-blue-700"
						></header>
						<div
							class="align-start flex flex-col justify-stretch overflow-y-auto"
						>
							<button
								v-for="(fh, idx) in funeralHomes"
								:key="fh.id"
								type="button"
							>
								<div
									class="flex h-16 cursor-pointer flex-row p-1"
									:class="
										plan.funeralHomeId === fh.id
											? 'border-2 border-primary-green-400'
											: 'border-2 border-transparent'
									"
									@click="() => handleSelect(fh.id)"
								>
									<img
										class="h-12"
										v-if="fhLogosAsAsset?.[idx]"
										:src="fhLogosAsAsset[idx]"
									/>
									<div class="ml-4 content-center">
										{{ fh.name }}
									</div>
								</div>
							</button>
						</div>
					</div>
				</template>
			</precoa-modal>
		</div>
	</div>
</template>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import PrecoaModal from '@/components/common/PrecoaModal.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { DeckFlavor } from '@/enums/Language'
import type { SelectionCardFuneralHomeStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { useContentStore } from '@/stores/content'
import { useSessionStore } from '@/stores/session'
import { PlayIcon } from '@heroicons/vue/24/solid'
import { renderRichText } from '@storyblok/vue'
import { computedAsync } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
	blok: SelectionCardFuneralHomeStoryblok
	plan: Plan
	index: number
}>()

const content = useContentStore()
const session = useSessionStore()
const assetStore = useAssetStore()
const value = t('plan.funeralHomeName')
const model = defineModel<string>()
const modalOpen = ref<boolean>(false)

const funeralHomes = computed(() => {
	return content.getFuneralHomeLocations(
		session.activeSession?.language || DeckFlavor.Default,
		{
			crmGroupId: session.activeSession?.crmFhGroupId,
		}
	)
})

const selectedFuneralHome = computed(() => {
	return funeralHomes.value.find((fh) => fh.id === props.plan.funeralHomeId)
})

const labelHtml = computed(() => {
	return renderRichText(props.blok?.prompt)
})

/** The card's label */
const label = computed(() => {
	return selectedFuneralHome.value?.name ?? ''
})

/** True if the card is currently selected */
const checked = computed(() => {
	return value === model.value
})

const selectedFHLogo = computedAsync(() => {
	return (
		selectedFuneralHome.value?.logo &&
		assetStore.getImageBlobUrl(selectedFuneralHome.value.logo)
	)
})

const fhLogosAsAsset = computedAsync(() => {
	return Promise.all(
		funeralHomes.value.map((fh) => {
			return fh.logo ? assetStore.getImageBlobUrl(fh.logo) : Promise.resolve('')
		})
	)
})

/** Select or unselect the card */
const handleClick = (event: MouseEvent | KeyboardEvent) => {
	if (checked.value) {
		model.value = ''
	} else {
		model.value = value
	}
}

/** Open the form to change the plan's funeral home */
const clickEdit = () => {
	modalOpen.value = true
	// When edit also set as checked
	model.value = value
}

const handleSelect = (id: string) => {
	// update current plan funeral home Id
	props.plan.funeralHomeId = id

	modalOpen.value = false
}
</script>
