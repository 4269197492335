<template>
	<div v-auto-animate>
		<BaseAlert
			type="error"
			v-if="error"
		>
			{{ error }}
		</BaseAlert>
		<FormKit
			type="form"
			@submit="onSubmit"
			:actions="false"
			#default="{ disabled }"
			form-class="mt-8"
			id="loginForm"
		>
			<FormKit
				type="email"
				name="email"
				:label="t('auth.email')"
				validation="required|email"
				v-model="email"
				outer-class="max-w-full"
			/>

			<FormKit
				type="password"
				name="password"
				:label="t('auth.password')"
				validation="required"
				outer-class="max-w-full"
				v-model="password"
			/>

			<!-- Avoid prettier warning unwrapping type coercion, which breaks syntax highlight -->
			<!-- prettier-ignore-attribute -->
			<FormKit
				type="submit"
				outer-class="max-w-full mt-9"
				input-class="w-full justify-center"
				:disabled="(disabled as boolean)"
				>{{ t('auth.login') }}</FormKit
			>
		</FormKit>
		<a
			:href="resetPasswordUrl"
			class="text-center text-sm text-red-400 hover:underline"
			>{{ t('auth.forgotPassword') }}</a
		>
	</div>
</template>

<script setup lang="ts">
import BaseAlert from '@/components/common/BaseAlert.vue'
import { useAuthStore } from '@/stores/auth'
import { useSyncStore } from '@/stores/sync'
import { FormKit } from '@formkit/vue'
import { setUser } from '@sentry/vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const auth = useAuthStore()
const email = ref('')
const password = ref('')
const resetPasswordUrl = import.meta.env.VITE_API_ROOT + '/reset'
const error = ref('')
const sync = useSyncStore()

/** Try to log in with email and password */
const onSubmit = () => {
	error.value = ''
	return auth
		.getUserFromServer({
			username: email.value,
			password: password.value,
		})
		.then(async () => {
			setUser({ id: auth.user?.hub.id, email: auth.user?.hub.email })
			await auth.getStoryBlokToken()
			auth.showLogin = false
			sync.beginSync()
		})
		.catch((e) => {
			error.value = e.message
		})
}
</script>
