<!--
Atomic component to render an image url as offline blob
-->
<template>
	<img
		:src="imageSrc"
		:alt="props.alt"
		draggable="false"
	/>
</template>

<script setup lang="ts">
import type { AssetStoryblok } from '@/sbTypes'
import { useAssetStore, type Asset } from '@/stores/asset'
import { computedAsync } from '@vueuse/core'
import { type PropType } from 'vue'

const asset = useAssetStore()

const props = defineProps({
	src: String,
	alt: String,
})

/** Computed to get the blob url of cached image */
const imageSrc = computedAsync(() =>
	asset.getImageBlobUrl({
		filename: props.src,
		name: props.alt,
	} as AssetStoryblok)
)
</script>
