import ComponentsView from '@/views/ComponentsView.vue'
import FuneralHomeGroupMedia from '@/views/FuneralHomeGroupMedia.vue'
import HomeView from '@/views/HomeView.vue'
import NewSession from '@/views/NewSession/NewSession.vue'
import PriceBookSandboxView from '@/views/PriceBookSandboxView.vue'
import SbPresentation from '@/views/SbPresentation.vue'
import SelectionCardsView from '@/views/SelectionCardsView.vue'
import SessionPresentation from '@/views/Session/SessionPresentation.vue'
import SessionView from '@/views/SessionView.vue'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
		{
			path: '/new-session',
			name: 'newSession',
			component: NewSession,
			props: (route) => ({ ...route.query, ...route.params }),
		},
		{
			path: '/session/:sessionId',
			props: true,
			component: SessionView,
			children: [
				{
					path: 'present',
					name: 'present',
					component: SessionPresentation,
					props: true,
				},
			],
		},
		// Views for Storyblok visual editor. URL matches path in Storyblok.
		{
			path: '/presentations/:presentationId(.+)',
			props: true,
			name: 'chapter-preview-default',
			component: SbPresentation,
		},
		{
			path: '/:lang/presentations/:presentationId(.+)',
			props: true,
			name: 'chapter-preview',
			component: SbPresentation,
		},
		// Views for Storyblok visual editor for Funeral Home Group Media.
		{
			path: '/funeral-home-info/:v4FhGroupId',
			props: true,
			name: 'funeral-home-group-media-default',
			component: FuneralHomeGroupMedia,
		},
		// Test page for viewing verious components
		{
			path: '/components',
			name: 'components',
			component: ComponentsView,
		},
		// Test page for viewing different kinds of selection cards
		{
			path: '/selection-cards',
			name: 'selection-cards',
			component: SelectionCardsView,
		},
		// Test page for developing price book features
		{
			path: '/price-book',
			name: 'price-book',
			component: PriceBookSandboxView,
		},
	],
})

export default router
