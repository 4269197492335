Logical view to parse URL query params and collect data for a new session
<template>
	<!-- Footer notifications -->
	<BaseAlertList class="!bottom-[104px]">
		<template v-if="showErrors">
			<BaseAlert
				type="warning"
				v-for="error in validationErrors"
				:key="error"
			>
				{{ error }}
			</BaseAlert>
		</template>
	</BaseAlertList>

	<form
		@submit.prevent="onSubmit"
		ref="form"
	>
		<!-- Main grid has three rows: header, columns, and footer -->
		<div
			class="grid h-dvh w-screen grid-rows-[max-content_minmax(0,1fr)_max-content]"
		>
			<HeaderNav />

			<!-- Two column layout -->
			<div
				class="grid h-full w-screen grid-cols-[minmax(0,1fr)_255px] grid-rows-[100%] overflow-x-visible bg-gray-50 p-5 pb-0 pr-0"
			>
				<!-- Left column -->
				<div class="-mr-2 w-full overflow-y-scroll bg-slate-50 py-4 pr-2">
					<div
						v-auto-animate
						v-if="sessionParams?.plans"
					>
						<h1 class="pb-5 font-serif text-4xl font-normal italic">
							{{ t('newSession.plansTitle') }}
						</h1>
						<FormKit
							type="repeater"
							:actions="false"
							v-model="sessionParams.plans"
							dynamic
							validation="min:1"
							min="1"
							max="10"
							id="plan-list"
							:add-button="false"
							:insert-control="true"
							:up-control="false"
							:down-control="false"
							content-class="relative mb-2 grid w-full auto-rows-[72px] grid-cols-12 flex-row gap-x-8 gap-y-5 p-8 pb-8"
							item-class="rounded test-id-plan-item"
						>
							<FormKit
								outer-class="col-span-4 max-w-full"
								type="text"
								name="firstName"
								:label="t('person.firstName')"
								validation="required"
								ref="firstNameRef"
								data-test-id="first-name"
							/>

							<FormKit
								outer-class="col-span-4 max-w-full"
								type="text"
								name="middleName"
								:label="t('person.middleName')"
							/>

							<FormKit
								outer-class="col-span-4 max-w-full"
								type="text"
								name="lastName"
								:label="t('person.lastName')"
							/>

							<FormKit
								outer-class="col-span-3 max-w-full"
								type="text"
								name="address"
								:label="t('person.address')"
							/>

							<FormKit
								outer-class="col-span-3 max-w-full"
								type="text"
								name="city"
								:label="t('person.city')"
							/>
							<FormKit
								outer-class="col-span-3 max-w-full"
								type="dropdown"
								name="state"
								:label="t('person.state')"
								:placeholder="
									t('plan.placeholders.select', {
										placeholderLabel: t('person.state'),
									})
								"
								:options="stateOptions"
							/>

							<FormKit
								outer-class="col-span-3 max-w-full"
								type="text"
								name="zip"
								:label="t('person.zip')"
								validation="matches: /^\d{5}(-\d{4})?$/"
								validation-visibility="live"
							/>

							<FormKit
								outer-class="col-span-6 max-w-full"
								type="tel"
								name="phone"
								:label="t('person.phone')"
								placeholder="xxx-xxx-xxxx"
								:validation="[
									['matches', /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/],
								]"
								:validation-messages="{
									matches: `${t('plan.validationMessages.phone')}`,
								}"
								validation-visibility="live"
							/>
							<FormKit
								outer-class="col-span-6 max-w-full"
								type="email"
								name="email"
								:label="t('person.email')"
								validation="email"
								validation-visibility="dirty"
								:placeholder="t('plan.placeholders.email')"
							/>
						</FormKit>

						<!-- Add new participant button -->
						<div class="group my-7 flex justify-center">
							<button
								type="button"
								class="flex font-bold text-primary-green-500 group-hover:text-primary-green-600"
								@click="addParticipant()"
							>
								<PlusCircleIcon
									class="mr-1 size-6"
									aria-hidden="true"
								/>
								{{ t('newSession.addPlan') }}
							</button>
						</div>
					</div>

					<RadioGroup v-model="sessionParams.FuneralHomeLocationId">
						<div class="flex flex-row gap-3 overflow-x-auto py-5">
							<SelectFuneralHomeCard
								class="w-32"
								v-for="location in allSessionLocations"
								:funeral-home="location"
							/>
						</div>
					</RadioGroup>
				</div>

				<!-- Right column -->
				<DeckPicker
					class="mr-2 h-full overflow-hidden"
					v-model="deckChoice"
				/>
			</div>

			<footer class="flex h-20 w-full items-center justify-center bg-white">
				<button
					type="submit"
					class="rounded-lg border-0 px-40 py-2.5 text-base font-extrabold"
					:class="[
						validationErrors.length === 0
							? 'bg-primary-green-400 text-primary-green-700 hover:bg-primary-green-700 hover:text-white'
							: 'bg-slate-200 text-slate-400',
					]"
				>
					<slot>
						{{
							existingSession ? t('newSession.resume') : t('newSession.submit')
						}}
					</slot>
				</button>
			</footer>
		</div>
	</form>
</template>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import { Session } from '@/classes/Session'
import CrmAppointmentImporter from '@/classes/importers/crmAppointmentImporter'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseAlertList from '@/components/common/BaseAlertList.vue'
import HeaderNav from '@/components/common/HeaderNav.vue'
import SelectFuneralHomeCard from '@/components/common/SelectFuneralHomeCard.vue'
import DeckPicker from '@/components/home/decks/DeckPicker.vue'
import { stateOptions } from '@/data/stateOptions'
import { DeckFlavor, Language, flavorLanguages } from '@/enums/Language'
import { useContentStore } from '@/stores/content'
import { useSessionStore } from '@/stores/session'
import type { SessionData } from '@/types'
import { faker } from '@faker-js/faker'
import { FormKit } from '@formkit/vue'
import { RadioGroup } from '@headlessui/vue'
import PlusCircleIcon from '@heroicons/vue/24/solid/PlusCircleIcon'
import { computed, nextTick, onMounted, ref, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const form = ref()

const props = defineProps({
	appointmentId: String,
	sessionId: String,
	deckId: String,
})

/** Collection of options for the new session */
const sessionParams = ref<Partial<SessionData>>({})

const showErrors = ref(false)
const sessionStore = useSessionStore()
const content = useContentStore()
const router = useRouter()
const errorMessage = ref('')
const language = ref(Language.English)
const existingSession = ref()

// Send the language ref to the deck repeater so we can update it ourselves
provide('language', language)

/** Array of validation error messages for the user */
const validationErrors = computed<string[]>(() => {
	const errors: string[] = []
	if (!sessionParams.value.slideDeckId) {
		errors.push(t('newSession.errors.noDeck'))
	}
	if (!sessionParams.value.plans || sessionParams.value.plans?.length === 0) {
		errors.push(t('newSession.errors.noPlans'))
	}
	if (!sessionParams.value.plans?.every((p) => !!p.firstName)) {
		errors.push(t('newSession.errors.noFirstName'))
	}
	if (!sessionParams.value.FuneralHomeLocationId) {
		errors.push(t('newSession.errors.noFuneralHome'))
	}

	return errors
})

/** Computed property for getting/setting the deck by uniqueId */
const deckChoice = computed<string | undefined>({
	get: () =>
		sessionParams.value.language && sessionParams.value.slideDeckId
			? sessionParams.value.language + ':' + sessionParams.value.slideDeckId
			: undefined,
	set: (value: string | undefined) => {
		if (!value) {
			sessionParams.value.language = undefined
			sessionParams.value.slideDeckId = undefined
			return
		}

		const [language, id] = value.split(':')
		console.assert(
			Object.values(DeckFlavor).includes(language as DeckFlavor),
			'Deck uniqueId has invalid language:',
			language
		)
		sessionParams.value.language = language as DeckFlavor
		sessionParams.value.slideDeckId = id
	},
})

/** Create a new, empty participant */
const addParticipant = () => {
	if (!sessionParams.value.plans) sessionParams.value.plans = []

	const newPlan = new Plan()

	// Ensure the new participant has the same leadId as the primary plan
	newPlan.leadId = sessionParams.value.plans[0]?.leadId ?? ''

	sessionParams.value.plans.push(newPlan)
	focusOnPlan(true)
}

/**
 * Handles form submission.
 * Displays validation errors if present.
 * Updates or creates a session in sessionStore, then navigates to the presentation.
 */
async function onSubmit() {
	if (validationErrors.value.length > 0) {
		showErrors.value = true
		return
	}

	// If there is an existing session, update it in the store and navigate to it
	if (existingSession.value) {
		await sessionStore.resumeSession(existingSession.value)
	}
	// If there is no existing session, begin a new one
	else {
		await beginSession()
	}
}

/** Create the new session and start it */
async function beginSession() {
	const session = new Session(sessionParams.value)

	// set each plan's funeral home id
	session.plans.forEach((plan) => {
		plan.funeralHomeId = sessionParams.value.FuneralHomeLocationId!
	})
	await sessionStore.startSession(session)
	await router.push({ name: 'present', params: { sessionId: session.id } })
}

/** Focus on the first name of the first or last plan */
async function focusOnPlan(focusLast: boolean = false) {
	// Focus the first name of the first plan
	await nextTick()
	const inputs = form.value.querySelectorAll('input[name="firstName"]')

	const target = focusLast ? inputs[inputs.length - 1] : inputs[0]
	target?.focus()
}

// When we arrive at this page, attempt
// to import data that was specified in the query string.
// If the query string specifies something we are unable to import,
// we must show an error.
onMounted(async () => {
	// Select a deck if one was provided
	if (props.deckId) {
		selectDeck(props.deckId)
	}

	// Import appointment if an id was provided
	if (props.appointmentId) {
		// First, try to find an existing session for the appointment
		const matchedSession = sessionStore.sessions.find(
			(session) => session.appointmentId === props.appointmentId
		)

		if (matchedSession) {
			existingSession.value = matchedSession
		} else {
			// Otherwise, import the data from the appointment for a new session
			await importAppointment(props.appointmentId)
		}

		// Import a previous session by id
	} else if (props.sessionId) {
		existingSession.value = sessionStore.sessions.find(
			(session) => session.id === props.sessionId
		)
	}

	if (existingSession.value) {
		// Set the sessionParams after the nextTick to ensure that the reactive
		// reference is updated before the FormKit repeater is updated.
		nextTick(() => {
			sessionParams.value = existingSession.value
			selectDeck(
				(existingSession.value.language as string) +
					':' +
					existingSession.value.slideDeckId
			)
		})
	} else if (
		!sessionParams.value.plans ||
		sessionParams.value.plans.length === 0
	) {
		// We are starting a blank session; add one participant
		addParticipant()
	}

	focusOnPlan()
})

/** All the locations appropriate for the current session */
const allSessionLocations = computed(() => {
	return content.getFuneralHomeLocations(
		sessionParams.value.language || DeckFlavor.Default,
		{
			crmGroupId: sessionParams.value.crmFhGroupId,
		}
	)
})

/** Find the specified appointment and convert into sessionParams */
async function importAppointment(id: string) {
	try {
		const importer = new CrmAppointmentImporter(id)
		sessionParams.value = await importer.import()
	} catch (error) {
		console.error(error)
		errorMessage.value = 'Unable to import appointment: ' + error
		addParticipant()
	}
}

/** Select the specified deck and its language */
async function selectDeck(uniqueId: string) {
	deckChoice.value = uniqueId
	language.value =
		flavorLanguages[sessionParams.value.language || DeckFlavor.Default]
}

defineExpose({
	sessionParams,
})
</script>
