<!-- Component to select multiple military records at once -->
<template>
	<div class="flex space-x-2 *:w-full lg:space-x-4">
		<div
			v-for="option in options"
			:key="option.value"
		>
			<label>
				<PictureCard
					:is-active="isSelected(option.value)"
					:label="option.label"
					:image-url="option.imageUrl"
					:full-width="option.fullWidth"
				>
					<slot name="checkbox">
						<div class="mt-2 flex items-center justify-center">
							<input
								name="militaryRecordCheckbox"
								v-model="selectedValues"
								:value="option.value"
								type="checkbox"
								class="h-4 w-4 cursor-pointer rounded border-gray-300 bg-gray-100 text-primary-green-400 focus:ring-primary-green-400"
							/>
						</div>
					</slot>
				</PictureCard>
			</label>
		</div>
	</div>
</template>

<script setup lang="ts">
import PictureCard from '@/components/common/PictureCard.vue'

interface Option {
	value: string
	label: string
	imageUrl?: string
	fullWidth?: boolean
}

interface Props {
	options: Option[]
}

defineProps<Props>()

const selectedValues = defineModel<string[]>({
	type: Array,
	default: () => [],
})

const isSelected = (value: string): boolean => {
	return selectedValues.value.includes(value)
}
</script>
