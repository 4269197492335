import Plan from './Plan'
import { DeckFlavor } from '@/enums/Language'
import type { PlanData, SessionData } from '@/types'
import { faker } from '@faker-js/faker'
import { toRaw } from 'vue'

export class Session {
	public plans: Plan[] = []
	public currentSlideIndex = 0
	public readonly appointmentId: string | null = null
	public readonly id: string
	public language: DeckFlavor = DeckFlavor.Default
	public startAt: string | null = null

	public slideDeckId = ''
	public FHGroupMediaId = ''
	public crmFhGroupId = undefined
	public FuneralHomeLocationId: string | undefined

	constructor(sessionData: Partial<SessionData>) {
		// Unpack Data
		this.id = sessionData.id ?? crypto.randomUUID()
		this.plans = sessionData.plans
			? sessionData.plans.map((p) => Plan.deserialize(p))
			: []
		this.currentSlideIndex = sessionData.currentSlideIndex ?? 0
		this.appointmentId = sessionData.appointmentId ?? null
		this.language = sessionData.language ?? DeckFlavor.Default
		this.FuneralHomeLocationId = sessionData.FuneralHomeLocationId
		this.startAt = sessionData.startAt ?? null

		if (sessionData.slideDeckId) {
			this.slideDeckId = sessionData.slideDeckId
		}

		if (sessionData.FHGroupMediaId) {
			this.FHGroupMediaId = sessionData.FHGroupMediaId
		}
	}

	/**
	 * Adds a new plan to the session's list of plans.
	 * The new plan is initialized with the funeral home ID and plan ID of the first plan in the list.
	 */
	public addPlan() {
		this.plans.push(
			new Plan({
				funeralHomeId: this.plans[0].funeralHomeId,
				id: faker.string.uuid(),
				leadId: this.plans[0].leadId,
			})
		)
	}

	/** Copy a field from one plan to another */
	public copyPlanField<T extends keyof PlanData>(
		toIndex: number,
		fromIndex: number,
		field: T
	): void {
		const to = this.plans[toIndex]
		const from = this.plans[fromIndex % this.plans.length]

		if (!to || !from) {
			throw new Error(
				`Invalid plan index provided: toIndex: ${toIndex}, fromIndex: ${fromIndex}`
			)
		}

		const value = from[field]
		to[field] = value
	}

	/**
	 * Copies data from one plan to another based on the specified fields or sections.
	 *
	 * @param {Array<keyof T> | keyof T} fieldsOrSections - Can be either an array of fields or a single field to be copied from the previous plan to the current plan.
	 * @param {number} fromIndex - The index of the plan to copy from.
	 * @param {number} toIndex - The index of the plan to copy to.
	 *
	 * @example
	 * // Copy multiple fields or sections from the previous plan to the current plan
	 * copy(['specialInstructions', 'anotherOne'], fromIndex, toIndex);
	 *
	 * @example
	 * // Copy a single field from the previous plan to the current plan
	 * copy('notesField', fromIndex, toIndex);
	 *
	 */
	public copy<T>(
		fieldsOrSections: Array<keyof T> | keyof T,
		fromIndex: number,
		toIndex: number
	) {
		if (!this.plans || this.plans.length < 2) {
			throw new Error('Insufficient number of plans')
		}

		if (
			fromIndex < 0 ||
			fromIndex >= this.plans.length ||
			toIndex < 0 ||
			toIndex >= this.plans.length ||
			fromIndex === toIndex
		) {
			throw new Error('Invalid plan index provided')
		}

		const currentPlan = this.plans[toIndex]
		const previousPlan = this.plans[fromIndex]

		if (!currentPlan || !previousPlan) {
			throw new Error('Unable to copy from previous plan to current plan')
		}

		const fieldsArray: (keyof T)[] = []
		if (typeof fieldsOrSections === 'string') {
			fieldsArray.push(fieldsOrSections as keyof T)
		} else if (Array.isArray(fieldsOrSections)) {
			fieldsArray.push(...fieldsOrSections)
		}

		fieldsArray.forEach((sectionName) => {
			if (!(sectionName in previousPlan)) {
				;(previousPlan as any)[sectionName] = ''
			}
			if (!(sectionName in currentPlan)) {
				;(currentPlan as any)[sectionName] = ''
			}

			const sourceSection = (previousPlan as any)[sectionName]
			const rawSourceSection = toRaw(sourceSection)

			try {
				;(currentPlan as any)[sectionName] = structuredClone(rawSourceSection)
			} catch (error) {
				throw new Error('Unable to copy from previous plan to current plan')
			}
		})
	}

	/**
	 * Copies spouse information from one plan to another.
	 * If both plans have `spouseInfo`, copies the spouse's first name, middle name, and last name from the previous plan's
	 * vital info section to the current plan and merges the remaining properties of `spouseInfo` from the previous plan into the current plan.
	 *
	 * @param {number} fromIndex - The index of the plan to copy spouse information from.
	 * @param {number} toIndex - The index of the plan to copy spouse information to.
	 * @return {void}
	 */
	public copySpouseInfo(fromIndex: number, toIndex: number): void {
		if (!this.plans || this.plans.length < 2) {
			throw new Error('Insufficient number of plans')
		}

		if (
			fromIndex < 0 ||
			fromIndex >= this.plans.length ||
			toIndex < 0 ||
			toIndex >= this.plans.length ||
			fromIndex === toIndex
		) {
			throw new Error('Invalid plan index provided')
		}

		const currentPlanIndex = toIndex
		const currentPlan = this.plans[currentPlanIndex]
		const previousPlan = this.plans[fromIndex]

		if (previousPlan.spouseInfo && currentPlan.spouseInfo) {
			currentPlan.spouseInfo.firstName = previousPlan.firstName || ''
			currentPlan.spouseInfo.middleName = previousPlan.middleName || ''
			currentPlan.spouseInfo.lastName = previousPlan.lastName || ''
			currentPlan.spouseInfo.maidenName = previousPlan.maidenName
				? previousPlan.maidenName
				: ''

			const { firstName, middleName, lastName, maidenName, ...rest } =
				previousPlan.spouseInfo
			Object.assign(currentPlan.spouseInfo, rest)
		}
	}

	public serialize(): SessionData {
		return {
			id: this.id,
			appointmentId: this.appointmentId,
			plans: this.plans.map((c) => c.serialize()),
			currentSlideIndex: this.currentSlideIndex,
			slideDeckId: this.slideDeckId,
			FHGroupMediaId: this.FHGroupMediaId,
			FuneralHomeLocationId: this.FuneralHomeLocationId,
			language: this.language,
			startAt: this.startAt,
		}
	}

	public static deserialize(data: SessionData) {
		const session = new Session(data)
		return session
	}
}
