<!-- 
Show a single option either with a label and value, or with a value that is used as its own label
-->
<template>
	<div
		class="w-full"
		:value="value"
		:aria-label="label"
		v-editable="blok"
		@click="handleClick"
	>
		<div
			:class="[
				'relative flex cursor-pointer justify-between rounded-lg border bg-white px-5 py-3 shadow-lg focus:outline-none',
			]"
		>
			<span
				:class="[
					checked ? 'border-green-500' : 'border-transparent',
					'pointer-events-none absolute -inset-px rounded-lg border-2',
				]"
				aria-hidden="true"
			></span>

			<span class="flex min-h-16 w-full flex-col items-start">
				<sb-image
					class="h-12 max-h-12 w-12 max-w-12"
					:blok="blok.image"
				></sb-image>
				<span class="text-base font-extrabold">{{ label }}</span>
			</span>

			<span
				class="mt-2 flex items-center justify-between text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
			>
				<PlanAvatar
					:plan="plan"
					:index="index"
					:filled="checked"
				/>
				<PencilIcon
					v-if="isEditable"
					class="h-4 w-4 text-xs text-green-500"
					@click.stop="clickEdit"
				/>
				<div v-else>&nbsp;</div>
			</span>

			<precoa-modal
				v-model:open="modalOpen"
				is-modal-for-value-input
				@submit="clickSave"
			>
				<template #default>
					<div
						class="flex h-full w-full flex-row"
						ref="formRef"
					>
						<PlanAvatar
							:plan="plan"
							:index="1"
							filled
						></PlanAvatar>
						<FormKit
							outer-class="w-full max-w-full h-full formkit-custom-target"
							inner-class="focus:ring-transparent focus-within:!ring-transparent focus-within:!border-neutral-400 shadow-none border-0"
							input-class="shadow-none text-slate-900 font-medium font-sans resize-none"
							type="textarea"
							auto-height
							v-model="textAreaValue"
						/>
					</div>
				</template>
				<template #buttons>
					<div class="flex w-full flex-row justify-end">
						<button
							class="rounded-lg border-0 bg-primary-green-400 p-2.5 px-10 text-base font-extrabold"
							type="submit"
						>
							{{ t('general.save') }}
						</button>
					</div>
				</template>
			</precoa-modal>
		</div>
	</div>
</template>

<script setup lang="ts">
import type Plan from '@/classes/Plan'
import SbImage from '@/components/blocks/SbImage.vue'
import PrecoaModal from '@/components/common/PrecoaModal.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import {
	isLabeledSelectionCardAbstract,
	isSelectionCardTextStoryblok,
} from '@/guards'
import type {
	SelectionCardTextStoryblok,
	SelectionCardViewingOptionStoryblok,
} from '@/sbTypes'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { computed, onMounted, ref, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const formRef = ref()

const props = defineProps<{
	blok: SelectionCardTextStoryblok | SelectionCardViewingOptionStoryblok
	plan: Plan
	index: number
}>()

const model = defineModel<string>()
const modalOpen = ref<boolean>(false)

/** A new value entered by the user and saved */
const customValue = ref<string>(props.blok.value)

/** A temporary value in the modal. May be dismissed. */
const textAreaValue = ref<string>('')

/** Get the label or fall back to the value */
const label = computed<string>(() => {
	return isLabeledSelectionCardAbstract(props.blok)
		? props.blok.label
		: value.value
})

/** The value of the card */
const value = computed(() => {
	return customValue.value
})

/** Open the form for typing a custom value */
const clickEdit = () => {
	textAreaValue.value = value.value
	modalOpen.value = true

	// Select the text so the user can just type a value
	nextTick(() => {
		formRef.value?.querySelector('textarea')?.select()
	})
}

/** True if the card is currently selected */
const checked = computed(() => {
	return value.value === model.value
})

/** Select or unselect the card */
const handleClick = (event: MouseEvent | KeyboardEvent) => {
	if (checked.value) {
		model.value = ''
	} else {
		model.value = value.value
	}
}

/** Is this card editable? Only possible with text cards. */
const isEditable = computed(() => {
	return isSelectionCardTextStoryblok(props.blok)
		? props.blok.isEditable
		: false
})

/** Save the custom value and close the modal */
const clickSave = () => {
	customValue.value = textAreaValue.value
	model.value = textAreaValue.value
	modalOpen.value = false
}
</script>
