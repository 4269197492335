import { useSessionStore } from './session'
import type { ChapterStoryblok, SlideAbstract } from '@/sbTypes'
import { kebabCase } from 'change-case'
import { defineStore } from 'pinia'
import { Swiper } from 'swiper'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

/**
 * Pinia store to handle the state of the slide presentation,
 * agnostic of whatever data the slides are dealing with.
 *
 * Not responsible for determining the flow of the presentation
 */
export const usePresentationController = defineStore(
	'presentationController',
	() => {
		const router = useRouter()
		const sessionStore = useSessionStore()

		const activeSlideIndex = ref(0)
		const navigatingForward = ref(true)
		const restartSlide = ref(false)
		let verticalIndexes: number[] = []

		/**
		 * Reactive proxy to the swiper API.
		 * The Swiper library edits the state outside of our observation,
		 * so we must track data that we care about manually.
		 */
		const swiper = ref<Swiper>()

		/** The index of the active chapter based on the active slide */
		const activeChapterIndex = computed<number | undefined>({
			get: () => {
				let slides = activeSlideIndex.value

				if (chapters.value === undefined || chapters.value.length === 0) {
					return undefined
				}

				for (let i = 0; i < chapters.value.length; i++) {
					slides -= chapters.value[i].slides?.length ?? 0

					if (slides < 0) {
						return i
					}
				}

				// Somehow we've gone too far
				console.assert(
					false,
					'failed to find active chapter index',
					activeSlideIndex.value,
					chapters.value
				)
				return 0
			},
			set: (index: number | undefined) => {
				// Count the number of slides before the chapter
				const slideCount = chapters.value
					?.slice(0, index)
					.reduce((count, chapter) => count + (chapter.slides?.length ?? 0), 0)

				// Set the active slide index
				if (slideCount !== undefined) {
					swiper.value?.slideTo(slideCount)
				}
			},
		})

		/** The active chapter based on the active slide */
		const activeChapter = computed<ChapterStoryblok | undefined>(() => {
			return chapters.value
				? chapters.value[activeChapterIndex.value as number]
				: undefined
		})

		/** All slides for all chapters in a flat array */
		const allSlides = computed(() =>
			sessionStore.activeSlideDeck?.content.chapters?.reduce<SlideAbstract[]>(
				(slides, chapter) => {
					if (!chapter || !chapter.slides) return slides
					slides.push(...chapter.slides)
					return slides
				},
				[]
			)
		)

		/** All the chapters in the slide deck */
		const chapters = computed(
			() => sessionStore.activeSlideDeck?.content.chapters ?? []
		)

		/** The slide object we are looking at */
		const activeSlide = computed<SlideAbstract | undefined>(() => {
			return allSlides.value
				? allSlides.value[activeSlideIndex.value]
				: undefined
		})

		/** The name of the active slide */
		const activeSlideName = computed(() => {
			return activeSlide.value?.title
		})

		/** Subscribe to the active slide index */
		watch(swiper, (swiper) => {
			if (!swiper) return
			swiper.on('slideChange', () => {
				activeSlideIndex.value = swiper.activeIndex
			})
		})

		const onSlideSubmit = () => {
			swiper.value?.slideNext()
			try {
				;(document.activeElement as HTMLElement).blur()
			} catch {
				// The active element was null or was an Element that was not HTMLElement
				// Ignore
			}
		}

		/** Temp example of leaving the presentation */
		const exit = () => router.push({ name: 'home' })

		/** Jump to the first slide in the requested chapter */
		function gotoChapterId(id: string) {
			gotoChapterIndex(chapters.value?.findIndex((c) => c._uid === id) ?? 0)
		}

		/** Jump to the first slide in the requested chapter */
		function gotoChapterIndex(index: number) {
			// Reset slide locks
			if (swiper.value) {
				swiper.value.allowSlideNext = true
				swiper.value.allowSlidePrev = true
			}

			// Count the number of slides in previous chapters
			const prevSlides = chapters.value
				?.slice(0, index)
				.reduce(
					(sum: number, chapter) => sum + (chapter.slides?.length ?? 0),
					0
				)

			// Set the active slide index
			if (prevSlides !== undefined) {
				swiper.value?.slideTo(prevSlides, 0)
			}
		}

		/** Navigate to the requested chapter by slug */
		const gotoChapter = (name: string) => {
			gotoChapterIndex(getChapterIndex(name))
		}

		const gotoNextChapter = () => {
			if (activeChapterIndex.value === undefined) {
				activeChapterIndex.value = 0
			} else {
				activeChapterIndex.value++
			}
		}

		const getChapterIndex = (name: string | undefined): number => {
			// Assume we're on the first chapter if no chapter is passed in
			if (name === undefined) {
				return 0
			}

			const slug = getSlugFromName(name)

			return (
				chapters.value?.findIndex(
					(ch) => ch.title && getSlugFromName(ch.title) === slug
				) || 0
			)
		}

		/** Get the next chapter object */
		const getNextChapter = () => {
			if (!chapters.value) {
				throw new Error('No active slide deck')
			}

			const index = chapters.value.findIndex(
				(s) => s.title === activeChapter.value?.title
			)

			if (index == -1) {
				throw new Error('Cannot find active chapter')
			}

			if (index == chapters.value.length - 1) {
				throw new Error('No next chapter')
			}

			verticalIndexes = []
			return chapters.value[index + 1]
		}

		/** Navigate to the requested slide by id */
		const gotoSlideId = (id: string, { transition = true } = {}) => {
			restartSlide.value = true
			if (swiper.value) {
				swiper.value.allowSlideNext = true
				swiper.value.allowSlidePrev = true
			} else {
				console.error('no swiper')
			}

			const index = allSlides.value?.findIndex((meta) => meta._uid === id)

			if (index == -1 || index == undefined) {
				throw new Error('Cannot find slide by _uid: ' + id)
			}

			if (transition) {
				swiper.value?.slideTo(index)
			} else {
				swiper.value?.slideTo(index, 0, false)
			}
		}

		/*
		 * This is the other half of ensuring that slides are handled correctly when
		 * sideswiping vertical slides
		 */
		let listeningToSwiperChange: boolean = false

		const setupLocalSlides = (clientIndex: number, rootSwiper: Swiper) => {
			if (swiper.value !== rootSwiper) {
				// Remove old listener if it exists before setting a new one
				if (swiper.value && listeningToSwiperChange) {
					swiper.value.off('slideChange', rootSwiperSlideChange)
					listeningToSwiperChange = false
				}

				swiper.value = rootSwiper
			}

			if (!listeningToSwiperChange && swiper.value) {
				swiper.value.on('slideChange', rootSwiperSlideChange)
				listeningToSwiperChange = true
			}

			if (verticalIndexes.indexOf(clientIndex) === -1) {
				verticalIndexes.push(clientIndex)
			}

			function rootSwiperSlideChange() {
				if (!swiper.value) return

				if (verticalIndexes.indexOf(swiper.value?.activeIndex) === -1) {
					swiper.value.allowSlideNext = true
					swiper.value.allowSlidePrev = true
				}
			}
		}

		/** Go to the next slide with a transition */
		const gotoNextSlide = () => {
			swiper.value?.slideNext()
		}

		/** Go to the previous slide with a transition */
		const gotoPreviousSlide = () => {
			swiper.value?.slidePrev()
		}

		/** reset the value of restartSlide */
		const resetSlideRestart = () => {
			restartSlide.value = false
		}

		return {
			activeSlideIndex,
			activeSlideName,
			activeSlide,
			activeChapter,
			allSlides,
			chapters,
			exit,
			getChapterIndex,
			getNextChapter,
			gotoChapter,
			gotoChapterId,
			gotoChapterIndex,
			gotoNextChapter,
			gotoNextSlide,
			gotoPreviousSlide,
			gotoSlideId,
			navigatingForward,
			onSlideSubmit,
			setupLocalSlides,
			resetSlideRestart,
			swiper,
			restartSlide,
		}
	}
)

export function getSlugFromName(name: string): string {
	// Convert string into kebab case
	return kebabCase(name)
}

export type PresentationController = Omit<
	ReturnType<typeof usePresentationController>,
	keyof ReturnType<typeof defineStore>
>
