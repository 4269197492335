import { useAssetStore } from './asset'
import { useAuthStore } from './auth'
import { useSessionStore } from './session'
import type {
	AppointmentFindResult,
	CounselorAppointmentsResult,
	PapiResult,
	V4FuneralHome,
	CounselorAppointment,
	ApiPriceBook,
	FuneralHomeGroup,
	V3FuneralHome,
} from '@/apiTypes'
import { PriceBook } from '@/classes/PriceBook'
import { axiosInstance } from '@/services/axiosInstance'
import type { Downloadable } from '@/types'
import { PriceBookExample } from '@factories/PriceBookExample'
import localforageLib from 'localforage'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { ref, toRaw } from 'vue'

export type StoredAppointments = Downloadable<Array<CounselorAppointment>>

const localforage = localforageLib.createInstance({
	name: 'crm',
})

/** Luxon for the beginning of the day today */
function begginingOfToday() {
	return DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export const useCrmStore = defineStore('crm', () => {
	const auth = useAuthStore()
	const appointments = ref<StoredAppointments>()
	const funeralHomes = ref<V4FuneralHome[]>()
	const sessionStore = useSessionStore()
	const priceBooks = ref<PriceBook[]>([])

	/** List of URLs used in price books. Exposed so they aren't deleted */
	const priceBookUrls = ref<Array<string>>([])

	/** Get the datetime of the first appt as ISO string */
	function getMinimumApptDate() {
		return appointments.value?.data?.[0]?.startAt
			? DateTime.fromISO(appointments.value.data[0].startAt)
					.set({
						hour: 0,
						minute: 0,
						second: 0,
						millisecond: 0,
					})
					.toISO()
			: begginingOfToday().toISO()
	}

	/** Try to download a new batch of appts from the API */
	async function getAppointmentsFromServer() {
		const minimumDate = getMinimumApptDate()

		const scopes = [
			'dispatched',
			'startAfter=' + minimumDate,
			'startBefore=' + begginingOfToday().plus({ month: 3 }).toISO(),
		]
		const result = await axiosInstance.get<CounselorAppointmentsResult>(
			'/v3/counselors/' + auth.user?.salesContact?.id + '/appointments',
			{
				params: {
					scope: scopes.join('|'),
					include: 'funeralHome,lead.funeralHome,campaignManager,event',
				},
			}
		)

		// Merge appointments with local appts
		const merged = toRaw(appointments.value?.data || [])

		for (const appt of result.data.data) {
			const index = merged.findIndex((a) => a.id === appt.id)
			if (index > -1) {
				merged[index] = appt
			} else {
				merged.push(appt)
			}
		}

		// Keep only appts that are unresulted, in the future, or are awaiting sync
		const filtered = merged.filter((appt) => {
			const session = sessionStore.getSessionForAppointment(appt.id)
			const status = session
				? sessionStore.requestQueue.getRequestStatus(session.id)
				: null

			return (
				// Appt is awaiting sync
				(status &&
					status.uploadStatus in ['failed', 'waitingForNetwork', 'sending']) ||
				// Appt is unresulted
				appt.resultId === null ||
				// Appt is in the future
				appt.startAt > begginingOfToday().toISO()
			)
		})

		// Sort
		const sortedAppts = filtered.sort((a, b) =>
			a.startAt.localeCompare(b.startAt)
		)

		// change (no string passed) to an empty string
		sortedAppts.forEach((item) => {
			if (item.lead?.data) {
				if (item.lead.data.address === '(no string passed)') {
					item.lead.data.address = ''
				}
				if (item.lead.data.city === '(no string passed)') {
					item.lead.data.city = ''
				}
			}
		})

		const apptsObj = {
			data: sortedAppts,
			downloadedAt: DateTime.now().toISO(),
		}

		appointments.value = apptsObj

		const promise = localforage
			.setItem('appointments', apptsObj)
			.catch(console.error)

		return promise
	}

	/** Try to fetch cached appointments from local storage */
	async function getAppointmentsFromStorage() {
		const result = await localforage.getItem<StoredAppointments>('appointments')

		if (result && !appointments.value) {
			appointments.value = result
		}
	}

	/** Try to fetch cached funeral homes from local storage */
	async function getFuneralHomesFromStorage() {
		const result = await localforage.getItem<V4FuneralHome[]>('funeralHomes')

		if (result && !funeralHomes.value) {
			funeralHomes.value = result
		}
	}

	/** Given the list of FHs the agent works with, download all FHs in those FH groups */
	async function getFuneralHomesFromServer(agentFuneralHomes: V3FuneralHome[]) {
		// Get the list of unique funeral home groups
		const funeralHomeGroups = Array.from(
			new Set(agentFuneralHomes.map((fh) => fh.groupId))
		)

		// Get all the FHs in each group
		try {
			const fhGroups = await Promise.all(
				funeralHomeGroups.map(async (groupId) => {
					const response = await axiosInstance.get<
						PapiResult<FuneralHomeGroup[]>
					>('/v4/fhgroups?include=funeralHomes&scope=crmid:' + groupId)

					return response.data.data.map((res) =>
						res.funeralHomes!.data.map((fh) => {
							// include crmGropuId
							return {
								...fh,
								crmGroupId: res.mscrmAutoGroupID,
							} as V4FuneralHome
						})
					)
				})
			)

			const fhs = fhGroups.flat(2)

			// Store as a flat array
			await localforage.setItem('funeralHomes', fhs)
			funeralHomes.value = fhs
		} catch (error) {
			console.error('Could not get FHs in groups', funeralHomeGroups)
			throw error
		}
	}

	async function getFuneralHomeGroupById(groupId: string) {
		try {
			const response = await axiosInstance.get<PapiResult<FuneralHomeGroup>>(
				'/v4/fhgroups/' + groupId + '?include=funeralHomes'
			)

			return response.data.data
		} catch (error) {
			console.error('Could not get FHs by groupId', groupId)
			throw error
		}
	}

	/** Delete everything from local storage */
	async function purge() {
		await localforage.clear()
	}

	/** Get an appointment by id */
	function findAppointment(id: string): CounselorAppointment | undefined {
		return appointments.value?.data.find((a) => a.id === id)
	}

	/** Fetch an appointment from the server by id */
	async function fetchAppointment(id: string) {
		const result = await axiosInstance.get<AppointmentFindResult>(
			'/v3/appointments/' +
				id +
				'?include=funeralHome,lead.funeralHome,campaignManager,event'
		)

		return result.data
	}

	/** Get a funeral home by id */
	function findFuneralHome(id: string) {
		return funeralHomes.value?.find((fh) => fh.id === id)
	}

	function findFuneralHomesInGroup(groupId: string) {
		return funeralHomes.value?.filter((fh) => fh.groupId === groupId)
	}

	/** Get the list of unique funeral home group ids */
	function findFhGroupIds() {
		return Array.from(
			new Set(funeralHomes.value?.map((fh) => fh.groupId) || [])
		)
	}

	/***  Price Books ***/
	/** Get the price books for all our funeral homes from PAPI */
	function getPriceBooksFromServer() {
		priceBookUrls.value = []
		const books = [PriceBookExample()]
		localforage.setItem('priceBooks', books)

		priceBooks.value = books.map((b) => new PriceBook(b))
		priceBookUrls.value = getPriceBookUrls(priceBooks.value)

		return downloadUrls(priceBookUrls.value)
	}

	/** Get a list of unique image urls in the given price books */
	function getPriceBookUrls(priceBooks: PriceBook[]): string[] {
		const urls = priceBooks
			.map((pb) => pb.getLineItems().map((li) => li.imageUrl))
			.flat(2)

		return Array.from(new Set(urls))
	}

	/** Download the assets for the given URLs */
	function downloadUrls(urls: string[]): Promise<any> {
		const assetStore = useAssetStore()

		return Promise.allSettled(urls.map((url) => assetStore.getAsset(url)))
	}

	/** Load the price books from IndexedDB */
	async function getPriceBooksFromStorage() {
		const books = await localforage.getItem<ApiPriceBook[]>('priceBooks')

		if (!books) return
		if (priceBooks.value) return

		priceBooks.value = books.map((b) => new PriceBook(b))
	}

	/** Get a price book by id */
	function findPriceBook(id: string): PriceBook | undefined {
		// TODO: Find by id
		return priceBooks.value[0]
	}

	/** Get the right price book for the given funeral home */
	function findPriceBookForFuneralHome(v4FhId: string): PriceBook | undefined {
		// TODO: Use FH data to find the price book id
		return priceBooks.value[0]
	}

	return {
		appointments,
		funeralHomes,
		fetchAppointment,
		findAppointment,
		findFuneralHome,
		findFhGroupIds,
		findFuneralHomesInGroup,
		findPriceBook,
		findPriceBookForFuneralHome,
		getAppointmentsFromServer,
		getAppointmentsFromStorage,
		getFuneralHomesFromServer,
		getFuneralHomesFromStorage,
		getPriceBooksFromServer,
		getPriceBooksFromStorage,
		getFuneralHomeGroupById,
		priceBookUrls,
		purge,
	}
})
