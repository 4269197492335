import type { ApiPriceBook } from '@/apiTypes'
import { PbProductType } from '@/enums/PbProductType'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { tag } from '@formkit/icons'

export function PriceBookExample(): ApiPriceBook {
	const imageUrl = 'https://placehold.co/600x400?text=Example+Price+Book'
	const tagMetalCasket = {
		id: '1',
		name: 'Metal',
	}
	const tagPremiumCasket = {
		id: '2',
		name: 'Premium',
	}
	const tagMetalUrn = {
		id: '3',
		name: 'Metal',
	}
	const tagBasicCasket = {
		id: '4',
		name: 'Basic',
	}
	const tagWoodCasket = {
		id: '5',
		name: 'Wood',
	}
	const tagPremiumUrn = {
		id: '6',
		name: 'Premium',
	}
	const tagBasicUrn = {
		id: '7',
		name: 'Basic',
	}
	return {
		id: '0',
		name: 'Example Price Book',
		startDate: '2022-01-01',
		endDate: null,
		lineItems: {
			data: [
				{
					id: '1',
					name: 'Gold Casket',
					description: 'A very nice gold casket.',
					guaranteed: true,
					price: 11000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagMetalCasket, tagPremiumCasket] },
					isStandIn: false,
				},
				{
					id: '2',
					name: 'Bronze Casket',
					description: 'An OK bronze casket.',
					guaranteed: true,
					price: 10000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagMetalCasket, tagBasicCasket] },
					isStandIn: false,
				},
				{
					id: '3',
					name: 'Oak Casket',
					description: 'A fancy casket mnade of oak.',
					guaranteed: true,
					price: 9000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagWoodCasket, tagPremiumCasket] },
					isStandIn: false,
				},
				{
					id: '4',
					name: 'Pine Casket',
					description: 'The cheapest casket.',
					guaranteed: true,
					price: 3500,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagWoodCasket, tagBasicCasket] },
					isStandIn: false,
				},
				{
					id: '4.5',
					name: 'Alternative Container',
					description: '',
					guaranteed: true,
					price: 210,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '5',
					name: 'Titanium Urn',
					description: 'A space-age urn for all of your ashes.',
					guaranteed: true,
					price: 25000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseUrn,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagMetalUrn, tagPremiumUrn] },
					isStandIn: false,
				},
				{
					id: '6',
					name: 'Marble Urn',
					description: 'A very nice marble urn.',
					guaranteed: true,
					price: 14999,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseUrn,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagPremiumUrn] },
					isStandIn: false,
				},
				{
					id: '6.1',
					name: 'Pine Urn',
					description: 'A very affordable urn made of pine.',
					guaranteed: true,
					price: 14999,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseUrn,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagBasicUrn] },
					isStandIn: false,
				},
				{
					id: 'group-1',
					name: 'Premium Casket Credit',
					description: 'Any one of our finest caskets.',
					guaranteed: true,
					price: 10000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseCasket,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagPremiumCasket] },
					isStandIn: true,
				},
				{
					id: 'group-2',
					name: 'Premium Urn Credit',
					description: 'Any one of our finest urns.',
					guaranteed: true,
					price: 5000,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseUrn,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagPremiumUrn] },
					isStandIn: true,
				},
				{
					id: 'group-basic-casket',
					name: 'Basic Casket',
					description: 'An included casket',
					guaranteed: true,
					price: 0,
					category: PbProductType.Merchandise,
					subcategory: PbSubcategory.MerchandiseUrn,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: { data: [tagBasicCasket] },
					isStandIn: true,
				},
				{
					id: '7',
					name: 'Forwarding remains to another funeral firm with casket selected from our funeral home',
					description: `This charge includes removal of remains, necessary services of staff, embalming, necessary
authorizations, local transportation, and delivery to Bozeman or Spokane airport (but not airfare).
This charge does not include dressing, cosmetology, visitation, rites or ceremonies prior to
forwarding of the body. Casket not included.`,
					guaranteed: false,
					price: 2595,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '8',
					name: 'Air shipping container',
					description: `If transferring remains to another funeral home and not purchasing a casket from us.`,
					guaranteed: false,
					price: 295,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '9',
					name: 'Receiving of remains from another funeral home',
					description: `This charge includes transportation from Bozeman or Spokane airport, temporary shelter of remains,
transportation of remains to cemetery, and necessary services of staff. This charge does not include
visitation, rites, or ceremonies.`,
					guaranteed: false,
					price: 2395,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '10',
					name: 'Receiving of cremated remains',
					description: '',
					guaranteed: false,
					price: 395,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				/** Packages */
				{
					id: 'pkg-1',
					name: 'Immediate Burial with minimum alternative container',
					description: `Our Charge for an immediate burial, without any attendant rites or ceremonies, includes removal and
shelter of remains, refrigeration, local transportation to the cemetery, necessary services of staff and
authorizations.`,
					guaranteed: true,
					price: 3550,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageBurial,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-1',
								price: 2095,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-1',
								price: 205,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-1',
								price: 365,
							},
							{
								id: '4',
								lineItemId: '30.5',
								packageId: 'pkg-1',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: 'pkg-2',
					name: 'Immediate Burial',
					description: `Our Charge for an immediate burial, without any attendant rites or ceremonies, includes removal and
shelter of remains, refrigeration, local transportation to the cemetery, necessary services of staff and
authorizations.`,
					guaranteed: true,
					price: 3340,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageBurial,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-2',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-2',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-2',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: 'pkg-4',
					name: 'Burial w/ Casket',
					description: `Burial and casket included`,
					guaranteed: true,
					price: 3340,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageBurial,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '4',
								lineItemId: 'group-basic-casket',
								packageId: 'pkg-4',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: 'pkg-3.1',
					name: 'Deluxe Burial',
					description: `Our recommendation for the best healing opportunity for the family and loved ones.`,
					guaranteed: true,
					price: 13340,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageBurial,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-3.1',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-3.1',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-3.1',
								price: 0,
							},
							{
								id: '4',
								lineItemId: '25',
								packageId: 'pkg-3.1',
								price: 0,
							},
							{
								id: '5',
								lineItemId: '30.1',
								packageId: 'pkg-3.1',
								price: 0,
							},
							{
								id: '6',
								lineItemId: 'group-1',
								packageId: 'pkg-3.1',
								price: 10000,
							},
							{
								id: '7',
								lineItemId: '40',
								packageId: 'pkg-3.1',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},

				/** Direct Cremations */
				{
					id: 'pkg-4',
					name: 'Direct cremation',
					description: `Our charge for a direct cremation without any attendant rites or ceremonies includes removal of
remains, refrigeration, necessary services of staff and authorization. If you want to arrange a direct
cremation, you can use an alternative container. Alternative containers encase the body and can be
made of material like fiberboard or composite materials (with or without and outside covering). The
containers we provide are heavy cardboard. *(Our price for Direct Cremation includes the crematory
charge.)`,
					guaranteed: true,
					price: 2960,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageCremation,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-4',
								price: 0,
							},
							{
								id: '4',
								lineItemId: '30.6',
								packageId: 'pkg-4',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: 'pkg-5',
					name: 'Direct cremation with minimum alternative container',
					description: `Our charge for a direct cremation without any attendant rites or ceremonies includes removal of
remains, refrigeration, necessary services of staff and authorization. If you want to arrange a direct
cremation, you can use an alternative container. Alternative containers encase the body and can be
made of material like fiberboard or composite materials (with or without and outside covering). The
containers we provide are heavy cardboard. *(Our price for Direct Cremation includes the crematory
charge.)`,
					guaranteed: true,
					price: 3170,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageCremation,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-5',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-5',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-5',
								price: 0,
							},
							{
								id: '4',
								lineItemId: '30.5',
								packageId: 'pkg-5',
								price: 0,
							},
							{
								id: '5',
								lineItemId: '30.6',
								packageId: 'pkg-5',
								price: 0,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: 'pkg-6',
					name: 'Deluxe cremation',
					description: ``,
					guaranteed: true,
					price: 2960,
					category: PbProductType.Package,
					subcategory: PbSubcategory.PackageCremation,
					imageUrl: imageUrl,
					calcRecordType: 'Package',
					recommendedPricingOptionId: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					packageProducts: {
						data: [
							{
								id: '1',
								lineItemId: '11',
								packageId: 'pkg-6',
								price: 0,
							},
							{
								id: '2',
								lineItemId: '14',
								packageId: 'pkg-6',
								price: 0,
							},
							{
								id: '3',
								lineItemId: '29',
								packageId: 'pkg-6',
								price: 0,
							},
							{
								id: '4',
								lineItemId: '30.6',
								packageId: 'pkg-6',
								price: 0,
							},
							{
								id: '5',
								lineItemId: 'group-2',
								packageId: 'pkg-6',
								price: 4000,
							},
							{
								id: '6',
								lineItemId: '40',
								packageId: 'pkg-6',
								price: 525,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},

				/** Itemized selections */
				{
					id: '11',
					name: 'Basic services of funeral director and staff',
					description: `Our fee for the services of funeral director and staff includes, but is not limited to, staff to respond
to initial request for service arrangement conference consultation with the family or responsible party,
arrangement of funeral, preparation, certification and filing of necessary documentation, authorizations
and permits, recording vital statistics, preparation and placement of obituary notices, coordination
with those providing other portions of services, e.g. cemetery, crematory, shipping or receiving agents
and others. Also included in this charge are overhead expenses relative to our facility such as
insurance, maintenance and utility expenses, secretarial and administration costs and equipment
and inventory expenses. This fee for our basic services and overhead will be added to the total cost of
the funeral arrangements you select. (This fee is already included in our charges for direct cremations,
immediate burials and forwarding or receiving of remains.)`,
					guaranteed: true,
					price: 2095,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '12',
					name: 'Embalming, bathing and disinfecting of remains',
					description: `Except in certain special cases, embalming is not required by law. Embalming may be necessary,
however, if you select certain funeral arrangements, such as a funeral with viewing. If you do not want
embalming, you usually have the right to choose an arrangement that does not require you to pay
for it, such as direct cremation or immediate burial. According to 37.166.103 Administrative Rules of
Montana, a body “en route more than 8 hours, or if the termination of common carrier transport occurs
more than 36 hours after the time of death, the body must be either embalmed or refrigerated at 35
degrees F or colder,” or, “when being transported by a private conveyor and the body will not reach
its destination within 48 hours after the time of death, must be either embalmed or refrigerated at 35
degrees F or colder.” This firm assumes no liability whatsoever for the condition or appearance of
remains not embalmed. In such cases, we reserve the right to regulate or restrict viewing as well as
time and manner of ceremony.`,
					guaranteed: true,
					price: 830,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '13',
					name: 'Sanitary care and disinfection, normal cosmetology, dressing and casketing',
					description: ``,
					guaranteed: true,
					price: 265,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '14',
					name: 'Care and preparation of un-embalmed remains',
					description: ``,
					guaranteed: true,
					price: 205,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '15',
					name: 'Care and preparation for receiving from another funeral home',
					description: ``,
					guaranteed: true,
					price: 205,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '16',
					name: 'Hair dressing',
					description: ``,
					guaranteed: false,
					price: 0,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '1',
								name: 'Simple',
								price: 65,
							},
							{
								id: '2',
								name: 'Deluxe',
								price: 120,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '17',
					name: 'Special care of autopsied remains',
					description: ``,
					guaranteed: true,
					price: 210,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},

				/** Use of Facilities */
				{
					id: '18',
					name: 'Shelter of remains (per day)',
					description: `This charge is made after the 5th day that our facilities are used to shelter the remains.`,
					guaranteed: true,
					price: 235,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '19',
					name: 'Use of facilities and staff services for visitation (per day)',
					description: `Our services include set-up of visitation area, placement of encased remains, display of
floral arrangements, supervision of and attendance during the visitation at funeral home,
church or other.`,
					guaranteed: true,
					price: 445,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '20',
					name: 'Use of community room for gathering/reception',
					description: `Catering services are additional`,
					guaranteed: true,
					price: 655,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '21',
					name: 'Use of community room for funeral service',
					description: `Our services include coordinating the funeral arrangements, supervision of funeral,
and staff to attend funeral ceremony at the funeral home and live streaming of the service.`,
					guaranteed: true,
					price: 655,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '22',
					name: 'Use of facilities and staff services for memorial service (without body present)',
					description: `Our services include coordinating the memorial service arrangements, supervision
of the memorial service and staff to attend the service at the funeral home.`,
					guaranteed: true,
					price: 655,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '23',
					name: 'Limited staff and equipment for funeral/memorial service outside of our facilities',
					description: `Our services include coordinating the service arrangements, supervision
of the service and staff to attend ceremony at church or other facility.`,
					guaranteed: true,
					price: 655,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '24',
					name: 'Equipment and staff services for graveside services',
					description: `Our services include accompaniment of remains to cemetery, supervision
of graveside service and staff to attend service.`,
					guaranteed: true,
					price: 335,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '25',
					name: 'Refrigeration charge for un-embalmed remains',
					description: `In addition to charges for the shelering of the remains.`,
					guaranteed: true,
					price: 315,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},

				/** Other Charges */
				{
					id: '26',
					name: 'Saturday service fee',
					description: ``,
					guaranteed: true,
					price: 400,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '27',
					name: 'Sunday service fee',
					description: ``,
					guaranteed: true,
					price: 800,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '28',
					name: 'USPS mailing of cremated remains (Domestic)',
					description: ``,
					guaranteed: true,
					price: 175,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '29',
					name: 'Transfer of remains to funeral home',
					description: ``,
					guaranteed: true,
					price: 365,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '1',
								name: 'Within 30 miles',
								price: 365,
							},
							{
								id: '2',
								name: 'Within 50 miles',
								price: 465,
							},
							{
								id: '3',
								name: 'Within 100 miles',
								price: 715,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.1',
					name: 'Casket coach (hearse)',
					description: `Add $5 per loaded mile outside of the 30 mile radius.`,
					guaranteed: true,
					price: 260,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '1',
								name: 'In town',
								price: 260,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.2',
					name: 'Funeral SUV',
					description: `Add $5 per loaded mile outside of the 30 mile radius.`,
					guaranteed: true,
					price: 195,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '2',
								name: 'In Town',
								price: 195,
							},
							{
								id: '3',
								name: 'Minivan/Utility vehicle',
								price: 125,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.3',
					name: 'Minivan/Utility vehicle',
					description: `Add $5 per loaded mile outside of the 30 mile radius.`,
					guaranteed: true,
					price: 125,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '2',
								name: 'In Town',
								price: 125,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.5',
					name: 'Alternative container',
					description: ``,
					guaranteed: true,
					price: 210,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.6',
					name: 'Crematory fee',
					description: ``,
					guaranteed: true,
					price: 1250,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.7',
					name: `Additional charge per hour for staff services and/or use of facilities`,
					description: `For partial ceremonies,
body donations or other items not include in the above charges`,
					guaranteed: true,
					price: 200,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '30.8',
					name: `WWAMI Anatomical donation`,
					description: ``,
					guaranteed: true,
					price: 1515,
					category: PbProductType.Service,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},

				/** Memorial Merchandise */
				{
					id: '31',
					name: 'Memorial Register Books',
					description: ``,
					guaranteed: false,
					price: 50,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '32',
					name: 'Acknowledgement cards per 25',
					description: ``,
					guaranteed: false,
					price: 18,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '33',
					name: 'Service folders',
					description: `Minimum of 50`,
					guaranteed: false,
					price: 1.75,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '34',
					name: 'Memorial Cards',
					description: `Minimum of 25`,
					guaranteed: false,
					price: 1.25,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '35',
					name: 'DVD Video Tribute',
					description: `Includes setup of photo slide show of up to 90 photos`,
					guaranteed: false,
					price: 210,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Custom',
					pricingOptions: {
						data: [
							{
								id: '1',
								name: 'Video Tribute',
								price: 210,
							},
							{
								id: '2',
								name: '+1 Additional Copy of DVD',
								price: 245,
							},
							{
								id: '3',
								name: '+4 Additional Copies of DVD',
								price: 350,
							},
						],
					},
					tags: null,
					isStandIn: false,
				},
				{
					id: '36',
					name: 'Memorial Candle (each)',
					description: ``,
					guaranteed: false,
					price: 25,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '37',
					name: 'Laminated Obituary (each)',
					description: ``,
					guaranteed: false,
					price: 5,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '38',
					name: 'Custom casket panel',
					description: ``,
					guaranteed: false,
					price: 195,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '39',
					name: 'Recording of Livestream (each)',
					description: ``,
					guaranteed: false,
					price: 35,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '40',
					name: 'Memorial Package "A"',
					description: `Register book, 150 folders, 10 Laminates, 1 DVD, 25 Ack. Cards`,
					guaranteed: false,
					price: 525,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
				{
					id: '41',
					name: 'Memorial Package "B"',
					description: `Register book, 50 half sheet memorial cards`,
					guaranteed: false,
					price: 100,
					category: PbProductType.Merchandise,
					imageUrl: imageUrl,
					calcRecordType: 'Product',
					recommendedPricingOptionId: null,
					packageProducts: null,
					pricingType: 'Fixed',
					pricingOptions: null,
					tags: null,
					isStandIn: false,
				},
			],
		},
	}
}
