<template>
	<div
		class="fixed left-0 top-0 z-30 h-14 w-full print:hidden"
		:class="[showProgress ? 'bg-white' : 'pointer-events-none bg-transparent']"
	>
		<header
			class="grid h-full w-full grid-cols-[max-content_1fr_max-content_max-content] grid-rows-[56px]"
		>
			<!-- Wrapper makes a space for the button which itself is fixed -->
			<div class="pointer-events-auto w-20">
				<button
					class="fixed left-4 top-2 z-50"
					@click="isOpen = !isOpen"
					data-test-id="presentation-nav-menu-button"
				>
					<Bars3Icon class="h-10 w-10 fill-slate-900" />
				</button>
			</div>

			<nav
				class="pointer-events-auto flex h-full flex-row items-center space-x-4 justify-self-center text-slate-900"
				v-if="showProgress"
			>
				<button
					class="font-['Avenir Next LT Pro'] h-full border-y-[3px] border-transparent text-xs font-medium uppercase tracking-wide"
					:class="{
						'border-b-precoa-blue-700 text-precoa-blue-700':
							presentation.activeSlide?._uid === slide._uid,
					}"
					v-for="slide in presentation.activeChapter?.slides"
					:key="slide.title"
					@click="gotoSlide(slide._uid)"
				>
					{{ slide.title }}
				</button>
			</nav>

			<!-- Overlay buttons -->
			<div class="flex items-center">
				<!-- List View Icon -->
				<button
					v-if="showListView"
					@click="
						showOverlay = showOverlay === 'listView' ? 'none' : 'listView'
					"
					class="pointer-events-auto relative z-30 px-2"
					:class="
						showOverlay === 'listView'
							? 'last:border-b-0-[3px] border-b border-precoa-blue-700'
							: ''
					"
				>
					<TableCellsIcon class="h-6 w-6 text-precoa-blue-700" />
				</button>

				<!-- GPL Document Icon -->
				<button
					v-if="showGPL"
					data-test-id="presentation-nav-show-gpl-button"
					@click="showOverlay = showOverlay === 'gpl' ? 'none' : 'gpl'"
					class="pointer-events-auto relative z-30 px-2"
					:class="
						showOverlay === 'gpl' ? 'border-b-[3px] border-precoa-blue-700' : ''
					"
				>
					<DocumentIcon
						class="h-6 w-6 text-precoa-blue-700"
						v-if="showOverlay === 'gpl'"
					/>
					<DocumentIconOutline
						class="h-6 w-6 text-precoa-blue-700"
						v-else
					/>
				</button>
			</div>

			<!-- Avatars -->
			<button
				v-if="showAvatars"
				data-test-id="presentation-nav-avatars-button"
				class="pointer-events-auto relative z-30 mx-5 flex cursor-pointer flex-row items-center space-x-3 justify-self-end"
				@click="toggleOverlay"
			>
				<PlanAvatar
					v-for="(plan, index) in sessionStore.activeSession?.plans"
					:key="index"
					:index="index"
					class="!text-md size-9"
					filled
					:plan="plan"
				/>
			</button>
		</header>

		<!-- Pull-out menu -->
		<Transition
			class="transition-all duration-300"
			enter-from-class="-left-72"
			enter-to-class="left-0"
			leave-from-class="left-0"
			leave-to-class="-left-72"
		>
			<nav
				v-if="isOpen"
				class="pointer-events-auto absolute top-0 z-40 flex h-dvh w-72 flex-col place-content-between bg-precoa-blue-50"
			>
				<!-- Chapter List -->
				<ol class="mt-24 divide-y divide-slate-200">
					<MenuItem
						v-for="chapter in presentation.chapters"
						:key="chapter.title"
						@click="gotoChapter(chapter._uid)"
						:active="presentation.activeChapter?.title === chapter.title"
					>
						<div v-if="chapter.title">
							{{ chapter.title }}
						</div>
					</MenuItem>
				</ol>

				<!-- Footer links (Placeholder) -->
				<ol class="divide-y divide-slate-200">
					<MenuItem
						@click="presentation.exit"
						data-test-id="presentation-nav-exit"
					>
						{{ t('presentation.exit') }}
					</MenuItem>
				</ol>
			</nav>
		</Transition>

		<!-- ListView Overlay -->

		<Transition
			class="transition-all duration-300"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				class="pointer-events-auto fixed bottom-0 left-0 right-0 top-0 z-20 flex justify-center bg-white px-4 pt-24"
				v-show="showOverlay === 'listView'"
				data-test-id="show-list-view"
			>
				<OverlayListView />
			</div>
		</Transition>

		<!-- GPL PDF Viewer -->
		<Transition
			class="transition-all duration-300"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				class="pointer-events-auto fixed bottom-0 left-0 right-0 top-0 z-20 flex items-stretch justify-center overflow-hidden bg-white"
				v-show="showOverlay === 'gpl'"
				data-test-id="show-gpl-pdf-viewer"
			>
				<iframe
					class="flex w-full flex-col items-center justify-center gap-12"
					:src="GPLDocumentURL"
				></iframe>
			</div>
		</Transition>

		<!-- Most Important Reason Overlay -->
		<Transition
			class="transition-all duration-300"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				class="pointer-events-auto fixed bottom-0 left-0 right-0 top-0 z-20 flex items-stretch justify-center overflow-hidden bg-white pt-14"
				v-if="showOverlay === 'mostImportantReason'"
			>
				<div
					class="flex w-full flex-col items-center justify-center gap-12"
					v-if="showHeadingAndTextInput"
				>
					<SbHeadingAndTextInput :blok="mostImportantReason" />
					<button
						type="button"
						class="rounded-lg border-0 bg-primary-green-400 px-40 py-2.5 text-base font-extrabold text-primary-green-700 hover:bg-primary-green-700 hover:text-white"
						@click="showOverlay = 'none'"
					>
						{{ t('presentation.returnToPresentation') }}
					</button>
				</div>
				<div
					class="flex w-full flex-col justify-center"
					v-else
				>
					<button
						@click="showHeadingAndTextInput = !showHeadingAndTextInput"
						class="mx-7 my-4 self-end"
					>
						<div class="flex items-center justify-start gap-2">
							<span class="rounded-full bg-primary-green-400 p-1">
								<PencilIcon class="h-2.5 w-2.5 text-xs text-white" />
							</span>
							<div class="font-sans text-base font-bold text-primary-green-500">
								{{ t('presentation.edit') }}
							</div>
						</div>
					</button>

					<CardQuoteList
						:plans="sessionStore.activeSession?.plans"
						class="h-full items-start self-center"
					/>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script setup lang="ts">
import SbHeadingAndTextInput from '../blocks/SbHeadingAndTextInput.vue'
import OverlayListView from './OverlayListView.vue'
import MenuItem from '@/components/common/MenuItem.vue'
import CardQuoteList from '@/components/presentation/CardQuoteList.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { usePresentationLocale } from '@/i18n'
import type { HeadingAndTextInputStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { usePresentationController } from '@/stores/presentationController'
import { useSessionStore } from '@/stores/session'
import { DocumentIcon as DocumentIconOutline } from '@heroicons/vue/24/outline'
import { TableCellsIcon } from '@heroicons/vue/24/outline'
import { Bars3Icon, PencilIcon, DocumentIcon } from '@heroicons/vue/24/solid'
import { computedAsync } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

const { t } = usePresentationLocale()

const isOpen = ref(false)
const sessionStore = useSessionStore()
const asset = useAssetStore()
const presentation = usePresentationController()

const showProgress = computed(() => presentation.activeChapter?.show_slide_nav)
const showAvatars = computed(
	() => presentation.activeChapter?.show_customer_avatars
)
const showGPL = computed(() => presentation.activeChapter?.show_gpl)

const showListView = computed(() => presentation.activeChapter?.show_list_view)

/**
 * Toggles the display state of the overlay and its content.
 * If the overlay is showing the MIR inputs, show the cards instead.
 * If the overlay is showing cards, hide everything.
 * If there are no cards available, show the inputs.
 * Otherwise, show the overlay and the cards.
 */
const toggleOverlay = () => {
	const noCardsAvailable =
		sessionStore.activeSession?.plans?.filter(
			(plan) => plan.mostImportantReason
		)?.length === 0

	if (
		showOverlay.value === 'mostImportantReason' &&
		showHeadingAndTextInput.value
	) {
		// If overlay is showing and inputs are displayed, show cards instead
		showHeadingAndTextInput.value = false

		// If no cards are available, hide the overlay
		if (noCardsAvailable) {
			showOverlay.value = 'none'
		}
	} else if (
		showOverlay.value === 'mostImportantReason' &&
		showHeadingAndTextInput.value === false
	) {
		// If overlay and cards are showing, hide everything
		showOverlay.value = 'none'
	} else if (noCardsAvailable) {
		// If there are no cards available, show the inputs
		showHeadingAndTextInput.value = true
		showOverlay.value = 'mostImportantReason'
	} else {
		// Show the overlay with the cards
		showHeadingAndTextInput.value = false
		showOverlay.value = 'mostImportantReason'
	}
}

/**
 * Returns the most important reason component from the presentation.
 * @returns {HeadingAndTextInputStoryblok} The most important reason component
 */
const mostImportantReason = computed(() => {
	// Loop through the slides and find the 'mostImportantReason' component
	for (const slide of presentation.allSlides ?? []) {
		if ('content' in slide && Array.isArray(slide.content)) {
			for (const component of slide.content) {
				if (
					component.component === 'heading-and-text-input' &&
					(component as HeadingAndTextInputStoryblok).plan_field ===
						'mostImportantReason'
				) {
					// Return the component as HeadingAndTextInputStoryblok
					return component as HeadingAndTextInputStoryblok
				}
			}
		}
	}
	// Return undefined if no matching component is found
	return undefined
})

/** Navigates to the given slide by id */
function gotoSlide(id: string) {
	showOverlay.value = 'none'
	presentation.gotoSlideId(id)
}

/** Navigates to the given chapter by id */
function gotoChapter(id: string) {
	showOverlay.value = 'none'
	isOpen.value = false
	presentation.gotoChapterId(id)
}

const showOverlay = ref<'none' | 'mostImportantReason' | 'gpl' | 'listView'>(
	'none'
)
const showHeadingAndTextInput = ref(false)

const GPLDocumentURL = computedAsync(() => {
	if (!showGPL.value) return
	const gplDocumentURL = 'https://web.precoa.com/bq_test_2024/test.pdf'
	return asset.getAssetBlobUrl(gplDocumentURL)
})

const activeSlideIndex = computed(() => presentation.activeSlideIndex)
// hide overlay when presentation active slide changes.
watch(activeSlideIndex, () => {
	showOverlay.value = 'none'
})
</script>
