import { flavorLanguages, Language } from './enums/Language'
import en from './locales/en.json'
import es from './locales/es.json'
import { useSessionStore } from './stores/session'
import { computed, watch } from 'vue'
import { createI18n, type I18nOptions, useI18n } from 'vue-i18n'

const datetimeFormats: I18nOptions['datetimeFormats'] = {
	en: {
		time: {
			hour12: true,
			hour: 'numeric',
			minute: 'numeric',
		},
		shortDate: {
			day: 'numeric',
			month: 'numeric',
		},
		mediumDate: {
			day: 'numeric',
			month: 'long',
		},
	},
	es: {
		time: {
			hour12: true,
			hour: 'numeric',
			minute: 'numeric',
		},
		shortDate: {
			day: 'numeric',
			month: 'numeric',
		},
		mediumDate: {
			day: 'numeric',
			month: 'long',
		},
	},
}

// This file is not a singleton so it's not suitable for storing global state that may change
export const appLocale = 'en'

const defaultConfig = {
	locale: appLocale,
	legacy: false,
	datetimeFormats,
	messages: {
		en,
		es,
	},
	availableLocales: ['en', 'es'],
}

/** Global plugin for vue */
export default createI18n(defaultConfig)

/** Get the current presentation's language */
const presentationLocale = computed(() => {
	const { activeSession } = useSessionStore()
	return activeSession?.language
		? flavorLanguages[activeSession?.language]
		: Language.English
})

/** Composable for translating copy into the presentation's locale */
export function usePresentationLocale() {
	const i18n = useI18n({
		...defaultConfig,
		useScope: 'local',
		inheritLocale: false,
	})

	/** Update the locale when the language changes */
	watch(presentationLocale, (value) => {
		i18n.locale.value = value
	})

	i18n.locale.value = presentationLocale.value

	return i18n
}
