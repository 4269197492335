<!-- Component to render a slide that either displays a list of most important reason cards for each plan, or renders a heading if there are no plans with a most important reason filled out -->
<template>
	<div
		class="flex h-full flex-col justify-center bg-white pt-14"
		:class="!hasCardQuotes ? 'm-auto max-w-3xl items-center' : ''"
	>
		<CardQuoteList
			v-if="hasCardQuotes"
			:plans="sessionStore.activeSession?.plans"
			class="h-full items-start self-center"
		/>
		<HeadingMedium
			v-else
			:richTextHtml="richTextHtml"
			class="text-center text-fluid-6xl leading-tight"
		/>
	</div>
</template>

<script setup lang="ts">
import HeadingMedium from '../presentation/HeadingMedium.vue'
import CardQuoteList from '@/components/presentation/CardQuoteList.vue'
import type { MostImportantReasonSlideStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { renderRichText } from '@storyblok/vue'
import { type PropType, computed } from 'vue'

const props = defineProps({
	blok: Object as PropType<MostImportantReasonSlideStoryblok>,
})

const sessionStore = useSessionStore()

// Computed property to check if there are any cardQuotes
const hasCardQuotes = computed(() => {
	return sessionStore.activeSession?.plans?.some(
		(plan) => plan.mostImportantReason?.length > 0
	)
})

const richTextHtml = computed(() => renderRichText(props.blok?.headingMedium))
</script>
