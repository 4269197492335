<!-- Test page for developing price book features  -->
<template>
	<div class="h-full overflow-y-auto">
		<div v-if="priceBook">
			<h1 class="text-3xl">Price Book</h1>

			<h2 class="text-2xl">Packages</h2>
			<ol>
				<li
					v-for="pkg in packages"
					:key="pkg.id"
					class="mt-4"
				>
					<h1 class="text-xl">{{ pkg.name }}: {{ formatPrice(pkg.price) }}</h1>

					<!-- Add to cart buttons -->
					<button
						v-for="plan in session.activeSession?.plans"
						:key="plan.id"
						type="button"
						@click="
							isLineItemInPlan(pkg, plan)
								? plan.hardDeleteCartItem(pkg.id)
								: plan.addCartPackage(
										pkg.id,
										priceBook.getProductsInPackage(pkg.id)
									)
						"
						class="m-2 rounded px-2 py-1 font-bold text-white"
						:class="
							isLineItemInPlan(pkg, plan)
								? 'bg-green-500 hover:bg-green-700'
								: 'bg-blue-500 hover:bg-blue-700'
						"
					>
						{{ plan.firstName }}
					</button>

					<!-- <pre>{{ pkg }}</pre> -->
					<ol class="ml-8">
						<li
							v-for="item in priceBook?.getProductsInPackage(pkg.id)"
							:key="item.id"
						>
							{{ item.lineItem?.name }}
							<span
								v-if="item.price !== null"
								class="bold"
								>{{ formatPrice(item.price) }}</span
							>
							<span class="ml-4 line-through">{{
								formatPrice(item.lineItem?.price)
							}}</span>
							<!-- <pre>{{ item }}</pre> -->
						</li>
					</ol>
				</li>
			</ol>

			<h2 class="text-2xl">Guaranteed Products</h2>
			<ol v-if="priceBook">
				<li
					v-for="item in priceBook.products.filter((i) => i.guaranteed)"
					:key="item.id"
				>
					{{ item.name }}

					<!-- Display price and add-to-cart button per plan -->
					<div
						class="ml-4"
						v-for="plan in session.activeSession?.plans"
						:key="plan.id"
					>
						<span class="bold">{{
							formatPrice(getPlanLineItemPrice(plan, item.id)?.price)
						}}</span>

						<button
							type="button"
							@click="
								isLineItemInPlan(item, plan)
									? plan.delete(findLineItemInPlan(item, plan) as CartItem)
									: plan.addCartProduct(item.id)
							"
							class="m-2 rounded px-2 py-1 font-bold text-white"
							:class="
								isLineItemInPlan(item, plan)
									? 'bg-green-500 hover:bg-green-700'
									: 'bg-blue-500 hover:bg-blue-700'
							"
						>
							{{ plan.firstName }}
						</button>
					</div>

					<!-- <pre>{{ item }}</pre> -->
				</li>
			</ol>

			<h2 class="text-2xl">Non-Guaranteed Products</h2>
			<ol v-if="priceBook">
				<li
					v-for="item in priceBook.products.filter((i) => !i.guaranteed)"
					:key="item.id"
				>
					{{ item.name }}

					<!-- List all the price options for this product -->
					<div
						v-for="priceOption in item.pricingOptions"
						:key="priceOption.id"
						class="ml-4"
					>
						<div class="bold">
							{{ priceOption.name }}:
							{{ formatPrice(priceOption.price) }}
							<span v-if="item.recommendedPricingOptionId === priceOption.id"
								>(Recommended)</span
							>
						</div>
						<!-- Add to cart buttons -->
						<button
							v-for="plan in session.activeSession?.plans"
							:key="plan.id"
							type="button"
							@click="plan.addCartProduct(item.id, 1, priceOption.price)"
							class="m-2 rounded px-2 py-1 font-bold text-white"
							:class="
								isLineItemInPlan(item, plan, priceOption.price)
									? 'bg-green-500 hover:bg-green-700'
									: 'bg-blue-500 hover:bg-blue-700'
							"
						>
							{{ plan.firstName }}
						</button>
					</div>

					<!-- Show quantity adjustments if there are no pricing options -->
					<div v-if="!item.pricingOptions?.length">
						<div
							class="ml-4"
							v-for="plan in session.activeSession?.plans"
							:key="plan.id"
						>
							<button
								type="button"
								class="m-2 rounded px-2 py-1 font-bold text-white"
								:class="
									isLineItemInPlan(item, plan)
										? 'bg-green-500 hover:bg-green-700'
										: 'bg-blue-500 hover:bg-blue-700'
								"
								@click="
									isLineItemInPlan(item, plan)
										? plan.hardDeleteCartItem(item.id)
										: plan.addCartProduct(item.id, 1)
								"
							>
								Check
							</button>
							{{ plan.firstName }}
							<button
								type="button"
								class="m-2 rounded bg-blue-500 px-2 py-1 font-bold text-white hover:bg-blue-700"
								@click="
									plan.addCartProduct(
										item.id,
										-1 + (findLineItemInPlan(item, plan)?.quantity ?? 0)
									)
								"
							>
								-
							</button>
							{{ findLineItemInPlan(item, plan)?.quantity ?? 0 }}
							<button
								type="button"
								class="m-2 rounded bg-blue-500 px-2 py-1 font-bold text-white hover:bg-blue-700"
								@click="
									plan.addCartProduct(
										item.id,
										1 + (findLineItemInPlan(item, plan)?.quantity ?? 0)
									)
								"
							>
								+
							</button>
						</div>
					</div>
				</li>
			</ol>

			<h2 class="text-2xl">Custom Items</h2>

			<h3 class="text-xl">Add custom product</h3>
			<button
				v-for="plan in session.activeSession?.plans"
				:key="plan.id"
				type="button"
				@click="
					plan.addCustomItem({
						description: 'custom item',
						title: 'custom item',
						guaranteed: false,
						sku: '12345',
						price: 500,
					})
				"
				class="m-2 rounded bg-blue-500 px-2 py-1 font-bold text-white hover:bg-blue-700"
			>
				{{ plan.firstName }}
			</button>

			<h3 class="text-xl">Add discount</h3>
			<button
				v-for="plan in session.activeSession?.plans"
				:key="plan.id"
				type="button"
				@click="
					plan.addCustomItem({
						description: 'discount',
						title: 'Veterans Discount',
						price: -500,
					})
				"
				class="m-2 rounded bg-blue-500 px-2 py-1 font-bold text-white hover:bg-blue-700"
			>
				{{ plan.firstName }}
			</button>

			<h1 class="text-3xl">Shopping Carts</h1>

			<template
				v-for="plan in session.activeSession?.plans"
				:key="plan.id"
			>
				<h2 class="text-2xl">{{ plan.firstName }} {{ plan.lastName }}</h2>

				<div
					v-for="cartItem in getPlanCartItems(plan.id)"
					:key="cartItem.lineItemId"
				>
					<span :class="cartItem.isSoftDeleted ? 'line-through' : ''"
						>{{ cartItem.lineItem?.name }} x {{ cartItem.quantity }}:
						{{
							(cartItem.price ?? cartItem.lineItem)
								? formatPrice(
										cartItem.quantity *
											(cartItem.price ?? cartItem.lineItem!.price)
									)
								: '???'
						}}</span
					>
					<button
						v-if="!cartItem.isSoftDeleted"
						@click="plan.softDeleteCartItem(cartItem.lineItemId)"
						type="button"
						class="m-2 rounded bg-red-500 px-2 py-1 font-bold text-white hover:bg-red-700"
					>
						<XMarkIcon class="size-4" />
					</button>
					<button
						v-else
						@click="plan.softDeleteCartItem(cartItem.lineItemId, false)"
						type="button"
						class="m-2 rounded bg-green-500 px-2 py-1 font-bold text-white hover:bg-green-700"
					>
						<CheckIcon class="size-4" />
					</button>

					<!-- Hard delete button -->
					<button
						v-if="cartItem.isSoftDeleted"
						type="button"
						@click="plan.hardDeleteCartItem(cartItem.lineItemId)"
					>
						<TrashIcon class="size-4" />
					</button>

					<!-- Package sub-items -->
					<div
						v-for="subItem in cartItem.subItems"
						:key="subItem.lineItemId"
						class="ml-8"
					>
						<span
							:class="
								(subItem.isSoftDeleted || cartItem.isSoftDeleted) &&
								'line-through'
							"
							>{{ subItem.lineItem?.name }}:
							{{ formatPrice(subItem.price) }}</span
						>
						<!-- Button to remove sub-item. Hide if package is soft-deleted -->
						<template v-if="!cartItem.isSoftDeleted">
							<button
								v-if="!subItem.isSoftDeleted"
								@click="
									plan.softDeleteCartSubItem(
										cartItem.lineItemId,
										subItem.lineItemId
									)
								"
								type="button"
								class="m-2 rounded bg-red-500 px-2 py-1 font-bold text-white hover:bg-red-700"
							>
								<XMarkIcon class="size-4" />
							</button>
							<button
								v-else
								@click="
									plan.softDeleteCartSubItem(
										cartItem.lineItemId,
										subItem.lineItemId,
										false
									)
								"
								type="button"
								class="m-2 rounded bg-green-500 px-2 py-1 font-bold text-white hover:bg-green-700"
							>
								<CheckIcon class="size-4" /></button
						></template>
					</div>
				</div>

				<!-- Custom items in the plan -->
				<div
					v-for="item in plan.customCartItems"
					:key="item.id"
				>
					<span :class="item.isSoftDeleted ? 'line-through' : ''">
						{{ item.description }}: {{ formatPrice(item.price) }}
					</span>

					<!-- Soft delete button -->
					<button
						@click="plan.softDeleteCustomItem(item.id, !item.isSoftDeleted)"
						type="button"
						class="m-2 rounded px-2 py-1 font-bold text-white"
						:class="
							item.isSoftDeleted
								? 'bg-green-500 hover:bg-green-700'
								: 'bg-red-500 hover:bg-red-700'
						"
					>
						<CheckIcon
							v-if="item.isSoftDeleted"
							class="size-4"
						/>
						<XMarkIcon
							v-else
							class="size-4"
						/>
					</button>

					<!-- Hard delete button -->
					<button
						type="button"
						v-if="item.isSoftDeleted"
						@click="plan.hardDeleteCustomItem(item.id)"
					>
						<TrashIcon class="size-4" />
					</button>
				</div>
			</template>
		</div>

		<div v-else>No Price Book Data</div>
	</div>
</template>

<script setup lang="ts">
import { useMerchandise } from '@/mixins/merchandise'
import { useCrmStore } from '@/stores/crm'
import { useSessionStore } from '@/stores/session'
import type { CartItem } from '@/types'
import SessionFactory from '@factories/SessionFactory'
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { computed, onMounted } from 'vue'

const session = useSessionStore()
const crm = useCrmStore()

const {
	priceBook,
	formatPrice,
	getPlanCartItems,
	getPlanLineItemPrice,
	isLineItemInPlan,
	findLineItemInPlan,
} = useMerchandise()
const packages = computed(() => priceBook.value?.packages)

onMounted(() => {
	session.activeSession = SessionFactory(2)
	crm.getPriceBooksFromServer()
})
</script>
