PlanAvatar
<template>
	<div
		:class="classes"
		class="grid cursor-pointer place-content-center rounded-full bg-[--bg-color] p-4 text-sm uppercase text-[--line-color] outline outline-1 outline-[--line-color]"
	>
		<span v-if="hasInitials">{{ initials }}</span>
		<span v-else>
			<UserIcon class="w-5" />
		</span>
	</div>
</template>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import UserIcon from '@heroicons/vue/24/outline/UserIcon'
import { computed, type PropType } from 'vue'

type Initialsable = Pick<Plan, 'firstName' | 'lastName'>

const props = defineProps({
	plan: Object as PropType<Initialsable>,
	index: Number,
	filled: Boolean,
	large: Boolean,
})

const classes = computed(() => {
	return [
		'index-' + props.index,
		props.filled ? 'filled' : 'wire',
		props.large ? 'large' : 'small',
	].filter((c) => c)
})

const initials = computed(() => getPlanInitials(props.plan))

/**
 * Determine if the plan has initials available.
 * @returns {boolean} True if the initials string length is greater than 0, otherwise false.
 */
const hasInitials = computed(() => initials.value.length > 0)

/** Get the initials for the given plan's name */
function getPlanInitials(plan: Initialsable | undefined) {
	if (!plan) {
		return ''
	}
	return plan.firstName.substring(0, 1) + plan.lastName.substring(0, 1)
}
</script>

<style>
/* planAvatar.css */
.index-0 {
	--color: var(--color-0);
}

.index-1 {
	--color: var(--color-1);
}

.index-2 {
	--color: var(--color-2);
}

.index-3 {
	--color: var(--color-3);
}

.index-4 {
	--color: var(--color-4);
}

.index-5 {
	--color: var(--color-5);
}

.index-6 {
	--color: var(--color-6);
}

.index-7 {
	--color: var(--color-7);
}

.index-8 {
	--color: var(--color-8);
}

.wire {
	--bg-color: white;
	--line-color: var(--color);
}

.filled {
	--bg-color: var(--color);
	--line-color: white;
}

.small {
	width: 32px;
	height: 32px;
}

.large {
	width: 40px;
	height: 40px;
}
</style>
