import { useAssetStore } from './asset'
import { useContentStore } from './content'
import { useCrmStore } from './crm'
import { useSessionStore } from './session'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useSyncStore = defineStore('sync', () => {
	const crm = useCrmStore()
	const content = useContentStore()
	const session = useSessionStore()
	const asset = useAssetStore()

	/** True if we think we're offline */
	const offline = ref<Boolean>(!navigator.onLine)

	/** True if a sync is in progress */
	const syncing = ref(false)

	/** Abstract download progress for everything */
	const percentComplete = computed(() => {
		return asset.assetDownloadPercentage
			? Math.floor(asset.assetDownloadPercentage * 100)
			: undefined
	})

	/** Start syncing everything */
	async function beginSync() {
		if (syncing.value) return

		syncing.value = true
		await Promise.allSettled([
			crm.getAppointmentsFromServer(),
			crm.getPriceBooksFromServer(),
			content.fetchDecksAndAssets(
				import.meta.env.VITE_APP_STORYBLOK_PREVIEW === 'true'
			),
			content.fetchFuneralHomeGroupMedias(
				import.meta.env.VITE_APP_STORYBLOK_PREVIEW === 'true'
			),
			session.requestQueue.retryAll(),
		])
		syncing.value = false

		// Clean up
		content.deleteOldAssets()
	}

	window.addEventListener('offline', () => {
		offline.value = true
	})

	window.addEventListener('online', () => {
		offline.value = false
	})

	return {
		beginSync,
		offline,
		percentComplete,
		syncing,
	}
})
