Slide template for displaying a single video or image that covers the screen.
The image will be hidden if there is a video provided.
<template>
	<div
		class="v-full h-full"
		v-editable="blok"
	>
		<SbMedia
			:blok="media"
			:active="active"
		/>
	</div>
</template>

<script setup lang="ts">
import SbMedia from '@/components/blocks/SbMedia.vue'
import type { FullscreenMediaStoryblok } from '@/sbTypes'
import { type PropType, computed } from 'vue'

const props = defineProps({
	blok: Object as PropType<FullscreenMediaStoryblok>,

	/** True iff the slide is the current one */
	active: Boolean,
})

const media = computed(() => props.blok?.media[0])
</script>
