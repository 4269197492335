<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<path
				id="Vector"
				d="M6.5 20C4.96667 20 3.66667 19.4666 2.6 18.4C1.53333 17.3333 1 16.0333 1 14.5C1 13.2166 1.39583 12.075 2.1875 11.075C2.97917 10.075 4 9.43331 5.25 9.14998C5.3 9.01664 5.35 8.88748 5.4 8.76248C5.45 8.63748 5.5 8.49998 5.55 8.34998L2.1 4.89998C1.91667 4.71664 1.825 4.48331 1.825 4.19998C1.825 3.91664 1.91667 3.68331 2.1 3.49998C2.28333 3.31664 2.51667 3.22498 2.8 3.22498C3.08333 3.22498 3.31667 3.31664 3.5 3.49998L20.5 20.5C20.6833 20.6833 20.7792 20.9125 20.7875 21.1875C20.7958 21.4625 20.7 21.7 20.5 21.9C20.3167 22.0833 20.0875 22.1791 19.8125 22.1875C19.5375 22.1958 19.3 22.1083 19.1 21.925L17.15 20H6.5ZM21.6 18.75L8.05 5.22498C8.63333 4.82498 9.25417 4.52081 9.9125 4.31248C10.5708 4.10414 11.2667 3.99998 12 3.99998C13.95 3.99998 15.6042 4.67914 16.9625 6.03748C18.3208 7.39581 19 9.04998 19 11C20.15 11.1333 21.1042 11.6291 21.8625 12.4875C22.6208 13.3458 23 14.35 23 15.5C23 16.15 22.875 16.7541 22.625 17.3125C22.375 17.8708 22.0333 18.35 21.6 18.75Z"
				fill="#F8FAFC"
			/>
		</g>
	</svg>
</template>
