<template>
	<div class="flex h-full w-full pt-14">
		<!-- Left Column -->
		<div class="w-fit bg-white p-4 shadow-lg">
			<ul
				class="flex flex-col gap-3 whitespace-nowrap text-center text-base uppercase text-slate-900 *:min-w-[126px] *:cursor-pointer *:text-left *:uppercase *:italic"
			>
				<li
					class="mt-4 hover:font-semibold hover:text-primary-green-300"
					:class="{
						'font-semibold text-primary-green-400': selectedType === 'All',
					}"
					@click="handleSelection('All')"
				>
					{{ t('general.all') }}
				</li>
				<li
					class="hover:font-semibold hover:text-primary-green-300"
					:class="{
						'font-semibold text-primary-green-400': selectedType === 'Burial',
					}"
					@click="handleSelection('Burial')"
				>
					{{ t('packageSlide.burial') }}
				</li>
				<li
					class="hover:font-semibold hover:text-primary-green-300"
					:class="{
						'font-semibold text-primary-green-400':
							selectedType === 'Cremation',
					}"
					@click="handleSelection('Cremation')"
				>
					{{ t('packageSlide.cremation') }}
				</li>
			</ul>
		</div>

		<!-- Right Column -->
		<div
			ref="scrollRef"
			class="flex-1 divide-y divide-gray-200 overflow-auto bg-white px-8 *:py-8 first:pt-0 last:pb-0"
		>
			<Package
				v-for="pkg in packagesBySelection"
				:key="pkg.id"
				:isSidePanelLayout="false"
				:pkg="pkg"
			/>
		</div>
	</div>
</template>

<!--TODO: Make Rocommended, Burial and Cremation links act as filters that will display the correct packages -->
<script setup lang="ts">
import Package from '@/components/packages/Package.vue'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { usePresentationLocale } from '@/i18n'
import { useSessionStore } from '@/stores/session'
import { computed, ref, watch, type VNodeRef } from 'vue'

const { t } = usePresentationLocale()

const sessionStore = useSessionStore()
const selectedType = ref<'All' | 'Recommended' | 'Burial' | 'Cremation'>('All')
const scrollRef = ref<VNodeRef | undefined>(undefined)

const handleSelection = (
	type: 'All' | 'Recommended' | 'Burial' | 'Cremation'
) => {
	if (selectedType.value === type) {
		selectedType.value = 'All'
	} else {
		selectedType.value = type
	}
}

const packages = computed(() => sessionStore.activePriceBook?.packages)

const packagesBySelection = computed(() => {
	if (selectedType.value === 'Burial') {
		return packages.value?.filter(
			(pkg) => pkg.subcategory === PbSubcategory.PackageBurial
		)
	}

	if (selectedType.value === 'Cremation') {
		return packages.value?.filter(
			(pkg) => pkg.subcategory === PbSubcategory.PackageCremation
		)
	}

	return packages.value
})

// When the selected type changes, reset the scroll position
watch(selectedType, () => {
	if (scrollRef.value) {
		scrollRef.value.scrollTop = 0
	} else {
		console.error('Scroll ref not found')
	}
})
</script>
