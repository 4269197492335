<template>
	<div
		class="relative w-2/5"
		ref="el"
	>
		<div class="relative flex items-center">
			<MagnifyingGlassIcon class="absolute left-3 h-6 w-6 text-gray-500" />
			<input
				type="text"
				class="w-full rounded-lg border py-2 pl-10 pr-4"
				placeholder="Search for Funeral Home Group"
				v-model="searchText"
				@focus="showSuggestion = true"
			/>
		</div>

		<!-- Show Search Suggestions -->
		<div
			v-if="showSuggestion"
			class="absolute bottom-[-5px] left-0 z-10 max-h-[50vh] w-full translate-y-full overflow-y-auto rounded-md bg-white"
		>
			<SuggestionCard
				v-for="s in suggestions"
				:suggestion="s"
				:key="s.id"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { FuneralHomeGroup } from '@/apiTypes';
import SuggestionCard from './SuggestionCard.vue'
import { useContentStore } from '@/stores/content'
import MagnifyingGlassIcon from '@heroicons/vue/24/outline/MagnifyingGlassIcon'
import { computed, onMounted, ref, type DirectiveBinding } from 'vue'

const content = useContentStore()
const searchText = ref<string>('')
const showSuggestion = ref<boolean>(false)
const el = ref<HTMLElement>()

const suggestions = computed(() => {
	// Need to do on this one again when we consider Search function again
	return [] as FuneralHomeGroup[];
	// return content.funeralHomeGroups.filter((item) => {
	// 	return (
	// 		item.name.includes(searchText.value) ||
	// 		item.id.includes(searchText.value) ||
	// 		item.city.includes(searchText.value) ||
	// 		item.state.includes(searchText.value)
	// 	)
	// })
})

onMounted(() => {
	const clickOutsideHandler = (event: MouseEvent) => {
		if (el.value && !el.value.contains(event.target as Node)) {
			showSuggestion.value = false
		}
	}

	document.addEventListener('click', clickOutsideHandler)
})
</script>
