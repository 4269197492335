<!--
Organism for displaying unresulted appointments and non-appt sessions in a grid.
-->
<template>
	<div
		class="border-1 grid h-auto auto-rows-[max-content] grid-cols-[minmax(min-content,2fr)_max-content_minmax(min-content,2fr)_minmax(min-content,1fr)_max-content] gap-x-3 rounded-xl border border-indigo-50 bg-white"
		v-auto-animate
	>
		<header
			class="col-span-5 mb-3 grid grid-cols-subgrid border-b border-gray-100 px-5 py-4 font-sans text-base font-bold text-zinc-900"
		>
			<div class="mx-3">{{ t('upcomingAppointments.lead') }}</div>
			<div>{{ t('upcomingAppointments.time') }}</div>
			<div>{{ t('upcomingAppointments.fhName') }}</div>
			<div>{{ t('upcomingAppointments.leadSource') }}</div>
		</header>
		<template
			v-for="day in appointmentDays"
			:key="day.date"
		>
			<div
				class="col-span-5 mx-5 items-center justify-start gap-2.5 rounded bg-gray-100 px-3 py-3"
				data-test-id="appointment-day"
			>
				<div
					class="rounded text-xs font-extrabold uppercase tracking-wide text-gray-500"
				>
					{{ day.dateLabel }}
				</div>
			</div>
			<div
				v-auto-animate
				class="col-span-5 grid grid-cols-subgrid divide-y divide-gray-100 px-5"
			>
				<template
					v-for="appt in day.appointments"
					:key="appt.id"
				>
					<NewLeadItem
						v-if="isSession(appt)"
						:session="appt"
						class="col-span-5 grid grid-cols-subgrid items-start py-4 hover:bg-precoa-blue-25"
					/>
					<AppointmentItem
						v-else
						class="col-span-5 grid grid-cols-subgrid items-start py-4 hover:bg-precoa-blue-25"
						:appointment="appt"
					/>
				</template>
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
import NewLeadItem from './NewLeadItem.vue'
import type { CounselorAppointment } from '@/apiTypes'
import type { Session } from '@/classes/Session'
import AppointmentItem from '@/components/home/AppointmentItem.vue'
import { isSession } from '@/guards'
import { useCrmStore } from '@/stores/crm'
import { useSessionStore } from '@/stores/session'
import { assert } from '@vueuse/core'
import { DateTime } from 'luxon'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { d, t } = useI18n()

interface AppointmentDay {
	date: string
	dateLabel: string
	appointments: Array<CounselorAppointment | Session>
}

const crm = useCrmStore()
const sessionStore = useSessionStore()

/** List of objects with a date label and a heterogeneous list of appointments and sessions */
const appointmentDays = computed<Array<AppointmentDay>>(() => {
	const yesterday = DateTime.now().minus({ days: 1 }).toISODate()
	const today = DateTime.now().toISODate()
	const tomorrow = DateTime.now().plus({ days: 1 }).toISODate()

	const appointments = crm.appointments?.data || []
	const sessions = sessionStore.sessions.filter((s) => s.appointmentId == null)

	const leads = [...appointments, ...sessions]

	// Group appointments by day
	const days = leads.reduce((days: Array<AppointmentDay>, appt) => {
		if (appt.startAt === null) return days

		const apptStart = DateTime.fromISO(appt.startAt)
		let dayMatch = days.find((day) => day.date === apptStart.toISODate())

		// Create a new day if it doesn't exist
		if (!dayMatch) {
			dayMatch = {
				date: apptStart.toISODate() || '',
				dateLabel: (() => {
					switch (apptStart.toISODate()) {
						case yesterday:
							return t('upcomingAppointments.yesterday')
						case today:
							return t('upcomingAppointments.today')
						case tomorrow:
							return t('upcomingAppointments.tomorrow')
						default:
							return d(apptStart.toJSDate(), 'mediumDate')
					}
				})(),
				appointments: [],
			}

			days.push(dayMatch)
		}

		assert(dayMatch !== undefined)

		// Add the appointment to the day
		dayMatch!.appointments.push(appt)

		return days
	}, [])

	// Make sure the days are in order
	days.sort((a, b) =>
		DateTime.fromISO(a.date).diff(DateTime.fromISO(b.date)).as('days')
	)

	// Make sure all the appointments/sessions for each day are in order
	days.forEach((day) => {
		day.appointments.sort((a, b) => {
			if (a.startAt === null) return 1
			if (b.startAt === null) return -1

			return DateTime.fromISO(a.startAt)
				.diff(DateTime.fromISO(b.startAt))
				.as('minutes')
		})
	})

	return days
})

/** Expose appointmentDays for unit tests */
defineExpose({
	appointmentDays,
})
</script>
