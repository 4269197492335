<template>
	<div
		class="relative h-full w-full overflow-hidden"
		v-if="blok"
		v-editable="blok"
		@click="onVideoClick"
	>
		<SbImage
			class="h-full w-full object-cover"
			:class="props.class + ' ' + blurClass"
			v-if="blok.image && (prefersNoVideo || !videoSrc)"
			:blok="blok.image"
		/>
		<video
			v-else-if="videoSrc"
			:src="videoSrc"
			:class="props.class + ' ' + blurClass"
			class="h-full w-full object-cover"
			:controls="props.blok?.showControls"
			:loop="props.blok?.loop"
			playsinline
			ref="video"
			@canplaythrough="onCanPlayThrough"
			muted
		></video>
		<div
			v-else
			:class="props.class"
		>
			No media configured
		</div>

		<!-- Lighten/Darken overlay -->
		<div
			v-if="blok.lightenDarken !== '' && blok.lightenDarkenAmount > 0"
			class="absolute inset-0 opacity-50"
			:class="[blok.lightenDarken === 'lighten' ? 'bg-white' : 'bg-black']"
			:style="'opacity: ' + blok.lightenDarkenAmount / 100"
		></div>
	</div>
</template>

<script setup lang="ts">
import SbImage from './SbImage.vue'
import type { SbMediaStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { computedAsync } from '@vueuse/core'
import { type PropType, ref, watch, computed } from 'vue'

const asset = useAssetStore()

/** Video HTML Element for controlling video */
const video = ref<HTMLVideoElement>()

const props = defineProps({
	blok: Object as PropType<SbMediaStoryblok>,

	/** True iff the slide is the current one */
	active: Boolean,

	/** Placeholder for no video feature */
	prefersNoVideo: Boolean,

	/** True if video is unStoppable */
	unStoppable: Boolean,

	class: String,
})

/** Computed to get the blob url of cached video */
const videoSrc = computedAsync(() =>
	props.blok?.videoUrl ? asset.getVideoBlobUrl(props.blok) : ''
)

const blurClass = computed(() => {
	switch (props.blok?.blur) {
		case 'sm':
			return 'blur-sm'
		case 'md':
			return 'blur-md'
		case 'lg':
			return 'blur-lg'
		case 'xl':
			return 'blur-xl'
		case '2xl':
			return 'blur-2xl'
		case '3xl':
			return 'blur-3xl'
		case null:
		case undefined:
		default:
			return ''
	}
})

/** Control the video when we become active or inactive */
watch(
	() => props.active,
	(newValue, oldValue) => {
		// The slide is active.
		if (newValue && !oldValue) {
			onNewlyActive()
		}

		// The slide is inactive. Pause the video.
		if (!newValue && oldValue) {
			video.value?.pause()
		}
	}
)

// Go to the start. Play it if autoplay is set
function onNewlyActive() {
	if (video.value) {
		video.value.currentTime = 0

		if (props.blok?.autoplay && props.active) {
			video.value?.play()
		}
	}
}

/** Handle autoplay in the case where we are active by the time the video loads */
function onCanPlayThrough() {
	if (props.blok?.autoplay && props.active) {
		video.value?.play()
	}
}

/** Play/pause the video when the video is clicked */
function onVideoClick() {
	if (video.value && !props.blok?.showControls) {
		if (video.value.paused) {
			video.value.play()
		} else {
			if (!props.unStoppable) video.value.pause()
		}
	}
}
</script>
