<template>
	<nav
		class="flex-0 sticky top-0 z-40 flex flex-row justify-between bg-white py-4 print:hidden"
	>
		<!-- Left Side -->
		<div class="ml-5 flex flex-row items-center">
			<RouterLink
				to="/"
				class="flex flex-row"
			>
				<img
					src="/assets/logos/studio-logo.svg"
					alt="Sales Studio logo"
					class="mr-4 h-6 w-6"
				/>
				<img
					src="/assets/logos/studio-name.svg"
					alt="Sales Studio name"
				/>
			</RouterLink>
		</div>

		<!-- Right Side -->
		<div class="mr-5 flex flex-row justify-center">
			<!-- Sync status -->
			<div
				@click="sync.beginSync()"
				class="flex cursor-pointer flex-row items-center"
			>
				<ArrowPathIcon
					class="mr-1 size-3 fill-slate-600"
					:class="sync.syncing ? 'animate-spin' : ''"
				/>
				<div
					class="text-[10px] font-normal tracking-wide text-slate-400"
					data-test-id="sync-updated-date"
				>
					<span
						class="uppercase"
						v-if="sync.syncing"
					>
						{{ t('home.syncing') }}
						<span v-if="sync.percentComplete && sync.percentComplete < 100"
							>{{ sync.percentComplete }}%</span
						>
					</span>
					<span
						v-else-if="crm.appointments?.downloadedAt"
						:title="`Last received appointments and pushed data to server on ${updatedDate}`"
					>
						<span class="mr-1 uppercase">{{ t('home.lastUpdated') }}</span>
						<span class="lowercase">{{ updatedDate }}</span>
					</span>
					<span v-else> {{ t('home.lastUpdatedNever') }}</span>
				</div>
			</div>

			<!-- Profile picture -->
			<div class="ml-3 size-9 overflow-hidden rounded-full">
				<img
					:src="profilePhotoUrl"
					class="h-full w-full object-cover"
				/>
			</div>

			<!-- Greetings and log out menu -->
			<div class="group relative flex cursor-pointer items-center">
				<div
					class="ml-2 flex text-sm font-medium text-precoa-blue-500 group-hover:text-precoa-blue-700"
				>
					{{ t('home.hello', { name: auth.user?.hub?.firstname }) }}
					<PlayIcon class="ml-1 mt-1 size-3 rotate-90" />
				</div>

				<!-- Hover menu wrapper to include some blank vertical space -->
				<div
					class="absolute right-0 top-8 z-30 hidden w-[225px] group-hover:block"
				>
					<menu
						class="mt-3 border-separate divide-y divide-slate-200 border-slate-200 bg-precoa-blue-50"
					>
						<MenuItem @click="modalOpen = true">
							{{ t('auth.logout') }}
						</MenuItem>
					</menu>
				</div>
			</div>
		</div>
	</nav>

	<!-- Confirm log out modal -->
	<precoa-modal
		v-model:open="modalOpen"
		:modal-title="t('auth.logout')"
		@submit="logout"
	>
		<template #default>
			<p class="text-sm text-gray-500">
				{{ t('auth.logoutConfirmation') }}
			</p>
		</template>
		<template #buttons>
			<FormKit
				type="button"
				@click="modalOpen = false"
				>{{ t('general.cancel') }}</FormKit
			>
			<FormKit type="submit">{{ t('auth.logout') }}</FormKit>
		</template>
	</precoa-modal>
</template>

<script setup lang="ts">
import MenuItem from './MenuItem.vue'
import DefaultProfile from '@/assets/default-profile.svg'
import PrecoaModal from '@/components/common/PrecoaModal.vue'
import { useAssetStore } from '@/stores/asset'
import { useAuthStore } from '@/stores/auth'
import { useCrmStore } from '@/stores/crm'
import { useSyncStore } from '@/stores/sync'
import { ArrowPathIcon, PlayIcon } from '@heroicons/vue/24/solid'
import { computedAsync } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const asset = useAssetStore()
const auth = useAuthStore()
const crm = useCrmStore()
const sync = useSyncStore()
const { d, t } = useI18n()

const modalOpen = ref(false)

const logout = () => {
	modalOpen.value = false
	auth.logOut()
}

/** Get the user's profile photo via the asset store */
const profilePhotoUrl = computedAsync(async () => {
	const imageId = auth.user?.hub?.profileImageId
	if (!imageId) return DefaultProfile

	return asset.getAssetBlobUrl(
		import.meta.env.VITE_API_ROOT +
			'/v1/images/' +
			imageId +
			'?width=72&height=72'
	)
})

/** Last updated date */
const updatedDate = computed(() => {
	if (!crm.appointments?.downloadedAt) return ''
	let now = new Date()
	let date = new Date(crm.appointments?.downloadedAt)
	if (date.toDateString() === now.toDateString()) {
		return d(date, 'time')
	} else {
		return d(date, 'shortDate')
	}
})
</script>
