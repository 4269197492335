<!-- Form for a product or service that allows interaction with the shopping cart -->
<template>
	<div
		class="flex overflow-hidden"
		:class="twoColumns ? 'flex-row gap-6' : 'flex-col-reverse'"
	>
		<!-- Content -->
		<div :class="twoColumns ? 'w-1/2' : 'w-full'">
			<!-- guarantee -->
			<div class="mx-1 mt-5 font-serif text-4xl italic text-slate-900">
				{{ product?.name }}
			</div>

			<!-- price -->
			<div
				v-if="product && product.pricingType !== 'Custom'"
				class="mt-6 flex items-center gap-2 text-[22px] font-bold text-precoa-blue-700"
			>
				{{ formatPrice(product.price) }}
				<ShieldCheckIcon class="size-6 text-primary-green-600" />
			</div>

			<!-- description -->
			<div class="my-4 text-sm text-slate-900">
				{{ product?.description }}
			</div>

			<!-- Input for adding to plans -->
			<div v-if="product?.pricingType === 'Custom'">
				<TabsPlans v-model="activePlanIndex" />
				<InputCardsPriceOptions
					:product="product"
					:active-plan-index="activePlanIndex"
				/>
			</div>
			<div v-else>
				<InputQuantity
					v-for="plan in plans"
					:key="plan.id"
					class="my-4 w-full"
					:plan="plan"
					:product="product"
					:hasCounter="hasCounter"
				/>
			</div>
		</div>

		<!-- Image -->
		<div :class="twoColumns ? 'w-1/2' : 'w-full'">
			<BlobImage
				class="max-h-screen w-full rounded-sm object-cover"
				:src="product?.imageUrl"
				:alt="product?.name"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import TabsPlans from '../presentation/TabsPlans.vue'
import InputCardsPriceOptions from './InputCardsPriceOptions.vue'
import InputQuantity from './InputQuantity.vue'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import { useMerchandise } from '@/mixins/merchandise'
import { useSessionStore } from '@/stores/session'
import ShieldCheckIcon from '@heroicons/vue/24/solid/ShieldCheckIcon'
import { type PropType, computed, ref } from 'vue'

const { formatPrice, getPlanLineItemPrice } = useMerchandise()
const session = useSessionStore()

const plans = computed(() => session.activeSession?.plans)
const activePlanIndex = ref(0)

const props = defineProps({
	product: Object as PropType<PbLineItemProductAndPricingOptions>,
	hasCounter: Boolean,
	twoColumns: Boolean,
})
</script>
