<template>
	<button
		@click="handleCopyButtonClick"
		:class="[
			'group inline-flex items-center justify-center gap-1 rounded-full bg-[--color] px-4 py-1 text-center text-base font-medium text-white hover:bg-[--bg-color]',
			`copy-button-${props.buttonIndex}`,
		]"
	>
		<component
			:is="iconComponent"
			class="h-4 w-4 group-hover:fill-white group-hover:stroke-current"
			:class="`${buttonClass}`"
		/>
		<span class="text-[12px]">
			{{ buttonText }}
		</span>
	</button>
</template>

<script setup lang="ts">
import DocumentDuplicateIcon from '@heroicons/vue/24/outline/DocumentDuplicateIcon'
import CheckBadgeIcon from '@heroicons/vue/24/solid/CheckBadgeIcon'
import { ref, computed } from 'vue'
import { usePresentationLocale } from '@/i18n'

const { t } = usePresentationLocale()

const props = defineProps({
	name: String,
	buttonIndex: Number,
})

const emits = defineEmits(['copy'])

const isCopied = ref(false)
let timeoutId: number | null = null

// Compute the class based on the isCopied state
const buttonClass = computed(() => (isCopied.value ? 'copied' : 'not-copied'))

// Compute the icon component based on the isCopied state
const iconComponent = computed(() =>
	isCopied.value ? CheckBadgeIcon : DocumentDuplicateIcon
)

// Compute the button text based on the isCopied state
const buttonText = computed(() => (isCopied.value ? t('copyButton.copiedText') : props.name))

/**
 * Sets the isCopied state to true, emits the 'copy' event with the button index,
 * and resets the isCopied state to false after a 1200ms delay.
 * @return {void}
 */
function handleCopyButtonClick() {
	isCopied.value = true
	emits('copy', props.buttonIndex)

	if (timeoutId !== null) {
		clearTimeout(timeoutId)
	}

	timeoutId = window.setTimeout(() => {
		isCopied.value = false
	}, 1200)
}
</script>

<style scoped>
.copy-button-0 {
	--color: var(--color-0);
	--bg-color: var(--bg-color-0);
}

.copy-button-1 {
	--color: var(--color-1);
	--bg-color: var(--bg-color-1);
}

.copy-button-2 {
	--color: var(--color-2);
	--bg-color: var(--bg-color-2);
}

.copy-button-3 {
	--color: var(--color-3);
	--bg-color: var(--bg-color-3);
}

.copy-button-4 {
	--color: var(--color-4);
	--bg-color: var(--bg-color-4);
}

.copy-button-5 {
	--color: var(--color-5);
	--bg-color: var(--bg-color-5);
}

.copy-button-6 {
	--color: var(--color-6);
	--bg-color: var(--bg-color-6);
}

.copy-button-7 {
	--color: var(--color-7);
	--bg-color: var(--bg-color-7);
}

.copy-button-8 {
	--color: var(--color-8);
	--bg-color: var(--bg-color-8);
}

.copied {
	stroke: none;
	fill: #fff;
}

.group:hover .not-copied {
	stroke: var(--bg-color);
}

.group:hover .copied {
	stroke: none;
}
</style>
