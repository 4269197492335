<template>
	<div
		class="flex flex-wrap gap-2"
		v-editable="blok"
	>
		<div
			v-for="(image, idx) in imageSrc"
			:key="`image-${idx}`"
			class="overflow-hidden rounded-lg"
			:class="getImageClass(idx)"
		>
			<img
				class="h-full w-full object-cover"
				:src="image"
				:alt="props.blok?.assets[idx].meta_data.title"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { AssetStoryblok, SbImageListStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { computedAsync } from '@vueuse/core'
import { type PropType, computed } from 'vue'

const asset = useAssetStore()

const props = defineProps({
	blok: Object as PropType<SbImageListStoryblok>,
})

const imageSrc = computedAsync(() => {
	return Promise.all(
		props.blok?.assets.map(
			(image: AssetStoryblok) => image.filename && asset.getImageBlobUrl(image)
		)
	)
})

const imageLength = computed(() => imageSrc.value.length)

// Draw all images at half width except for the last one in and odd list
function getImageClass(index: number) {
	if (index < imageLength.value - 1 || imageLength.value % 2 === 0) {
		return 'w-half'
	}
}
</script>

<style scoped>
.w-half {
	width: calc(50% - 0.25rem);
}
</style>
