export enum BurialInternment {
	Mausoleum = 'mausoleum',
	LawnCrypt = 'lawnCrypt',
	GroundBurial = 'groundBurial',
}

export enum CremationInternment {
	Burial = 'burial',
	Niche = 'niche',
	FamilyPickUp = "familyPickUp",
	Scatter = 'scatter',
}