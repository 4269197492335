<!--
Presentational component to display a single custom cart item.
Emits events if the user tries to soft-delete it.
-->
<template>
	<div
		class="border-b border-slate-100"
		v-if="plan && item"
	>
		<SwipeableToggle
			:model-value="!item.isSoftDeleted"
			@update:model-value="$event ? $emit('undelete') : $emit('delete')"
		>
			<div
				class="grid w-full grid-cols-[max-content_minmax(0,1fr)_max-content] grid-rows-1 items-center gap-4 overflow-hidden"
				:class="{
					'is-soft-deleted': item.isSoftDeleted,
					'is-negative-price': item.price && item.price < 0,
				}"
			>
				<!-- Hide icon for non-discount items but take up space -->
				<GiftIcon
					class="h-10 w-10 object-cover text-primary-green-600"
					:class="{
						'opacity-0': item.price >= 0,
					}"
				/>

				<div class="flex-1">
					<div class="flex items-center">
						<div
							class="text-sm font-bold"
							:class="{
								'text-slate-400 line-through': item.isSoftDeleted,
								'!text-primary-green-600': isDiscount && !item.isSoftDeleted,
								'text-precoa-blue-700': !item.isSoftDeleted && !isDiscount,
							}"
						>
							{{ item.title }}
						</div>
						<ShieldCheckIcon
							v-if="item.guaranteed"
							class="ml-2 h-5 w-5 text-primary-green-600"
						/>
					</div>
					<div
						class="truncate text-sm"
						:class="[
							item.isSoftDeleted
								? 'text-slate-400 line-through'
								: 'text-slate-900',
						]"
					>
						{{ item.description }}
					</div>
				</div>
				<PriceDetailsDisplay
					:price-details="priceDetails"
					:deleted="item.isSoftDeleted"
				/>
			</div>
		</SwipeableToggle>
	</div>
</template>

<script setup lang="ts">
import PriceDetailsDisplay from './PriceDetailsDisplay.vue'
import Plan from '@/classes/Plan'
import SwipeableToggle from '@/components/common/SwipeableToggle.vue'
import { type PricingDetails } from '@/mixins/merchandise'
import type { CustomCartItem } from '@/types'
import { GiftIcon, ShieldCheckIcon } from '@heroicons/vue/24/solid'
import { type PropType, computed } from 'vue'

const props = defineProps({
	plan: Object as PropType<Plan>,
	item: Object as PropType<CustomCartItem>,
})

defineEmits(['delete', 'undelete'])

const isDiscount = computed(() => props.item?.price && props.item.price < 0)

const priceDetails = computed<PricingDetails | undefined>(() => {
	if (!props.item) return undefined

	return {
		price: props.item.price,
		originalPrice: props.item.price,
		guaranteed: props.item.guaranteed ? props.item.price : 0,
		nonGuaranteed: props.item.guaranteed ? 0 : props.item.price,
	}
})
</script>
