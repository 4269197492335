import { rootClasses } from './formkit.theme'
import disableAutofillPlugin from './src/plugins/disableAutofillPlugin'
import {
	createAutoAnimatePlugin,
	createAutoHeightTextareaPlugin,
} from '@formkit/addons'
import type { FormKitOptions } from '@formkit/core'
import { en, es, vi } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { createProPlugin, inputs } from '@formkit/pro'
import { defaultConfig } from '@formkit/vue'

const formKitPro = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_KEY, inputs)

function getGlobalSettings() {
	return {
		locales: { en, es, vi },
		locale: 'en',
		config: {
			rootClasses,
		},
		plugins: [
			createAutoAnimatePlugin(),
			createAutoHeightTextareaPlugin(),
			formKitPro,
			disableAutofillPlugin,
		],
		icons: {
			...genesisIcons,
		},
	}
}

const config: FormKitOptions = defaultConfig(getGlobalSettings())

export default config

export function localizedConfig(locale: string): FormKitOptions {
	const globalConfig = getGlobalSettings()
	globalConfig.locale = locale

	return defaultConfig(globalConfig)
}
