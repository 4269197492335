<!-- Input to pick between the different plans -->
<template>
	<div class="mb-1 flex h-14 w-full flex-row items-center justify-start gap-4">
		<div
			v-for="(plan, index) in sessionStore.activeSession?.plans"
			:key="index"
			:value="index"
			class="cursor-pointer rounded-md px-3 py-2 text-base"
			data-test-id="selection-cards-tab"
			@click="activePlanIndex = index"
			:class="[
				activePlanIndex === index
					? 'bg-blue-50 font-bold text-precoa-blue-700'
					: 'font-normal',
			]"
		>
			{{ `${plan.firstName}${plan.lastName ? ` ${plan.lastName[0]}.` : ''}` }}
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSessionStore } from '@/stores/session'

const activePlanIndex = defineModel<number>()

const sessionStore = useSessionStore()
</script>