export class UnauthorizedError extends Error {
	constructor(message: string) {
		super(message)
		this.name = 'UnauthorizedError'
	}
}

export function isUnauthorizedError(
	error: unknown
): error is UnauthorizedError {
	return error instanceof UnauthorizedError
}
