<!-- 
Logical component to draw a radio group of the available decks in the content store 
-->
<template>
	<RadioGroup v-model="value">
		<DeckRepeater v-slot="{ deck, renderDeckThumbnail }">
			<RadioGroupOption
				v-slot="{ checked }"
				:value="deck.uniqueId"
				:key="deck.uniqueId"
				class="inline-block cursor-pointer"
			>
				<component
					:is="renderDeckThumbnail()"
					:selected="checked"
					:inactive="value && !checked"
				/>
			</RadioGroupOption>
		</DeckRepeater>
	</RadioGroup>
</template>

<script setup lang="ts">
import DeckRepeater from './DeckRepeater.vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'

const value = defineModel<string>()
</script>
