<!-- Component for rendering a list item 'slide' with a video-->
<template>
	<div
		class="relative h-full w-full"
		v-if="slide?.videoUrl"
	>
		<video
			:src="videoSrc"
			autoplay
			muted
			playsinline
			class="h-full w-full object-cover"
		></video>
	</div>
	<div v-else>No video available</div>
</template>

<script setup lang="ts">
import type { ListItemVideoSlideStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { computedAsync } from '@vueuse/core'
import { type PropType } from 'vue'

const asset = useAssetStore()

const props = defineProps({
	slide: Object as PropType<ListItemVideoSlideStoryblok>,
})

/** Computed to get the blob url of cached video */
const videoSrc = computedAsync(() =>
	props.slide?.videoUrl ? asset.getVideoBlobUrl(props.slide) : ''
)
</script>
