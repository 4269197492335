<template>
	<button
		@click="handleCopyButtonClick"
		class="group inline-flex items-center justify-center gap-1 rounded-full bg-[--color] px-4 py-1 text-center text-base font-medium text-white hover:bg-[--bg-color]"
	>
		<component
			:is="iconComponent"
			class="h-6 w-6 text-[#0C58B7]"
		/>
		<span
			v-if="isCopied"
			class="text-[12px] text-[#0C58B7]"
		>
			COPIED!
		</span>
	</button>
</template>

<script setup lang="ts">
import DocumentDuplicateIcon from '@heroicons/vue/24/outline/DocumentDuplicateIcon'
import CheckBadgeIcon from '@heroicons/vue/24/solid/CheckBadgeIcon'
import { ref, computed } from 'vue'

const props = defineProps({
	textToCopy: {
		type: String,
		required: true,
	},
})

const isCopied = ref(false)

// Compute the icon component based on the isCopied state
const iconComponent = computed(() =>
	isCopied.value ? CheckBadgeIcon : DocumentDuplicateIcon
)

/**
 * Sets the isCopied state to true, emits the 'copy' event with the button index,
 * and resets the isCopied state to false after a 1200ms delay.
 * @return {void}
 */
function handleCopyButtonClick(event: MouseEvent) {
	event.stopPropagation()

	navigator.clipboard.writeText(props.textToCopy)
	isCopied.value = true

	// unset isCopied after 3 seconds
	setTimeout(() => {
		isCopied.value = false
	}, 3000)
}
</script>

<style scoped></style>
