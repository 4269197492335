<!-- Component that renders an accordion with collapsible sections -->
<template>
	<div class="flex w-full flex-col space-y-3 overflow-y-auto px-5 pb-4">
		<div
			v-for="section in sections"
			:key="section.key"
			class="w-full"
		>
			<!-- Section Header -->
			<div
				class="min-h-[48px] w-full cursor-pointer rounded-md bg-slate-100 px-3 py-4 font-bold uppercase text-slate-500"
				@click="toggleSection(section.key)"
			>
				{{ section.title }}
			</div>

			<!-- Collapsible Content with Dynamic Transition -->
			<Transition
				class="transition-all duration-300"
				enter-from-class="max-h-0 opacity-0"
				enter-to-class="max-h-[800px] opacity-100"
				leave-from-class="max-h-[800px] opacity-100"
				leave-to-class="max-h-0 opacity-0"
			>
				<div
					v-if="openSections[section.key]"
					class="my-3 overflow-hidden"
				>
					<div class="accordion-content">
						<div v-if="section.items && section.items.length">
							<div
								v-for="item in section.items"
								:key="item.id"
								@click="updateSelectedItem(item)"
								:class="{ 'bg-slate-50': selectedItem?.id === item.id }"
								class="grid w-full grid-cols-[minmax(5%,1fr)_minmax(25%,1fr)_minmax(40%,2fr)_minmax(10%,1fr)_minmax(10%,1fr)] items-center gap-4 border-b border-slate-100 px-3 py-2 text-slate-500 last:border-b-0 hover:bg-slate-50"
							>
								<BlobImage
									class="max-h-[50px] w-full max-w-[50px]"
									:src="item.imageUrl"
									:alt="item.name"
								/>
								<div class="font-bold text-precoa-blue-700">
									{{ item.name }}
								</div>
								<div
									class="overflow-hidden truncate text-ellipsis whitespace-nowrap"
								>
									{{ item.description }}
								</div>
								<PlanAvatarList
									:plans="productPlans[item.id]"
									filled
									class="justify-end"
								/>
								<div class="text-right">{{ formatPrice(item.price) }}</div>
							</div>
						</div>
						<div v-else>None at this time</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import PlanAvatarList from '../presentation/PlanAvatarList.vue'
import type { PbLineItem } from '@/apiTypes'
import type { IndexedPlan } from '@/classes/Plan'
import { useMerchandise } from '@/mixins/merchandise'
import { ref, defineProps } from 'vue'

const { formatPrice } = useMerchandise()

interface Section {
	key: string
	title: string
	items: any[]
}

const props = defineProps<{
	sections: Section[]
	productPlans: Record<string, IndexedPlan[]>
}>()

const selectedItem = defineModel<PbLineItem | null>()

/**
 * Make first section open by default.
 */
const openSections = ref<Record<string, boolean>>(
	props.sections.reduce(
		(acc, section, index) => {
			acc[section.key] = index === 0
			return acc
		},
		{} as Record<string, boolean>
	)
)

/**
 * Toggle the open state of a section.
 */
const toggleSection = (key: string) => {
	openSections.value[key] = !openSections.value[key]
}

// Update selectedItem when an item is clicked
const updateSelectedItem = (item: any) => {
	selectedItem.value = item
}
</script>
