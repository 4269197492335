<template>
	<div
		class="flex h-full w-full flex-col items-center justify-center"
		v-if="blok && activePlan"
		v-editable="blok"
	>
		<!-- Tab list to select a plan -->
		<TabsPlans v-model="activePlanIndex" />

		<!-- Tab content: show options for the plans -->
		<div
			class="w-full"
			v-for="(plan, index) in sessionStore.activeSession?.plans"
			:key="index"
		>
			<div
				class="grid grid-cols-1 gap-3"
				:class="[activePlanIndex !== index && 'hidden']"
			>
				<component
					v-for="card in blok.cards"
					:key="card._uid"
					data-test-id="selection-cards-option"
					:is="card.component"
					:blok="card"
					:plan="plan"
					:index="index"
					v-model="activePlan[planProperty]"
				>
				</component>
			</div>
		</div>
	</div>
</template>

<style scoped>
formkit-custom-target .formkit-wrapper {
	height: 100%;
}
</style>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import TabsPlans from '@/components/presentation/TabsPlans.vue'
import type { SelectionCardsStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { computed, ref } from 'vue'

const props = defineProps<{
	blok: SelectionCardsStoryblok
}>()

const activePlan = computed<Plan | undefined>(() => {
	return sessionStore.activeSession?.plans[activePlanIndex.value]
})
const activePlanIndex = ref<number>(0)
const sessionStore = useSessionStore()
const planProperty = computed<keyof Plan>(() => {
	return props.blok?.planProperty
})
</script>
