import Plan from '@/classes/Plan'
import { useCrmStore } from '@/stores/crm'
import type { SessionData } from '@/types'
import { faker } from '@faker-js/faker'

/** Class to import data from an appointment into the session builder */
export default class CrmAppointmentImporter {
	private appointmentId: string

	public constructor(appointmentId: string) {
		this.appointmentId = appointmentId
	}

	public async import(): Promise<Partial<SessionData>> {
		const sessionData: Partial<SessionData> = {
			plans: [{}],
		}
		const crmStore = useCrmStore()

		let appt = crmStore.findAppointment(this.appointmentId)

		if (!appt) {
			console.debug(
				'Could not find appointment locally with ID: ' + this.appointmentId
			)

			const result = await crmStore.fetchAppointment(this.appointmentId)
			appt = result.data

			console.debug('Loaded appt from API', appt)
		}

		/** Session-wide data */
		sessionData.appointmentId = appt.id

		if (appt.funeralHome?.data.groupId) {
			sessionData.crmFhGroupId = appt.funeralHome.data.groupId
		}

		/** Plan data */
		const lead = appt.lead?.data

		if (!lead) {
			console.error('Loaded appointment has no lead')
			return sessionData
		}

		const firstNames = lead.firstname
			.replace(' And ', ' & ')
			.replace(' and ', ' & ')
			.split(' & ')

		const primary = new Plan()
		primary.firstName = firstNames[0].trim()
		primary.lastName = lead.lastname
		primary.address = lead.address
		primary.city = lead.city
		primary.state = lead.state
		primary.zip = lead.zip
		primary.leadId = lead.id
		primary.email = lead.email
		primary.phone = lead.primaryPhone

		sessionData.plans = [primary]

		// If a second first name is found create a separate plan for it
		if (firstNames.length > 1) {
			const secondary = new Plan(primary)
			secondary.id = faker.string.uuid()
			secondary.firstName = firstNames[1].trim()
			sessionData.plans.push(secondary)
		}

		return sessionData
	}
}
