<template>
	<video
		class="h-full w-full object-cover"
		ref="videoRef"
		muted
	></video>
</template>

<script setup lang="ts">
import { localNavigation } from '@/mixins/localNavigation'
import type { SbVideoStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { computedAsync, useMediaControls } from '@vueuse/core'
import { Swiper } from 'swiper'
import { type PropType, type Ref, ref } from 'vue'

const asset = useAssetStore()

let currentVideoIndex = 0
const videoRef = ref()
let currentTime: Ref

// NOTE WHEN WE GET THIS AS AN INPUT WE NEED TO SORT
const props = defineProps({
	blok: Object as PropType<SbVideoStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
})

const { blok, parentSwiper, slideIndex } = props

const videos = computedAsync(async () => {
	if (!blok?.videos) {
		return []
	}

	const values = await Promise.all(
		blok.videos.map(async (vid) => ({
			url: await asset.getVideoBlobUrl(vid),
		}))
	)

	return values
})

const { changeAllowNext, changeAllowPrev } = localNavigation({
	onNext,
	onPrev,
	onSlideChange,
	parentSwiper,
	slideIndex,
	swipingElRef: videoRef,
})

/**
 * Play the video at the currently selected index.
 *
 * If the index is the first or last video (on the current slide), this will also update the
 * `changeAllowNext` and `changeAllowPrev` callbacks to prevent the user from
 * swiping to the next or previous slide as needed.
 */
const playVideo = () => {
	if (!videos.value) return

	const { playing, currentTime: currentTimeVid } = useMediaControls(videoRef, {
		src: videos.value[currentVideoIndex].url,
	})
	currentTime = currentTimeVid
	playing.value = true
	if (currentVideoIndex === 0) {
		changeAllowNext(false)
		changeAllowPrev(true)
	} else if (currentVideoIndex === videos.value.length - 1) {
		changeAllowNext(true)
		changeAllowPrev(false)
	} else {
		changeAllowNext(false)
		changeAllowPrev(false)
	}
}

function onNext() {
	if (!videos.value) return
	if (currentVideoIndex + 1 < videos.value.length) currentVideoIndex++
	playVideo()
}

function onPrev() {
	// so we can skip back if we try within small period
	const skipBackTime = 1.5
	const time = currentTime.value
	if (time - skipBackTime <= 0 && currentVideoIndex !== 0) currentVideoIndex--
	playVideo()
}

/**
 * When the slide changes, reset the current video index,
 * enable swiping to the next and previous slides, and call `playVideo`.
 */
 function onSlideChange(swiper: Swiper) {
	if (!swiper || swiper.activeIndex !== slideIndex) {
		return
	}
	if (swiper.activeIndex === slideIndex) {
		currentVideoIndex = 0
		changeAllowNext(true)
		changeAllowPrev(true)
		playVideo()
	}
}
</script>
