<!-- Demo page for the different types of selection cards -->
<template>
	<div class="m-8">
		<h2 class="mt-16 text-2xl">Meeting Location</h2>
		<SbSelectionCards :blok="meetingLocationBlok" />
		<div>{{ session.activeSession?.plans.map((p) => p.placeOfGathering) }}</div>

		<h2 class="mt-16 text-2xl">Boolean</h2>
		<SbSelectionCards :blok="boolBlok" />
		<div>{{ session.activeSession?.plans.map((p) => p.postServiceMeal) }}</div>

		<h2 class="mt-16 text-2xl">Viewing Preference</h2>
		<SbSelectionCards :blok="viewingBlok" />
		<div>
			{{ session.activeSession?.plans.map((p) => p.viewingPreference) }}
		</div>
	</div>
</template>

<script setup lang="ts">
import SbSelectionCards from '@/components/blocks/SelectionCards/SbSelectionCards.vue'
import { ViewingOption } from '@/enums/ViewingOption'
import type { SelectionCardsStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import SessionFactory from '@factories/SessionFactory'
import { AssetFactory } from '@factories/Storyblok/AssetFactory'
import { RichTextFactory } from '@factories/Storyblok/RichTextFactory'
import { onMounted } from 'vue'

const session = useSessionStore()

const meetingLocationBlok: SelectionCardsStoryblok = {
	planProperty: 'placeOfGathering',
	_uid: '0',
	component: 'selection-cards',
	cards: [
		{
			_uid: '1',
			prompt: RichTextFactory('Select the funeral home location'),
			component: 'selection-card-funeral-home',
		},
		{
			value: 'Place of Worship',
			_uid: '2',
			image: AssetFactory(),
			component: 'selection-card-text',
			isEditable: true,
		},
		{
			value: 'Other',
			_uid: '3',
			image: AssetFactory(),
			component: 'selection-card-text',
			isEditable: true,
		},
	],
}

const boolBlok: SelectionCardsStoryblok = {
	planProperty: 'postServiceMeal',
	_uid: '0',
	component: 'selection-cards',
	cards: [
		{
			label: 'Yes, I would like a post service meal',
			_uid: '1',
			image: AssetFactory(),
			component: 'selection-card-bool',
		},
	],
}

const viewingBlok: SelectionCardsStoryblok = {
	planProperty: 'viewingPreference',
	_uid: '0',
	component: 'selection-cards',
	cards: [
		{
			value: ViewingOption.Public,
			label: "Yes, let's do a public viewing",
			_uid: '1',
			image: AssetFactory(),
			component: 'selection-card-viewing-option',
		},
		{
			value: ViewingOption.Private,
			label: "Yes, let's do a private viewing",
			_uid: '2',
			image: AssetFactory(),
			component: 'selection-card-viewing-option',
		},
	],
}

onMounted(() => {
	// Start a new fake session
	session.activeSession = SessionFactory(2, {})
})
</script>
