<!-- A control for the quantity of a line item in a plan -->
<template>
	<div class="w-full">
		<div
			v-if="plan && product"
			class="flex w-full items-center justify-between rounded-lg border-2 bg-white p-3 shadow-md"
			:class="
				cartItem !== undefined
					? 'border-primary-green-500'
					: 'border-transparent'
			"
		>
			<div>
				<input
					type="checkbox"
					class="h-5 w-5 cursor-pointer rounded border border-solid border-gray-300 text-primary-green-400 focus:ring-primary-green-400"
					:checked="cartItem !== undefined"
					@change="toggleSelect"
				/>
				<!-- Name -->
				<span class="ml-3 text-sm text-slate-900">
					{{ plan.firstName }} {{ plan.lastName }}
				</span>
			</div>
			<!-- Counter -->
			<div
				v-if="hasCounter"
				class="flex w-1/3 items-center justify-between"
			>
				<button
					class="px-2 py-1 text-3xl text-slate-400"
					:disabled="cartItem === undefined || cartItem.quantity === 0"
					@click="updateCount(-1)"
				>
					&minus;
				</button>
				<span
					class="w-6 text-center font-semibold"
					:class="cartItem?.quantity ? 'text-slate-900' : 'text-slate-400'"
				>
					{{ cartItem?.quantity ?? 0 }}
				</span>
				<button
					class="px-2 py-1 text-3xl text-slate-400"
					@click="updateCount(1)"
				>
					+
				</button>
			</div>
			<!-- Price -->
			<div
				v-else
				class="flex w-1/3 items-center justify-end text-sm font-semibold text-precoa-blue-700"
			>
				<PriceDetailsDisplay :price-details="priceDetails" />
			</div>
		</div>
		<div v-else>Missing plan or product</div>
	</div>
</template>

<script setup lang="ts">
import CartPrice from './CartPrice.vue'
import PriceDetailsDisplay from './PriceDetailsDisplay.vue'
import type { PbLineItem } from '@/apiTypes'
import type Plan from '@/classes/Plan'
import { useMerchandise } from '@/mixins/merchandise'
import { computed, type PropType } from 'vue'

const props = defineProps({
	plan: Object as PropType<Plan>,
	product: Object as PropType<PbLineItem>,
	hasCounter: Boolean,
})

const { findLineItemInPlan, formatPrice, getPlanLineItemPrice } =
	useMerchandise()

/** Toggle the presence of the line item in the plan */
const toggleSelect = () => {
	if (undefined === cartItem.value) {
		props.plan!.addCartProduct(props.product!.id)
	} else {
		props.plan!.delete(cartItem.value)
	}
}

/** Increment or decrement the quantity of the line item in the plan */
const updateCount = (inc: number) => {
	props.plan!.addCartProduct(
		props.product!.id,
		inc + (cartItem.value?.quantity ?? 0)
	)
}

/** The cartItem representing the line item in the plan */
const cartItem = computed(() => {
	return findLineItemInPlan(props.product as PbLineItem, props.plan as Plan)
})

const priceDetails = computed(() => {
	if (!props.plan || !props.product) return undefined
	return getPlanLineItemPrice(props.plan as Plan, props.product.id)
})
</script>
