import { usePresentationController } from '@/stores/presentationController'
import { onKeyStroke, useSwipe } from '@vueuse/core'
import { Swiper } from 'swiper'
import { onBeforeUnmount, onMounted, type Ref, ref, watch } from 'vue'

/*
 * localNavigation
 * This allows a slide to prevent the parent swiper instance from swiping away
 * from a slide that has local navigation it needs to have done.
 * Parameters
 * slideIndex - is the index of the parent swiper so that we can know when to stop
 *    the parents swipping
 * parentSwiper - this is provided as a prop by the presentation
 * parentDiv - needed to determine swiping also provided as a prop
 * onNext - a callback given to tell the consuming component when to go next
 * onPrev - a callback given to tell the consuming component when to go back
 * onSlideChange - callback to be used for determining when they slide in on the
 *    correct slide to start local behavior
 *
 * returned
 * changeAllowNext & changeAllowPrev - receives a boolean so the consuming
 *    component can set weather we can be allowed to navigate before and after
 **/
export function localNavigation(opts: {
	slideIndex?: number
	swipingElRef?: Ref<HTMLElement>
	parentSwiper?: Swiper
	onNext: () => void
	onPrev: () => void
	onSlideChange: (a: any) => void
}) {
	const {
		slideIndex,
		swipingElRef,
		parentSwiper,
		onNext,
		onPrev,
		onSlideChange,
	} = opts
	const controller = usePresentationController()

	/**
	 * Track if we are the active slide or not.
	 * We only update it after the epoch because we get keyboard events
	 * after swiper already moved us to the next slide
	 */
	const delayedIsActiveSlide = ref(false)
	watch(
		() => controller.activeSlideIndex,
		(value) => {
			setTimeout(() => {
				delayedIsActiveSlide.value = value === slideIndex
			}, 0)
		}
	)

	if (swipingElRef) {
		useSwipe(swipingElRef, {
			onSwipeEnd(e, direction) {
				// We only want to allow this if we are on the correct slide
				if (controller.activeSlideIndex !== slideIndex) return
				if (direction === 'left') onNext()
				if (direction === 'right') onPrev()
			},
		})
	}

	/*
	 * Only trigger keyboard navigation if we were on this slide
	 * when the user pressed the key
	 */
	onKeyStroke(['ArrowLeft', 'ArrowRight'], ({ code }) => {
		// We only want to allow this if we are on the correct slide
		if (!delayedIsActiveSlide.value) return
		if (code === 'ArrowRight') onNext()
		if (code === 'ArrowLeft') onPrev()
	})

	onMounted(() => {
		if (slideIndex !== undefined && parentSwiper) {
			controller.setupLocalSlides(slideIndex, parentSwiper)
			parentSwiper.on('slideChange', onSlideChange)
		}
	})

	onBeforeUnmount(() => {
		if (parentSwiper) parentSwiper.off('slideChange', onSlideChange)
	})

	function changeAllowNext(allowNext: boolean) {
		// We only want to allow this if we are on the correct slide
		if (!parentSwiper || parentSwiper.activeIndex !== slideIndex) return
		parentSwiper.allowSlideNext = allowNext
	}

	function changeAllowPrev(allowPrev: boolean) {
		// We only want to allow this if we are on the correct slide
		if (!parentSwiper || parentSwiper.activeIndex !== slideIndex) return
		parentSwiper.allowSlidePrev = allowPrev
	}

	return { changeAllowNext, changeAllowPrev }
}
