<!-- View for choosing a funeral home -->
<template>
	<div class="relative h-full w-full overflow-hidden">
		<div
			class="h-full w-full transition-transform duration-1000"
			:class="[showContent ? '-translate-x-1/8' : 'translate-x-0']"
		>
			<SbMedia
				v-if="backgroundVideo"
				:blok="backgroundVideo"
				:active="active"
				:un-stoppable="true"
				class="h-full w-full object-cover"
			></SbMedia>
		</div>
		<div
			class="absolute left-full top-0 h-full w-1/4 overflow-y-auto bg-white p-12 transition-transform duration-1000"
			:class="[showContent ? '-translate-x-full' : 'translate-x-0']"
		>
			<!-- FH picker -->
			<FormKit
				type="dropdown"
				v-model="selectedLocationId"
				:options="funeralHomeOptions"
				:deselect="false"
			/>

			<!-- FH location media -->
			<component
				v-for="item in fhContent"
				:key="item._uid"
				:is="item.component"
				:blok="item"
				class="my-8 w-full text-base font-light leading-5 text-slate-900"
			>
			</component>
		</div>
	</div>
</template>

<script setup lang="ts">
import SbMedia from '../blocks/SbMedia.vue'
import type { V4FuneralHome } from '@/apiTypes'
import { DeckFlavor } from '@/enums/Language'
import type {
	FuneralHomeMediaStoryblok,
	FuneralHomeIntroductionSlideStoryblok,
} from '@/sbTypes'
import { type FhLocationMedia, useContentStore } from '@/stores/content'
import { useSessionStore } from '@/stores/session'
import { Swiper } from 'swiper'
import { computed, watch, ref, type PropType, onBeforeUnmount } from 'vue'

const showContent = ref(false)
const sessionStore = useSessionStore()
let timeoutId: NodeJS.Timeout
const contentStore = useContentStore()

const props = defineProps({
	blok: Object as PropType<FuneralHomeIntroductionSlideStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

/** The ID of the selected funeral home */
const selectedLocationId = computed<string | undefined>({
	get() {
		return sessionStore.activeFh?.id ?? ''
	},
	set(value) {
		if (sessionStore.activeSession) {
			sessionStore.activeSession.FuneralHomeLocationId = value
		}

		// Update all plans to match
		if (value) {
			sessionStore.activeSession?.plans.forEach((plan) => {
				plan.funeralHomeId = value
			})
		}
	},
})

/** The V4 Funeral Home object for the session */
const selectedLocation = computed<V4FuneralHome | undefined>(() => {
	return sessionStore.activeFh
})

/** The array of FHs the customers can choose from */
const funeralHomeOptions = computed<Record<string, string>>(() => {
	const options = contentStore.getFuneralHomeLocations(
		sessionStore.activeSession?.language ?? DeckFlavor.Default,
		{ crmGroupId: sessionStore.activeSession?.crmFhGroupId }
	)

	return options.reduce(
		(acc, option) => ({
			...acc,
			[option.id]: option.name,
		}),
		{}
	)
})

const backgroundVideo = computed(() => {
	// fetching video from activeMedia and selectedLocation first
	const video =
		sessionStore.activeFhMedia?.introBackground ?? props.blok?.background?.[0]

	// Force auto and loop to true so it auto play and loop.
	if (video) {
		video.autoplay = true
		video.loop = true
	}
	return video
})

/** Blurb and photos for the selected funeral home location */
const fhContent = computed<FuneralHomeMediaStoryblok['content']>(() => {
	const fhLocationMedia =
		selectedLocation.value && sessionStore.activeSession
			? contentStore.findMediaForFh(
					selectedLocation.value,
					sessionStore.activeSession.language
				)
			: undefined
	return fhLocationMedia?.location?.content ?? props.blok?.content
})

watch(
	() => props.active,
	(active) => {
		if (active) {
			// show content when it reaches second slide and after 1.5 seconds
			timeoutId = setTimeout(() => {
				showContent.value = true
			}, 1500)
		}

		// If we leave this slide, keep the content visible so the user
		// doesn't have to wait again
	}
)

/** Cleanup before leaving */
onBeforeUnmount(() => {
	clearTimeout(timeoutId)
})
</script>
