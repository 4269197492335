<!-- Component to select multiple languages at once -->
<template>
	<fieldset>
		<legend class="sr-only">Notifications</legend>
		<div class="space-y-2">
			<div
				v-for="lang in Language"
				:key="lang"
				class="relative flex items-start"
			>
				<div class="flex h-6 items-center">
					<input
						:id="'lang-option-' + lang"
						name="comments"
						type="checkbox"
						:value="lang"
						v-model="selected"
						class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
					/>
				</div>
				<div class="ml-3 text-sm leading-6">
					<label
						:for="'lang-option-' + lang"
						class="font-medium text-gray-900"
						>{{ labels[lang] }}</label
					>
				</div>
			</div>
		</div>
	</fieldset>
</template>

<script setup lang="ts">
import { Language, labels } from '@/enums/Language'

const selected = defineModel<Language[]>()
</script>
