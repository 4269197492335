<!-- An SVG that looks like this: > -->
<template>
	<svg
		width="10"
		height="16"
		viewBox="0 0 10 16"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.443823 13.5462L6.33315 7.992L0.443823 2.43675C0.30337 2.30555 0.191733 2.14866 0.115504 1.97534C0.0392745 1.80203 7.06291e-08 1.61581 6.24058e-08 1.42768C5.41824e-08 1.23955 0.0392745 1.05333 0.115503 0.880013C0.191733 0.706699 0.30337 0.549806 0.443823 0.418602C0.73008 0.150237 1.11433 -4.8709e-08 1.51446 -6.6199e-08C1.91458 -8.3689e-08 2.29883 0.150237 2.58509 0.418602L9.55561 6.99039C9.69623 7.12161 9.80801 7.27857 9.88434 7.45198C9.96067 7.6254 10 7.81174 10 8C10 8.18826 9.96067 8.3746 9.88434 8.54802C9.80801 8.72143 9.69623 8.87839 9.55561 9.00961L2.58509 15.5814C2.29884 15.8498 1.91458 16 1.51446 16C1.11433 16 0.73008 15.8498 0.443823 15.5814C0.304458 15.4478 0.193894 15.2892 0.118457 15.1146C0.0430196 14.94 0.0041901 14.7528 0.00419009 14.5638C0.00419009 14.3748 0.0430196 14.1876 0.118457 14.013C0.193894 13.8384 0.304458 13.6797 0.443823 13.5462Z"
		/>
	</svg>
</template>
