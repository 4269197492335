<!-- Card with a quote of the plan's most important reason -->
<template>
	<div
		class="relative flex max-h-[600px] min-h-full min-w-[400px] max-w-[500px] flex-1 flex-col text-center"
	>
		<PlanAvatar
			:index="index"
			:plan="plan"
			:filled="true"
			class="absolute -top-16 h-32 w-32 self-center !text-5xl"
		/>

		<div
			class="flex grow flex-col items-center justify-center border border-solid border-gray-100 bg-white px-8 pb-9 pt-16 shadow-2xl"
		>
			<div class="flex flex-col items-center font-serif">
				<span class="mt-4 text-6xl leading-[.5] text-yellow-400">“</span>
				<div
					:class="getDynamicFontSize(plan.mostImportantReason)"
					class="text-slate-900"
				>
					{{ plan.mostImportantReason }}
				</div>
				<span class="mt-9 text-6xl leading-[.5] text-yellow-400">”</span>
			</div>
			<div class="flex flex-col items-center">
				<div
					v-if="plan.firstName || plan.lastName"
					class="text-late-900 mb-3 font-sans text-xs"
				>
					– {{ plan.firstName }} {{ plan.lastName }}
				</div>
				<PlanAvatar
					:index="index"
					:plan="plan"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { type PropType } from 'vue'

defineProps({
	plan: {
		type: Object as PropType<{
			firstName: string
			lastName: string
			mostImportantReason: string
		}>,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
})

/**
 * Dynamically determine the font size class based the character count of the string.
 * @param {string}
 * @returns {string} the font size class
 */
const getDynamicFontSize = (mostImportantReason: string): string => {
	const length = mostImportantReason.length
	let fontSizeClass

	if (length <= 10) {
		fontSizeClass = 'text-[2.25rem]'
	} else if (length <= 20) {
		fontSizeClass = 'text-[1.75rem]'
	} else if (length <= 100) {
		fontSizeClass = 'text-[1.5rem] overflow-text'
	} else if (length <= 200) {
		fontSizeClass = 'text-[1.1rem] overflow-text'
	} else {
		fontSizeClass = 'text-[1.1rem] overflow-text'
	}

	return fontSizeClass
}
</script>

<style scoped>
.overflow-text {
	@apply text-overflow-shadow max-h-[170px] overflow-auto;
}
/* This text-overflow-shadow idea comes from https://lea.verou.me/blog/2012/04/background-attachment-local/ */
.text-overflow-shadow {
	background:
	  /* Shadow covers */
		linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		/* Shadows with radial blur effect */
			radial-gradient(
				farthest-side at 50% 100%,
				rgba(0, 0, 0, 0.2),
				rgba(0, 0, 0, 0)
			)
			0 100%;

	background-repeat: no-repeat;
	background-color: white;
	background-size:
		100% 20px,
		100% 20px,
		100% 20px,
		100% 20px;

	background-attachment: local, local, scroll, scroll;
}
</style>
