<!--
Atomic component to render an AssetStoryblok image offline
-->
<template>
	<img
		v-editable="blok"
		:src="imageSrc"
		:alt="blok?.alt"
		draggable="false"
	/>
</template>

<script setup lang="ts">
import type { AssetStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { computedAsync } from '@vueuse/core'
import { type PropType } from 'vue'

const asset = useAssetStore()

const props = defineProps({
	blok: {
		type: Object as PropType<AssetStoryblok>,
		required: true,
	},
})

/** Computed to get the blob url of cached image */
const imageSrc = computedAsync(
	() => props.blok?.filename && asset.getImageBlobUrl(props.blok)
)
</script>
