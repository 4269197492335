import { Session } from '@/classes/Session'
import type { SessionData } from '@/types'
import { faker } from '@faker-js/faker'

export default function SessionFactory(
	planCount: Number,
	props: Partial<SessionData> = {}
): Session {
	const lastName = faker.person.lastName()
	const plans = [...Array(planCount).keys()].map(() => ({
		id: faker.string.uuid(),
		firstName: faker.person.firstName(),
		lastName: lastName,
		address: faker.location.streetAddress(),
		city: faker.location.city(),
		state: faker.location.state(),
		zip: faker.location.zipCode(),
	}))

	return new Session({
		plans,
		...props,
	})
}
