<!-- 
Logical component to list thumbnails for all decks for a given language.
Order matches the order in Storyblok. 
-->
<template>
	<div
		class="grid h-full grid-cols-[100%] grid-rows-[min-content_minmax(0,1fr)] gap-5 overflow-hidden"
	>
		<LangPicker
			class="ml-3 mr-3"
			v-model="language"
		/>
		<div class="mr-3 h-full w-full overflow-y-auto overflow-x-visible">
			<div class="h-auto w-full space-y-5 overflow-x-visible pb-5 pl-3">
				<template
					v-for="deck in filteredDecks"
					:key="deck.uniqueId"
				>
					<slot
						:deck="deck"
						:render-deck-thumbnail="() => renderDeckThumbnail(deck)"
					>
						<component :is="renderDeckThumbnail(deck)" />
					</slot>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import DeckThumbnail from './DeckThumbnail.vue'
import LangPicker from '@/components/forms/LangPicker.vue'
import { flavorLanguages, Language } from '@/enums/Language'
import { useContentStore } from '@/stores/content'
import type { DeckPreview } from '@/types'
import { ref, computed, h, inject } from 'vue'

const content = useContentStore()
const language = inject('language', ref(Language.English))

const filteredDecks = computed(() => {
	return content.availableDecks.filter(
		(deck) => flavorLanguages[deck.lang] === language.value
	)
})

/** Render function so parent component can choose where to put the thumbnail in the slot */
function renderDeckThumbnail(deck: DeckPreview) {
	return h(DeckThumbnail, {
		deck: deck,
		imageUrl: deck.thumbnailUrl,
		title: deck.title,
		downloadProgress:
			(deck.downloadProgress && deck.downloadProgress < 1) ||
			deck.downloadProgress === 0
				? deck.downloadProgress
				: undefined,
	})
}
</script>
