<!-- A component that renders a list of quote cards for each plan that has a most important reason filled out -->
<template>
	<div
		class="flex max-w-full snap-x gap-4 overflow-hidden overflow-x-auto px-8 pb-20 pt-20"
	>
		<CardQuote
			v-for="(plan, index) in filteredPlans"
			class="snap-center"
			:key="index"
			:plan="plan"
			:index="index"
		/>
	</div>
</template>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import CardQuote from '@/components/presentation/CardQuote.vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	plans: {
		type: Array as PropType<Plan[]>,
		default: () => [],
	},
})

/**
 * Filter out plans that don't have a most important reason filled out
 */
const filteredPlans = computed(() => {
	return props.plans.filter((plan) => plan.mostImportantReason)
})
</script>
