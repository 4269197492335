import { useSessionStore } from './session'
import type {
	SlideAbstract,
	FuneralHomeLogoSlideStoryblok,
	FuneralHomeIntroductionSlideStoryblok,
	FuneralHomeMediaStoryblok,
} from '@/sbTypes'
import { kebabCase } from 'change-case'
import { defineStore } from 'pinia'
import { Swiper } from 'swiper'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

/**
 * Pinia store to handle the state of the slide presentation,
 * agnostic of whatever data the slides are dealing with.
 *
 * Not responsible for determining the flow of the presentation
 */
export const useFuneralHomeGroupMediaController = defineStore(
	'funeralHomeGroupMediaController',
	() => {
		const router = useRouter()
		const sessionStore = useSessionStore()

		const activeSlideIndex = ref(0)

		/**
		 * Reactive proxy to the swiper API.
		 * The Swiper library edits the state outside of our observation,
		 * so we must track data that we care about manually.
		 */
		const swiper = ref<Swiper>()

		const allSlides = computed(() => {
			const activeMedia = sessionStore.activeFHGroupMedia

			if (!activeMedia) return []
			// create a fake slide to interact with swiper
			const logo_slide: FuneralHomeLogoSlideStoryblok = {
				_uid: activeMedia.content._uid + '_logo_slide',
				title: activeMedia.name + ' Logo Slide',
				component: 'funeral-home-logo-slide',
				background: activeMedia.content.background,
				logo: activeMedia.content.logo,
				darkStartText: false,
			}

			// TODO: Should get the current location
			const exampleLocation: FuneralHomeMediaStoryblok | undefined =
				activeMedia.content.locations?.[0]

			const introduction_slide: FuneralHomeIntroductionSlideStoryblok = {
				_uid: activeMedia.content._uid + '_introduction_slide',
				title: activeMedia.name + ' Introduction Slide',
				component: 'funeral-home-introduction-slide',
				background: exampleLocation?.background,
				name: exampleLocation?.name,
				content: exampleLocation?.content,
			}

			return [logo_slide, introduction_slide] as SlideAbstract[]
		})

		/** The slide object we are looking at */
		const activeSlide = computed<SlideAbstract | undefined>(() => {
			return allSlides.value
				? allSlides.value[activeSlideIndex.value]
				: undefined
		})

		/** The name of the active slide */
		const activeSlideName = computed(() => {
			return activeSlide.value?.title
		})

		const gotoSlideIndex = (index: number) => {
			activeSlideIndex.value = index
		}

		const gotoNextSlide = () => {
			swiper.value?.slideNext() // just one since it does not have any more slide
		}

		/** Subscribe to the active slide index */
		watch(swiper, (swiper) => {
			if (!swiper) return
			swiper.on('slideChange', () => {
				activeSlideIndex.value = swiper.activeIndex
			})
		})

		const onSlideSubmit = () => {
			swiper.value?.slideNext()
			try {
				;(document.activeElement as HTMLElement).blur()
			} catch {
				// The active element was null or was an Element that was not HTMLElement
				// Ignore
			}
		}

		/** Temp example of leaving the presentation */
		const exit = () => router.push({ name: 'home' })

		return {
			activeSlideIndex,
			activeSlideName,
			activeSlide,
			allSlides,
			exit,
			onSlideSubmit,
			gotoSlideIndex,
			swiper,
			gotoNextChapter: gotoNextSlide,
			gotoNextSlide,
			gotoChapter: () => null,
		}
	}
)

export function getSlugFromName(name: string): string {
	// Convert string into kebab case
	return kebabCase(name)
}

export type FuneralHomeGroupMediaController = Omit<
	ReturnType<typeof useFuneralHomeGroupMediaController>,
	keyof ReturnType<typeof defineStore>
>
