<!--
Molecule for displaying an appointment in a grid.
If clicked, go to the new session view.
-->
<template>
	<router-link
		:to="{ name: 'newSession', query: { appointmentId: appointment.id } }"
		:class="[
			isCanceled ? 'text-slate-400 line-through' : '',
			event || campaignManager ? 'pointer-events-none cursor-not-allowed' : '',
		]"
	>
		<div class="ml-3 flex flex-row gap-3">
			<!-- Status -->
			<div
				v-if="status"
				class="flex-0"
			>
				<img
					class="flex-0 h-6 w-6"
					v-if="status.uploadStatus === 'complete'"
					src="/assets/icons/cloud-done.svg"
				/>
				<div v-else>{{ status?.uploadStatus }}</div>
			</div>
			<div v-if="status?.uploadError">{{ status.uploadError }}</div>
			<div
				v-if="
					['failed', 'waitingForNetwork'].includes(status?.uploadStatus || '')
				"
			>
				<button
					class="font-base rounded bg-blue-500 px-2 py-1 text-white hover:bg-blue-700"
					type="button"
					v-if="session"
					@click="retry(session.id)"
				>
					{{ t('general.retry') }}
				</button>
			</div>
			<!-- Name -->
			<div
				v-if="lead"
				class="text-sm font-extrabold"
				:class="[isCanceled ? 'text-slate-400' : 'text-sky-700']"
			>
				{{ lead?.firstname }}
				{{ lead?.lastname }}
			</div>
			<div
				v-else
				class="text-sm font-extrabold italic text-slate-400"
			>
				{{ eventName }}
			</div>
		</div>

		<!-- Time -->
		<div :class="[!lead ? 'italic text-slate-400' : '']">
			{{ startAt }} &ndash; {{ endAt }}
		</div>

		<!--Funeral Home Name-->

		<div v-if="lead">
			{{ lead?.funeralHome?.data.name }}
		</div>
		<div
			v-else
			class="italic text-slate-400"
		>
			{{ appointment?.funeralHome?.data.name }}
		</div>

		<!--Lead Source-->
		<div v-if="lead">{{ leadSourceName }}</div>
		<div
			v-else
			class="italic text-slate-400"
		>
			{{ eventType }}
		</div>

		<!-- Result button -->
		<div
			v-if="isCanceled"
			data-test-id="result-text"
		>
			<div class="mt-1 text-right text-xs uppercase text-red-400">
				{{ t('upcomingAppointments.canceled') }}
			</div>
		</div>
		<div
			v-else
			data-test-id="result-button"
			class="justify-self-end"
		>
			<ButtonSmall
				v-if="lead && appointment.resultId"
				success
			>
				{{ t('upcomingAppointments.resulted') }}
			</ButtonSmall>
			<a
				v-else-if="lead"
				:href="resultHref"
				@click.stop
			>
				<ButtonSmall>
					{{ t('upcomingAppointments.result') }}
				</ButtonSmall>
			</a>
		</div>
	</router-link>
</template>

<script setup lang="ts">
import type { CounselorAppointment } from '@/apiTypes'
import ButtonSmall from '@/components/forms/ButtonSmall.vue'
import { EventType } from '@/enums/EventType'
import { ObjectState } from '@/enums/ObjectState'
import { useSessionStore } from '@/stores/session'
import { DateTime } from 'luxon'
import { computed, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sessionStore = useSessionStore()

const props = defineProps({
	appointment: {
		type: Object as PropType<CounselorAppointment>,
		required: true,
	},
})

/**
 * Computes the name of the event associated with the appointment.
 * If the appointment has a campaign manager, that name is returned.
 * Otherwise, the location name from the event is returned.
 */
const eventName = computed(() => {
	// Check if there's a campaign manager and return their name if it exists
	if (props.appointment.campaignManager) {
		return props.appointment.campaignManager.data?.name || ''
	}
	// Otherwise, return the location name from the event
	return props.appointment.event?.data?.locationName || ''
})

/**
 * Computes the type of event associated with the appointment.
 * If there is a campaign manager, the event type is "Discovery".
 * Otherwise, the event type is determined by the cepEventTypeId of the associated event
 */
const eventType = computed(() => {
	if (props.appointment.campaignManager) {
		return 'Discovery'
	}
	if (
		props.appointment.event?.data?.cepEventTypeId === EventType.TRADITIONAL_CEP
	) {
		return 'CEP'
	}
	return 'ACEP'
})

/** The event associated with the appointment */
const event = computed(() => props.appointment.event?.data)

/** The campaign manager associated with the appointment */
const campaignManager = computed(() => props.appointment.campaignManager?.data)

/** The lead associated with the appointment */
const lead = computed(() => props.appointment.lead?.data)

/** The lead source associated with the appointment */
const leadSourceName = computed(() =>
	lead.value ? t(`leadSources.${lead.value.leadSourceId}`) : null
)

/** The human-readable start time. Includes "AM" if it starts in the morning and ends in the evening. */
const startAt = computed(() => {
	// Format like 1:00 PM
	let time = DateTime.fromISO(props.appointment.startAt).toLocaleString(
		DateTime.TIME_SIMPLE
	)

	// Strip out the AM/PM if it matches the endAt
	if (
		time.substring(time.length - 2) ===
		endAt.value.substring(endAt.value.length - 2)
	) {
		time = time.substring(0, time.length - 3)
	}

	return time
})

/** The human-readable end time */
const endAt = computed(() =>
	DateTime.fromISO(props.appointment.endAt).toLocaleString(DateTime.TIME_SIMPLE)
)

/** Get the presentation session associated with the appointment */
const session = computed(() =>
	sessionStore.getSessionForAppointment(props.appointment.id)
)

/** Get the upload status for a session by id */
const status = computed(() =>
	session.value
		? sessionStore.requestQueue.getRequestStatus(session.value.id)
		: null
)

/** URL for resulting the appointment */
const resultHref = computed(() => {
	if (!lead.value) {
		return undefined
	}
	return (
		import.meta.env.VITE_WEB_PORTAL_ROOT + `/#/lead-details/${lead.value.id}`
	)
})

/** Check if the appointment has been canceled */
const isCanceled = computed(
	() => props.appointment.objectStateId === ObjectState.Canceled
)

/** Retry saving a single session */
function retry(id: string) {
	sessionStore.requestQueue.retry(id)
}
</script>
