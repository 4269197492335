import { useCrmStore } from './crm'
import type { PriceBookData } from '@/apiTypes'
import { PriceBook } from '@/classes/PriceBook'
import { Session } from '@/classes/Session'
import { useRequestQueue } from '@/mixins/requestQueue'
import { windowRequestQueueConfig } from '@/mixins/requestQueueWindowConfig'
import Router from '@/router'
import { useContentStore } from '@/stores/content'
import type { CombinedFhLocation, SessionData } from '@/types'
import localforageLib from 'localforage'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { computed, ref, toRaw } from 'vue'
import type { NavigationFailure } from 'vue-router'

const localforage = localforageLib.createInstance({
	name: 'sessions',
})

export const useSessionStore = defineStore('session', () => {
	const content = useContentStore()
	const requestQueue = useRequestQueue('sessions', windowRequestQueueConfig)

	const activeSession = ref<Session>()
	const sessions = ref<Session[]>([])
	const activeSlideDeck = computed(() => {
		return activeSession.value
			? content.findDeck(
					activeSession.value.slideDeckId,
					activeSession.value.language
				)
			: undefined
	})

	const activeFHGroupMedia = computed(() => {
		return activeSession.value
			? content.findFHGroupMedia(
					activeSession.value.FHGroupMediaId,
					activeSession.value.language
				)
			: undefined
	})

	function gotoSession(
		sessionId: string
	): Promise<void | NavigationFailure | undefined> {
		return Router.push({ name: 'present', params: { sessionId } })
	}

	/**
	 * Load session data from local storage.
	 * Call once to boot up the store when the app loads.
	 */
	function loadAll(): Promise<void> {
		return new Promise((resolve) => {
			const allSessions: Session[] = []
			localforage.iterate(
				(sessionData: SessionData) => {
					allSessions.push(Session.deserialize(sessionData))
				},
				() => {
					sessions.value = allSessions
					resolve()
				}
			)
		})
	}

	function getSessionForAppointment(apptId: string) {
		return sessions.value?.find((session) => session.appointmentId === apptId)
	}

	/** Create a new session for the given lead and begin the presentation */
	async function startSession(session: Session) {
		if (activeSession.value) {
			throw Error("Can't start a new session when one is in progress")
		}

		// Add to list in memory
		sessions.value?.push(session)

		// Record timestamp
		session.startAt = DateTime.now().toISO()

		// Save locally
		await saveSession(session)
	}

	/** Resume the given presentation session */
	async function resumeSession(session: Session) {
		if (activeSession.value) {
			throw new Error(
				"Can't resume a session when a session is already active."
			)
		}

		await saveSession(session)

		gotoSession(session.id)
	}

	/** Load a session from storage by id. Set it as the active session. */
	async function loadSession(sessionId: string) {
		const loaded = await localforage.getItem<SessionData>(sessionId)

		if (loaded === null) {
			throw new Error("Can't load session, id not found: " + sessionId)
		}

		activeSession.value = Session.deserialize(loaded)
	}

	/** Commit the active session to local storage */
	async function saveActiveSession() {
		if (activeSession.value === undefined) {
			throw new Error("Can't save active session: it is undefined")
		}
		return await saveSession(activeSession.value)
	}

	/**
	 * Recursively converts a deeply nested object or array into a raw version by replacing Vue reactive properties with their raw values.
	 * @param {any} value - The value to convert to a raw object.
	 * @return {any} The raw version of the input value.
	 */
	function deepToRaw(value: any): any {
		// Check if the value is an object (including arrays)
		if (value && typeof value === 'object') {
			if (Array.isArray(value)) {
				// Map each element of the array to its raw version using deepToRaw recursively
				return value.map((item) => deepToRaw(item))

				// If the value is an object (but not an array)
			} else {
				// Create a new raw object
				const rawObj: { [key: string]: any } = {}

				// Iterate over each key in the object
				for (const key in value) {
					// Convert each property to its raw version using toRaw and deepToRaw recursively
					rawObj[key] = deepToRaw(toRaw(value[key]))
				}
				return rawObj
			}
		}
		// If the value is not an object or array, return it as-is
		return value
	}

	/** Commit the given session to local storage */
	async function saveSession(session: Session) {
		const rawSession = deepToRaw(toRaw(session.serialize()))
		await localforage.setItem(session.id, rawSession)
	}

	/** Get a list of all the session ids */
	async function getSavedSessionIds(): Promise<string[]> {
		return await localforage.keys()
	}

	/** Save a session to the remote database */
	async function submitUpdatedSession(id: string) {
		try {
			// TODO: Interface with actual API
			await requestQueue.addRequest(id, {
				url: 'https://httpbin.org/status/200',
				method: 'PUT',
			})
		} catch (error) {
			console.error('failed to save appointment result', error)
		}
	}

	const activeFh = computed(() => {
		const crm = useCrmStore()
		return activeSession.value?.FuneralHomeLocationId
			? crm.findFuneralHome(activeSession.value?.FuneralHomeLocationId)
			: undefined
	})

	const activeFhMedia = computed<CombinedFhLocation | undefined>(() => {
		const fh = activeFh.value

		return fh && activeSession.value
			? content.getCombinedFhLocation(fh, activeSession.value.language)
			: undefined
	})

	/** Computed for the price book for this session */
	const activePriceBook = computed<PriceBook | undefined>(() => {
		const crm = useCrmStore()
		return crm.findPriceBookForFuneralHome('')
		// return activeFh.value
		// ? crm.findPriceBookForFuneralHome(activeFh.value.id)
		// : undefined
	})

	return {
		activeSession,
		activeSlideDeck,
		activeFh,
		activeFhMedia,
		activeFHGroupMedia,
		activePriceBook,
		getSavedSessionIds,
		getSessionForAppointment,
		loadSession,
		loadAll,
		resumeSession,
		requestQueue,
		saveActiveSession,
		saveSession,
		submitUpdatedSession,
		sessions,
		startSession,
	}
})
