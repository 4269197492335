// Store for managing slide deck data
import { useAssetStore } from './asset'
import { useCrmStore } from './crm'
import type { V4FuneralHome } from '@/apiTypes'
import { DeckFlavor } from '@/enums/Language'
import { isBlock } from '@/guards'
import type {
	AssetStoryblok,
	FuneralHomeMediaStoryblok,
	SlideDeckStoryblok,
	SbMediaStoryblok,
} from '@/sbTypes'
import {
	getFuneralHomeGroupMedias,
	getSlideDecks,
} from '@/services/storyblokService.js'
import type {
	AnnotatedDeck,
	StoryblokStory,
	DeckPreview,
	StoryblokDeck,
	AnnotatedGroupMedia,
	AnnotatedStory,
	StoryblokGroupMedia,
	CombinedFhLocation,
} from '@/types.d.ts'
import localforageLib from 'localforage'
import { defineStore } from 'pinia'
import { computed, ref, shallowRef, triggerRef } from 'vue'

export type FhLocationMedia = {
	group: AnnotatedGroupMedia
	location: FuneralHomeMediaStoryblok | undefined
}

/** Store JSON data for slide decks, key by <language:uuid> */
const localDecks = localforageLib.createInstance({
	name: 'content',
	storeName: 'decks',
})

const localFHGroupMedias = localforageLib.createInstance({
	name: 'content',
	storeName: 'FHGroupMedias',
})

export const useContentStore = defineStore('content', () => {
	const assetStore = useAssetStore()
	const crm = useCrmStore()

	const decks = shallowRef<AnnotatedDeck[]>([])
	const FHGroupMedias = shallowRef<AnnotatedGroupMedia[]>([])
	const thumbnailUrls = ref<Record<string, string>>({})

	const availableDecks = computed<DeckPreview[]>(() => {
		const deckPreviews: DeckPreview[] = decks.value.map((deck) => ({
			title: deck.content.title || '',
			uniqueId: deck.uniqueId,
			lang: deck.lang,
			thumbnailUrl: thumbnailUrls.value[deck.uniqueId] || '',
			downloadProgress:
				deck.assetUrls.filter((url) => assetStore.isAssetCached(url)).length /
				deck.assetUrls.length,
			position: deck.position,
		}))

		deckPreviews.sort((a, b) => a.position - b.position)

		return deckPreviews
	})

	/** Download the thumbnail asset for a deck */
	async function downloadThumbnail(deck: AnnotatedDeck) {
		if (deck.content.thumbnail) {
			const thumbnailUrl = assetStore.getImageUrl(deck.content.thumbnail)
			if (thumbnailUrl) {
				const blobUrl = await assetStore.getAssetBlobUrl(thumbnailUrl)
				if (blobUrl) {
					thumbnailUrls.value[deck.uniqueId] = blobUrl
				}
			}
		}
	}

	/** Get the unique ID for a deck. Uuids are shared between languages */
	function getUniqueId<T>(story: StoryblokStory<T>): string {
		return story.lang + ':' + story.uuid
	}

	/** Add metadata to a story, producing an AnnotatedStory */
	function annotateStory<T extends Object>(
		story: StoryblokStory<T>
	): AnnotatedStory<T> {
		// Collect all asset URLs
		const urls = [
			...discoverImages(story)
				.map((asset) => assetStore.getImageUrl(asset))
				.filter((url) => url !== undefined),
			...discoverVideos(story)
				.map((media) => assetStore.getVideoUrl(media))
				.filter((url) => url !== undefined),
		]

		return {
			...story,

			// Deduplicate asset URLs
			assetUrls: Array.from(new Set(urls)),

			// Add unique ID
			uniqueId: getUniqueId(story),
		}
	}

	/** Download all the assets for a story */
	async function downloadAssets<T>(story: AnnotatedStory<T>) {
		await Promise.all(story.assetUrls.map((url) => assetStore.getAsset(url)))
	}

	/**
	 * Download all slide decks and their assets for the specified languages.
	 *
	 * @param langs
	 * @param preview
	 */
	async function fetchDecksAndAssets(preview = false): Promise<void> {
		// Get all decks for each language
		// todo: don't just assume that it works
		const allStories = await getSlideDecks(preview).then((stories) =>
			stories.map(annotateStory)
		)

		// Download all assets
		await Promise.all(
			allStories.map(async (story) => {
				// Download the thumnbail
				await downloadThumbnail(story)

				// Download everything else
				downloadAssets(story)

				// Replace old version of the deck if it exists
				const oldDeckIndex = decks.value.findIndex(
					(deck) => deck.uniqueId === story.uniqueId
				)
				if (oldDeckIndex >= 0) {
					decks.value.splice(oldDeckIndex, 1)
				}
				decks.value.push(story)
				triggerRef(decks)
				saveDeck(story)
			})
		)

		// Remove any decks which are no longer in Storyblok
		const toDelete = decks.value.filter(
			(deck) => !allStories.some((story) => story.uniqueId === deck.uniqueId)
		)

		for (const deck of toDelete) {
			decks.value.splice(decks.value.indexOf(deck), 1)
			triggerRef(decks)
			await localDecks.removeItem(deck.uniqueId)
		}
	}

	/**
	 * Purge any assets that are no longer in use. Make sure that we have the latest
	 * stories from storyblok before calling this.
	 */
	function deleteOldAssets(): Promise<void> {
		const deckUrls = decks.value.flatMap((deck) => deck.assetUrls)
		const fhGroupUrls = FHGroupMedias.value.flatMap((group) => group.assetUrls)
		const priceBookUrls = useCrmStore().priceBookUrls

		return assetStore.deleteUnusedAssets([
			...deckUrls,
			...fhGroupUrls,
			...priceBookUrls,
		])
	}

	/** Save a deck to cache */
	function saveDeck(deck: AnnotatedDeck): void {
		localDecks.setItem(deck.uniqueId, deck)
	}

	/** Save a deck to cache */
	function saveFHGroupMedia(media: AnnotatedGroupMedia): void {
		localFHGroupMedias.setItem(media.uniqueId, media)
	}

	/**
	 * Load all slide decks from cache. They will not have thumbnails until
	 * fetchMissingAssets is called
	 */
	async function loadDecksFromCache(): Promise<void> {
		const keys = await localDecks.keys()

		const fetchedStories = await Promise.all(
			keys.map((key) => localDecks.getItem<StoryblokDeck>(key))
		)

		decks.value = fetchedStories.filter((s) => s !== null).map(annotateStory)
	}

	/** Download the media for all the agent's funeral homes */
	async function fetchFuneralHomeGroupMedias(preview: boolean): Promise<void> {
		const groups = crm.findFhGroupIds()
		const stories = await getFuneralHomeGroupMedias(preview, groups)
		const annotated = stories.map(
			(story): AnnotatedGroupMedia => annotateStory(story)
		)

		// Store the groups locally
		localFHGroupMedias.clear()
		await Promise.all(annotated.map((story) => saveFHGroupMedia(story)))

		FHGroupMedias.value = annotated

		// Start downloading the assets
		await Promise.all(annotated.map((story) => downloadAssets(story)))
	}

	async function loadFHGroupMediasFromCache(): Promise<void> {
		const keys = await localFHGroupMedias.keys()

		const fetchedStories = await Promise.all(
			keys.map((key) => localFHGroupMedias.getItem<AnnotatedGroupMedia>(key))
		)

		FHGroupMedias.value = fetchedStories.filter((s) => s !== null)
	}

	/** Get all assets for all decks from cache or server */
	function fetchMissingAssets(): Promise<any> {
		return Promise.all([
			...decks.value.map(async (story) => {
				// Download the thumnbail
				await downloadThumbnail(story)

				// Download everything else
				await downloadAssets(story)
			}),

			...FHGroupMedias.value.map((story) => downloadAssets(story)),
		])
	}

	/** Find all objects in the given object that match the predicate */
	function findAllInStory(
		value: object | Array<unknown>,
		predicate: (obj: Object) => boolean
	): Object[] {
		// Return all the targets in the array
		if (Array.isArray(value)) {
			return value.map((val) => findAllInStory(val, predicate)).flat(1)
		}

		if (typeof value === 'object' && value !== null) {
			// Return this object if it is the target
			if (predicate(value)) {
				return [value]
			}

			// Return all the targets in the object
			return Object.values(value)
				.map((val) => findAllInStory(val, predicate))
				.flat(1)
		}

		// This is a primitive, not the target
		return []
	}

	/**
	 * Return a list of video objects for all videos found in the given slide deck
	 */
	function discoverVideos<T extends Object>(
		story: StoryblokStory<T>
	): SbMediaStoryblok[] {
		return findAllInStory(
			story.content,
			(obj) =>
				isBlock(obj) &&
				['sb-media', 'list-item-video-slide'].includes(obj.component) &&
				'videoUrl' in obj
		) as SbMediaStoryblok[]
	}

	/**
	 * Return a list of URLs for all images found in the given slide deck
	 */
	function discoverImages<T extends Object>(
		deck: StoryblokStory<T>
	): AssetStoryblok[] {
		return findAllInStory(
			deck.content,
			(obj) =>
				// Object is asset
				'fieldtype' in obj &&
				obj.fieldtype === 'asset' &&
				// Asset is not video
				'filename' in obj &&
				typeof obj.filename === 'string' &&
				!obj.filename.includes('.mp4')
		) as AssetStoryblok[]
	}

	/**
	 * Find a deck by its uuid and language.
	 * Use this to get the whole deck for the presentation.
	 * If browsing available decks, use availableDecks instead.
	 */
	function findDeck(uuid: string, language: DeckFlavor) {
		return decks.value.find(
			(deck) => deck.uuid === uuid && deck.lang === language
		)
	}

	/** Get a FH group media by crm group id */
	function findFHGroupMedia(uuid: string, language: DeckFlavor) {
		return FHGroupMedias.value.find(
			(media) => media.uuid === uuid && media.lang === language
		)
	}

	/** Manually set the list of decks. Used for testing and CMS visual editor. */
	function setTestDecks(testDecks: StoryblokStory<SlideDeckStoryblok>[]) {
		decks.value = testDecks.map(annotateStory)
	}

	// Manually set the list of FH Group Medias
	function setTestFHGroupMedias(testFHGroupMedias: StoryblokGroupMedia[]) {
		const annotated: AnnotatedGroupMedia[] = testFHGroupMedias.map((story) =>
			annotateStory(story)
		)

		FHGroupMedias.value = annotated

		// Save Media to the cache
		annotated.forEach((media) => saveFHGroupMedia(media))
	}

	/** Get the group and location media for a FH */
	function findMediaForFh(
		fh: V4FuneralHome,
		lang: DeckFlavor
	): FhLocationMedia | undefined {
		const group = FHGroupMedias.value.find(
			(media) => media.slug === fh.groupId && media.lang === lang
		)

		if (!group) {
			console.error(
				'Could not find group for funeral home',
				fh,
				FHGroupMedias.value
			)
			return undefined
		}

		const location = group.content.locations?.find((loc) => loc.id === fh.id)

		return {
			group,
			location,
		}
	}

	/**
	 * Get FH locations to display to the user.
	 * They can be filtered by group, will have any available media,
	 * and will be sorted by group and name.
	 */
	function getFuneralHomeLocations(
		lang: DeckFlavor,
		filters: { crmGroupId?: string | undefined } = {}
	): CombinedFhLocation[] {
		// Get filtered FHs
		let fhs = crm.funeralHomes || []
		if (filters.crmGroupId) {
			fhs = fhs?.filter((fh) => fh.crmGroupId === filters.crmGroupId)
		}

		// Combine FH, location media, and group media
		const fhMedia = fhs.map((fh) => getCombinedFhLocation(fh, lang))

		// Sort by group and then name
		return fhMedia.sort((a, b) =>
			a.groupId == b.groupId
				? a.name.localeCompare(b.name)
				: a.groupId.localeCompare(b.groupId)
		)
	}

	/** Returns the first element of the given array, or undefined if not possible */
	function getFirstElement<T>(list: T[] | undefined): T | undefined {
		if (!list || list.length === 0) {
			return undefined
		}

		return list[0]
	}

	/** Combine FH media, group media, and FH info into a single object */
	function getCombinedFhLocation(
		fh: V4FuneralHome,
		lang: DeckFlavor
	): CombinedFhLocation {
		const media = findMediaForFh(fh, lang)

		return {
			id: fh.id,
			groupId: fh.groupId,
			sfId: undefined,
			sfGroupId: undefined,
			name: media?.location?.name || fh.name,
			warmUpBackground: getFirstElement(media?.group?.content.warmUpBackground),
			introBackground:
				getFirstElement(media?.location?.background) ??
				getFirstElement(media?.group?.content.background),
			logo: getFirstElement(media?.group?.content.logo)?.asset,
			useDarkStartText: media?.group?.content.darkStartText || false,
		}
	}

	/** Delete everything from local storage and refs from memory */
	async function purge() {
		await Promise.allSettled([localDecks.clear(), localFHGroupMedias.clear()])
		decks.value = []
		FHGroupMedias.value = []
		thumbnailUrls.value = {}
	}

	return {
		thumbnailUrls: thumbnailUrls,
		availableDecks,
		deleteOldAssets,
		findDeck,
		findFHGroupMedia,
		findMediaForFh,
		fetchDecksAndAssets,
		fetchFuneralHomeGroupMedias,
		fetchMissingAssets,
		getCombinedFhLocation,
		getFuneralHomeLocations,
		getUniqueId,
		loadDecksFromCache,
		loadFHGroupMediasFromCache,
		setTestDecks,
		setTestFHGroupMedias,
		purge,
	}
})
