import { useAssetStore } from './asset'
import { useCrmStore } from './crm'
import type { Role } from '@/apiTypes'
import { axiosInstance } from '@/services/axiosInstance'
import { useContentStore } from '@/stores/content'
import type { CombinedUser } from '@/types'
import axios, {
	type AxiosError,
	type AxiosRequestConfig,
	type AxiosBasicCredentials,
} from 'axios'
import localforageLib from 'localforage'
import { defineStore } from 'pinia'
import { ref } from 'vue'

enum LoginResult {
	Success,
	Unauthorized,
	NoNetwork,
}

const localforage = localforageLib.createInstance({
	name: 'auth',
})

export const useAuthStore = defineStore('auth', () => {
	/** Struct with user data from Hub and Salesforce */
	const user = ref<CombinedUser>()

	const sessionExpired = ref(false)
	const showLogin = ref(false)
	const authToken = ref<string>('')
	const crm = useCrmStore()
	const storyBlokToken = ref<string | undefined>()

	/** True if we are currently unable to validate user with server */
	const usingStoredAuth = ref(false)

	/** Method to fetch our user record from storage and ping the server for a new one */
	async function fetchUser(auth?: AxiosBasicCredentials) {
		await Promise.any([getUserFromServer(auth), getUserFromLocal()])
		if (!user.value) showLogin.value = true
	}

	async function getUserFromServer(
		auth?: AxiosBasicCredentials
	): Promise<LoginResult> {
		try {
			const userRequestConfig: AxiosRequestConfig = {
				params: {
					include: 'roles',
				},
			}
			if (auth) userRequestConfig.auth = auth
			const userResponse = await axiosInstance.get(
				'/v2/user',
				userRequestConfig
			)

			// This is present so that we can interact with auth state from multiple places
			sessionExpired.value = false
			usingStoredAuth.value = false

			// Fetch associated sales contact record
			const salesContactRole: Role = userResponse.data.roles?.data.find(
				(role: Role) => role.roleId == '1AA0B4EC-198B-4F7F-AF6B-B1B75CF6B0AA'
			)

			if (!salesContactRole) {
				console.error('User does not have a sales contact role')

				if (import.meta.env.MODE == 'development') {
					// Pretend this is OK. The user still must have
					// Permissions to get the calendar info from the API.
					const combinedUser = {
						salesContact: {
							id: import.meta.env.VITE_APP_DEV_SALES_CONTACT_ID,
							firstName: 'Dev',
							lastName: 'User',
						},
						hub: userResponse.data,
					}

					await localforage.setItem('user', combinedUser)

					user.value = combinedUser

					return LoginResult.Success
				} else {
					throw new Error('You do not have a sales contact role')
				}
			}

			// Fetch sales contact
			const contactResponse = await axiosInstance.get(
				'/v3/counselors/' + salesContactRole.crmUserId + '?include=funeralHomes'
			)
			const contactData = contactResponse.data.data

			// Use funeral homes to download all FH groups
			await crm.getFuneralHomesFromServer(contactData.funeralHomes?.data)
			delete contactData.funeralHomes

			const combinedUser: CombinedUser = {
				salesContact: contactData,
				hub: userResponse.data,
			}

			await localforage.setItem('user', combinedUser)

			user.value = combinedUser

			return LoginResult.Success
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if ((error as AxiosError).response?.status == 401) {
					if (auth?.password && auth?.username) {
						throw new Error('Username and password are not valid.')
					}

					console.warn('You are not logged in')
					throw new Error('You are not logged in')
					// return LoginResult.Unauthorized
				}

				if ((error as AxiosError).code == 'ERR_NETWORK') {
					console.error("Can't reach the login server")
					throw new Error("Can't reach the login server.")
				}
			}

			throw error
		}
	}

	async function getUserFromLocal(): Promise<void> {
		const savedUser = await localforage.getItem<CombinedUser>('user')
		if (!savedUser) {
			throw new Error('No user found in local storage')
		}

		if (!user.value) {
			user.value = savedUser
			usingStoredAuth.value = true
		}
	}

	async function logOut(): Promise<void> {
		await Promise.all([
			useCrmStore().purge(),
			localforage.clear(),
			useContentStore().purge(),
			useAssetStore().purge(),
			// todo fix cors error on logout
			axiosInstance.get('/logout', { maxRedirects: 0 }),
		]).finally(() => {
			sessionExpired.value = false
			location.reload()
		})
	}

	async function getLoginEmail() {
		const result = await localforage.getItem<string>('userLoginEmail')
		return result || ''
	}

	async function setLoginEmail(email: string) {
		const result = await localforage.setItem('userLoginEmail', email)
		return result || ''
	}

	/** Set the user's access token for their login session */
	function setAuthToken(token: string) {
		authToken.value = token
		return localforage.setItem('papiAuthToken', token)
	}

	/** Get the user's access token for their login session */
	async function getAuthToken() {
		if (authToken.value) return authToken.value

		const token = await localforage.getItem<string>('papiAuthToken')

		if (token) {
			authToken.value = token
			return token
		}

		return null
	}

	/** Get the Storyblok token from the app API */
	async function getStoryBlokToken(): Promise<string | undefined> {
		if (storyBlokToken.value) return storyBlokToken.value
		let body

		// Specifically using fetch to get around axios interceptors
		let response = await fetch(
			`${import.meta.env.VITE_SALES_STUDIO_API_URL}/token?pUserToken=${authToken.value}`,
			{
				credentials: 'include',
			}
		)
		if (response.status === 401) {
			sessionExpired.value = true
			throw 'Unable to log into papi'
		}

		body = await response.json()

		storyBlokToken.value = body.storyBlokToken
		return storyBlokToken.value
	}

	return {
		user,
		showLogin,
		sessionExpired,
		fetchUser,
		logOut,
		usingStoredAuth,
		getUserFromLocal,
		getUserFromServer,
		getLoginEmail,
		setLoginEmail,
		getAuthToken,
		setAuthToken,
		getStoryBlokToken,
	}
})
