<template>
	<!-- Footer notifications -->
	<BaseAlertList />

	<div class="grid h-dvh grid-rows-[max-content_minmax(0,1fr)]">
		<HeaderNav />
		<main
			class="grid grid-cols-[1fr_255px] grid-rows-[minmax(0,1fr)] gap-y-5 bg-gray-50 p-5 pr-0"
		>
			<div class="-mr-1 overflow-x-hidden overflow-y-scroll pr-1">
				<UpcomingAppointments />
			</div>
			<DeckRepeater
				class="mr-2"
				v-slot="{ deck, renderDeckThumbnail }"
			>
				<RouterLink
					class="inline-block"
					:to="{ name: 'newSession', query: { deckId: deck.uniqueId } }"
				>
					<component :is="renderDeckThumbnail" />
				</RouterLink>
			</DeckRepeater>
		</main>
	</div>
</template>

<script setup lang="ts">
import BaseAlertList from '@/components/common/BaseAlertList.vue'
import HeaderNav from '@/components/common/HeaderNav.vue'
import UpcomingAppointments from '@/components/home/UpcomingAppointments.vue'
import DeckRepeater from '@/components/home/decks/DeckRepeater.vue'
import { useSyncStore } from '@/stores/sync'
import { onMounted } from 'vue'

const sync = useSyncStore()

/** When the user arrives here, start up a sync if we're not already syncing */
onMounted(() => {
	sync.beginSync()
})
</script>
