<!--
Component to display an interactive slide show representing one chapter of a
presentation, plus a nav to change chapters
-->
<template>
	<div class="flex h-full select-none flex-col bg-white">
		<FuneralHomeAssistant v-if="showAssistant" />
		<div class="w-full flex-grow overflow-auto">
			<FormKitProvider :config="formKitConfig">
				<PresentationNav v-if="showNavBar" />
				<!-- Note: The border on print is necessary to get the page to display correctly without giant gaps between sections on the memorial guide. -->
				<swiper-container
					class="h-full w-full print:h-auto print:border print:border-transparent"
					ref="swiper"
					speed="500"
					:mousewheel="false"
					:keyboard="true"
					effect="slide"
				>
					<swiper-slide
						v-for="(slide, index) in controller.allSlides"
						:key="slide._uid"
					>
						<component
							v-if="swiper?.swiper"
							:is-active="index == controller.swiper?.activeIndex"
							:inert="index !== controller.swiper?.activeIndex"
							@submit="controller.onSlideSubmit"
							:is="slide.component"
							:blok="slide"
							:slideIndex="index"
							:parentSwiper="swiper?.swiper"
							@goto-next-chapter="controller.gotoNextChapter"
							@goto-chapter="controller.gotoChapter"
							:active="controller.activeSlideIndex == index"
						></component>
					</swiper-slide>
				</swiper-container>
			</FormKitProvider>
		</div>
	</div>
</template>

<script setup lang="ts">
import { localizedConfig } from '@/../formkit.config'
import FuneralHomeAssistant from '@/components/funeral-home-assistant/FuneralHomeAssistant.vue'
import PresentationNav from '@/components/presentation/PresentationNav.vue'
import { usePresentationLocale } from '@/i18n'
import { useFuneralHomeGroupMediaController } from '@/stores/funeralHomeGroupMediaController'
import { usePresentationController } from '@/stores/presentationController'
import { type FormKitOptions } from '@formkit/core'
import { FormKitProvider } from '@formkit/vue'
import { usePointer } from '@vueuse/core'
import { Swiper } from 'swiper'
import { register } from 'swiper/element/bundle'
import { computed, ref, onMounted, watch, onBeforeUnmount } from 'vue'

const { mode = 'SlideDeck' } = defineProps({
	mode: String,
})

const { pointerType } = usePointer()
const controller =
	mode === 'FuneralHome'
		? useFuneralHomeGroupMediaController()
		: usePresentationController()
const { locale } = usePresentationLocale()

/** The swiper element, which has a reference to the Swiper API */
const swiper = ref<{ swiper: Swiper }>()

/** The WakeLock sentinel object */
const wakeLock = ref<WakeLockSentinel>()

/** True if the user is using a mouse */
const mouseMode = computed(() => pointerType.value === 'mouse')

/** Config to localize FormKit according to the presentation */
const formKitConfig = computed<FormKitOptions>(() =>
	localizedConfig(locale.value)
)

const showNavBar = computed(() => mode === 'SlideDeck')
const showAssistant = computed(() => mode === 'FuneralHome')
/**
 * Apply mouse settings to slide presentation.
 * This should be possible with dynamic bindings to
 * grab-cursor and allow-touch-move, but it wasn't working.
 */
watch(mouseMode, (newValue) => {
	if (!swiper.value || !swiper.value.swiper) {
		return
	}

	if (newValue) {
		swiper.value.swiper.unsetGrabCursor()
		swiper.value.swiper.allowTouchMove = false
	} else {
		swiper.value.swiper.setGrabCursor()
		swiper.value.swiper.allowTouchMove = true
	}
})

/** Set up Swiper and the presentation controller */
onMounted(async () => {
	register()

	if (!swiper.value) {
		throw new Error('Swiper was unable to mount')
	}

	controller.swiper = swiper.value.swiper

	// Device detection
	const userAgent = navigator.userAgent
	const isMobileOrTablet =
		/iPhone|iPad|Android/.test(userAgent) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
	const isDesktop = !isMobileOrTablet

	// Check if the app is running in standalone mode
	const isStandalone =
		(window.navigator as Navigator & { standalone?: boolean }).standalone ||
		window.matchMedia('(display-mode: standalone)').matches

	const isMobileStandalone = isMobileOrTablet && isStandalone

	if (isMobileStandalone || isDesktop) {
		// Skip fullscreen request if requestFullscreen api is not supported
		if (!document.body.requestFullscreen) {
			return
		}

		try {
			// Go to fullscreen mode
			await document.body.requestFullscreen({
				navigationUI: 'hide',
			})
		} catch (e) {
			console.warn('Could not enter fullscreen', e)
		}

		// Enable WakeLock
		try {
			wakeLock.value = await navigator.wakeLock?.request('screen')
			console.info('WakeLock acquired')
		} catch (e) {
			console.warn('Could not lock screen', e)
		}
	} else {
		console.info(
			'Fullscreen not applied: App is not in standalone mode or is being viewed on an unsupported device.'
		)
	}

	swiper.value.swiper.params.keyboard = {
		enabled: false,
	}
})

/** Clean up when leaving */
onBeforeUnmount(() => {
	// Remove chapter data from the presentation controller
	controller.activeSlideIndex = 0
	controller.swiper = undefined

	// Release fullscreen from `document.body`
	if (document.fullscreenElement === document.body) {
		document.exitFullscreen().catch((e) => {
			console.warn('Could not exit fullscreen', e)
		})
	}

	// Release WakeLock
	wakeLock.value
		?.release()
		.catch((e) => console.warn('Could not release WakeLock', e))
})
</script>
