export enum EducationLevelOptions {
	SomeHighSchool = 'someHighSchool',
	HighSchoolDiplomaGED = 'highSchoolDiplomaGED',
	SomeCollege = 'someCollege',
	AssociateDegree = 'associateDegree',
	BachelorDegree = 'bachelorDegree',
	MasterDegree = 'masterDegree',
	Doctorate = 'doctorate',
	ProfessionalDegree = 'professionalDegree',
	Other = 'other',
  }
