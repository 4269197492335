<!-- Slide for displaying funeral over time budget based on inflation rate -->
<template>
	<div class="h-full overflow-y-auto px-10 pt-14">
		<div
			v-for="(plan, index) in plans"
			class="border-b border-slate-200 p-5"
			:key="plan.id"
		>
			<div class="mx-5 my-3 flex items-center">
				<PlanAvatar
					:plan="plan"
					:index="index"
					large
					filled
				/>
				<div class="ml-3 text-2xl font-bold text-slate-900">
					{{ planNames![index] }}'s
					{{ t('inflationEstimateSlide.funeralOverTime') }}
				</div>
			</div>
			<div class="m-5 flex">
				<div class="w-1/2">
					<BlobImage
						class="h-auto w-full rounded-xl object-cover"
						:src="planImages![index]"
						:alt="plan.lastName"
					/>
				</div>
				<div class="mx-10 w-1/2">
					<div class="font-bold">Linear Charts</div>
					<div>
						<div class="my-4 flex justify-between">
							<div class="font-bold text-slate-900">
								{{ t('inflationEstimateSlide.todayCost') }}
							</div>
							<div class="text-sm font-medium text-precoa-blue-600">
								{{ formatPrice(planPrice![index].total) }}
							</div>
						</div>
						<div class="my-4 flex justify-between">
							<div class="text-sm font-medium uppercase text-red-600">
								5 {{ t('inflationEstimateSlide.years') }}
							</div>
							<div class="text-sm font-medium text-slate-900">
								{{
									formatPrice(
										calculateInflationEstimate(
											planPrice![index].total,
											5,
											inflationRate
										)
									)
								}}
							</div>
						</div>
						<div class="my-4 flex justify-between">
							<div class="text-sm font-bold uppercase text-red-600">
								10 {{ t('inflationEstimateSlide.years') }}
							</div>
							<div class="text-sm font-medium text-slate-900">
								{{
									formatPrice(
										calculateInflationEstimate(
											planPrice![index].total,
											10,
											inflationRate
										)
									)
								}}
							</div>
						</div>
						<div class="my-4 flex justify-between">
							<div class="text-sm font-extrabold uppercase text-red-600">
								15 {{ t('inflationEstimateSlide.years') }}
							</div>
							<div class="text-sm font-medium text-slate-900">
								{{
									formatPrice(
										calculateInflationEstimate(
											planPrice![index].total,
											15,
											inflationRate
										)
									)
								}}
							</div>
						</div>
						<div class="my-4 flex justify-between">
							<div class="text-sm font-black uppercase text-red-600">
								20 {{ t('inflationEstimateSlide.years') }}
							</div>
							<div class="text-sm font-medium text-slate-900">
								{{
									formatPrice(
										calculateInflationEstimate(
											planPrice![index].total,
											20,
											inflationRate
										)
									)
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import PlanAvatar from '../presentation/PlanAvatar.vue'
import { usePresentationLocale } from '@/i18n'
import { useMerchandise } from '@/mixins/merchandise'
import type { InflationEstimateSlideStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { Swiper } from 'swiper'
import { computed, onMounted, ref, type PropType } from 'vue'

const { t } = usePresentationLocale()
const props = defineProps({
	blok: Object as PropType<InflationEstimateSlideStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

const sessionStore = useSessionStore()
const {
	getPlanImage,
	calculateCartTotals,
	formatPrice,
	calculateInflationEstimate,
} = useMerchandise()

const plans = computed(() => {
	return sessionStore.activeSession?.plans
})

const inflationRate = computed(() => parseFloat(props.blok?.rate ?? '0'))

const planNames = computed(() => {
	return plans.value?.map((plan) => {
		return [plan.firstName, plan.lastName].filter((p) => !!p).join(' ')
	})
})

const planPrice = computed(() => {
	return plans.value?.map((plan) => calculateCartTotals(plan))
})

const planImages = computed(() => {
	return plans.value?.map((plan) => getPlanImage(plan.id))
})
</script>
