import config from '../formkit.config'
import App from './App.vue'
import type { CounselorAppointment, V4FuneralHome } from './apiTypes'
import './app.css'
import type Plan from './classes/Plan'
import globalComponents from './globalComponents'
import i18n from './i18n'
import router from './router'
import type { DeckPreview } from './types'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { plugin } from '@formkit/vue'
import * as Sentry from '@sentry/vue'
import { StoryblokVue, apiPlugin } from '@storyblok/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

const app = createApp(App)
const pinia = createPinia()

if (import.meta.env.VITE_APP_SENTRY_ENVIRONMENT) {
	pinia.use(
		Sentry.createSentryPiniaPlugin({
			// Filter out any PII or large objects
			stateTransformer: (state) => {
				return {
					auth: {
						...state.auth,
						user: state.auth?.user?.id,
					},
					content: {
						availableDecks: state.content?.availableDecks.map(
							(d: DeckPreview) => d.uniqueId
						),
					},
					crm: {
						...state.crm,
						appointments: state.crm?.appointments.map(
							(a: CounselorAppointment) => a.id
						),
						funeralHomes: state.crm?.funeralHomes.map(
							(f: V4FuneralHome) => f.id
						),
					},
					presentationController: {
						activeSlideIndex: state.presentationController?.activeSlideIndex,
						activeSlideName: state.presentationController?.activeSlideName,
						navigatingForward: state.presentationController?.navigatingForward,
					},
					session: {
						activeSession: state.session?.activeSession
							? {
									...state.session?.activeSession,
									plans: state.session?.activeSession.plans.map((p: Plan) => ({
										id: p.id,
										leadId: p.leadId,
									})),
								}
							: null,
						activeSlideDeck: state.session?.activeSlideDeck?.id,
					},
					sync: state.sync,
				}
			},
		})
	)

	Sentry.init({
		app,
		dsn: 'https://35d889b28aad6cb952cbf337b4a9ae96@o32894.ingest.us.sentry.io/4508445523902464',
		normalizeDepth: 10,
		environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
		integrations: [],
	})
}

app.use(autoAnimatePlugin)
app.use(pinia)
app.use(router)
app.use(plugin, config)
app.use(i18n)
app.use(StoryblokVue, {
	accessToken: 'someToken',
	use: [apiPlugin],
	apiOptions: {
		region: 'us',
	},
	bridge: import.meta.env.VITE_APP_STORYBLOK_BRIDGE === 'true',
})

// Register global components used by Storyblok
for (const [key, value] of Object.entries(globalComponents)) {
	app.component(key, value)
}

app.mount('#app')

/** Web Types */
// See https://github.com/GoogleChrome/workbox/issues/2946
interface SyncManager {
	getTags(): Promise<string[]>
	register(tag: string): Promise<void>
}

declare global {
	interface ServiceWorkerRegistration {
		readonly sync: SyncManager
	}

	interface SyncEvent extends ExtendableEvent {
		readonly lastChance: boolean
		readonly tag: string
	}

	interface ServiceWorkerGlobalScopeEventMap {
		sync: SyncEvent
	}
}
