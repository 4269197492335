<!-- 
List of status alerts plus any additional provided by the slot 
-->
<template>
	<div
		class="pointer-events-none absolute bottom-14 z-40 flex w-full flex-col items-center justify-end gap-4"
	>
		<slot></slot>
		<BaseAlert
			type="critical"
			v-if="auth.sessionExpired && !sync.offline"
			>{{ t('auth.sessionExpired') }}
			<button
				type="button"
				class="pointer-events-auto ml-2 rounded-sm border border-white p-1 text-xs font-normal uppercase text-white hover:bg-white hover:text-red-700"
				@click="auth.showLogin = true"
			>
				{{ t('auth.login') }}
			</button></BaseAlert
		>
		<BaseAlert
			type="critical"
			:icon="SlashCloudIcon"
			v-if="sync.offline"
			>{{ t('home.offlineNotice') }}
		</BaseAlert>
	</div>
</template>

<script setup lang="ts">
import BaseAlert from './BaseAlert.vue'
import SlashCloudIcon from '@/components/icons/SlashCloudIcon.vue'
import { useAuthStore } from '@/stores/auth'
import { useSyncStore } from '@/stores/sync'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const auth = useAuthStore()
const sync = useSyncStore()
</script>
