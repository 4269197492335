<!--Component for rendering a list of text inputs based on the plan-->
<template>
	<div class="flex flex-col justify-center space-y-4">
		<div
			v-for="(plan, index) in session.activeSession?.plans"
			:key="plan.id"
		>
			<label
				class="group/copyButton relative"
				:class="[
					showCopyFeature
						? 'grid-cols-[2.5rem_5fr_auto]'
						: 'grid-cols-[2.5rem_6fr]',
					isLargeSize ? 'grid pb-4' : 'flex flex-col items-start justify-start',
				]"
			>
				<div
					:class="isLargeSize ? 'block' : 'hidden'"
					class="absolute bottom-0 z-0 w-full max-w-5xl border-b-2 border-gray-200 group-focus-within/copyButton:border-primary-green-400"
				></div>

				<div
					class="z-10 px-0 group-focus-within/copyButton:border-primary-green-400"
					:class="isLargeSize ? 'static' : 'absolute'"
				>
					<PlanAvatar
						:plan="plan"
						:index="index"
						:filled="!!plan[planField]"
					/>
				</div>

				<input
					class="mb-0 w-full bg-white focus-within:!ring-transparent focus:outline-none focus:ring-transparent group-focus-within/copyButton:border-primary-green-400"
					:class="
						isLargeSize
							? 'border-0 p-0 pr-2'
							: 'relative z-0 border-x-0 border-b-2 border-t-0 border-gray-200 pb-4 pl-10 pt-1'
					"
					type="text"
					v-model="plan[planField]"
				/>

				<CopyButtonList
					v-if="showCopyFeature"
					:field-or-section="planField"
					:active-index="index"
					class="flex justify-end !pb-0 group-focus-within/copyButton:border-primary-green-400"
					:class="isLargeSize ? 'mt-0 w-auto' : 'mt-3.5 w-full'"
				/>
			</label>
		</div>
	</div>
</template>

<script setup lang="ts">
import CopyButtonList from '../presentation/CopyButtonList.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { useSessionStore } from '@/stores/session'
import type { PlanData } from '@/types'
import type { PropType } from 'vue'

const session = useSessionStore()

defineProps({
	isLargeSize: {
		type: Boolean,
		default: false,
	},
	showCopyFeature: {
		type: Boolean,
		default: false,
	},
	planField: {
		type: String as PropType<keyof PlanData>,
		required: true,
	},
})
</script>
