import { useAuthStore } from '@/stores/auth'
import { useSyncStore } from '@/stores/sync'
import axios, { type CreateAxiosDefaults } from 'axios'

export function getAxiosInstance(options: CreateAxiosDefaults = {}) {
	const instance = axios.create({
		withCredentials: true,
		baseURL: import.meta.env.VITE_API_ROOT,
		headers: {
			'X-Requested-With': 'XMLHTTPRequest',
		},
		...options,
	})

	/** In local environment, add pUserToken to request from auth token */
	instance.interceptors.request.use(async (request) => {
		const token = await useAuthStore().getAuthToken()

		request.params = {
			...request.params,
			pUserToken: token,
		}
		return request
	})

	instance.interceptors.response.use(
		(response) => {
			// If we get a new access token, save it for later use
			const token = response.headers['x-access-token']

			if (token) {
				useAuthStore().setAuthToken(token)
			}
			return response
		},
		(error) => {
			if (error.response?.status === 401) {
				const auth = useAuthStore()
				if (auth.user) auth.sessionExpired = true
			}

			return Promise.reject(error)
		}
	)

	// Update offline status based on the result of the axios request
	instance.interceptors.response.use(
		(response) => {
			const sync = useSyncStore()
			sync.offline = false
			return response
		},
		(error) => {
			if (axios.isAxiosError(error)) {
				const sync = useSyncStore()

				// Set to offline if the server is broken or unreachable
				if (!error.response || error.response.status >= 500) {
					sync.offline = true
				} else if (error.response && error.response.status >= 400) {
					// Set to online if it is a client error
					sync.offline = false
				}
			}

			return Promise.reject(error)
		}
	)

	return instance
}

export const axiosInstance = getAxiosInstance()
