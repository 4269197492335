<!-- Selection card that toggles a value between true and false -->
<template>
	<div
		class="w-full"
		as="div"
		:aria-label="blok.label"
	>
		<div
			@click="handleClick"
			:class="[
				'relative flex cursor-pointer justify-between rounded-lg border bg-white px-5 py-3 shadow-lg focus:outline-none',
			]"
		>
			<span
				:class="[
					model ? 'border-green-500' : 'border-transparent',
					'pointer-events-none absolute -inset-px rounded-lg border-2',
				]"
				aria-hidden="true"
			></span>

			<span class="flex min-h-10 w-full flex-col items-start">
				<sb-image
					class="h-12 max-h-12 w-12 max-w-12"
					:blok="blok.image"
				></sb-image>
				<span class="text-base font-extrabold">{{ blok.label }}</span>
			</span>

			<span
				class="mt-2 flex items-center justify-between text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
			>
				<PlanAvatar
					:plan="plan"
					:index="index"
					:filled="model"
				/>
				<div>&nbsp;</div>
			</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import type Plan from '@/classes/Plan'
import SbImage from '@/components/blocks/SbImage.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import type { SelectionCardBoolStoryblok } from '@/sbTypes'

const props = defineProps<{
	blok: SelectionCardBoolStoryblok
	plan: Plan
	index: number
}>()

const model = defineModel<boolean>()

/** Toggle the value of the model */
const handleClick = (event: MouseEvent | KeyboardEvent) => {
	model.value = !model.value
}
</script>
