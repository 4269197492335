<!-- Slide for displaying a list of products that are not packages, urns, or caskets -->
<template>
	<div class="h-full overflow-y-auto px-10 pt-14">
		<div
			v-for="(plan, index) in plans"
			class="flex border-b border-slate-200 p-5"
			:key="plan.id"
		>
			<div class="mx-5 w-1/3">
				<BlobImage
					class="h-auto w-full rounded-xl object-cover"
					:src="planImages![index]"
					:alt="plan.lastName"
				/>
			</div>
			<div class="w-2/3">
				<div class="flex w-full justify-between">
					<div class="flex items-center">
						<PlanAvatar
							:plan="plan"
							:index="index"
							large
							filled
						/>
						<div class="ml-3 text-2xl font-semibold text-slate-900">
							{{ plan.firstName }} {{ plan.lastName }}
						</div>
					</div>
					<div class="flex items-center">
						<Cog8ToothIcon
							class="h-6 w-6 cursor-pointer text-slate-300"
							@click="openCustomItemModal(plan)"
						/>
					</div>
				</div>

				<!-- Cart Items -->
				<CartItem
					v-for="cartItem in planCartItems![index]"
					:key="cartItem.lineItemId"
					:cart-item="cartItem"
					:plan="plan"
					@undelete="plan.softDeleteCartItem(cartItem.lineItemId, false)"
					@delete="plan.softDeleteCartItem(cartItem.lineItemId, true)"
				/>

				<!-- Custom Items -->
				<CartCustomItem
					v-for="customItem in customCartItems![index]"
					:key="customItem.id"
					:item="customItem"
					:plan="plan"
					@undelete="plan.softDeleteCustomItem(customItem.id, false)"
					@delete="plan.softDeleteCustomItem(customItem.id, true)"
				/>

				<!-- Cart Totals -->
				<div class="py-5">
					<div class="my-1 flex">
						<div class="flex flex-1 items-center justify-end">
							<div class="text-right text-base font-medium text-slate-500">
								{{ t('reviewSlide.guaranteed') }}
							</div>
							<ShieldCheckIcon class="ml-2 h-5 w-5 text-primary-green-600" />
						</div>
						<div class="w-32 text-right text-base font-medium text-slate-500">
							{{ formatPrice(planPrice![index].guaranteedSubTotal) }}
						</div>
					</div>
					<div class="my-1 flex">
						<div class="flex-1 text-right text-base font-medium text-slate-500">
							{{ t('reviewSlide.notGuaranteed') }}
						</div>
						<div class="w-32 text-right text-base font-medium text-slate-500">
							{{ formatPrice(planPrice![index].nonGuaranteedSubTotal) }}
						</div>
					</div>
					<div class="my-1 flex">
						<div class="flex-1 text-right text-xl font-bold text-slate-900">
							{{ t('reviewSlide.total') }}
						</div>
						<div class="w-32 text-right text-xl font-bold text-slate-900">
							{{ formatPrice(planPrice![index].total) }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<precoa-modal v-model:open="modalOpen">
			<template #default>
				<div class="flex flex-col pt-2">
					<div class="my-2 text-center font-bold text-slate-900">
						{{ t('reviewSlide.manualAdd') }}
					</div>
					<div class="my-2 text-center text-sm text-slate-500">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
						amet labore.
					</div>
					<FormKit
						type="form"
						:actions="false"
						v-model="manualAddItem"
					>
						<FormKit
							type="dropdown"
							:label="t('reviewSlide.type')"
							name="type"
							outer-class="max-w-full w-full"
							:deselect="false"
							:options="customItemTypeOptions"
							validation="required"
						/>
						<FormKit
							type="text"
							:label="t('reviewSlide.title')"
							name="title"
							outer-class="max-w-full w-full"
							:placeholder="t('reviewSlide.veteransDiscount')"
							validation="required"
						/>
						<FormKit
							type="text"
							:label="t('reviewSlide.description')"
							name="description"
							outer-class="max-w-full w-full"
							placeholder="Lorem ipsum dolor sit amet consectetu"
							validation="required"
						/>
						<div class="flex">
							<FormKit
								type="text"
								:label="t('reviewSlide.sku')"
								name="sku"
								outer-class="!max-w-32 mr-2"
								placeholder="123456"
								v-if="manualAddItem.type === ManualAddCartItemType.CustomItem"
							/>

							<FormKit
								type="text"
								:label="t('reviewSlide.amount')"
								name="amount"
								outer-class="max-w-full flex-1"
								placeholder="125"
								aria-required="true"
								validation="required"
							/>
						</div>
						<div
							v-if="manualAddItem.type === ManualAddCartItemType.CustomItem"
							class="flex w-full justify-between"
						>
							<div class="text-sm font-bold text-slate-950">
								{{ t('reviewSlide.guaranteedItem') }}
							</div>
							<FormKit
								type="toggle"
								name="guaranteed"
								outer-class="!max-w-fit"
								track-color-on="#8AE4A4"
							/>
						</div>

						<button
							type="button"
							class="mt-4 w-full rounded-lg bg-primary-green-400 p-3 text-center font-extrabold text-primary-green-700 disabled:bg-slate-300"
							@click="addCustomItem"
							:disabled="!customModalValidation"
						>
							{{ t('reviewSlide.add') }}
						</button>
					</FormKit>
				</div>
			</template>
		</precoa-modal>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import CartCustomItem from '../merchandise/CartCustomItem.vue'
import PlanAvatar from '../presentation/PlanAvatar.vue'
import type Plan from '@/classes/Plan'
import PrecoaModal from '@/components/common/PrecoaModal.vue'
import CartItem from '@/components/merchandise/CartItem.vue'
import { PbProductType } from '@/enums/PbProductType'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { usePresentationLocale } from '@/i18n'
import {
	ManualAddCartItemType,
	useMerchandise,
	type ManualAddCartItem,
} from '@/mixins/merchandise'
import type { ReviewSlideStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { FormKit } from '@formkit/vue'
import { Cog8ToothIcon, ShieldCheckIcon } from '@heroicons/vue/24/solid'
import { Swiper } from 'swiper'
import { computed, ref, type PropType } from 'vue'

const { t } = usePresentationLocale()
defineProps({
	blok: Object as PropType<ReviewSlideStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

const modalOpen = ref<boolean>(false)
const modalPlan = ref<Plan>()
const manualAddItem = ref<ManualAddCartItem>({
	type: ManualAddCartItemType.Discount,
	title: '',
	description: '',
	amount: '0',
})

const customItemTypeOptions = [
	{
		label: t('reviewSlide.discount'),
		value: ManualAddCartItemType.Discount,
	},
	{
		label: t('reviewSlide.customItem'),
		value: ManualAddCartItemType.CustomItem,
	},
]

const sessionStore = useSessionStore()
const {
	getPlanCartItems,
	formatPrice,
	getPlanLineItemPrice,
	calculateCartTotals,
	getPlanImage,
} = useMerchandise()

const plans = computed(() => {
	return sessionStore.activeSession?.plans
})

const planCartItems = computed(() => {
	return plans.value?.map((plan) => getPlanCartItems(plan.id) ?? [])
})

const customCartItems = computed(() => {
	return plans.value?.map((plan) => plan.customCartItems)
})

const planPrice = computed(() => {
	return plans.value?.map((plan) => calculateCartTotals(plan))
})

const planImages = computed(() => {
	return plans.value?.map((plan) => getPlanImage(plan.id))
})

const openCustomItemModal = (plan: Plan) => {
	modalOpen.value = true
	modalPlan.value = plan
}

const customModalValidation = computed(() => {
	// title, description need to be provided
	if (!manualAddItem.value.title) return false
	if (!manualAddItem.value.description) return false
	if (
		manualAddItem.value.type === ManualAddCartItemType.Discount ||
		manualAddItem.value.type === ManualAddCartItemType.CustomItem
	) {
		return true
	}

	return false
})

const addCustomItem = () => {
	// close Modal
	modalOpen.value = false
	// Add Custom Item to Cart
	modalPlan.value?.addCustomItem({
		title: manualAddItem.value.title,
		description: manualAddItem.value.description,
		guaranteed: manualAddItem.value.guaranteed,
		price:
			manualAddItem.value.type === ManualAddCartItemType.Discount
				? -Math.abs(parseFloat(manualAddItem.value.amount))
				: parseFloat(manualAddItem.value.amount),
		sku: manualAddItem.value.sku,
	})

	// clear form data after add
	manualAddItem.value = {
		type: ManualAddCartItemType.Discount,
		title: '',
		description: '',
		amount: '0',
		sku: '',
	}
}
</script>
