<template>
	<div
		class="relative flex w-2/5 justify-end"
		ref="el"
	>
		<div>
			<div class="font-bold text-[#0C58B7]">
				{{ selectedFHGroup?.name || (isLoading ? 'Loading' : 'No Match') }}
			</div>
			<div
				v-if="selectedFHGroup"
				@click="openLocations"
				class="flex cursor-pointer justify-end text-right text-xs text-[#64748B]"
			>
				<div class="mr-1">
					{{ matchLocationCount }}/{{ totalLocationCount }} Locations
				</div>
				<component
					:is="iconComponent"
					class="h-5 w-5 text-slate-300"
				/>
			</div>
		</div>

		<!-- Show Available Locations -->
		<div
			v-if="showLocations"
			class="absolute bottom-[-5px] left-0 z-10 max-h-[50vh] w-full translate-y-full overflow-y-auto rounded-md bg-white"
		>
			<LocationCard
				v-for="location in allLocations"
				:location="location"
				:is-matched="matchedLocations.includes(location.id)"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import LocationCard from './LocationCard.vue'
import type { V4FuneralHome } from '@/apiTypes'
import { useCrmStore } from '@/stores/crm'
import { useSessionStore } from '@/stores/session'
import CheckCircleIcon from '@heroicons/vue/24/outline/CheckCircleIcon'
import InformationCircleIcon from '@heroicons/vue/24/solid/InformationCircleIcon'
import { computedAsync } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

const crm = useCrmStore()
const sessionStore = useSessionStore()
const showLocations = ref<boolean>(false)
const el = ref<HTMLElement>()
const isLoading = ref<boolean>(false)

const activeStoryblokFHGroupId = computed(() => {
	return sessionStore.activeFHGroupMedia?.slug
})

const selectedFHGroup = computedAsync(async () => {
	if (!activeStoryblokFHGroupId.value) return undefined
	// show loading status
	isLoading.value = true
	const fh = await crm.getFuneralHomeGroupById(activeStoryblokFHGroupId.value)
	isLoading.value = false
	return fh
})

const allLocations = computed(() => {
	return selectedFHGroup.value?.funeralHomes?.data
})

const matchedLocations = computed(() => {
	const matchedIds = []
	const locationIds =
		allLocations.value?.reduce<Record<string, boolean>>(
			(prev, curr: V4FuneralHome) => {
				prev[curr.id] = true
				return prev
			},
			{}
		) ?? {}

	const locations = sessionStore.activeFHGroupMedia?.content.locations
	if (locations) {
		for (let i = 0; i < locations.length; i++) {
			if (locationIds[locations[i].id]) {
				matchedIds.push(locations[i].id)
			}
		}
	}

	return matchedIds
})

const matchLocationCount = computed(() => {
	return matchedLocations.value.length
})

const totalLocationCount = computed(() => allLocations.value?.length ?? 0)

const allMatched = computed(
	() => totalLocationCount.value === matchLocationCount.value
)

const iconComponent = computed(() => {
	return allMatched.value ? CheckCircleIcon : InformationCircleIcon
})

const openLocations = () => {
	showLocations.value = true
}

onMounted(() => {
	const clickOutsideHandler = (event: MouseEvent) => {
		if (el.value && !el.value.contains(event.target as Node)) {
			showLocations.value = false
		}
	}

	document.addEventListener('click', clickOutsideHandler)
})
</script>
