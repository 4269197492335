<template>
	<div
		v-if="blok"
		v-editable="blok"
		class="space-y-2 md:space-y-5"
	>
		<!-- HeadingSmall field-->
		<HeadingSmall
			v-if="blok.headingSmall"
			:headingText="blok.headingSmall"
		/>

		<!-- HeadingLarge field-->
		<HeadingLarge :richTextHtml="richTextHtml" />
	</div>
</template>

<script setup lang="ts">
import HeadingLarge from '../presentation/HeadingLarge.vue'
import HeadingSmall from '../presentation/HeadingSmall.vue'
import type { HeadingStoryblok } from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<HeadingStoryblok>,
})

const richTextHtml = computed(() => renderRichText(props.blok?.headingLarge))
</script>
