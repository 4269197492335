<template>
	<div
		v-editable="blok"
		:ref="(el) => (rootDiv = el)"
		class="v-full grid h-full grid-cols-2-media grid-rows-1 bg-white fluid"
	>
		<form
			class="flex w-full flex-col justify-center"
			:class="{ 'px-16 py-8': !blok?.noPadding }"
			@submit.stop.prevent="$emit('submit')"
		>
			<template
				v-for="b in props.blok?.content"
				:key="b._uid"
			>
				<component
					v-if="rootDiv"
					:blok="b"
					:slideIndex="slideIndex"
					:parentSwiper="parentSwiper"
					:parentDivRef="rootDiv"
					:is="b.component"
					:isLargeSize="false"
				/>
			</template>
			<button
				type="submit"
				class="hidden"
			></button>
		</form>
		<SbMedia
			:active="active"
			:blok="media"
		/>
	</div>
</template>

<script setup lang="ts">
import SbMedia from '@/components/blocks/SbMedia.vue'
import type { TwoColumnContentAssetStoryblok } from '@/sbTypes'
import { Swiper } from 'swiper'
import { type PropType, ref, computed } from 'vue'

const rootDiv = ref()
const props = defineProps({
	blok: Object as PropType<TwoColumnContentAssetStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

const media = computed(() => props.blok?.media[0])
</script>
