<template>
	<button
		v-editable="blok"
		type="button"
		class="rounded-md border bg-white px-4 py-2"
		@click="gotoNextChapter()"
	>
		{{ blok?.label }}
	</button>
</template>

<script setup lang="ts">
import { usePresentationController } from '@/stores/presentationController'

const { gotoNextChapter } = usePresentationController()

const { blok } = defineProps({
	blok: Object,
})
</script>
