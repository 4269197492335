<template>
	<div class="flex gap-2 pb-4">
		<CopyButton
			v-for="button in buttonNames"
			:button-index="button.buttonIndex"
			:field-or-section="fieldOrSection"
			:key="button.buttonIndex"
			:name="button.name"
			@copy="handleCopy"
			type="button"
		/>
	</div>
</template>

<script setup lang="ts">
import CopyButton from '@/components/presentation/CopyButton.vue'
import { useSessionStore } from '@/stores/session'
import { computed } from 'vue'
import { usePresentationLocale } from '@/i18n'

const { t } = usePresentationLocale()

const props = defineProps({
	fieldOrSection: {
		type: [String, Array] as unknown as () => string | string[],
		required: false,
	},
	activeIndex: {
		type: Number,
		required: true,
	},
})

const emits = defineEmits(['copy', 'click'])
const session = useSessionStore()

/**
 * Computes an array of objects containing the name and button index for each plan except the active one.
 * The name is either the plan's first name in uppercase or "PERSON X" where X is the person count if the plan has no name.
 * @returns {Array<Object<string, number>>} An array of objects with name and buttonIndex properties.
 */
const buttonNames = computed(() => {
	const names: { name: string; buttonIndex: number }[] = []
	const activeIndex = props.activeIndex

	if (!session.activeSession?.plans) return names

	let personCount = 1

	session.activeSession?.plans.forEach((plan, buttonIndex) => {
		if (buttonIndex !== activeIndex) {
			let name: string

			if (plan.firstName) {
				name = plan.firstName.toUpperCase()
			} else {
				name = `${t('copyButton.person').toUpperCase()} ${personCount++}`
			}

			names.push({ name, buttonIndex })
		}
	})
	return names
})

/**
 * Handles the copy functionality for a specific button index.
 * @param {number} buttonIndex - The index of the button to handle the copy for.
 */
function handleCopy(buttonIndex: number) {
	emits('click', buttonIndex, props.activeIndex)

	if (props.fieldOrSection) {
		session.activeSession?.copy(
			props.fieldOrSection,
			buttonIndex,
			props.activeIndex
		)
	}
}
</script>

<style scoped>
/* Component styles here */
</style>
