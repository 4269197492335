Component for working with the active presentation session
<template>
	<div class="h-full w-full">
		<router-view
			v-if="sessionStore.activeSession"
			v-slot="{ Component }"
		>
			<component :is="Component" />
		</router-view>
	</div>
</template>

<script setup lang="ts">
import { Session } from '@/classes/Session'
import { useSessionStore } from '@/stores/session'
import { watchThrottled } from '@vueuse/core'
import { onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

const sessionStore = useSessionStore()
const router = useRouter()

const props = defineProps({
	sessionId: String,
})

onMounted(async () => {
	// Try to load the session that the URL is asking for
	const savedSessions = await sessionStore.getSavedSessionIds()

	if (!props.sessionId) {
		// Not sure this branch is possible
		router.push({ name: 'new-session' })
	} else {
		if (savedSessions.includes(props.sessionId)) {
			// Load the saved session
			await sessionStore.loadSession(props.sessionId)

			// Save the session whenever it is updated
			watchThrottled<Session | undefined>(
				() => sessionStore.activeSession,
				(newValue) => {
					console.debug('Saving session', newValue)

					if (newValue === undefined) {
						return
					}

					// Save locally
					sessionStore.saveActiveSession()

					// Save remotely
					sessionStore.submitUpdatedSession(newValue.id)
				},
				{ deep: true, throttle: 4000 }
			)
		} else {
			throw new Error(
				"URL is requesting a session we don't have in storage: " +
					props.sessionId
			)
		}
	}
})

onUnmounted(() => {
	sessionStore.activeSession = undefined
})
</script>
