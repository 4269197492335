<template>
	<TransitionRoot
		as="template"
		:show="open"
	>
		<Dialog
			as="div"
			class="relative z-50"
			@close="onClose"
		>
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
				></div>
			</TransitionChild>

			<div class="fixed inset-0 z-50 w-screen">
				<div
					class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							:class="[
								isModalForValueInput
									? 'precoa-modal-size'
									: 'sm:w-full sm:max-w-lg',
								'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8',
							]"
						>
							<button
								v-if="canClose"
								type="button"
								@click="onClose"
								title="Cancel"
								class="absolute right-4 top-4 z-50 h-6 w-6 stroke-slate-300 hover:stroke-slate-700"
							>
								<XMarkIcon />
							</button>
							<FormKit
								type="form"
								@submit="onSubmit"
								:actions="false"
							>
								<div class="flex h-full flex-col">
									<div
										class="grow overflow-hidden bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
									>
										<div class="mt-3 text-center sm:mt-0 sm:text-left">
											<DialogTitle
												v-if="modalTitle"
												class="text-center font-sans text-base font-bold leading-normal text-slate-900"
											>
												{{ modalTitle }}
											</DialogTitle>
										</div>
										<slot></slot>
									</div>
									<div class="px-4 py-3 sm:flex sm:flex-row sm:px-6">
										<slot name="buttons"></slot>
									</div>
								</div>
							</FormKit>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<style>
.precoa-modal-size {
	height: 90vh;
	width: 80vw;
}

.precoa-modal-size .formkit-form {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media screen and (min-width: 768px) {
	.precoa-modal-size {
		height: 60vh;
		max-width: 768px;
	}
}
</style>

<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import {
	Dialog,
	DialogPanel,
	DialogTitle,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'

defineEmits<{
	submit: [formData: any]
}>()
/*
 *
 *  */
const props = withDefaults(
	defineProps<{
		canClose?: boolean
		modalTitle?: string
		isModalForValueInput?: boolean

		// OnSubmit passed as a function so it can return a promise to FormKit
		onSubmit?: (formData: any) => any
	}>(),
	{ canClose: true }
)

const onClose = () => {
	if (props.canClose) open.value = false
}

const open = defineModel<boolean>('open')
</script>
