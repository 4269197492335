<template>
	<div class="h-dvh overflow-y-auto p-8">
		<h1 class="text-3xl font-bold">Components Page</h1>

		<section>
			<h3 class="mb-4 mt-8 text-xl">PictureCard</h3>
			<div class="flex w-full gap-2 space-x-4">
				<div>
					<span>No Image</span>
					<PictureCard label="Title" />
				</div>

				<div>
					<span>Image</span>
					<PictureCard
						label="Title"
						image-url="https://a-us.storyblok.com/f/1019754/775x774/c29ebed80a/fh-home-pic.png"
					></PictureCard>
				</div>
				<div>
					<span>Active</span>
					<PictureCard
						label="Title"
						image-url="https://a-us.storyblok.com/f/1019754/775x774/c29ebed80a/fh-home-pic.png"
						:is-active="true"
					></PictureCard>
				</div>
			</div>
		</section>

		<section class="max-w-lg">
			<h3 class="mb-4 mt-8 text-xl">PictureCardCheckboxes</h3>
			<span class="pb-2">Full Width (fills container) / Fixed Width</span>
			<PictureCardCheckboxes
				v-model="selectedValues"
				name="militaryRecord"
				:options="exampleCheckboxOptions"
			/>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">ButtonSmall</h3>

			<div class="flex w-36 flex-col items-start gap-2 bg-precoa-blue-25 p-2">
				<ButtonSmall>Default</ButtonSmall>
				<ButtonSmall disabled>Disabled</ButtonSmall>
				<ButtonSmall success>Success</ButtonSmall>
			</div>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">CopyButton</h3>

			<div class="flex gap-2 overflow-hidden overflow-x-auto pb-4">
				<CopyButton
					v-for="(plan, index) in copyPlans"
					:key="index"
					:button-index="index"
					:name="plan.firstName"
					type="button"
				/>
			</div>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">PlanAvatar</h3>
			<dl>
				<dt>Standard</dt>
				<dd>
					<PlanAvatar
						:index="0"
						:plan="kevin"
					/>
				</dd>
				<dd>
					<PlanAvatar
						:index="1"
						:plan="nicole"
					/>
				</dd>

				<dt>Filled</dt>
				<dd>
					<PlanAvatar
						:index="0"
						:plan="kevin"
						filled
					/>
				</dd>
				<dd>
					<PlanAvatar
						:index="1"
						:plan="nicole"
						filled
					/>
				</dd>
			</dl>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">LangMultiSelect</h3>
			<lang-multi-select v-model="selectedLanguages" />
			<h4 class="text-large">Selected Languages</h4>
			<p class="p-2">
				{{ selectedLanguages }}
			</p>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">BaseAlert</h3>
			<BaseAlert
				type="warning"
				class="m-4 max-w-72"
				>This is a warning alert.</BaseAlert
			>
			<BaseAlert
				type="error"
				class="m-4 max-w-72"
				>This is an error alert!</BaseAlert
			>
			<BaseAlert
				type="critical"
				class="m-4 max-w-72"
				>This is a critical alert!!!</BaseAlert
			>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">DeckThumbnail</h3>
			<label
				>Download Progress<input
					type="number"
					step="0.1"
					v-model="deckDownloadProgress"
					class="ml-2"
			/></label>
			<div class="grid grid-cols-4 gap-4">
				<DeckThumbnail
					title="Presentation 1"
					:image-url="deckThumbnailUrl"
					:download-progress="deckDownloadProgress"
				/>
				<DeckThumbnail
					title="Presentation 3"
					:image-url="deckThumbnailUrl"
					selected
				/>
				<DeckThumbnail
					:image-url="deckThumbnailUrl"
					title="Presentation 1"
				/>
				<DeckThumbnail
					title="Presentation 1"
					:image-url="deckThumbnailUrl"
					inactive
				/>
				<DeckThumbnail
					title="Presentation 1"
					:image-url="deckThumbnailUrl"
					error
				/>
				<DeckThumbnail
					title="Presentation 1"
					:image-url="deckThumbnailUrl"
					fresh
				/>
				<DeckThumbnail
					title="This is a very, very long presentation name that should be truncated."
					:image-url="deckThumbnailUrl"
					:download-progress="deckDownloadProgress"
				/>
			</div>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">LoginModal</h3>
			<button
				type="button"
				class="font-base rounded bg-blue-500 px-2 py-1 text-white hover:bg-blue-700"
				@click="auth.showLogin = true"
			>
				Open
			</button>
		</section>

		<section>
			<h3 class="mb-4 mt-8 text-xl">CardQuoteList</h3>
			<CardQuoteList :plans="cardQuoteListPlans" />
		</section>

		<hr class="mt-8" />
		<h3 class="mb-4 mt-8 text-2xl font-semibold">
			Storyblok Builder Components
		</h3>
		<section class="py-4">
			<h3 class="mb-4 text-xl">SbHeading (Heading)</h3>
			<SbHeading :blok="headingText" />
		</section>

		<div class="fluid">
			<p class="mt-4 text-lg">
				I will scale like "mt-fluid-4" and "text-fluid-lg"!
			</p>
			<p class="mt-16 text-4xl">
				I will scale like "mt-fluid-4" and "text-fluid-lg"!
			</p>
			<div class="w-96 h-96 bg-red-500">Scale It To Me Baby</div>
			<div class="w-96 h-96 bg-red-500">Scale It To Me Baby</div>
		</div>


		<section class="fluid py-4">
			<h1 class="text-[24px]">Does this work?</h1>
		</section>


		<section class="py-4">
			<h1 class="text-fluid-xs h-fluid-96 w-fluid-96 bg-red-200">Fluid Sizing</h1>
			<h1 class="text-fluid-sm">sm SbHeading (Heading)</h1>
			<h1 class="text-fluid-base">base SbHeading (Heading)</h1>
			<h1 class="text-fluid-lg">lg SbHeading (Heading)</h1>
			<h1 class="text-fluid-xl">xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-2xl">2xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-3xl">3xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-4xl">4xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-5xl">5xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-6xl">6xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-7xl">7xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-8xl">8xl SbHeading (Heading)</h1>
			<h1 class="text-fluid-9xl">9xl SbHeading (Heading)</h1>
			<button class="bg-sky-500 hover:bg-sky-400 ~@lg/2xl:~px-4/8 ~@lg/2xl:~py-2/3.5 ~@lg/2xl:~text-sm/xl rounded-full font-semibold text-white">Fluid button</button>
		</section>

		<section class="prose py-4">
			<h1>Prose</h1>

			<h1>H1 Prose</h1>
			<h2>H2 Prose</h2>
			<h3>H3 Prose</h3>
			<h4>H4 Prose</h4>
			<h5>H5 Prose</h5>
			<h6>H6 Prose</h6>
			<p>P Prose</p>
		</section>


		<section class="prose prose-fluid py-4">
			<h1>Prose Fluid</h1>

			<h1>H1 Prose Fluid</h1>
			<h2>H2 Prose Fluid</h2>
			<h3>H3 Prose Fluid</h3>
			<h4>H4 Prose Fluid</h4>
			<h5>H5 Prose Fluid</h5>
			<h6>H6 Prose Fluid</h6>
			<p>P Prose</p>
			
		</section>
	</div>
</template>

<script setup lang="ts">
import Plan from '@/classes/Plan'
import SbHeading from '@/components/blocks/SbHeading.vue'
import BaseAlert from '@/components/common/BaseAlert.vue'
import PictureCard from '@/components/common/PictureCard.vue'
import ButtonSmall from '@/components/forms/ButtonSmall.vue'
import LangMultiSelect from '@/components/forms/LangMultiSelect.vue'
import DeckThumbnail from '@/components/home/decks/DeckThumbnail.vue'
import PictureCardCheckboxes from '@/components/memorialGuide/PictureCardCheckboxes.vue'
import CardQuoteList from '@/components/presentation/CardQuoteList.vue'
import CopyButton from '@/components/presentation/CopyButton.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { Language } from '@/enums/Language'
import type { HeadingStoryblok } from '@/sbTypes'
import { useAuthStore } from '@/stores/auth'
import { ref } from 'vue'

const auth = useAuthStore()

const selectedLanguages = ref<Language[]>([])

const kevin = ref<Plan>(new Plan())
kevin.value.firstName = 'Kevin'
kevin.value.lastName = 'Wang'

const nicole = ref<Plan>(new Plan())
nicole.value.firstName = 'Nicole'
nicole.value.lastName = 'Wang'

const deckDownloadProgress = ref<number>(0.7)

const deckThumbnailUrl =
	'https://a-us.storyblok.com/f/1019754/2560x1539/e5652825d4/family-gathering.webp'

// Initialize an array of Plan objects with different first names
const copyPlans = ref<Plan[]>(
	Array.from({ length: 8 }, (_, i) => {
		const plan = new Plan()
		plan.firstName = [
			'Tammy',
			'John',
			'Alice',
			'Bob',
			'Carol',
			'David',
			'Eve',
			'Frank',
		][i]
		return plan
	})
)

// Define the plans array with mapped Plan instances directly
const cardQuoteListPlans = ref<Plan[]>(
	[
		{
			firstName: 'Kevin',
			lastName: 'Woodfill',
			mostImportantReason: 'Affordable plans.',
		},
		{
			firstName: 'Jane',
			lastName: 'Doe',
			mostImportantReason:
				'To not burden my children with unnecessary expenses.',
		},
		{
			firstName: 'Tammy',
			lastName: 'Bernard',
			mostImportantReason:
				'Affordable and comprehensive plans. I want to ensure everything is arranged in advance, giving my family peace of mind and avoiding stress during a difficult time.',
		},
		{
			firstName: 'Ben',
			lastName: 'Darrel',
			mostImportantReason:
				'Affordable and comprehensive plans that cover every detail. I want to ensure everything is arranged well in advance so that my family doesn’t have to make difficult decisions or face unexpected financial burdens.',
		},
		{
			firstName: 'Pete',
			lastName: 'Thompson',
			mostImportantReason:
				"Affordable and comprehensive plans that cover every detail. I want to ensure everything is arranged well in advance so that my family doesn’t have to make difficult decisions or face unexpected financial burdens. Preplanning gives them peace of mind, allowing them to focus on grieving and supporting one another without worrying about logistics or costs. It's important to me that I leave things in order, ensuring my loved ones can navigate this challenging time with as little stress as possible. Affordable and comprehensive plans that cover every detail. I want to ensure everything is arranged well in advance so that my family doesn’t have to make difficult decisions or face unexpected financial burdens. Preplanning gives them peace of mind, allowing them to focus on grieving and supporting one another without worrying about logistics or costs. It's important to me that I leave things in order, ensuring my loved ones can navigate this challenging time with as little stress as possible.",
		},
	].map((data) => new Plan(data))
)

const exampleCheckboxOptions = [
	{
		label: 'Full Width Example',
		value: 'Full Width Example',
		imageUrl:
			'https://a-us.storyblok.com/f/1019754/775x774/c29ebed80a/fh-home-pic.png',
		fullWidth: true,
	},
	{
		label: 'Fixed Width Example',
		value: 'Fixed Width Example',
		imageUrl:
			'https://a-us.storyblok.com/f/1019754/775x774/c29ebed80a/fh-home-pic.png',
	},
]

const selectedValues = defineModel<string[]>({
	type: Array,
	default: () => [],
})

const headingText: HeadingStoryblok = {
	headingSmall: 'Small Heading Text',
	headingLarge: {
		type: 'doc',
		content: [
			{
				type: 'paragraph',
				content: [
					{
						type: 'text',
						text: 'Large Italic Heading',
					},
				],
			},
		],
	},
	_uid: '',
	component: 'heading',
}
</script>
