<!--
Presentational alert component with error and warning flavors
-->
<template>
	<div
		class="pointer-events-auto flex flex-row rounded-md p-4 shadow"
		:class="style.background"
	>
		<component
			class="mr-3 h-6 w-6"
			:class="style.iconClass || style.text"
			:is="icon || style.icon"
		/>
		<p
			class="font-medium"
			:class="style.text"
		>
			<slot></slot>
		</p>
	</div>
</template>

<script setup lang="ts">
import {
	XCircleIcon,
	ExclamationCircleIcon,
	ExclamationTriangleIcon,
} from '@heroicons/vue/24/solid'
import { computed, type Component } from 'vue'

const props = defineProps<{
	type: 'critical' | 'error' | 'warning'
	icon?: Component
}>()

type Flavor = {
	icon: Component
	background: string
	text: string
	iconClass?: string
}

const flavors: Record<string, Flavor> = {
	critical: {
		icon: ExclamationCircleIcon,
		background: 'bg-red-500 hover:bg-red-600',
		text: 'text-gray-50',
	},
	error: {
		icon: XCircleIcon,
		background: 'bg-red-50',
		text: 'text-red-500',
	},
	warning: {
		icon: ExclamationTriangleIcon,
		background: 'bg-yellow-50',
		text: 'text-yellow-700',
		iconClass: 'text-yellow-500',
	},
}

const style = computed(() => flavors[props.type])
</script>
