<!-- Component for displaying a list of products and their details -->
<template>
	<div class="flex h-full px-10 pt-14">
		<!-- Sidebar dropdown -->
		<div class="w-1/5 overflow-y-auto border-r border-slate-200 pr-8">
			<ProductDropdown
				v-model="selectedProduct"
				:productPlans="productPlans"
				:productType="props.blok?.productType"
			/>
		</div>

		<!-- Main content (product details) -->
		<div class="w-4/5 pl-10">
			<FormProduct
				v-if="selectedProduct"
				class="h-full items-center"
				twoColumns
				:product="selectedProduct"
				:key="selectedProduct?.id"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { PbLineItem } from '@/apiTypes'
import type { IndexedPlan } from '@/classes/Plan'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import FormProduct from '@/components/merchandise/FormProduct.vue'
import ProductDropdown from '@/components/products/ProductDropdown.vue'
import { useMerchandise } from '@/mixins/merchandise'
import type { ProductsSlideStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { ref, computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<ProductsSlideStoryblok>,
})

const { priceBook, isLineItemInPlan } = useMerchandise()

const sessionStore = useSessionStore()

const plans = computed(() => {
	return sessionStore.activeSession?.plans
})

/**
 * Map products and packages to their corresponding plans.
 */
const productPlans = computed<Record<string, IndexedPlan[]>>(() => {
	if (!priceBook.value?.products && !priceBook.value?.packages) return {}

	const items: PbLineItem[] = [
		...(priceBook.value?.products ?? []),
		...(priceBook.value?.packages ?? []),
	]

	return items.reduce<Record<string, IndexedPlan[]>>((acc, item) => {
		acc[item.id] = (plans.value ?? [])
			.map(
				(plan, index): IndexedPlan => ({
					index,
					plan,
				})
			)
			.filter((indexedPlan) => isLineItemInPlan(item, indexedPlan.plan))

		return acc
	}, {})
})

// Reactive ref for the selected product
const selectedProduct = ref<PbLineItemProductAndPricingOptions | undefined>(
	undefined
)
</script>
