import type { RichTextStoryblok } from '@/sbTypes'
import { faker } from '@faker-js/faker'

export function RichTextFactory(text: string = ''): RichTextStoryblok {
	text = text ?? faker.lorem.sentence()

	return {
		type: 'doc',
		content: [
			{
				type: 'paragraph',
				content: [
					{
						type: 'text',
						text: text,
					},
				],
			},
		],
	}
}
