<template>
	<div
		v-editable="blok"
		:ref="(el) => (rootDiv = el)"
		class="v-full grid h-full grid-cols-1 place-content-center bg-white"
	>
		<form
			class="grid grid-cols-1 place-content-center justify-items-center space-y-8 text-center"
			@submit.stop.prevent="$emit('submit')"
		>
			<template
				v-for="b in props.blok?.content"
				:key="b._uid"
			>
				<component
					v-if="rootDiv"
					:class="
						b.component === 'list-item-slides' ? 'min-w-full p-0' : 'p-16'
					"
					:blok="b"
					:parentSwiper="parentSwiper"
					:slideIndex="slideIndex"
					:parentDivRef="rootDiv"
					:is="b.component"
					isLargeSize
				/>
			</template>
			<button
				type="submit"
				class="hidden"
			></button>
		</form>
	</div>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper'
import { type PropType, ref } from 'vue'

const rootDiv = ref()
const props = defineProps({
	blok: Object,
	parentSwiper: Object as PropType<Swiper>,
	slideIndex: Number,
})
</script>
