export enum PbSubcategory {
	PackageBurial = 'packageBurial',
	PackageCremation = 'packageCremation',

	DispositionFullServiceBurial = 'fullServiceBurial',
	DispositionFullServiceCremation = 'fullServiceCremation',
	DispositionDirectBurial = 'directBurial',
	DispositionDirectCremation = 'directCremation',
	DispositionMemorialServiceCremation = 'memorialServiceCremation',
	DispositionOther = 'dispositionOther',

	ServiceEmbalming = 'embalming',
	ServiceRefrigeration = 'refrigeration',
	ServiceBodyPreparation = 'bodyPreparation',
	ServiceVisitationViewing = 'visitationViewing',
	ServiceFuneralMemorialService = 'funeralMemorialService',
	ServiceGravesideService = 'gravesideService',
	ServiceCremationService = 'cremationService',
	ServiceForwardingRemains = 'forwardingRemains',
	ServiceReceivingRemains = 'receivingRemains',

	TransportationTransfer = 'transportationTransfer',
	TransportationHearse = 'transportationHearse',
	TransportationFamilyCarLimo = 'transportationFamilyCarLimo',
	TransportationUtilityVehicleVan = 'transportationUtilityVehicleVan',
	TransportationOtherTransportation = 'transportationOther',

	MerchandiseCasket = 'MerchandiseCasket',
	MerchandiseOuterBurialContainer = 'MerchandiseOuterBurialContainer',
	MerchandiseUrn = 'MerchandiseUrn',
	MerchandiseRegisterBookMemorialFoldersAckCards = 'MerchandiseRegisterBookMemorialFoldersAckCards',
	MerchandiseOtherMerchandise = 'MerchandiseOther',

	CashAdvanceDeathCertificates = 'cashAdvanceDeathCertificates',
	CashAdvanceObituary = 'cashAdvanceObituary',
	CashAdvanceFlowers = 'cashAdvanceFlowers',
	CashAdvanceHairdresser = 'cashAdvanceHairdresser',
	CashAdvanceClothing = 'cashAdvanceClothing',
	CashAdvanceClergyHonorarium = 'cashAdvanceClergyHonorarium',
	CashAdvanceMusicianVocalist = 'cashAdvanceMusicianVocalist',
	CashAdvanceMusic = 'cashAdvanceMusic',
	CashAdvanceOtherCashAdvance = 'cashAdvanceOther',

	FamilyTravelAirfare = 'familyTravelAirfare',
	FamilyTravelLodging = 'familyTravelLodging',

	CemeteryEscorts = 'cemeteryEscorts',
	CemeteryInternment = 'cemeteryInternment',
	CemeteryPlot = 'cemeteryPlot',
	CemeteryMausoleum = 'cemeteryMausoleum',
	CemeteryColumbarium = 'cemeteryColumbarium',
	CemeteryVaultSetting = 'cemeteryVaultSetting',
	CemeteryMonumentMarker = 'cemeteryMonumentMarker',
	CemeteryOtherCemetery = 'cemeteryOther',

	AfterwardLocation = 'afterwardLocation',
	AfterwardCatering = 'afterwardCatering',
	Afterwardother = 'afterwardOther',

	OtherFamilyAllowance = 'otherFamilyAllowance',
	OtherSalesTax = 'otherSalesTax',
	Other = 'other',
}
