<!-- A component to display card radio options -->
<template>
	<RadioGroup
		v-model="value"
		class="w-full"
	>
		<div class="flex w-full space-x-4">
			<RadioGroupOption
				v-for="radio in options"
				:key="radio.value"
				:value="radio.value"
				v-slot="{ checked }"
				class="h-full w-full cursor-pointer print:w-auto"
			>
				<!-- Normal View -->
				<component
					:is="radio.icon"
					:is-active="checked"
					class="print:hidden"
				>
					<slot name="label">
						<span class="pt-4">{{ radio.label }}</span>
					</slot>
				</component>
				<!-- Print View -->
				<div class="hidden space-x-6 print:flex">
					<label class="child flex cursor-pointer items-center space-x-1.5">
						<input
							type="checkbox"
							:value="radio.value"
							:checked="value === radio.value"
							class="h-4 w-4 rounded border-gray-300 text-green-500 focus:ring-green-500"
						/>
						<span>{{ radio.label }}</span>
					</label>
				</div>
			</RadioGroupOption>
		</div>
	</RadioGroup>
</template>

<script setup lang="ts">
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'

interface Option {
	value: string
	icon: any
	label: string
}

interface Props {
	options: Option[]
}

defineProps<Props>()
const value = defineModel<string>()
</script>
