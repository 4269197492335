<!-- View for chit-chatting -->
<template>
	<div
		class="relative h-full w-full place-content-center"
		@click="onTap"
	>
		<SbMedia
			v-if="background"
			:blok="background"
			:active="active"
		></SbMedia>

		<SbImage
			v-if="logo"
			:blok="logo"
			class="absolute left-1/2 top-1/2 max-w-[300px] -translate-x-1/2 -translate-y-1/2 transform"
		/>

		<h2
			class="absolute bottom-10 right-1/2 translate-x-1/2 text-2xl font-bold"
			:class="useDarkStartText ? 'text-slate-900' : 'text-white'"
		>
			TAP TO START
		</h2>
	</div>
</template>

<script setup lang="ts">
import SbImage from '@/components/blocks/SbImage.vue'
import SbMedia from '@/components/blocks/SbMedia.vue'
import type { SbMediaStoryblok, FuneralHomeLogoSlideStoryblok } from '@/sbTypes'
import { useFuneralHomeGroupMediaController } from '@/stores/funeralHomeGroupMediaController'
import { usePresentationController } from '@/stores/presentationController'
import { useSessionStore } from '@/stores/session'
import { Swiper } from 'swiper'
import { computed, type PropType } from 'vue'

const sessionStore = useSessionStore()
const presentationController = usePresentationController()
const fhController = useFuneralHomeGroupMediaController()

const props = defineProps({
	blok: Object as PropType<FuneralHomeLogoSlideStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

const activeFhMedia = computed(() => sessionStore.activeFhMedia)

const background = computed<SbMediaStoryblok | undefined>(() => {
	// fetch from activeFhMedia and if no background in media group then use fallback prop
	return activeFhMedia.value?.warmUpBackground ?? props.blok?.background?.[0]
})

const logo = computed(() => {
	// fetch from activeMedia and if no logo in media group then use fallback
	return activeFhMedia.value?.logo ?? props.blok?.logo?.[0].asset
})

/** Take the FH's value if they have their own warm-up background */
const useDarkStartText = computed(() => {
	return activeFhMedia.value?.warmUpBackground
		? activeFhMedia.value.useDarkStartText
		: props.blok?.darkStartText
})

function onTap() {
	presentationController.gotoNextSlide()
	fhController.gotoNextSlide()
}
</script>
