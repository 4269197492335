<!--
Molecule for displaying a session for a new lead in a grid.
If clicked, go to the new session view to resume the session.
-->
<template>
	<router-link :to="{ name: 'newSession', query: { sessionId: session.id } }">
		<div class="ml-3 flex flex-row gap-3">
			<!-- Status -->
			<div
				v-if="status"
				class="flex-0"
			>
				<img
					class="flex-0 h-6 w-6"
					v-if="status.uploadStatus === 'complete'"
					src="/assets/icons/cloud-done.svg"
				/>
				<div v-else>{{ status?.uploadStatus }}</div>
			</div>
			<div v-if="status?.uploadError">{{ status.uploadError }}</div>
			<div
				v-if="
					['failed', 'waitingForNetwork'].includes(status?.uploadStatus || '')
				"
			>
				<button
					class="font-base rounded bg-blue-500 px-2 py-1 text-white hover:bg-blue-700"
					type="button"
					v-if="session"
					@click="retry(session.id)"
				>
					{{ t('general.retry') }}
				</button>
			</div>
			<!-- Name -->
			<div
				v-if="plan"
				class="text-sm font-extrabold text-sky-700"
			>
				{{ plan.firstName }}
				{{ plan.lastName }}
			</div>
			<div
				v-else
				class="text-sm font-extrabold italic text-slate-400"
			>
				{{ t('upcomingAppointments.noPlans') }}
			</div>
		</div>

		<!-- Time -->
		<div>
			{{ startAt }}
		</div>

		<!--Funeral Home Name-->
		<div v-if="funeralHomeName">
			{{ funeralHomeName }}
		</div>
		<div
			v-else
			class="italic text-slate-400"
		>
			{{ t('upcomingAppointments.noFuneralHome') }}
		</div>

		<!--Lead Source-->
		<div>{{ t('upcomingAppointments.newLead') }}</div>

		<!-- Result button -->
		<div
			data-test-id="result-button"
			class="justify-self-end"
		>
			<a
				:href="resultHref"
				@click.stop
			>
				<ButtonSmall>
					{{ t('upcomingAppointments.resultNewLead') }}
				</ButtonSmall>
			</a>
		</div>
	</router-link>
</template>

<script setup lang="ts">
import type { Session } from '@/classes/Session'
import ButtonSmall from '@/components/forms/ButtonSmall.vue'
import { useCrmStore } from '@/stores/crm'
import { useSessionStore } from '@/stores/session'
import { DateTime } from 'luxon'
import { computed, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sessionStore = useSessionStore()
const crm = useCrmStore()

const props = defineProps({
	session: {
		type: Object as PropType<Session>,
		required: true,
	},
})

/** The human-readable start time. Includes "AM" if it starts in the morning and ends in the evening. */
const startAt = computed(() => {
	if (props.session.startAt === null) return ''

	// Format like 1:00 PM
	let time = DateTime.fromISO(props.session.startAt).toLocaleString(
		DateTime.TIME_SIMPLE
	)

	return time
})

const funeralHomeName = computed(() =>
	props.session.FuneralHomeLocationId
		? crm.findFuneralHome(props.session.FuneralHomeLocationId)?.name
		: undefined
)

const plan = computed(() =>
	props.session.plans.length > 0 ? props.session.plans[0] : null
)

/** Get the upload status for a session by id */
const status = computed(() =>
	props.session
		? sessionStore.requestQueue.getRequestStatus(props.session.id)
		: null
)

/** URL for resulting the session */
const resultHref = computed(() => {
	return import.meta.env.VITE_WEB_PORTAL_ROOT + `/#/add-lead`
})

/** Retry saving a single session */
function retry(id: string) {
	sessionStore.requestQueue.retry(id)
}
</script>
