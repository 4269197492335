<!-- Form for picking a predefined price or inputting your own -->
<template>
	<div>
		<div
			class="grid-auto-rows-auto grid w-full grid-cols-1"
			v-if="product && activePlan"
		>
			<div
				v-for="option in product.pricingOptions"
				:key="option.id"
				class="my-2 flex cursor-pointer rounded-lg border-2 p-4 pt-6 shadow-lg"
				:class="
					isLineItemInPlan(product, activePlan, option.price)
						? 'border-primary-green-500'
						: 'border-transparent'
				"
				data-test-id="price-option-card"
				@click="clickPriceOption(option.price)"
			>
				<div class="flex-1">
					<div class="flex">
						<div class="font-bold text-slate-500">{{ option.name }}</div>
						<!-- Recommended -->
						<div
							v-if="product.recommendedPricingOptionId === option.id"
							class="ml-4 flex items-center text-xs font-bold uppercase tracking-wider text-primary-green-500"
						>
							<StarIcon class="mr-1 h-5 w-5" />
							{{ t('priceOptions.recommended') }}
						</div>
					</div>
					<div class="text-[44px] font-bold text-slate-900">
						{{ formatPrice(option.price) }}
					</div>
				</div>
				<PlanAvatar
					class="mt-5"
					:plan="activePlan"
					:index="activePlanIndex"
					:filled="isLineItemInPlan(product, activePlan, option.price)"
				/>
			</div>
			<!-- Custom User Input Price -->
			<div
				class="overflow-hidden transition-all duration-300 ease-in-out"
				:class="showOtherPriceOption ? 'max-h-40' : 'max-h-0'"
			>
				<div
					class="my-2 flex cursor-pointer rounded-lg border-2 p-4 pt-6 shadow-lg"
					:class="
						isOtherSelected ? 'border-primary-green-500' : 'border-transparent'
					"
					@click="clickCustomPriceOption()"
					data-test-id="user-input-price-option-card"
				>
					<div class="flex-1">
						<div class="font-bold text-slate-500">
							{{ t('priceOptions.other') }}
						</div>
						<FormKit
							type="form"
							:actions="false"
							:key="activePlanIndex"
						>
							<FormKit
								type="number"
								name="otherPrice"
								outer-class="my-3 rounded-lg"
								v-model="otherPrice"
								@click="(e: MouseEvent) => e.stopPropagation()"
								step="1"
							>
								<template #prefix>
									<span class="mr-2 text-slate-400">$</span>
								</template>
							</FormKit>
						</FormKit>
					</div>
					<PlanAvatar
						class="ml-5 mt-5"
						:plan="activePlan"
						:index="activePlanIndex"
						:filled="isOtherSelected"
					/>
				</div>
			</div>
			<!-- Show/Hide Custom Input -->
			<div
				class="my-3 flex w-fit cursor-pointer items-center"
				@click="showHideCustomInput"
			>
				<component
					:is="showOtherPriceOption ? MinusCircleIcon : PlusCircleIcon"
					class="h-6 w-6 text-slate-300"
				/>
				<span class="ml-2 font-bold tracking-tight text-slate-400">{{
					showOtherPriceOption ? 'Less' : 'More'
				}}</span>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import PlanAvatar from '../presentation/PlanAvatar.vue'
import { type PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import { usePresentationLocale } from '@/i18n'
import { useMerchandise } from '@/mixins/merchandise'
import { useSessionStore } from '@/stores/session'
import { FormKit } from '@formkit/vue'
import {
	StarIcon,
	PlusCircleIcon,
	MinusCircleIcon,
} from '@heroicons/vue/24/solid'
import { computed, onMounted, ref, watch, type PropType } from 'vue'

const { t } = usePresentationLocale()

const { formatPrice, isLineItemInPlan, findLineItemInPlan } = useMerchandise()
const props = defineProps({
	product: Object as PropType<PbLineItemProductAndPricingOptions>,
	activePlanIndex: {
		type: Number,
		required: true,
	},
})
const sessionStore = useSessionStore()

const activePlan = computed(() => {
	return sessionStore.activeSession?.plans[props.activePlanIndex]
})

const showOtherPriceOption = ref<boolean>(false)
const otherPrice = ref<string>('0')
const isOtherSelected = computed(() => {
	if (props.product === undefined || !activePlan.value) return false

	const existingCartItem = findLineItemInPlan(props.product, activePlan.value)

	if (existingCartItem) {
		// if there's match with defiend custom price type then
		if (
			props.product.pricingOptions.find(
				(p) => p.price === existingCartItem?.price
			)
		)
			return false
		else return true
	}
	return false
})

function clickCustomPriceOption() {
	if (props.product === undefined) throw new Error('Product is undefined')

	if (!activePlan.value) throw new Error('Plan is undefined')

	if (isOtherSelected.value) {
		const existingCartItem = findLineItemInPlan(
			props.product,
			activePlan.value,
			parseFloat(otherPrice.value)
		)
		if (existingCartItem) {
			activePlan.value.delete(existingCartItem)
		}
	} else {
		activePlan.value.addCartProduct(
			props.product.id,
			1,
			parseFloat(otherPrice.value)
		)
	}
}

onMounted(() => {
	if (props.product === undefined) throw new Error('Product is undefined')
	if (!activePlan.value) throw new Error('Plan is undefined')

	if (isOtherSelected.value) {
		const existingCartItem = findLineItemInPlan(props.product, activePlan.value)

		if (existingCartItem) {
			showOtherPriceOption.value = true
			otherPrice.value = String(existingCartItem.price ?? 0)
		}
	}
})

// When Tab changes, initialize the other price input with correct price
watch(
	() => props.activePlanIndex,
	() => {
		if (props.product === undefined) throw new Error('Product is undefined')
		if (!activePlan.value) throw new Error('Plan is undefined')

		if (isOtherSelected.value) {
			const existingCartItem = findLineItemInPlan(
				props.product,
				activePlan.value
			)

			otherPrice.value = existingCartItem ? String(existingCartItem.price) : '0'
			
			showOtherPriceOption.value = true
		} else {
			otherPrice.value = '0'
			showOtherPriceOption.value = false
		}
	}
)

watch(otherPrice, (newPrice, oldPrice) => {
	if (props.product === undefined) throw new Error('Product is undefined')
	if (!activePlan.value) throw new Error('Plan is undefined')

	if (isOtherSelected.value) {
		activePlan.value.addCartProduct(props.product.id, 1, parseFloat(newPrice))
	}
})

/** Add the item to the cart at the specified price, or remove it if it already exists */
function clickPriceOption(price: number) {
	if (props.product === undefined) throw new Error('Product is undefined')

	if (!activePlan.value) throw new Error('Plan is undefined')

	const existingCartItem = findLineItemInPlan(
		props.product,
		activePlan.value,
		price
	)
	if (existingCartItem) {
		activePlan.value.delete(existingCartItem)
	} else {
		activePlan.value.addCartProduct(props.product.id, 1, price)
	}
}

function showHideCustomInput() {
	showOtherPriceOption.value = !showOtherPriceOption.value

	// When collapse, if other price is selected then remove selection.
	if (!showOtherPriceOption.value && isOtherSelected.value) {
		if (props.product === undefined) throw new Error('Product is undefined')
		if (!activePlan.value) throw new Error('Plan is undefined')

		const existingCartItem = findLineItemInPlan(props.product, activePlan.value)

		if (existingCartItem) {
			activePlan.value.delete(existingCartItem)
		}
	}
}
</script>
