import type { PbLineItem } from '@/apiTypes'
import type Plan from '@/classes/Plan'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import { PbProductType } from '@/enums/PbProductType'
import { PbSubcategory } from '@/enums/PbSubcategory'
import {
	isCartSubItem,
	isPbLineItemPackage,
	isPbLineItemProductAndPricingOptions,
} from '@/guards'
import { useSessionStore } from '@/stores/session'
import type { CartItem, CartSubItem } from '@/types'
import { computed } from 'vue'

/** CartSubItem with included lineItem */
export type CartSubItemAndLineItem = CartSubItem & {
	lineItem: PbLineItem | undefined
}

/** CartItem with included lineItem */
export type CartItemAndLineItem = CartItem & {
	lineItem: PbLineItem | undefined
	subItems: CartSubItemAndLineItem[]
}

export enum ManualAddCartItemType {
	Discount = 'discount',
	CustomItem = 'customItem',
}

export type ManualAddCartItem = {
	type: ManualAddCartItemType
	title: string
	description: string
	sku?: string
	amount: string
	guaranteed?: boolean
}

/** Extra information about the price of a single item */
export type PricingDetails = {
	price: number
	quantity?: number
	originalPrice: number

	guaranteed: number
	nonGuaranteed: number
}

/** Composable for merchandise helper methods */
export function useMerchandise() {
	const session = useSessionStore()
	const priceBook = computed(() => session.activePriceBook)

	/** Return a list of the CartItems in the plan, with the lineItem data included */
	function getPlanCartItems(planId: string): CartItemAndLineItem[] | undefined {
		const plan = session.activeSession?.plans.find((p) => p.id === planId)
		return plan?.cartItems.map((i) => ({
			...i,
			lineItem: priceBook.value?.findLineItem(i.lineItemId),
			subItems: i.subItems.map((subItem) => ({
				...subItem,
				lineItem: priceBook.value?.findLineItem(subItem.lineItemId),
			})),
		}))
	}

	/** Format a price number as a string */
	function formatPrice(price: number | null | undefined) {
		if (price === undefined || price === null || Number.isNaN(price)) return ''
		if (typeof price !== 'number')
			throw new Error('Cannot format price: it is not a number')

		return '$' + price.toFixed(2)
	}

	/** Get the CartItem or CartSubItem in the plan that matches the given parameters */
	function findLineItemInPlan(
		lineItem: PbLineItem,
		plan: Plan,
		price: number | null = null
	): CartItem | CartSubItem | undefined {
		const rootItem = plan.cartItems.find((item) => {
			// Ignore if soft deleted
			if (item.isSoftDeleted) return undefined

			// Match if same line item and price (ignore null price query)
			if (
				item.lineItemId === lineItem.id &&
				(price === null || item.price === price)
			) {
				return item
			}
		})

		if (rootItem) {
			return rootItem
		}

		// If not a match, do same search on subItems
		for (const item of plan.cartItems) {
			for (const subItem of item.subItems) {
				if (
					subItem.lineItemId === lineItem.id &&
					!subItem.isSoftDeleted &&
					(price === null || subItem.price === price)
				) {
					return subItem
				}
			}
		}
	}

	/** Returns true if the given line item can be found in the plan by id */
	function isLineItemInPlan(
		lineItem: PbLineItem,
		plan: Plan,
		price: number | null = null
	) {
		return undefined !== findLineItemInPlan(lineItem, plan, price)
	}

	/** Get the price of a cart item to display in the cart */
	function getCartItemPrice(
		plan: Plan,
		cartItem: CartItemAndLineItem | CartSubItemAndLineItem
	): PricingDetails {
		const quantity = cartItem.quantity ?? 1

		// Use override price if available
		if (cartItem.price !== null) {
			const price = cartItem.price * quantity
			return {
				price,
				originalPrice: cartItem.price * quantity,
				quantity,
				guaranteed: cartItem.lineItem?.guaranteed ? price : 0,
				nonGuaranteed: cartItem.lineItem?.guaranteed ? 0 : price,
			}
		}

		// Use same logic as getPlanLineItemPrice if it is not a package
		if (isCartSubItem(cartItem) || cartItem.subItems.length === 0) {
			const base = getPlanLineItemPrice(plan, cartItem.lineItemId)
			if (!base) throw new Error('Cannot find base price for cart item')

			const price = base.price * quantity

			return {
				price,
				originalPrice: base.originalPrice * quantity,
				quantity,
				guaranteed: cartItem.lineItem?.guaranteed ? price : 0,
				nonGuaranteed: cartItem.lineItem?.guaranteed ? 0 : price,
			}
		}

		const originalPrice =
			priceBook.value?.findLineItem(cartItem.lineItemId)?.price ?? 0

		// In the cart, discount packages based on removed sub-items
		const price = cartItem.subItems.reduce<PricingDetails>(
			(acc, subItem) => {
				let discount = 0
				let nonGuaranteed = 0

				const subLineItem = priceBook.value?.findLineItem(subItem.lineItemId)

				if (subItem.isSoftDeleted) {
					discount = subItem.price ?? subLineItem?.price ?? 0
				} else if (!subLineItem?.guaranteed) {
					nonGuaranteed = subItem.price ?? subLineItem?.price ?? 0
				}

				return {
					price: acc.price - discount,
					originalPrice: acc.originalPrice,
					quantity: acc.quantity,
					guaranteed: acc.guaranteed - discount - nonGuaranteed,
					nonGuaranteed: acc.nonGuaranteed + nonGuaranteed,
				}
			},
			{
				price: originalPrice,
				originalPrice,
				quantity: 1,
				guaranteed: originalPrice,
				nonGuaranteed: 0,
			}
		)

		return price
	}

	/**
	 * Get the price to show on a product page for a particular line item.
	 * The price may be lower if a plan already includes a
	 * package with that item or a stand-in for the item (to match by tag).
	 */
	function getPlanLineItemPrice(
		plan: Plan,
		lineItemId: string
	): PricingDetails {
		if (!priceBook.value) throw new Error('Price book not found')

		const lineItem = priceBook.value.findLineItem(lineItemId)
		if (!lineItem) throw new Error('Line item not found: ' + lineItemId)

		// If the item is a package, its price will not change
		if (!isPbLineItemProductAndPricingOptions(lineItem)) {
			console.log('package')
			return {
				price: lineItem.price,
				originalPrice: lineItem.price,
				guaranteed: lineItem.guaranteed ? lineItem.price : 0,
				nonGuaranteed: lineItem.guaranteed ? 0 : lineItem.price,
			}
		}

		let price = lineItem.price
		let originalPrice = lineItem.price

		// Search items in the cart
		for (const cartItem of plan.cartItems) {
			if (cartItem.isSoftDeleted) continue

			const cartLineItem = priceBook.value?.findLineItem(cartItem.lineItemId)
			if (!cartLineItem) throw new Error('Line item not found')

			// If package sub-item is the product or a stand-in for the product,
			// use the sub-item price if lower
			if (qualifiesForDiscount(lineItem) && isPbLineItemPackage(cartLineItem)) {
				for (const subCartItem of cartItem.subItems) {
					if (subCartItem.isSoftDeleted) continue

					const subLineItem = priceBook.value?.findProduct(
						subCartItem.lineItemId
					)
					if (!subLineItem) {
						console.error(subCartItem)
						throw new Error(
							'Product sub line item not found. Is there a package inside a package?',
							{ cause: subCartItem }
						)
					}

					// If package item price was set to 0, discount the entire price.
					// Otherwise, the first value we find is the amount to subtract.
					const discount =
						subCartItem.price === 0
							? lineItem.price
							: (subCartItem.price ?? subLineItem.price)

					if (
						discount != null &&
						(subLineItem.id === lineItem.id ||
							(subLineItem.isStandIn &&
								doLineItemsShareTag(subLineItem, lineItem)))
					) {
						const newPrice = Math.max(0, originalPrice - discount)
						price = Math.min(price, newPrice)
					}
				}
			}
		}

		return {
			price,
			originalPrice,
			guaranteed: lineItem.guaranteed ? price : 0,
			nonGuaranteed: lineItem.guaranteed ? 0 : price,
		}
	}

	/** Returns true if the line item should be discounted by a matching product in a package. */
	function qualifiesForDiscount(lineItem: PbLineItem) {
		if (lineItem.subcategory == null) return false

		return [
			PbSubcategory.MerchandiseCasket,
			PbSubcategory.MerchandiseUrn,
		].includes(lineItem.subcategory)
	}

	/** Get the subtotals for a plan's entire cart */
	function calculateCartTotals(plan: Plan): {
		guaranteedSubTotal: number
		nonGuaranteedSubTotal: number
		total: number
	} {
		let guaranteedSubTotal = 0
		let nonGuaranteedSubTotal = 0
		const cartItems = getPlanCartItems(plan.id) ?? []
		const customItems = plan.customCartItems

		// Add up all the cart items
		cartItems
			.filter((item) => !item.isSoftDeleted)
			.forEach((item) => {
				const price = getCartItemPrice(plan, item)

				guaranteedSubTotal += price.guaranteed
				nonGuaranteedSubTotal += price.nonGuaranteed
			})

		// Add up the custom items
		for (let i = 0; i < customItems.length; i++) {
			if (customItems[i].isSoftDeleted) continue

			if (customItems[i].guaranteed) {
				guaranteedSubTotal += customItems[i].price
			} else {
				nonGuaranteedSubTotal += customItems[i].price
			}
		}
		return {
			guaranteedSubTotal,
			nonGuaranteedSubTotal,
			total: guaranteedSubTotal + nonGuaranteedSubTotal,
		}
	}

	/** Returns true if the given line item has the given tag */
	function doesLineItemHaveTag(
		lineItem: PbLineItemProductAndPricingOptions,
		tagId: string
	) {
		return lineItem.tags.some((tag) => tag.id === tagId)
	}

	/** Returns true if the two line items have at least one tag in common */
	function doLineItemsShareTag(
		lineItem1: PbLineItemProductAndPricingOptions,
		lineItem2: PbLineItemProductAndPricingOptions
	) {
		return lineItem1.tags.some((tag) =>
			lineItem2.tags.some((t) => t.id === tag.id)
		)
	}

	function getPlanImage(planId: string): string | undefined {
		const cartItems = getPlanCartItems(planId) ?? []

		if (cartItems.length <= 0) return

		// if package then show package image
		const pkg = cartItems.find(
			(c) => c.lineItem?.category === PbProductType.Package
		)
		if (pkg) return pkg.lineItem?.imageUrl

		// if no package then find casket
		const casket = cartItems.find(
			(c) => c.lineItem?.subcategory === PbSubcategory.MerchandiseCasket
		)
		if (casket) return casket.lineItem?.imageUrl

		// if no casket then find urn
		const urn = cartItems.find(
			(c) => c.lineItem?.subcategory === PbSubcategory.MerchandiseUrn
		)
		if (urn) return urn.lineItem?.imageUrl

		// if no package, casket, or urn then find most expensive one

		let topPrice = -1
		let imageURL = cartItems[0].lineItem?.imageUrl
		for (let i = 0; i < cartItems.length; i++) {
			if ((cartItems[i].lineItem?.price ?? 0) > topPrice) {
				topPrice = cartItems[i].lineItem?.price ?? 0
				imageURL = cartItems[i].lineItem?.imageUrl
			}
		}

		return imageURL
	}

	/** Get the estimated total affected by inflation */
	function calculateInflationEstimate(
		total: number,
		year: number,
		rate: number | undefined
	): number {
		if (rate === undefined) return total
		return total * Math.pow((100 + rate) / 100, year)
	}

	return {
		findLineItemInPlan,
		formatPrice,
		getPlanCartItems,
		getCartItemPrice,
		getPlanLineItemPrice,
		isLineItemInPlan,
		doesLineItemHaveTag,
		priceBook,
		calculateCartTotals,
		qualifiesForDiscount,
		getPlanImage,
		calculateInflationEstimate,
	}
}
