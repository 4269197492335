<template>
	<FormKitProvider :config="formKitConfig">
		<RouterView v-if="loaded && auth.user" />
		<LoginView v-else-if="loaded && !auth.user" />
		<div v-else>Loading...</div>
		<login-modal />
	</FormKitProvider>
</template>

<script setup lang="ts">
import { appLocale } from './i18n'
import { useContentStore } from './stores/content'
import { useCrmStore } from './stores/crm'
import { useSessionStore } from './stores/session'
import { useSyncStore } from './stores/sync'
import { localizedConfig } from '@/../formkit.config'
import LoginModal from '@/components/common/LoginModal.vue'
import { useAuthStore } from '@/stores/auth'
import LoginView from '@/views/LoginView.vue'
import { type FormKitOptions } from '@formkit/core'
import { FormKitProvider } from '@formkit/vue'
import { captureException, setUser } from '@sentry/vue'
import { onMounted, ref, computed } from 'vue'

/*** Data ***/
const auth = useAuthStore()
const loaded = ref(false)

/** Config to localize FormKit according to the App's language */
const formKitConfig = computed<FormKitOptions>(() => localizedConfig(appLocale))

onMounted(async () => {
	const crm = useCrmStore()
	const session = useSessionStore()
	const content = useContentStore()
	const sync = useSyncStore()

	const localUser = auth.getUserFromLocal()
	const serverUser = auth.getUserFromServer()

	// Check if the user is on Windows
	const isWindows = navigator.userAgent.indexOf('Win') !== -1

	// Apply the scrollbar windows class based on whether or not it is Windows
	const appElement = document.getElementById('app')
	if (appElement) {
		if (isWindows) {
			appElement.classList.add('windows')
		} else {
			appElement.classList.remove('windows')
		}
	}

	// Load data. Continue if one auth attempt succeeds
	await Promise.allSettled([localUser, serverUser])

	setUser({ id: auth.user?.hub.id, email: auth.user?.hub.email })

	if (auth.user) {
		await Promise.all([
			auth.getStoryBlokToken().catch((e) => captureException(e)),
			crm.getAppointmentsFromStorage(),
			crm.getFuneralHomesFromStorage(),
			session.loadAll(),
			content.loadDecksFromCache(),
			content.loadFHGroupMediasFromCache(),
		])

		// Start getting thumbnails from cache and fetch any missing assets from server
		content.fetchMissingAssets()

		// Try uploading all our stuff and downloading new stuff
		sync.beginSync()
	}

	loaded.value = true
})
</script>
