<!--
	View for downloading a presentation from Storyblok and display it with dummy data.
	This is only used with the Storyblok visual editor.
-->
<template>
	<SwiperPresentation mode="FuneralHome" />
</template>

<script setup lang="ts">
import SwiperPresentation from '@/components/presentation/SwiperPresentation.vue'
import { DeckFlavor } from '@/enums/Language'
import { isUnauthorizedError } from '@/errors/UnauthorizedError'
import type { FuneralHomeGroupMediaStoryblok } from '@/sbTypes'
import { getFuneralHomeGroupMedia } from '@/services/storyblokService'
import { useAuthStore } from '@/stores/auth'
import { useContentStore } from '@/stores/content'
import { useFuneralHomeGroupMediaController } from '@/stores/funeralHomeGroupMediaController'
import { useSessionStore } from '@/stores/session'
import type { StoryblokGroupMedia } from '@/types'
import SessionFactory from '@factories/SessionFactory'
import { useStoryblokBridge, type ISbStoryData } from '@storyblok/vue'
import { onMounted, nextTick, type PropType } from 'vue'

const session = useSessionStore()
const content = useContentStore()
const swiperController = useFuneralHomeGroupMediaController()

const props = defineProps({
	v4FhGroupId: String,
	lang: String as PropType<DeckFlavor>,
})

/** Set the active chapter to the one indicated by the given slug */
async function setTestFHGroupMedia(FHGroupMedia: StoryblokGroupMedia) {
	content.setTestFHGroupMedias([FHGroupMedia])

	await nextTick()
	swiperController.swiper?.update()
}

/** Update the presentation controller when the slide deck loads */
onMounted(async () => {
	if (!swiperController.swiper) {
		throw new Error('Swiper was unable to mount')
	}

	if (!props.v4FhGroupId) {
		throw new Error('No Funeral Home Group ID provided')
	}

	// Load the slide deck from Storyblok and pass to the content store
	const story = await getFuneralHomeGroupMedia(
		props.v4FhGroupId,
		props.lang || DeckFlavor.Default,
		process.env.NODE_ENV !== 'production'
	).catch((e: any) => {
		if (isUnauthorizedError(e)) {
			console.error('Unauthorized', e)
			useAuthStore().showLogin = true
		}
	})

	if (!story) {
		throw new Error('Unable to load Funeral Home Group Media')
	}

	// Start a new fake session
	session.activeSession = SessionFactory(2, {
		FHGroupMediaId: story.uuid,
		language: story.lang,
	})

	// Update the content store
	await setTestFHGroupMedia(story)

	// Set up listener for visual editor updates
	useStoryblokBridge(
		story.id,
		async (newStory: ISbStoryData<FuneralHomeGroupMediaStoryblok>) => {
			// Lang comes through as a string, so we have to make sure it can
			// be cast to a DeckFlavor

			if (
				Object.values<string>(DeckFlavor).includes(newStory.lang) ||
				!newStory.lang
			) {
				const lang = (newStory.lang as DeckFlavor) || DeckFlavor.Default
				const replacement = {
					...newStory,
					lang,
					uniqueId: lang + ':' + newStory.uuid,
				}

				await setTestFHGroupMedia(replacement)
			} else {
				alert('Unknown deck flavor: ' + newStory.lang)
			}
		}
	)
})
</script>
