<!-- Slide for displaying the memorial guide form -->
<template>
	<div
		class="relative h-full pl-6 pr-8 pt-14 print:mt-8 print:min-w-[1200px] print:max-w-[1200px] print:!p-0"
	>
		<div
			v-if="sessionStore.activeSession"
			class="relative flex h-full overflow-y-scroll print:m-0 print:min-w-[1200px] print:max-w-[1200px]"
		>
			<div
				class="sticky top-0 flex flex-col justify-between pb-4 pr-4 print:hidden"
			>
				<RadioGroup
					v-model="activePlanIndex"
					class="avatars pr-2"
					as="div"
				>
					<div class="flex flex-col items-center justify-center">
						<RadioGroupOption
							v-for="(plan, index) in sessionStore.activeSession?.plans"
							:key="plan.id"
							:value="index"
							class="relative cursor-pointer p-2 focus:outline-none"
							as="div"
							@click="handleAvatarClick(index)"
						>
							<PlanAvatar
								:plan="plan"
								large
								:index="index"
								:filled="index === activePlanIndex"
							/>
						</RadioGroupOption>
						<button
							type="button"
							aria-label="Add"
							@click="addParticipant()"
						>
							<PlusCircleIcon
								class="mt-1 size-6 text-primary-green-400 hover:text-primary-green-500"
								aria-hidden="true"
							/>
						</button>
					</div>
				</RadioGroup>

				<button
					class="group flex border-t border-slate-200 pt-4 text-precoa-blue-500 hover:text-precoa-blue-600"
					@click="handlePrint"
				>
					<PrinterIcon
						class="mr-1 size-6 text-precoa-blue-500 group-hover:text-precoa-blue-600"
						aria-hidden="true"
					/>{{ t('memorialGuide.print') }}
				</button>
			</div>

			<!-- Main Content -->
			<div
				class="w-full"
				v-if="activePlan"
			>
				<h2 class="pb-4 pl-6 text-3xl italic">
					{{
						t(
							'plan.memorialGuideTitle',
							{
								name: activePlanName,
							},
							activePlanName ? 0 : 1
						)
					}}
				</h2>
				<FormKit
					type="form"
					:actions="false"
					:key="activePlanIndex"
					v-model="activePlan"
					outer-class="pb-32"
				>
					<div class="hidden">
						<!-- Total hack.. For some reason date picker is needed at start top level to show other date pickers -->
						<FormKit
							type="date"
							name="birthday"
							label="Birthday"
							help="Select your birthday"
							placeholder="Select a birthday"
						/>
					</div>
					<!-- Vital Info Section -->
					<div class="w-full bg-[#F1F6FE] p-6 print:pb-2">
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.vitalInfo') }}
							<span class="font-normal italic">
								{{ t('plan.sectionSubtitle.usedForDeathCertificate') }}
							</span>
						</h2>

						<section>
							<div
								class="section-grid-row grid grid-cols-4 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="text"
									name="firstName"
									:label="t('person.firstName')"
								/>

								<FormKit
									type="text"
									name="middleName"
									:label="t('person.middleName')"
								/>

								<FormKit
									type="text"
									name="lastName"
									:label="t('person.lastName')"
								/>

								<FormKit
									type="text"
									name="maidenName"
									:label="t('person.maidenName')"
								/>
							</div>

							<CopyButtonList
								:field-or-section="[
									'address',
									'city',
									'state',
									'zip',
									'phone',
									'email',
								]"
								:active-index="activePlanIndex"
								class="print:hidden"
							/>

							<div
								class="section-grid-row grid grid-cols-4 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="text"
									name="address"
									:label="t('person.address')"
								/>

								<FormKit
									type="text"
									name="city"
									:label="t('person.city')"
								/>

								<FormKit
									type="autocomplete"
									:label="t('person.state')"
									name="state"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('person.state'),
										})
									"
									:options="stateOptions"
								/>

								<FormKit
									type="text"
									name="zip"
									:label="t('person.zip')"
									validation="matches: /^\d{5}(-\d{4})?$/"
									validation-visibility="live"
								/>
							</div>

							<div
								class="section-grid-row grid grid-cols-2 *:w-full *:max-w-none *:px-1 *:pb-1"
							>
								<FormKit
									type="tel"
									name="phone"
									:label="t('person.phone')"
									placeholder="xxx-xxx-xxxx"
									:validation="[
										['matches', /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/],
									]"
									:validation-messages="{
										matches: `${t('plan.validationMessages.phone')}`,
									}"
									validation-visibility="live"
								/>
								<FormKit
									type="email"
									:label="t('person.email')"
									name="email"
									validation="email"
									validation-visibility="dirty"
									:placeholder="t('plan.placeholders.email')"
								/>
							</div>
						</section>

						<hr class="my-4 pb-4" />

						<section>
							<div
								class="section-grid-row grid grid-cols-4 *:w-full *:max-w-none *:px-1 *:pb-1"
							>
								<FormKit
									type="date"
									name="birthDate"
									:label="t('plan.birthDate')"
									:validation="[['date_between', pastDateMin, currentDate]]"
									validation-visibility="live"
									:help="
										t('plan.helpText.recorded', {
											helpTextLabel: t('plan.birthDate'),
										})
									"
								/>
								<FormKit
									type="text"
									name="birthCity"
									:label="t('plan.birthCity')"
								/>
								<FormKit
									type="autocomplete"
									:label="t('plan.birthState')"
									name="birthState"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('person.state'),
										})
									"
									:options="stateOptions"
								/>

								<FormKit
									type="mask"
									name="ssn"
									mode="select"
									mask="###-##-####"
									overlay
									:label="t('plan.ssn')"
									:help="
										t('plan.helpText.recorded', {
											helpTextLabel: t('plan.ssn'),
										})
									"
								/>
							</div>

							<div
								class="section-grid-row grid grid-cols-3 *:w-full *:max-w-none *:px-1 *:pb-1"
							>
								<FormKit
									type="text"
									name="occupation"
									:label="t('plan.occupation')"
								/>

								<FormKit
									type="number"
									name="occupationYears"
									:label="t('plan.occupationYears')"
									:value="String(0)"
									step="1"
									validation="between:0,125"
									validation-visibility="live"
								/>

								<FormKit
									type="text"
									name="businessType"
									:label="t('plan.businessType')"
								/>
							</div>

							<div
								class="section-grid-row grid grid-cols-3 *:w-full *:max-w-none *:px-1 *:pb-1"
							>
								<FormKit
									type="dropdown"
									:label="t('plan.educationLevel')"
									name="educationLevel"
									:options="educationLevelOptions"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.educationLevel'),
										})
									"
								/>

								<FormKit
									type="dropdown"
									:label="t('plan.race')"
									name="race"
									:options="raceOptions"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.race'),
										})
									"
								/>

								<FormKit
									type="dropdown"
									:label="t('plan.sex')"
									name="sex"
									:options="sexOptions"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.sex'),
										})
									"
								/>
							</div>

							<div
								class="section-grid-row grid grid-cols-1 *:w-full *:max-w-none *:px-1"
							>
								<FormKit
									type="textarea"
									auto-height
									name="vitalInfoNotes"
									:label="t('plan.notes')"
									:outer-class="{ 'mb-0': true }"
									:placeholder="t('plan.placeholders.vitalInfoNotes')"
									validation="length:0,10000"
									validation-visibility="live"
								/>
							</div>
						</section>
					</div>

					<!-- Spouse Info Section -->
					<div class="w-full bg-[#F1F6FE] p-6 pt-0 print:pb-2">
						<hr class="pb-4" />
						<section>
							<CopyButtonList
								:active-index="activePlanIndex"
								@click="handleCopySpouseInfo"
								class="print:hidden"
							/>

							<FormKit
								id="spouseInfo"
								name="spouseInfo"
								type="group"
							>
								<div
									class="section-grid-row grid grid-cols-4 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="text"
										name="firstName"
										:label="t('plan.spouseInfo.firstName')"
									/>

									<FormKit
										type="text"
										name="middleName"
										:label="t('plan.spouseInfo.middleName')"
									/>

									<FormKit
										type="text"
										name="lastName"
										:label="t('plan.spouseInfo.lastName')"
									/>

									<FormKit
										type="text"
										name="maidenName"
										:label="t('plan.spouseInfo.maidenName')"
									/>
								</div>

								<div
									class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="date"
										name="marriageDate"
										:label="t('plan.spouseInfo.marriageDate')"
										:validation="[['date_between', pastDateMin, currentDate]]"
										validation-visibility="live"
										:help="
											t('plan.helpText.recorded', {
												helpTextLabel: t('plan.spouseInfo.marriageDate'),
											})
										"
									/>

									<FormKit
										type="text"
										name="marriageCity"
										:label="t('plan.spouseInfo.marriageCity')"
									/>
								</div>

								<div
									class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="autocomplete"
										:label="t('plan.spouseInfo.marriageState')"
										name="marriageState"
										:placeholder="
											t('plan.placeholders.select', {
												placeholderLabel: t('plan.spouseInfo.marriageDate'),
											})
										"
										:options="stateOptions"
									/>
									<FormKit
										type="text"
										name="marriageCounty"
										label="County of Marriage"
									/>
								</div>
							</FormKit>
						</section>

						<hr class="my-4 pb-4" />

						<section>
							<div
								class="section-grid-row grid grid-cols-5 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									id="fatherInfo"
									name="fatherInfo"
									type="group"
								>
									<FormKit
										type="text"
										name="firstName"
										:label="t('plan.fatherInfo.firstName')"
									/>

									<FormKit
										type="text"
										name="lastName"
										:label="t('plan.fatherInfo.lastName')"
									/>

									<FormKit
										type="text"
										name="city"
										:label="t('person.city')"
									/>

									<FormKit
										type="autocomplete"
										name="state"
										:label="t('person.state')"
										:placeholder="
											t('plan.placeholders.select', {
												placeholderLabel: t('person.state'),
											})
										"
										:options="stateOptions"
									/>

									<FormKit
										type="text"
										name="county"
										:label="t('person.county')"
									/>
								</FormKit>
							</div>

							<div
								class="section-grid-row grid grid-cols-5 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									id="motherInfo"
									name="motherInfo"
									type="group"
								>
									<FormKit
										type="text"
										name="firstName"
										:label="t('plan.motherInfo.firstName')"
									/>

									<FormKit
										type="text"
										name="maidenName"
										:label="t('plan.motherInfo.maidenName')"
									/>

									<FormKit
										type="text"
										name="city"
										:label="t('person.city')"
									/>

									<FormKit
										type="autocomplete"
										name="state"
										:label="t('person.state')"
										:placeholder="
											t('plan.placeholders.select', {
												placeholderLabel: t('person.state'),
											})
										"
										:options="stateOptions"
									/>

									<FormKit
										type="text"
										name="county"
										:label="t('person.county')"
									/>
								</FormKit>
							</div>
							<div
								class="section-grid-row grid grid-cols-1 *:w-full *:max-w-none *:px-1"
							>
								<FormKit
									type="textarea"
									auto-height
									name="parentalInfoNotes"
									:label="t('plan.notes')"
									:outer-class="{ 'mb-0': true }"
									:placeholder="t('plan.placeholders.parentalInfoNotes')"
									validation="length:0,10000"
									validation-visibility="live"
								/>
							</div>
						</section>
					</div>

					<!-- Military Record Section will go here -->
					<div class="w-full p-6 print:break-inside-avoid print:pb-2">
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.militaryRecord') }}
						</h2>
						<div
							class="section-grid-row grid grid-cols-1 overflow-x-auto px-1 pb-7 *:w-full *:max-w-none print:pb-4"
						>
							<PictureCardCheckboxes
								v-model="activePlan.militaryRecord"
								class="print:hidden"
								name="militaryRecord"
								:options="militaryRecordOptions"
							/>

							<FormKit
								type="checkbox"
								name="militaryRecord"
								outer-class="mt-0 hidden print:block"
								option-class="mb-1.5 md:mb-0"
								options-class="flex print:flex-row print:gap-6 md:items-center pt-2 md:gap-6"
								:label="t('plan.militaryRecord')"
								:options="militaryRecordOptions"
							/>
						</div>
						<div
							class="section-grid-row grid *:w-full *:max-w-none sm:grid-cols-1"
						>
							<FormKit
								type="checkbox"
								name="militaryRequests"
								outer-class="mt-0"
								option-class="mb-1.5 md:mb-0"
								options-class="flex print:flex-row print:gap-6 flex-col md:flex-row md:items-center pt-2 md:gap-6"
								:label="t('plan.militaryRequests')"
								:options="militaryRequestOptions"
							/>
						</div>
					</div>

					<!-- Persons Responsible Section -->
					<div class="w-full p-6 print:break-inside-avoid print:pb-2">
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.personsResponsible') }}
						</h2>

						<CopyButtonList
							field-or-section="peopleResponsible"
							:active-index="activePlanIndex"
							class="print:hidden"
						/>

						<section>
							<FormKit
								id="peopleResponsible"
								name="peopleResponsible"
								type="repeater"
								:draggable="false"
								add-button
								:insert-control="true"
								:up-control="false"
								:down-control="false"
							>
								<div
									class="section-grid-row grid grid-cols-3 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="text"
										name="firstName"
										:label="t('person.firstName')"
									/>
									<FormKit
										type="text"
										name="lastName"
										:label="t('person.lastName')"
									/>
									<FormKit
										type="text"
										name="relationship"
										:label="t('plan.relationship')"
									/>
								</div>
								<div
									class="section-grid-row grid grid-cols-3 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="text"
										name="city"
										:label="t('person.city')"
									/>

									<FormKit
										type="autocomplete"
										:label="t('person.state')"
										name="state"
										:placeholder="
											t('plan.placeholders.select', {
												placeholderLabel: t('person.state'),
											})
										"
										:options="stateOptions"
									/>
									<FormKit
										type="text"
										name="zip"
										:label="t('person.zip')"
										validation="matches:/^\d{5}(-\d{4})?$/"
										validation-visibility="live"
									/>
								</div>
								<div
									class="section-grid-row grid grid-cols-2 *:w-full *:max-w-none *:px-1 *:pb-1"
								>
									<FormKit
										type="tel"
										name="phone"
										:label="t('person.phone')"
										placeholder="xxx-xxx-xxxx"
										:validation="[
											['matches', /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/],
										]"
										:validation-messages="{
											matches: `${t('plan.validationMessages.phone')}`,
										}"
										validation-visibility="live"
									/>
									<FormKit
										type="email"
										name="email"
										:label="t('person.email')"
										validation="email"
										validation-visibility="dirty"
										:placeholder="t('plan.placeholders.email')"
									/>
								</div>
								<div
									class="section-grid-row grid grid-cols-1 *:w-full *:max-w-none *:px-1"
								>
									<FormKit
										type="textarea"
										auto-height
										name="notes"
										:label="t('plan.notes')"
										:outer-class="{ 'mb-0': true }"
										:placeholder="
											t('plan.placeholders.personsResponsibleNotes')
										"
										validation="length:0,10000"
										validation-visibility="live"
									/>
								</div>
							</FormKit>

							<div class="group flex print:hidden">
								<PlusCircleIcon
									class="mr-1 size-6 text-primary-green-400 group-hover:text-primary-green-500"
									aria-hidden="true"
								/>
								<button
									class="push text-primary-green-400 group-hover:text-primary-green-500"
									@click="addPersonResponsible($event)"
								>
									{{ t('plan.addPersonResponsible') }}
								</button>
							</div>
						</section>
					</div>

					<!-- Funeral Service & Gathering Preferences Section -->
					<div class="w-full p-6 print:pb-2">
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.funeralServicePreferences') }}
						</h2>

						<CopyButtonList
							:field-or-section="[
								'funeralHomeName',
								'placeOfService',
								'placeOfGathering',
								'religiousPreference',
								'serviceOrganization',
							]"
							:active-index="activePlanIndex"
							class="print:hidden"
						/>

						<section>
							<div
								class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="select"
									name="funeralHomeId"
									:label="t('plan.funeralHomeName')"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.funeralHomeName'),
										})
									"
									:options="funeralHomeOptions"
								/>

								<FormKit
									type="text"
									name="placeOfService"
									:label="t('plan.placeOfService')"
								/>
							</div>
							<div
								class="section-grid-row grid grid-cols-3 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="text"
									name="placeOfGathering"
									:label="t('plan.placeOfGathering')"
								/>

								<FormKit
									type="text"
									name="religiousPreference"
									:label="t('plan.religiousPreference')"
								/>

								<FormKit
									type="text"
									name="serviceOrganization"
									:label="t('plan.serviceOrganization')"
									:placeholder="t('plan.placeholders.organizationExamples')"
								/>
							</div>
							<div
								class="section-grid-row grid grid-cols-1 *:w-full *:max-w-none *:px-1"
							>
								<FormKit
									type="textarea"
									auto-height
									name="serviceNotes"
									:label="t('plan.notes')"
									:outer-class="{ 'mb-0': true }"
									:placeholder="t('plan.placeholders.serviceNotes')"
									validation="length:0,10000"
									validation-visibility="live"
								/>
							</div>
						</section>
					</div>

					<!-- Internment Preferences Section -->
					<div
						class="w-full bg-[#F1F6FE] p-6 print:break-inside-avoid print:pb-2"
					>
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.internmentPreferences') }}
						</h2>

						<section>
							<div
								class="section-grid-row grid grid-cols-1 gap-2 *:w-full *:max-w-none"
							>
								<div class="flex pb-6">
									<CardRadio
										v-model="activePlan.burialOrCremation"
										name="burialOrCremation"
										:options="burialOrCremationOptions"
									/>
								</div>
							</div>
							<div
								class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="dropdown"
									name="cemeteryPurchaseStatus"
									:label="t('plan.cemeteryPurchaseStatus')"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.cemeteryPurchaseStatus'),
										})
									"
									:options="cemeteryPurchaseStatusOptions"
								/>

								<FormKit
									type="dropdown"
									:label="t('plan.internmentPreference')"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('plan.internmentPreference'),
										})
									"
									name="internmentPrefs"
									:options="internmentPref"
								/>

								<FormKit
									type="text"
									outer-class="w-full max-w-none"
									name="cemeteryPropertyOwner"
									:label="t('plan.cemeteryPropertyOwner')"
								/>

								<FormKit
									type="text"
									outer-class="w-full max-w-none"
									name="cemeteryName"
									:label="t('plan.cemeteryName')"
								/>
							</div>
							<div
								class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="text"
									name="internmentCity"
									:label="t('person.city')"
								/>

								<FormKit
									type="autocomplete"
									name="internmentState"
									:label="t('person.state')"
									:placeholder="
										t('plan.placeholders.select', {
											placeholderLabel: t('person.state'),
										})
									"
									:options="stateOptions"
								/>
							</div>
							<!-- Burial and Cremation options will go here -->
							<div
								class="section-grid-row grid grid-cols-1 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="textarea"
									auto-height
									name="internmentNotes"
									:label="t('plan.notes')"
									:outer-class="{ 'mb-0': true }"
									:placeholder="t('plan.placeholders.internmentNotes')"
									validation="length:0,10000"
									validation-visibility="live"
								/>
							</div>
						</section>
					</div>

					<!-- Special Instructions Section-->
					<div class="w-full p-6 print:break-inside-avoid print:pb-2">
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.specialInstructions') }}
						</h2>

						<CopyButtonList
							field-or-section="specialInstructions"
							:active-index="activePlanIndex"
							class="print:hidden"
						/>

						<section>
							<FormKit
								id="specialInstructions"
								name="specialInstructions"
								type="group"
							>
								<div
									class="section-grid-row grid grid-cols-3 gap-2 *:w-full *:max-w-none"
								>
									<FormKit
										type="text"
										name="flowerType"
										:label="t('plan.flowerType')"
									/>

									<FormKit
										type="text"
										name="colorPreferences"
										:label="t('plan.colorPreferences')"
									/>
									<FormKit
										type="text"
										name="musicPreference"
										:label="t('plan.musicPreference')"
									/>
								</div>

								<div class="section-grid-row grid grid-cols-1 gap-2">
									<div class="*w-full flex items-center gap-2">
										<FormKit
											type="text"
											outer-class="max-w-none"
											name="casketBearers"
											:label="t('plan.casketBearers')"
										/>
										<FormKit
											type="checkbox"
											outer-class="checkbox-inline"
											:label="t('plan.wantsToNotifyBearers')"
											name="wantsToNotifyBearers"
										/>
									</div>
								</div>

								<div class="section-grid-row grid grid-cols-1 gap-2">
									<div class="*w-full flex items-center gap-2">
										<FormKit
											type="text"
											outer-class="max-w-none"
											name="obitPublications"
											:label="t('plan.obitPublications')"
										/>

										<FormKit
											type="checkbox"
											outer-class="checkbox-inline"
											:label="t('plan.wantsObitPhoto')"
											name="wantsObitPhoto"
										/>
									</div>
								</div>
							</FormKit>

							<div
								class="section-grid-row grid grid-cols-1 gap-2 pt-6 *:w-full *:max-w-none print:break-inside-avoid"
							>
								<h2 class="form-title text-md pb-2 font-bold">
									{{ t('plan.sectionTitle.jewelryAndAccessories') }}
								</h2>
								<FormKit
									id="accessoriesInstructions"
									name="accessoriesInstructions"
									type="repeater"
									:draggable="false"
									add-button
									:insert-control="true"
									:up-control="false"
									:down-control="false"
								>
									<div
										class="section-grid-row grid grid-cols-2 gap-2 *:w-full *:max-w-none"
									>
										<FormKit
											type="text"
											name="item"
											:label="t('plan.accessoriesInstructions.item')"
										/>

										<FormKit
											type="text"
											name="returnTo"
											:label="t('plan.accessoriesInstructions.returnTo')"
										/>
									</div>
								</FormKit>
							</div>
						</section>
					</div>

					<!-- Other Section -->
					<div
						class="w-full bg-[#F1F6FE] p-6 print:break-inside-avoid print:pb-2"
					>
						<h2 class="form-title pb-6 text-2xl font-bold print:pb-4">
							{{ t('plan.sectionTitle.other') }}
						</h2>

						<CopyButtonList
							field-or-section="otherMemorialNotes"
							:active-index="activePlanIndex"
							class="print:hidden"
						/>

						<section>
							<div
								class="section-grid-row mb-16 grid grid-cols-1 grid-rows-1 gap-2 *:w-full *:max-w-none"
							>
								<FormKit
									type="textarea"
									auto-height
									name="otherMemorialNotes"
									:label="t('plan.notes')"
									:outer-class="{ 'mb-0': true }"
									:placeholder="t('plan.placeholders.otherMemorialNotes')"
									validation="length:0,10000"
									validation-visibility="live"
								/>
							</div>
						</section>
					</div>
				</FormKit>
			</div>
		</div>
		<div v-else>No active session</div>
	</div>
</template>

<script setup lang="ts">
//TODO: Add in Funeral Homes to selection dropdown when available
import airforce from '@/assets/US-military-seals-airforce-80x80.png'
import army from '@/assets/US-military-seals-army-80x80.png'
import coastguard from '@/assets/US-military-seals-coastguard-80x80.png'
import marine from '@/assets/US-military-seals-marine-80x80.png'
import nationalguard from '@/assets/US-military-seals-nationalguard-80x80.png'
import navy from '@/assets/US-military-seals-navy-80x80.png'
import spaceforce from '@/assets/US-military-seals-spaceforce-80x80.png'
import Plan from '@/classes/Plan'
import BurialCard from '@/components/memorialGuide/BurialCard.vue'
import CardRadio from '@/components/memorialGuide/CardRadio.vue'
import CremationCard from '@/components/memorialGuide/CremationCard.vue'
import PictureCardCheckboxes from '@/components/memorialGuide/PictureCardCheckboxes.vue'
import CopyButtonList from '@/components/presentation/CopyButtonList.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { stateOptions } from '@/data/stateOptions'
import { CemeteryPurchaseStatus } from '@/enums/CemeteryPurchaseStatus'
import { Disposition } from '@/enums/Disposition'
import { EducationLevelOptions } from '@/enums/EducationLevel'
import { BurialInternment, CremationInternment } from '@/enums/Internment'
import { DeckFlavor } from '@/enums/Language'
import { MilitaryRecord } from '@/enums/MilitaryRecord'
import { MilitaryRequests } from '@/enums/MilitaryRequests'
import { RaceOptions } from '@/enums/Race'
import { SexOptions } from '@/enums/Sex'
import { usePresentationLocale } from '@/i18n'
import type { MemorialGuideSlideStoryblok } from '@/sbTypes'
import { useContentStore } from '@/stores/content'
import { useSessionStore } from '@/stores/session'
import { FormKit } from '@formkit/vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import PlusCircleIcon from '@heroicons/vue/24/solid/PlusCircleIcon'
import PrinterIcon from '@heroicons/vue/24/solid/PrinterIcon'
import { ref, computed, watch, type PropType } from 'vue'

const { t } = usePresentationLocale()

defineProps({
	blok: Object as PropType<MemorialGuideSlideStoryblok>,
})

const content = useContentStore()

const pastDateMin = '1900-01-01' // Minimum date value
const currentDate = new Date().toISOString().split('T')[0] // Current date in YYYY-MM-DD format
const sessionStore = useSessionStore()
const activePlanIndex = ref(0)
const activePlan = ref(sessionStore.activeSession?.plans[activePlanIndex.value])

/**
 * Adds a new participant plan to the active session.
 * If there's no active session, it throws an error.
 * Updates the active plan index to the newly added plan.
 *
 * @throws {Error} Throws an error if there is no active session.
 */
const addParticipant = () => {
	if (!sessionStore.activeSession) {
		throw new Error('No active session')
	}

	sessionStore.activeSession.addPlan()
	activePlanIndex.value = sessionStore.activeSession!.plans.length - 1
}

/**
 * The two options for burial or cremation radio cards.
 */
const burialOrCremationOptions = [
	{
		label: t('plan.disposition.burial'),
		value: Disposition.Burial,
		icon: BurialCard,
	},
	{
		label: t('plan.disposition.cremation'),
		value: Disposition.Cremation,
		icon: CremationCard,
	},
]

/** Key-value pairs of funeral home IDs and names for the user to pick from */
const funeralHomeOptions = computed(() => {
	const fhs = content.getFuneralHomeLocations(
		sessionStore.activeSession?.language || DeckFlavor.Default,
		{
			crmGroupId: sessionStore.activeSession?.crmFhGroupId,
		}
	)

	// Reorganize into key-value pairs
	return fhs.reduce(
		(acc, fh) => ({
			...acc,
			[fh.id]: fh.name,
		}),
		{}
	)
})

/**
 * The options for military record radio cards.
 */
const militaryRecordOptions = [
	{
		label: t('plan.militaryRecordOptions.army'),
		value: MilitaryRecord.Army,
		imageUrl: army,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.marineCorp'),
		value: MilitaryRecord.MarineCorp,
		imageUrl: marine,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.navy'),
		value: MilitaryRecord.Navy,
		imageUrl: navy,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.airForce'),
		value: MilitaryRecord.AirForce,
		imageUrl: airforce,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.spaceForce'),
		value: MilitaryRecord.SpaceForce,
		imageUrl: spaceforce,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.coastGuard'),
		value: MilitaryRecord.CoastGuard,
		imageUrl: coastguard,
		fullWidth: true,
	},
	{
		label: t('plan.militaryRecordOptions.nationalGuard'),
		value: MilitaryRecord.NationalGuard,
		imageUrl: nationalguard,
		fullWidth: true,
	},
]

const militaryRequestOptions = [
	{
		label: t('plan.militaryRequestOptions.ddRequested'),
		value: MilitaryRequests.DDRequested,
	},
	{
		label: t('plan.militaryRequestOptions.honors'),
		value: MilitaryRequests.Honors,
	},
	{
		label: t('plan.militaryRequestOptions.militaryMarker'),
		value: MilitaryRequests.MilitaryMarker,
	},
	{
		label: t('plan.militaryRequestOptions.flagDraped'),
		value: MilitaryRequests.FlagDraped,
	},
	{
		label: t('plan.militaryRequestOptions.flagFolded'),
		value: MilitaryRequests.FlagFolded,
	},
	{
		label: t('plan.militaryRequestOptions.veteransCemetery'),
		value: MilitaryRequests.VeteransCemetery,
	},
]

/**
 * The options for Cemetery Purchase Status.
 */
const cemeteryPurchaseStatusOptions = [
	{
		label: t('plan.cemeteryPurchaseStatusOptions.purchased'),
		value: CemeteryPurchaseStatus.Purchased,
	},
	{
		label: t('plan.cemeteryPurchaseStatusOptions.notPurchased'),
		value: CemeteryPurchaseStatus.NotPurchased,
	},
	{
		label: t('plan.cemeteryPurchaseStatusOptions.notInterested'),
		value: CemeteryPurchaseStatus.NotInterested,
	},
]

/**
 * The options for race.
 */
const raceOptions = [
	{
		label: t('plan.raceOptions.americanIndianOrAlaskaNative'),
		value: RaceOptions.AmericanIndianOrAlaskaNative,
	},
	{
		label: t('plan.raceOptions.asian'),
		value: RaceOptions.Asian,
	},
	{
		label: t('plan.raceOptions.blackOrAfricanAmerican'),
		value: RaceOptions.BlackOrAfricanAmerican,
	},
	{
		label: t('plan.raceOptions.nativeHawaiianOrOtherPacificIslander'),
		value: RaceOptions.NativeHawaiianOrOtherPacificIslander,
	},
	{
		label: t('plan.raceOptions.white'),
		value: RaceOptions.White,
	},
	{
		label: t('plan.raceOptions.other'),
		value: RaceOptions.Other,
	},
]

const educationLevelOptions = [
	{
		label: t('plan.educationLevelOptions.someHighSchool'),
		value: EducationLevelOptions.SomeHighSchool,
	},
	{
		label: t('plan.educationLevelOptions.highSchoolDiplomaGED'),
		value: EducationLevelOptions.HighSchoolDiplomaGED,
	},
	{
		label: t('plan.educationLevelOptions.someCollege'),
		value: EducationLevelOptions.SomeCollege,
	},
	{
		label: t('plan.educationLevelOptions.associateDegree'),
		value: EducationLevelOptions.AssociateDegree,
	},
	{
		label: t('plan.educationLevelOptions.bachelorDegree'),
		value: EducationLevelOptions.BachelorDegree,
	},
	{
		label: t('plan.educationLevelOptions.masterDegree'),
		value: EducationLevelOptions.MasterDegree,
	},
	{
		label: t('plan.educationLevelOptions.doctorate'),
		value: EducationLevelOptions.Doctorate,
	},
	{
		label: t('plan.educationLevelOptions.professionalDegree'),
		value: EducationLevelOptions.ProfessionalDegree,
	},
	{
		label: t('plan.educationLevelOptions.other'),
		value: EducationLevelOptions.Other,
	},
]

/**
 * The options for sex.
 */
const sexOptions = [
	{
		label: t('plan.sexOptions.male'),
		value: SexOptions.Male,
	},
	{
		label: t('plan.sexOptions.female'),
		value: SexOptions.Female,
	},
	{
		label: t('plan.sexOptions.nonBinary'),
		value: SexOptions.NonBinary,
	},
	{
		label: t('plan.sexOptions.other'),
		value: SexOptions.Other,
	},
]

/**
 * Returns a list of internment preferences based on the active plan's burial or cremation preference.
 * If there is no active plan return and empty array.
 */
const internmentPref = computed(() => {
	if (activePlan.value?.burialOrCremation === Disposition.Burial) {
		return Object.values(BurialInternment).map((option) => ({
			label: t(`plan.burialInternment.${option}`),
			value: option,
		}))
	} else if (activePlan.value?.burialOrCremation === Disposition.Cremation) {
		return Object.values(CremationInternment).map((option) => ({
			label: t(`plan.cremationInternment.${option}`),
			value: option,
		}))
	}
	return []
})

/**
 * Computes the name for the memorial guide title based on the active plan's first and last name.
 * All names should be capitalized.
 * @returns {string} The computed memorial guide title.
 */
const activePlanName = computed(() => {
	const name = (
		activePlan.value?.firstName +
		' ' +
		activePlan.value?.lastName
	).trim()
	const capitalizedName = name
		.toLowerCase()
		.replace(/(^|\s)\S/g, (char) => char.toUpperCase())
	return capitalizedName
})

/**
 * Adds a blank item to the end of peopleResponsible
 */
const addPersonResponsible = (event: MouseEvent) => {
	event.preventDefault() // Prevent the default form submission behavior
	activePlan.value?.peopleResponsible.push({
		firstName: '',
		lastName: '',
		relationship: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		email: '',
		notes: '',
	})
}

/**
 * Updates the active plan in the sessionStore.activeSession based on user input.
 * @return {void} This function does not return a value.
 */
const updateActivePlan = () => {
	if (
		sessionStore.activeSession &&
		sessionStore.activeSession.plans &&
		activePlan.value !== undefined
	) {
		sessionStore.activeSession.plans[activePlanIndex.value] = activePlan.value
	}
}

/**
 * Copies spouse information from the active session to the specified plan index.
 * @param {number} buttonIndex - The index of the button that triggered the copy action.
 * @return {void} This function does not return a value.
 */
function handleCopySpouseInfo(buttonIndex: number) {
	sessionStore.activeSession?.copySpouseInfo(buttonIndex, activePlanIndex.value)
}

/**
 * Updates the active plan index with the provided index.
 * @param {number} index - The index of the active plan.
 * @return {void}
 */
function handleAvatarClick(index: number) {
	activePlanIndex.value = index
}

/**
 * Print the Memorial Guide Page
 */
function handlePrint() {
	window.print()
}

/**
 * Watches for changes in the activePlan and updates the plan accordingly.
 */
watch(activePlan, () => {
	updateActivePlan()
})

/**
 * Watches for changes in the activePlanIndex and updates the active plan accordingly.
 * @param {Ref<number>} activePlanIndex - The reactive reference to the active plan index.
 */
watch(activePlanIndex, (newIndex) => {
	activePlan.value = sessionStore.activeSession?.plans[newIndex]
})

/**
 * Watches for changes to the burial or cremation preference of the active plan.
 * If the preference changes for the activePlanIndex then reset the internment preference to an empty string.
 */
let previousPlanIndex = activePlanIndex.value

watch(
	() => activePlan.value?.burialOrCremation,
	(newVal, oldVal) => {
		if (activePlanIndex.value !== previousPlanIndex) {
			previousPlanIndex = activePlanIndex.value
			return // If the plan index changes don't reset the internment preferences
		}
		if (newVal !== oldVal && activePlan.value) {
			activePlan.value.internmentPrefs = ''
		}
	}
)
</script>

<style scoped>
@media print {
	@page {
		margin: 0.1in;
		width: 8.5in;
		height: 11in;
	}
}
</style>
