<!-- Component for displaying a dropdown of products that can be selected based on tags -->
<template>
	<div class="relative w-full">
		<!-- Dropdown Trigger -->
		<div
			class="group flex cursor-pointer items-center justify-between rounded-md border bg-white p-3 hover:border-precoa-blue-700 hover:text-precoa-blue-700"
			@click="toggleDropdown"
			:class="
				isDropdownOpen
					? 'border-precoa-blue-700 text-precoa-blue-700'
					: 'border-slate-300'
			"
		>
			{{ selectedFilterOption?.name }}
			<PlayIcon
				class="h-4 w-4 text-xs transition-transform duration-300 group-hover:text-precoa-blue-700"
				:class="
					isDropdownOpen
						? 'rotate-90 text-precoa-blue-700'
						: '-rotate-90 text-slate-500'
				"
			/>
		</div>

		<!-- Dropdown Menu -->
		<div
			v-if="isDropdownOpen"
			class="absolute left-0 z-50 mt-2 w-full rounded-md border border-slate-300 bg-white shadow-lg"
		>
			<div
				v-for="tag in filterOptions"
				:key="tag.id"
				class="cursor-pointer p-3 hover:bg-slate-100"
				@click="selectFilterOption(tag)"
			>
				{{ tag.name }}
			</div>
		</div>

		<!-- Product List -->
		<div
			v-if="selectedProducts?.length"
			class="mt-1 rounded-md bg-white"
		>
			<div
				v-for="p in selectedProducts"
				:key="p.id"
				class="group flex cursor-pointer flex-col border-b border-slate-200 py-4 last:border-b-0"
				@click="selectProduct(p)"
			>
				<BlobImage
					class="aspect-[16/11] w-full rounded-sm object-cover"
					:src="p.imageUrl"
					:alt="p.name"
				/>

				<div class="flex min-h-[48px] w-full items-center justify-between pt-4">
					<span
						class="group-hover:font-semibold group-hover:text-primary-green-600"
						:class="
							selectedProduct?.id === p.id
								? 'font-semibold text-primary-green-600'
								: 'text-slate-900'
						"
					>
						{{ p.name }}
					</span>
					<PlanAvatarList
						:plans="productPlans[p.id]"
						filled
					/>
				</div>
			</div>
		</div>

		<div
			v-else
			class="pt-4 text-center text-slate-500"
		>
			No products available for this tag.
		</div>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import PlanAvatarList from '../presentation/PlanAvatarList.vue'
import type { PbLineItem } from '@/apiTypes'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { usePresentationLocale } from '@/i18n'
import { useMerchandise } from '@/mixins/merchandise'
import { PlayIcon } from '@heroicons/vue/24/solid'
import { idText } from 'typescript'
import { ref, onMounted, type PropType, computed, watch } from 'vue'

const { priceBook, doesLineItemHaveTag } = useMerchandise()
const { t } = usePresentationLocale()

type ProductType = 'urn' | 'casket'

const props = defineProps({
	productPlans: {
		type: Object,
		required: true,
	},
	productType: {
		type: String as PropType<ProductType>,
		required: true,
		default: 'urn',
	},
})

interface Product {
	id: string
	name: string
	imageUrl: string
}

/** A filter that can be selected in the drop-down */
interface FilterOption {
	id: string

	/** Label to display to the user */
	name: string

	/** Filter to apply to the products */
	filter: (product: PbLineItemProductAndPricingOptions) => boolean
}

const selectedProduct = defineModel<Product>()

const isDropdownOpen = ref(false)
const selectedFilterOption = ref<FilterOption>()
const selectedProducts = ref()

/** All the filter options for the drop-down */
const filterOptions = computed<FilterOption[]>(() => {
	const productTags =
		priceBook.value
			?.getProductTags(productFilter.value)
			.map((sfTag) => ({
				...sfTag,
				filter: (product: PbLineItemProductAndPricingOptions) =>
					doesLineItemHaveTag(product, sfTag.id),
			}))
			.sort((tagA, tagB) => tagA.name.localeCompare(tagB.name)) ?? []

	/** Manually add the "All" option */
	const allTagOption = {
		id: 'all',
		name: t('general.all'),
		filter: () => true,
	}

	return [allTagOption, ...productTags]
})

/** Filter function for all the products we have access to */
const productFilter = computed(() => {
	return props.productType === 'urn'
		? (product: PbLineItem) =>
				product.subcategory === PbSubcategory.MerchandiseUrn &&
				!product.isStandIn
		: (product: PbLineItem) =>
				product.subcategory === PbSubcategory.MerchandiseCasket &&
				!product.isStandIn
})

// Toggle the dropdown menu
const toggleDropdown = () => {
	isDropdownOpen.value = !isDropdownOpen.value
}

// Select a product
const selectProduct = (product: Product) => {
	selectedProduct.value = product
}

// Filter products based on selected tag and product type
const applyFilter = (
	filter: (product: PbLineItemProductAndPricingOptions) => boolean
) => {
	if (!priceBook.value || !priceBook.value.products) return

	const taggedProducts = priceBook.value.products.filter(filter)
	const productsFilteredByType = taggedProducts.filter(productFilter.value)

	if (productsFilteredByType.length > 0) {
		selectedProducts.value = productsFilteredByType
		selectProduct(productsFilteredByType[0]) // Auto-select first product
	} else {
		console.log('No products matched the tag and type filters')
		selectedProducts.value = []
	}
}

// Select a tag and filter products
const selectFilterOption = (option: FilterOption) => {
	selectedFilterOption.value = option
	applyFilter(option.filter)
	isDropdownOpen.value = false
}

// On mount auto-select first tag
onMounted(() => {
	if (filterOptions.value.length > 0) {
		selectFilterOption(filterOptions.value[0])
	}
})
</script>
