<!-- Layout component for rendering a heading and a text input list -->
<template>
	<div
		:class="isLargeSize ? 'space-y-24' : 'space-y-16'"
		class="w-full max-w-4xl"
		v-if="blok"
	>
		<!-- HeadingMedium field-->
		<div
			v-editable="blok"
			v-html="richTextHtml"
			class=" text-fluid-5xl mx-auto my-0 text-center font-serif leading-tight text-slate-900 [&_i]:font-bold [&_i]:text-precoa-blue-700"
		></div>

		<!-- Text Input List-->
		<TextInputList
			:isLargeSize="isLargeSize"
			:showCopyFeature="blok.show_copy_feature"
			:planField="blok.plan_field"
		/>
	</div>
	<div v-else>No heading and text input configured</div>
</template>

<script setup lang="ts">
import TextInputList from '../presentation/TextInputList.vue'
import type { HeadingAndTextInputStoryblok } from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<HeadingAndTextInputStoryblok>,
	isLargeSize: {
		type: Boolean,
		default: false,
	},
})

const richTextHtml = computed(() => {
	return renderRichText(props.blok?.headingMedium)
})
</script>
