<!-- Subcomponent for a single list item in the ListWithIcons component -->
<template>
	<div
		class="flex items-center"
		v-editable="blok"
		v-if="blok"
	>
		<div class="mr-4 flex-shrink-0">
			<SbImage
				class="h-16 w-16 rounded-xl border border-slate-50 bg-white p-3 text-gray-300 shadow-md"
				v-if="blok.icon"
				:blok="blok.icon"
			/>
		</div>
		<div
			v-html="paragraphHtml"
			class="prose prose-fluid grow text-slate-900"
		></div>

		<InfoHover
			v-if="infoHoverContent"
			:richTextHtml="infoHoverContent"
		/>
	</div>
</template>

<script setup lang="ts">
import InfoHover from '../presentation/InfoHover.vue'
import SbImage from './SbImage.vue'
import type { ListWithIconsItemStoryblok } from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<ListWithIconsItemStoryblok>,
})

const paragraphHtml = computed(() => renderRichText(props.blok?.text))

const infoHoverContent = computed(() =>
	renderRichText(props.blok?.infoHoverContent)
)
</script>
