<!-- Presentational component for displaying the price of something in the cart -->
<template>
	<div v-if="plan && cartItem && priceDetails">
		<PriceDetailsDisplay
			:priceDetails="priceDetails"
			:deleted="cartItem.isSoftDeleted"
			:credit="isCredit"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	useMerchandise,
	type CartItemAndLineItem,
	type CartSubItemAndLineItem,
} from '../../mixins/merchandise'
import PriceDetailsDisplay from './PriceDetailsDisplay.vue'
import type Plan from '@/classes/Plan'
import { isCartSubItem } from '@/guards'
import type { CartItem, CartSubItem } from '@/types'
import { computed, type PropType } from 'vue'

const props = defineProps({
	plan: Object as PropType<Plan>,
	cartItem: Object as PropType<CartItemAndLineItem | CartSubItemAndLineItem>,
	credit: Boolean,
})

const { getCartItemPrice, qualifiesForDiscount } = useMerchandise()

const priceDetails = computed(() => {
	if (!props.plan || !props.cartItem) return undefined

	return getCartItemPrice(props.plan as Plan, props.cartItem)
})

const isCredit = computed(() => {
	if (!props.cartItem || !props.cartItem.lineItem) return false

	return (
		isCartSubItem(props.cartItem) &&
		priceDetails.value &&
		priceDetails.value.price > 0 &&
		qualifiesForDiscount(props.cartItem.lineItem)
	)
})
</script>
