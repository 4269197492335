import { faker } from '@faker-js/faker'
import type { AssetStoryblok } from '@/sbTypes'

export function AssetFactory(
	properties: Partial<AssetStoryblok> = {}
): AssetStoryblok {
	return {
		id: faker.number.int(),
		alt: faker.lorem.words(),
		name: faker.lorem.words(),
		title: faker.lorem.words(),
		filename: faker.image.url(),
		is_external_url: faker.datatype.boolean(),
		...properties,
	}
}
