<!-- Presentational component for displaying a price, possibly with a discount -->
<template>
	<div
		v-if="priceDetails"
		class="flex"
		:class="{
			'text-sm': size === 'sm',
			'text-xs': size === 'xs',
		}"
	>
		<!-- Crossed-out original price -->
		<span
			class="mr-2 text-slate-300 line-through"
			v-if="
				priceDetails.originalPrice &&
				priceDetails.originalPrice > priceDetails.price
			"
		>
			{{ formatPrice(priceDetails.originalPrice) }}
		</span>

		<!-- Price and quantity -->
		<div
			class=""
			:class="{
				'text-slate-400 line-through': deleted,
				'text-primary-green-600': priceDetails.price < 0 && !deleted,
			}"
		>
			<span v-if="priceDetails.price === 0">{{
				t('reviewSlide.included')
			}}</span>
			<span v-else-if="credit">{{ creditString }}</span>
			<span v-else>{{ formatPrice(priceDetails.price) }}</span>
		</div>

		<span v-if="priceDetails.quantity && priceDetails.quantity > 1">
			QTY: {{ priceDetails.quantity }}</span
		>
	</div>
</template>

<script setup lang="ts">
import { usePresentationLocale } from '@/i18n'
import { useMerchandise, type PricingDetails } from '@/mixins/merchandise'
import { type PropType, computed } from 'vue'

const { formatPrice } = useMerchandise()
const { t } = usePresentationLocale()

const props = defineProps({
	priceDetails: Object as PropType<PricingDetails>,
	deleted: Boolean,
	credit: Boolean,
	size: {
		type: String,
		default: 'sm',
		options: ['sm', 'xs'],
	},
})

const creditString = computed(() => {
	if (!props.priceDetails) return ''

	return t('merchandise.credit', {
		amount: formatPrice(props.priceDetails.price),
	})
})
</script>
