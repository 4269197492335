<!--
	View for downloading a presentation from Storyblok and display it with dummy data.
	This is only used with the Storyblok visual editor.
-->
<template>
	<SwiperPresentation />
</template>

<script setup lang="ts">
import SwiperPresentation from '@/components/presentation/SwiperPresentation.vue'
import { DeckFlavor } from '@/enums/Language'
import { isUnauthorizedError } from '@/errors/UnauthorizedError'
import type { SlideDeckStoryblok } from '@/sbTypes'
import { getSlideDeck } from '@/services/storyblokService'
import { useAuthStore } from '@/stores/auth'
import { useContentStore } from '@/stores/content'
import { usePresentationController } from '@/stores/presentationController'
import { useSessionStore } from '@/stores/session'
import type { StoryblokStory } from '@/types'
import SessionFactory from '@factories/SessionFactory'
import { useStoryblokBridge, type ISbStoryData } from '@storyblok/vue'
import { onMounted, nextTick, type PropType } from 'vue'

const session = useSessionStore()
const content = useContentStore()
const auth = useAuthStore()
const presentationController = usePresentationController()

const props = defineProps({
	presentationId: String,
	lang: String as PropType<DeckFlavor>,
})

/** Set the active chapter to the one indicated by the given slug */
async function setTestDeck(deck: StoryblokStory<SlideDeckStoryblok>) {
	content.setTestDecks([deck])

	await nextTick()
	presentationController.swiper?.update()
}

/** Update the presentation controller when the slide deck loads */
onMounted(async () => {
	if (!presentationController.swiper) {
		throw new Error('Swiper was unable to mount')
	}

	if (!props.presentationId) {
		throw new Error('No presentation ID provided')
	}

	if (auth.sessionExpired) {
		auth.showLogin = true
		return
	}

	// Load the slide deck from Storyblok and pass to the content store
	const story = await getSlideDeck(
		props.presentationId,
		props.lang || DeckFlavor.Default,
		import.meta.env.VITE_APP_STORYBLOK_PREVIEW === 'true'
	).catch((e: any) => {
		if (isUnauthorizedError(e)) {
			console.error('Unauthorized', e)
			const auth = useAuthStore()
			auth.showLogin = true
		}
	})

	if (!story) {
		throw new Error('Unable to load story')
	}

	// Start a new fake session
	session.activeSession = SessionFactory(2, {
		slideDeckId: story.uuid,
		language: story.lang,
	})

	// Update the content store
	await setTestDeck(story)

	// Set up listener for visual editor updates
	useStoryblokBridge(
		story.id,
		async (newStory: ISbStoryData<SlideDeckStoryblok>) => {
			// Lang comes through as a string, so we have to make sure it can
			// be cast to a DeckFlavor

			if (
				Object.values<string>(DeckFlavor).includes(newStory.lang) ||
				!newStory.lang
			) {
				const lang = (newStory.lang as DeckFlavor) || DeckFlavor.Default
				const replacement = {
					...newStory,
					lang,
					uniqueId: lang + ':' + newStory.uuid,
				}

				await setTestDeck(replacement)
			} else {
				alert('Unknown deck flavor: ' + newStory.lang)
			}
		}
	)
})
</script>
