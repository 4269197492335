<template>
	<!-- Accordion with sections -->
	<BaseAccordion
		v-model="selectedItem"
		:sections="sections"
		:productPlans="productPlans"
	/>

	<div class="w-2/5 !overflow-y-auto pr-4">
		<Package
			v-if="selectedPackage"
			:pkg="selectedPackage"
			:isSidePanelLayout="true"
		/>

		<FormProduct
			v-else-if="selectedProduct"
			:product="selectedProduct"
			:key="selectedProduct?.id"
			hasCounter
		/>

		<div v-else>
			{{
				selectedItem?.category
					? selectedItem.category.charAt(0).toUpperCase() +
						selectedItem.category.slice(1) +
						' component'
					: 'Component'
			}}
			coming soon
		</div>
	</div>
</template>

<script setup lang="ts">
import BaseAccordion from '../common/BaseAccordion.vue'
import FormProduct from '../merchandise/FormProduct.vue'
import type { PbLineItemPackage } from '@/apiTypes'
import type { PbLineItem } from '@/apiTypes'
import type { IndexedPlan } from '@/classes/Plan'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import Package from '@/components/packages/Package.vue'
import { PbProductType } from '@/enums/PbProductType'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { usePresentationLocale } from '@/i18n'
import { useMerchandise } from '@/mixins/merchandise'
import { useSessionStore } from '@/stores/session'
import { computed, ref } from 'vue'

const { t } = usePresentationLocale()
const { priceBook, isLineItemInPlan } = useMerchandise()

const sessionStore = useSessionStore()
const plans = computed(() => sessionStore.activeSession?.plans ?? [])
const selectedItem = ref<PbLineItem | undefined>({} as PbLineItem)

const recommendedItems = computed(() => [])

const caskets = computed(
	() =>
		priceBook.value?.products.filter(
			(p) =>
				p.subcategory === PbSubcategory.MerchandiseCasket ||
				p.subcategory === PbSubcategory.MerchandiseOuterBurialContainer
		) ?? []
)
const urns = computed(
	() =>
		priceBook.value?.products.filter(
			(p) => p.subcategory === PbSubcategory.MerchandiseUrn
		) ?? []
)
const basicServices = computed(
	() =>
		priceBook.value?.products.filter(
			(p) =>
			[
				PbProductType.Disposition,
				PbProductType.Service,
				PbProductType.Transportation,
			].includes(p.category)
		) ?? []
)

/**
 * Map products and packages to their corresponding plans.
 */
const productPlans = computed<Record<string, IndexedPlan[]>>(() => {
	if (!plans.value.length) return {}
	if (!priceBook.value?.products && !priceBook.value?.packages) return {}

	const items: PbLineItem[] = [
		...(priceBook.value.products ?? []),
		...(priceBook.value.packages ?? []),
	]

	return items.reduce<Record<string, IndexedPlan[]>>(
		(acc: Record<string, IndexedPlan[]>, item: PbLineItem) => {
			acc[item.id] = plans.value
				.map(
					(plan, index): IndexedPlan => ({
						index,
						plan,
					})
				)
				.filter((indexedPlan: IndexedPlan) =>
					isLineItemInPlan(item, indexedPlan.plan)
				)

			return acc
		},
		{}
	)
})

// Find the corresponding package based on the selected item's ID
const selectedPackage = computed<PbLineItemPackage | undefined>(() => {
	return priceBook.value?.packages.find((p) => p.id === selectedItem.value?.id)
})

// Find the corresponding product based on the selected item's ID
const selectedProduct = computed<
	PbLineItemProductAndPricingOptions | undefined
>(() => {
	return priceBook.value?.products.find((p) => p.id === selectedItem.value?.id)
})

const sections = computed(() => [
	{
		key: 'recommendedItems',
		title: t('presentation.recommended'),
		items: recommendedItems.value,
	},
	{
		key: PbProductType.Package,
		title: t('presentation.packages'),
		items: priceBook.value?.packages || [],
	},
	{
		key: 'caskets',
		title: t('presentation.caskets'),
		items: caskets.value,
	},
	{
		key: 'urns',
		title: t('presentation.urns'),
		items: urns.value,
	},
	{
		key: PbProductType.Service,
		title: t('presentation.services'),
		items: basicServices.value,
	},
	{
		key: PbProductType.Merchandise,
		title: t('presentation.merchandise'),
		items: priceBook.value?.getMiscMerchandise() || [],
	},
])
</script>
