import type {
	ApiPriceBook,
	PbLineItem,
	PbLineItemPackage,
	PbLineItemProduct,
	PbPackageProduct,
	PbPricingOption,
	PriceBookData,
	SfTag,
} from '@/apiTypes'
import { PbSubcategory } from '@/enums/PbSubcategory'
import { isPbLineItemPackage, isPbLineItemProduct } from '@/guards'

export type PbLineItemProductAndPricingOptions = PbLineItemProduct & {
	pricingOptions: Array<PbPricingOption>
	tags: Array<SfTag>
}

/** Class for holding price bookdata */
export class PriceBook {
	public data: PriceBookData

	public products: PbLineItemProductAndPricingOptions[] = []
	public packages: PbLineItemPackage[] = []
	public packageProducts: PbPackageProduct[] = []

	/** Get all the line items (packages and products) in the price book */
	public getLineItems(): PbLineItem[] {
		return [...this.products, ...this.packages]
	}

	/** Get the top-level price book metadata */
	public getPriceBook(): PriceBookData {
		return this.data
	}

	/** Find the given line item by id */
	public findLineItem(id: string): PbLineItem | undefined {
		return this.getLineItems().find((li) => li.id === id)
	}

	/** Find the given product by id */
	public findProduct(
		id: string
	): PbLineItemProductAndPricingOptions | undefined {
		return this.products.find((li) => li.id === id)
	}

	/** Find the given package by id */
	public findPackage(id: string): PbLineItemPackage | undefined {
		return this.packages.find((li) => li.id === id)
	}

	/** Get all the products in the given package */
	public getProductsInPackage(packageId: string) {
		return this.packageProducts
			.filter((p) => p.packageId === packageId)
			.map((pp) => ({
				...pp,
				lineItem: this.products.find((p) => p.id === pp.lineItemId),
			}))
	}

	/** Get all the ids of products in the given package */
	public getProductIdsInPackage(packageId: string) {
		return this.getProductsInPackage(packageId).map((product) => product.id)
	}

	/** Get all the packages that this product is in */
	public getPackagesForProduct(productId: string) {
		return this.packageProducts
			.filter((packProd) => packProd.lineItemId === productId)
			.map((packProd) => {
				const foundPackage = this.packages.find(
					(pack) => pack.id === packProd.packageId
				)

				if (!foundPackage) throw new Error('Package not found')

				return {
					...packProd,
					package: foundPackage,
				}
			})
	}

	/** Get a list of all unique tags found in items filtered by predicate */
	public getProductTags(
		predicate: (lineItem: PbLineItemProductAndPricingOptions) => boolean = () =>
			true
	): SfTag[] {
		const tags = this.products
			.filter(predicate)
			.map((lineItem) => lineItem.tags)
			.flat(2)

		const dedupedIds = new Set(tags?.map((tag) => tag.id))

		const dedupedTags = Array.from(dedupedIds)
			.map((id) => tags?.find((t) => t.id === id))
			.filter((tag) => tag !== undefined)

		return dedupedTags
	}

	/** Get all the products with the given tag */
	public getProductsWithTag(
		tagId: string
	): PbLineItemProductAndPricingOptions[] {
		return this.products.filter(
			(product) =>
				!product.isStandIn && product.tags.some((tag) => tag.id === tagId)
		)
	}

	/** Get all the merchandise that aren't caskets or urns */
	public getMiscMerchandise(): PbLineItemProductAndPricingOptions[] {
		return this.products.filter(
			(p) =>
				p.category === 'merchandise' &&
				p.subcategory !== PbSubcategory.MerchandiseCasket &&
				p.subcategory !== PbSubcategory.MerchandiseUrn
		)
	}

	// Save data and unpack into flat objects
	constructor(data: ApiPriceBook) {
		const dataCopy = structuredClone(data)

		// Find all package-product junction records
		this.packageProducts =
			dataCopy.lineItems?.data
				.filter(
					(lineItem) =>
						isPbLineItemPackage(lineItem) && lineItem.packageProducts
				)
				.flatMap((lineItem) => lineItem.packageProducts!.data) || []

		// Save all packages
		this.packages =
			dataCopy.lineItems?.data
				.filter((lineItem) => isPbLineItemPackage(lineItem))
				.map((lineItem) => ({
					...lineItem,
					packageProducts: undefined,
				})) || []

		// Save all products
		this.products =
			dataCopy.lineItems?.data
				.filter((lineItem) => isPbLineItemProduct(lineItem))
				.map((lineItem) => ({
					...lineItem,

					// Set the pricingOptions to an array (without "data") and sort by price, ascending
					pricingOptions:
						lineItem.pricingOptions?.data?.sort((a, b) => a.price - b.price) ||
						[],

					// Set the tags to an array (without "data")
					tags: lineItem.tags?.data || [],
				})) || []

		// Save base price book properties
		const { lineItems, ...dataWithoutLineItems } = dataCopy
		this.data = dataWithoutLineItems
	}
}
