/** Enum for a type of line item in a price book */
export enum PbProductType {
	Disposition = 'disposition',
	Package = 'package',
	Service = 'service',
	Transportation = 'transportation',
	Merchandise = 'merchandise',
	CashAdvance = 'cashAdvance',
	FamilyTravel = 'familyTravel',
	Cemetery = 'cemetery',
	Afterward = 'afterward',
}
