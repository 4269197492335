import type { RequestQueueConfig } from '@/mixins/requestQueue'
import { axiosInstance } from '@/services/axiosInstance'

/** Configuration for use in a window context */
export const windowRequestQueueConfig: RequestQueueConfig = {
	axiosInstance,

	requestBackgroundSync: async (tag: string) => {
		const registration = await navigator.serviceWorker.ready
		await registration.sync?.register(tag)
	},
}
