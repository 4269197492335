/**
 * The flavors of a presentation that we can get from Storyblok.
 * This uses Storyblok's tranlation feature, so they are all marked as languages.
 */
export enum DeckFlavor {
	Default = 'default',
	Black = 'en-us',
	Spanish = 'es',
	Vietnamese = 'vi',
}

/** The language options we can translate the app into. Each is a locale in i18n */
export enum Language {
	English = 'en',
	Spanish = 'es',
	Vietnamese = 'vi',
}

export const labels = {
	[Language.English]: 'English',
	[Language.Spanish]: 'Español',
	[Language.Vietnamese]: 'Tiếng Việt',
}

/** Map from CMS Flavor to i18n locale */
export const flavorLanguages = {
	[DeckFlavor.Default]: Language.English,
	[DeckFlavor.Black]: Language.English,
	[DeckFlavor.Spanish]: Language.Spanish,
	[DeckFlavor.Vietnamese]: Language.Vietnamese,
}

/** Map from i18n locale to CMS Flavor */
export const languageFlavors = {
	[Language.English]: [DeckFlavor.Default, DeckFlavor.Black],
	[Language.Spanish]: [DeckFlavor.Spanish],
	[Language.Vietnamese]: [DeckFlavor.Vietnamese],
}
