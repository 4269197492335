<!-- Component to render a list of slides that can be cycled through-->
<template>
	<div class="grid h-dvh grid-cols-[278px,1fr] bg-white">
		<!-- Navigation Column-->
		<div class="mt-28 flex flex-col gap-6 overflow-y-auto px-6 pb-8 xl:mt-20">
			<ul
				v-for="(slide, index) in blok.Slides"
				:key="slide._uid"
				v-editable="slide"
				@click="goToSlide(index)"
			>
				<li
					:class="[
						'group flex w-full cursor-pointer items-center gap-2 text-blue-600 hover:text-green-600',
						activeSlideIndex === index ? 'text-green-600' : '',
					]"
				>
					<div
						:class="[
							'aspect-square w-32 overflow-hidden rounded-full border border-indigo-50 bg-indigo-50 group-hover:shadow-lg hover:border-green-600',
							activeSlideIndex === index ? 'border-green-600 shadow-lg' : '',
						]"
					>
						<SbImage
							:blok="slide.image"
							class="object-fit h-full w-full rounded-full"
							alt="Slide icon"
						/>
					</div>
					<div class="w-full text-sm uppercase">{{ slide.text }}</div>
				</li>
			</ul>
		</div>

		<!-- Slide Content Column -->
		<div class="relative flex overflow-y-auto">
			<swiper-container
				class="h-full"
				ref="swiperRef"
				speed="500"
				:mousewheel="false"
				:keyboard="false"
				effect="slide"
				@swiperslidechange="onSlideChange"
				direction="vertical"
			>
				<swiper-slide
					v-for="Slide in blok?.Slides"
					:key="Slide._uid"
					class="flex items-center justify-center"
					v-editable="blok"
				>
					<component
						:is="Slide.component"
						:slide="Slide"
						:key="Slide._uid"
						v-editable="Slide"
					/>
				</swiper-slide>
			</swiper-container>
		</div>
	</div>
</template>

<script setup lang="ts">
import SbImage from '@/components/blocks/SbImage.vue'
import { localNavigation } from '@/mixins/localNavigation'
import type { ListItemSlidesStoryblok } from '@/sbTypes'
import { usePresentationController } from '@/stores/presentationController'
import { Swiper } from 'swiper'
import { type MaybeRef, ref } from 'vue'

const props = defineProps<{
	blok: ListItemSlidesStoryblok
	parentSwiper: Swiper
	parentDivRef: any
	slideIndex: number
}>()
const swiperRef = ref<MaybeRef | { swiper: Swiper }>()
const activeSlideIndex = ref(0)

/**
 * Switch slides on click
 */
function goToSlide(index: number) {
	swiperRef.value.swiper.slideTo(index)
}

const controller = usePresentationController()

const { changeAllowNext, changeAllowPrev } = localNavigation({
	onNext,
	onPrev,
	onSlideChange,
	parentSwiper: props.parentSwiper,
	slideIndex: props.slideIndex,
	swipingElRef: props.parentDivRef,
})

/*
 * This tries to make sure we aren't interacting with the current slide unless
 * we are on the correct slide. We are also disabling the parent from sliding
 * so the current page doesn't go out of view.
 */
function onSlideChange() {
	if (!props.blok?.Slides) return
	const swiper = swiperRef.value.swiper
	activeSlideIndex.value = swiper.activeIndex
	if (
		controller.restartSlide &&
		controller.activeSlideIndex === props.slideIndex
	) {
		swiper.slideTo(0)
		controller.resetSlideRestart()
	}

	if (props.blok && swiper.activeIndex < props.blok.Slides.length - 1) {
		changeAllowNext(false)
	}
	if (props.blok && swiper.activeIndex === props.blok.Slides.length - 1) {
		changeAllowNext(true)
	}

	if (swiper.activeIndex > 0) changeAllowPrev(false)
	if (swiper.activeIndex === 0) changeAllowPrev(true)
}

function onNext() {
	swiperRef.value?.swiper.slideNext()
}

function onPrev() {
	swiperRef.value?.swiper.slidePrev()
}
</script>

<style scoped>
.swiper-pagination-bullets {
	display: none;
}
</style>
