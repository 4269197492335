// Type guard function definitions. TypeScript understands that a variable
// is of the type indicated if the type guard returns true.
import type {
	PbLineItem,
	PbLineItemPackage,
	PbLineItemProduct,
} from './apiTypes'
import type { PbLineItemProductAndPricingOptions } from './classes/PriceBook'
import type { Session } from './classes/Session'
import type {
	SelectionCard,
	LabeledSelectionCardAbstract,
	SelectionCardTextStoryblok,
	SelectionCardAbstract,
	Block,
} from './sbTypes'
import type { CartItem, CartSubItem } from './types'

/** Custom type guard for labeled selection cards */
export const isLabeledSelectionCardAbstract = (
	x: SelectionCardAbstract
): x is LabeledSelectionCardAbstract => 'label' in x

/** Custom type guard for text selection cards */
export const isSelectionCardTextStoryblok = (
	x: SelectionCard
): x is SelectionCardTextStoryblok => x.component === 'selection-card-text'

/** Type guard for any Storyblok block */
export function isBlock(x: any): x is Block {
	return '_uid' in x && 'component' in x
}

/** Type guard for an instance of the Session class */
export function isSession(x: object): x is Session {
	return (
		Object.hasOwnProperty.call(x, 'plans') &&
		'plans' in x &&
		Array.isArray(x.plans)
	)
}

/** Type guard for PbLineItemProduct */
export function isPbLineItemProduct(
	lineItem: PbLineItem
): lineItem is PbLineItemProduct {
	return lineItem.calcRecordType === 'Product'
}

/** Type guard for PbLineItemPackage */
export function isPbLineItemPackage(
	lineItem: PbLineItem
): lineItem is PbLineItemPackage {
	return lineItem.calcRecordType === 'Package'
}

/** Type guard for PbLineItemProductAndPricingOptions */
export function isPbLineItemProductAndPricingOptions(
	lineItem: PbLineItem
): lineItem is PbLineItemProductAndPricingOptions {
	return isPbLineItemProduct(lineItem) && 'pricingOptions' in lineItem
}

/** Type guard for CartItem */
export function isCartItem(item: any): item is CartItem {
	return 'subItems' in item
}

/** Type guard for CartSubItem */
export function isCartSubItem(item: any): item is CartSubItem {
	return 'packageLineItemId' in item
}
