<!-- Slide for displaying a list of products that are not packages, urns, or caskets -->
<template>
	<div class="flex h-full px-10 pt-14">
		<div
			class="w-2/3 overflow-y-auto"
			ref="scrollContainer"
		>
			<div
				class="flex cursor-pointer p-5 hover:bg-precoa-blue-25"
				:class="selectedProduct?.id === p.id ? 'bg-precoa-blue-25' : ''"
				v-for="p in merchandiseProduct"
				:key="p.id"
				@click="selectedProduct = p"
			>
				<BlobImage
					class="h-52 w-[298px] flex-1 rounded-sm object-cover"
					:src="p.imageUrl"
					:alt="p.name"
				/>
				<div class="ml-10 flex w-1/2 flex-col justify-center">
					<div class="text-xl font-bold text-slate-900">{{ p.name }}</div>
					<div class="mb-4 mt-3 text-slate-900">
						{{ p.description }}
					</div>
					<PlanAvatarList
						:plans="productPlans[p.id]"
						filled
					/>
				</div>
			</div>
		</div>
		<div class="w-1/3 overflow-y-auto px-10 py-5">
			<FormProduct
				:product="selectedProduct"
				:key="selectedProduct?.id"
				hasCounter
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import BlobImage from '../common/BlobImage.vue'
import FormProduct from '../merchandise/FormProduct.vue'
import PlanAvatarList from '../presentation/PlanAvatarList.vue'
import type { IndexedPlan } from '@/classes/Plan'
import type { PbLineItemProductAndPricingOptions } from '@/classes/PriceBook'
import { useMerchandise } from '@/mixins/merchandise'
import type { EssentialSlideStoryblok } from '@/sbTypes'
import { useSessionStore } from '@/stores/session'
import { Swiper } from 'swiper'
import { computed, ref, type PropType, watch } from 'vue'

const props = defineProps({
	blok: Object as PropType<EssentialSlideStoryblok>,
	parentSwiper: Swiper,
	slideIndex: Number,
	active: Boolean,
})

const sessionStore = useSessionStore()
const { priceBook, isLineItemInPlan } = useMerchandise()

const scrollContainer = ref<HTMLElement | null>()

const plans = computed(() => {
	return sessionStore.activeSession?.plans
})

/** List of products of type merchandise */
const merchandiseProduct = computed(() => {
	return priceBook.value?.getMiscMerchandise()
})

const selectedProduct = ref<PbLineItemProductAndPricingOptions | undefined>(
	merchandiseProduct.value?.[0]
)

/** Dictionary for each product of which plans have it in their cart */
const productPlans = computed<Record<string, IndexedPlan[]>>(() => {
	if (!plans.value) return {}
	if (!merchandiseProduct.value) return {}

	return merchandiseProduct.value.reduce<Record<string, IndexedPlan[]>>(
		(acc, product) => {
			return {
				...acc,
				[product.id]: plans
					.value!.map(
						(plan, index): IndexedPlan => ({
							index,
							plan,
						})
					)
					.filter((indexedPlan) => isLineItemInPlan(product, indexedPlan.plan)),
			}
		},
		{}
	)
})

// Scroll to top when the slide becomes active
watch(
	() => props.active,
	(isActive) => {
		isActive && scrollContainer.value?.scrollTo(0, 0)
	}
)
</script>
