import type { PbPackageProduct } from '@/apiTypes'
import { ViewingOption } from '@/enums/ViewingOption'
import { isCartSubItem } from '@/guards'
import type { CartItem, CartSubItem, CustomCartItem, PlanData } from '@/types'
import { faker } from '@faker-js/faker'

export enum PlanSelectionDataName {
	visitation = 'visitationLocationSelection',
}

export type IndexedPlan = {
	index: number
	plan: Plan
}

export default class Plan implements PlanData {
	id = ''

	leadId = ''
	funeralHomeId = ''
	// Vital Info
	firstName = ''
	middleName = ''
	lastName = ''
	maidenName = ''
	address = ''
	city = ''
	state = ''
	zip = ''
	email = ''
	phone = ''
	birthDate = undefined
	birthCity = ''
	birthState = ''
	educationLevel = ''
	occupation = ''
	occupationYears = ''
	businessType = ''
	race = ''
	sex = ''
	vitalInfoNotes = ''

	// Spouse Info
	spouseInfo = {
		firstName: '',
		middleName: '',
		lastName: '',
		maidenName: '',
		marriageDate: undefined,
		marriageCity: '',
		marriageState: '',
		marriageCounty: '',
	}

	// Parental Info
	fatherInfo = {
		firstName: '',
		lastName: '',
		city: '',
		state: '',
		county: '',
	}
	motherInfo = {
		firstName: '',
		maidenName: '',
		city: '',
		state: '',
		county: '',
	}
	parentalInfoNotes = ''
	militaryRecord = []
	militaryRequests = []
	peopleResponsible = [
		{
			firstName: '',
			lastName: '',
			relationship: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
			email: '',
			notes: '',
		},
	]

	// Service Preferences
	funeralHomeName = ''
	placeOfService = ''
	placeOfGathering = ''
	religiousPreference = ''
	serviceOrganization = ''
	serviceNotes = ''

	burialOrCremation = ''
	internmentPrefs = ''
	cemeteryPurchaseStatus = ''
	cemeteryPropertyOwner = ''
	cemeteryName = ''
	internmentCity = ''
	internmentState = ''
	internmentNotes = ''
	postServiceMeal = false
	viewingPreference = ViewingOption.None
	specialInstructions = {
		flowerType: '',
		colorPreferences: '',
		musicPreference: '',
		casketBearers: '',
		wantsToNotifyBearers: false,
		obitPublications: '',
		wantsObitPhoto: false,
	}
	accessoriesInstructions = [
		{
			item: '',
			returnTo: '',
		},
	]
	otherMemorialNotes = ''

	mostImportantReason = ''

	visitationLocationSelection = ''
	cartItems: CartItem[] = []
	customCartItems: CustomCartItem[] = []

	constructor(plan: Partial<PlanData> | undefined = undefined) {
		if (!plan) {
			this.id = faker.string.uuid()
			return
		}

		Object.assign(this, plan)
	}

	public serialize(): PlanData {
		return {
			...this,
		}
	}

	public static deserialize(data: Partial<PlanData>): Plan {
		const plan = new Plan(data)
		return plan
	}

	public getInitials(): string {
		return this.firstName.substring(0, 1) + this.lastName.substring(0, 1)
	}

	/*** Shopping Cart Methods for updating plan data. Should not rely on Vue or Pinia. ***/
	/** Utility to add a line item to the shopping cart */
	public addCartProduct(
		lineItemId: string,
		quantity: number = 1,
		price: number | null = null
	) {
		if (quantity <= 0) {
			return this.hardDeleteCartItem(lineItemId)
		}

		const match = this.cartItems.find((i) => i.lineItemId === lineItemId)

		if (match) {
			match.isSoftDeleted = false
			match.quantity = Math.max(1, quantity)
			match.price = price
			return
		}
		this.cartItems.push({
			quantity,
			lineItemId,
			price,
			isSoftDeleted: false,
			subItems: [],
		})
	}

	/** Add a package to a cart */
	public addCartPackage(lineItemId: string, subItems: PbPackageProduct[]) {
		const match = this.cartItems.find((i) => i.lineItemId === lineItemId)

		const cartSubItems: CartSubItem[] = subItems.map((item) => ({
			packageLineItemId: lineItemId,
			lineItemId: item.lineItemId,
			isSoftDeleted: false,
			price: item.price,
			quantity: undefined,
		}))

		if (match) {
			match.subItems = cartSubItems
			match.isSoftDeleted = false
			return
		}
		this.cartItems.push({
			lineItemId,
			quantity: 1,
			price: null,
			isSoftDeleted: false,
			subItems: cartSubItems,
		})
	}

	/** Mark or unmark a cart item as soft-deleted */
	public softDeleteCartSubItem(
		lineItemId: string,
		subItemId: string,
		isSoftDeleted: boolean = true
	) {
		const matchItem = this.cartItems.find((i) => i.lineItemId === lineItemId)

		if (!matchItem) {
			throw new Error('Line item not found; cannot soft remove')
		}

		const matchSubItem = matchItem.subItems.find(
			(i) => i.lineItemId === subItemId
		)

		if (!matchSubItem) {
			throw new Error('Line item not found; cannot soft remove')
		}

		matchSubItem.isSoftDeleted = isSoftDeleted
	}

	/** Mark or unmark an item as soft deleted */
	public softDeleteCartItem(lineItemId: string, isSoftDeleted: boolean = true) {
		const match = this.cartItems.find((i) => i.lineItemId === lineItemId)

		if (!match) {
			throw new Error('Line item not found; cannot soft remove')
		}

		match.isSoftDeleted = isSoftDeleted
	}

	/** Remove an item or sub-item from the cart */
	public delete(cartItem: CartSubItem | CartItem) {
		if (isCartSubItem(cartItem)) {
			// We don't want to hard delete sub-items because we can't
			// add them back, and might need them for calculating discounts
			this.softDeleteCartSubItem(
				cartItem.packageLineItemId,
				cartItem.lineItemId
			)
		} else {
			this.hardDeleteCartItem(cartItem.lineItemId)
		}
	}

	/** Remove a cart item */
	public hardDeleteCartItem(lineItemId: string) {
		this.cartItems = this.cartItems.filter((i) => i.lineItemId !== lineItemId)
	}

	/** Add a custom item to the plan */
	public addCustomItem(item: Omit<CustomCartItem, 'id' | 'isSoftDeleted'>) {
		// Generate an id 1 higher than the current max ID
		const id =
			1 +
			this.customCartItems.reduce((maxId, item) => Math.max(maxId, item.id), 0)

		this.customCartItems.push({
			...item,
			id,
			isSoftDeleted: false,
		})
	}

	/** Mark or unmark an item as soft deleted */
	public softDeleteCustomItem(id: number, isSoftDeleted: boolean = true) {
		const match = this.customCartItems.find((i) => i.id === id)

		if (!match) {
			throw new Error('Custom cart item not found; cannot soft remove')
		}

		match.isSoftDeleted = isSoftDeleted
	}

	/** Remove a custom item */
	public hardDeleteCustomItem(itemId: number) {
		this.customCartItems = this.customCartItems.filter((i) => i.id !== itemId)
	}
}
