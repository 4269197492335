import SbHeading from './components/blocks/SbHeading.vue'
import SbHeadingAndListWithIcons from './components/blocks/SbHeadingAndListWithIcons.vue'
import SbHeadingAndSelectionCards from './components/blocks/SbHeadingAndSelectionCards.vue'
import SbHeadingAndTextInput from './components/blocks/SbHeadingAndTextInput.vue'
import SbMedia from './components/blocks/SbMedia.vue'
import SbSelectionCardBool from './components/blocks/SelectionCards/SbSelectionCardBool.vue'
import SbSelectionCardFuneralHome from './components/blocks/SelectionCards/SbSelectionCardFuneralHome.vue'
import SbSelectionCardText from './components/blocks/SelectionCards/SbSelectionCardText.vue'
import SbListItemImageSlide from './components/blocks/twoColumnNavigationSlide/SbListItemImageSlide.vue'
import SbListItemSlides from './components/blocks/twoColumnNavigationSlide/SbListItemSlides.vue'
import SbListItemTextSlide from './components/blocks/twoColumnNavigationSlide/SbListItemTextSlide.vue'
import SbListItemVideoSlide from './components/blocks/twoColumnNavigationSlide/SbListItemVideoSlide.vue'
import SwiperPresentation from './components/presentation/SwiperPresentation.vue'
import InflationEstimateSlide from './components/slides/InflationEstimateSlide.vue'
import MemorialGuide from './components/slides/MemorialGuide.vue'
import MostImportantReasonSlide from './components/slides/MostImportantReasonSlide.vue'
import PackagesSlide from './components/slides/PackagesSlide.vue'
import ProductsSlide from './components/slides/ProductsSlide.vue'
import SbImageList from '@/components/blocks/SbImageList.vue'
import SbListWithIcons from '@/components/blocks/SbListWithIcons.vue'
import SbListWithIconsItem from '@/components/blocks/SbListWithIconsItem.vue'
import SbNextChapterBtn from '@/components/blocks/SbNextChapterBtn.vue'
import SbRichTextBlock from '@/components/blocks/SbRichTextBlock.vue'
import SbVerticalSegments from '@/components/blocks/SbVerticalSegments.vue'
import SbSelectionCards from '@/components/blocks/SelectionCards/SbSelectionCards.vue'
import SbCenteredContentSlide from '@/components/slideTemplates/SbCenteredContentSlide.vue'
import SbFullscreenMediaSlide from '@/components/slideTemplates/SbFullscreenMediaSlide.vue'
import SbTwoColumnMediaSlide from '@/components/slideTemplates/SbTwoColumnMediaSlide.vue'
import SbVideoSlide from '@/components/slideTemplates/SbVideoSlide.vue'
import EssentialSlide from '@/components/slides/EssentialSlide.vue'
import ReviewSlide from '@/components/slides/ReviewSlide.vue'
import SbFhIntroductionSlide from '@/components/slides/SbFhIntroductionSlide.vue'
import SbFhLogoSlide from '@/components/slides/SbFhLogoSlide.vue'

// Enumerate and alias global components used by Storyblok
const components = {
	'centered-content': SbCenteredContentSlide,
	'funeral-home-logo-slide': SbFhLogoSlide,
	'funeral-home-introduction-slide': SbFhIntroductionSlide,
	'fullscreen-media': SbFullscreenMediaSlide,
	heading: SbHeading,
	'heading-and-list-with-icons': SbHeadingAndListWithIcons,
	'heading-and-selection-cards': SbHeadingAndSelectionCards,
	'heading-and-text-input': SbHeadingAndTextInput,
	'list-with-icons-item': SbListWithIconsItem,
	'list-with-icons': SbListWithIcons,
	'list-item-image-slide': SbListItemImageSlide,
	'list-item-text-slide': SbListItemTextSlide,
	'list-item-video-slide': SbListItemVideoSlide,
	'list-item-slides': SbListItemSlides,
	'memorial-guide-slide': MemorialGuide,
	'most-important-reason-slide': MostImportantReasonSlide,
	'next-chapter-btn': SbNextChapterBtn,
	'packages-slide': PackagesSlide,
	'products-slide': ProductsSlide,
	'rich-text-block': SbRichTextBlock,
	'sb-media': SbMedia,
	'selection-card-funeral-home': SbSelectionCardFuneralHome,
	'selection-card-text': SbSelectionCardText,
	'selection-card-viewing-option': SbSelectionCardText,
	'selection-card-bool': SbSelectionCardBool,
	'sb-image-list': SbImageList,
	'slide-deck': SwiperPresentation,
	'two-column-content-asset': SbTwoColumnMediaSlide,
	'vertical-segments': SbVerticalSegments,
	'video-slide': SbVideoSlide,
	'selection-cards': SbSelectionCards,
	'essential-slide': EssentialSlide,
	'review-slide': ReviewSlide,
	'inflation-estimate-slide': InflationEstimateSlide,
}

export default components
