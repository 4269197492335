<template>
	<div class="h-full w-full">
		<swiper-container
			class="h-full"
			ref="swiperRef"
			speed="500"
			:mousewheel="false"
			:keyboard="false"
			effect="slide"
			@swiperslidechange="onSlideChange"
			direction="vertical"
			:pagination="{
				el: '.swiper-pagination',
				dynamicBullets: true,
			}"
		>
			<swiper-slide
				v-for="segment in blok?.segments"
				:key="segment._uid"
				class="grid items-center p-16"
				v-editable="blok"
			>
				<!-- Support old mode of wrapping content in a segment -->
				<template v-if="segment.component === 'segment'">
					<component
						v-for="b in segment.content"
						:key="b._uid"
						:blok="b"
						:is="b.component"
					></component>
				</template>

				<!-- New method: each layout is assumed to be its own slide -->
				<component
					v-else
					:blok="segment"
					:is="segment.component"
				></component>
			</swiper-slide>
		</swiper-container>
	</div>
</template>

<script setup lang="ts">
import { localNavigation } from '@/mixins/localNavigation'
import type { VerticalSegmentsStoryblok } from '@/sbTypes'
import { usePresentationController } from '@/stores/presentationController'
import { Swiper } from 'swiper'
import { type MaybeRef, ref, type PropType } from 'vue'

const swiperRef = ref<MaybeRef | { swiper: Swiper }>()
const props = defineProps({
	blok: Object as PropType<VerticalSegmentsStoryblok>,
	parentSwiper: Object as PropType<Swiper>,
	parentDivRef: Object as any,
	slideIndex: Number,
})
const controller = usePresentationController()

const { changeAllowNext, changeAllowPrev } = localNavigation({
	onNext,
	onPrev,
	onSlideChange,
	parentSwiper: props.parentSwiper,
	slideIndex: props.slideIndex,
	swipingElRef: props.parentDivRef,
})

/*
 * This tries to make sure we aren't interacting with the current slide unless
 * we are on the correct slide. We are also disabling the parent from sliding
 * so the current page doesn't go out of view.
 */
function onSlideChange() {
	const swiper = swiperRef.value.swiper
	if (
		controller.restartSlide &&
		controller.activeSlideIndex === props.slideIndex
	) {
		swiper.slideTo(0)
		controller.resetSlideRestart()
	}

	if (props.blok && swiper.activeIndex < props.blok?.segments.length - 1) {
		changeAllowNext(false)
	}
	if (props.blok && swiper.activeIndex === props.blok?.segments.length - 1) {
		changeAllowNext(true)
	}

	if (swiper.activeIndex > 0) changeAllowPrev(false)
	if (swiper.activeIndex === 0) changeAllowPrev(true)
}

function onNext() {
	swiperRef.value?.swiper.slideNext()
}

function onPrev() {
	swiperRef.value?.swiper.slidePrev()
}
</script>

<style>
swiper-container {
	--swiper-pagination-right: auto;
	--swiper-pagination-left: 4px;
	--swiper-pagination-color: #0c58b7; /* Precoa Blue 700 */
}
</style>
