<template>
	<precoa-modal
		v-model:open="auth.showLogin"
		modal-title="Session Expired"
	>
		<template #default>
			<div
				class="text-center font-sans text-sm font-normal leading-tight text-slate-500"
			>
				<span class="max-w-[267px]">
					Please re-enter your password to continue syncing your data.</span
				>
			</div>
			<LoginForm />
		</template>
	</precoa-modal>
</template>

<script setup lang="ts">
import LoginForm from '../forms/LoginForm.vue'
import PrecoaModal from '@/components/common/PrecoaModal.vue'
import { useAuthStore } from '@/stores/auth'

const auth = useAuthStore()
</script>
