import { DeckFlavor } from '@/enums/Language'
import { UnauthorizedError } from '@/errors/UnauthorizedError'
import type { SlideDeckStoryblok } from '@/sbTypes'
import { useAuthStore } from '@/stores/auth'
import type {
	StoryblokDeck,
	StoryblokGroupMedia,
	StoryblokStory,
} from '@/types'
import { useStoryblokApi } from '@storyblok/vue'

/** Fetches the requested stories in all flavors */
export async function getStories<T>(
	starts_with: string,
	preview: boolean
): Promise<StoryblokStory<T>[]> {
	const storyblokApi = useStoryblokApi()
	const token = await getToken()

	return Promise.all(
		Object.values(DeckFlavor).map((lang) =>
			storyblokApi
				.get('cdn/stories', {
					version: preview ? 'draft' : 'published',
					starts_with: starts_with,
					language: lang,
					per_page: 100,
					token,
				})
				.then((response): StoryblokStory<T>[] => response.data.stories)
		)
	).then((langResults) => langResults.flat(1))
}

/** Fetches a single story in all available flavors */
export async function getStory<T>(
	path: string,
	preview: boolean
): Promise<StoryblokStory<T>[]> {
	const storyblokApi = useStoryblokApi()
	const token = await getToken()

	return Promise.all(
		Object.values(DeckFlavor).map((lang) =>
			storyblokApi
				.get(path, {
					version: preview ? 'draft' : 'published',
					language: lang,
					token,
				})
				.then((response) => response.data.stories[0])
		)
	)
}

export async function getSlideDecks(
	preview: boolean
): Promise<StoryblokDeck[]> {
	// Always load regular decks
	const toLoad = [
		getStories<SlideDeckStoryblok>('presentations/slide-decks', preview),
	]

	// If in development, also load dev decks
	if (import.meta.env.VITE_APP_STORYBLOK_DEV === 'true') {
		toLoad.push(
			getStories<SlideDeckStoryblok>('presentations/dev-slide-decks', preview)
		)
	}

	const deckGroups = await Promise.all(toLoad)

	return deckGroups.flat(1)
}

export async function getFuneralHomeGroupMedias(
	preview: boolean,
	groupIds: string[]
): Promise<StoryblokGroupMedia[]> {
	const storyblokApi = useStoryblokApi()

	// Concat the ids together into a single query
	const idQuery = groupIds.map((id) => 'funeral-home-info/' + id).join(',')
	const token = await getToken()

	// Do one query per deck flavor
	const responses = await Promise.all(
		Object.values(DeckFlavor).map((lang) =>
			storyblokApi
				.get('cdn/stories', {
					language: lang,
					by_slugs: idQuery,
					version: preview ? 'draft' : 'published',
					token,
				})
				.then((response) => response.data.stories)
		)
	)

	return responses.flat(1)
}

/**
 * Get a single slide deck by story slug
 *
 * @param path - The story path and slug, e.g. 'slide-decks/example-presentation'
 * @param lang - The language for the deck
 * @param preview - If true, fetches the preview version. Otherwise, returns published version.
 */
export async function getSlideDeck(
	slug: string,
	lang: DeckFlavor,
	preview = false
): Promise<StoryblokStory<SlideDeckStoryblok>> {
	const storyblokApi = useStoryblokApi()
	const token = await getToken()

	return storyblokApi
		.get('cdn/stories/presentations/' + slug, {
			version: preview ? 'draft' : 'published',
			language: lang,
			token,
		})
		.then(
			(response) => response.data.story as StoryblokStory<SlideDeckStoryblok>
		)
}

export async function getFuneralHomeGroupMedia(
	slug: string,
	lang: DeckFlavor,
	preview = false
): Promise<StoryblokGroupMedia> {
	const storyblokApi = useStoryblokApi()
	const token = await getToken()

	return storyblokApi
		.get('cdn/stories/funeral-home-info/' + slug, {
			version: preview ? 'draft' : 'published',
			language: lang,
			token,
		})
		.then((response) => response.data.story as StoryblokGroupMedia)
}

async function getToken(): Promise<string> {
	const auth = useAuthStore()
	const token = await auth.getStoryBlokToken()
	if (!token) {
		throw new UnauthorizedError('Cannot use storyblok: we have no token')
	}

	return token
}
