<!-- 
Presentational component for displaying the loading status of a slide deck 
-->
<template>
	<figure
		class="relative h-[171px] w-[225px] overflow-hidden rounded-xl"
		:class="{
			'border-[3px] border-precoa-blue-700 shadow-xl': selected,
		}"
	>
		<!-- Background -->
		<div
			class="absolute inset-0 bg-cover"
			:class="{
				'blur-xl': downloadProgress !== undefined || error,
				'contrast-[.4] grayscale': inactive,
				'!inset-[-3px]': selected,
			}"
			:style="`background-image: url(${imageUrl || 'https://placehold.co/215x171'});`"
		></div>

		<!-- Checkmark - Draw outline over solid -->
		<div
			v-if="selected"
			class="absolute right-2 top-2 h-6 w-6 *:absolute *:inset-0"
		>
			<CheckCircleIconSolid class="z-10 fill-precoa-blue-700" />
			<CheckCircleIconOutline class="z-20 stroke-white" />
		</div>

		<div class="absolute inset-0 grid place-content-center">
			<ExclamationCircleIcon
				v-if="error"
				class="h-8 w-8 fill-red-500 stroke-white"
			/>
			<ProgressCircle
				v-else-if="downloadProgress !== undefined"
				:progress="downloadProgress"
			/>
		</div>

		<!-- New tag -->
		<div
			v-if="fresh"
			class="absolute left-2 top-2 rounded-md bg-primary-green-500 px-2 py-1 font-sans text-base font-medium uppercase tracking-tight text-slate-50"
		>
			New
		</div>

		<!-- Title -->
		<figcaption
			class="absolute line-clamp-2 rounded-md px-2 py-1 font-sans text-base font-medium tracking-tight text-slate-50"
			:class="[
				selected
					? 'bottom-2 left-2 bg-precoa-blue-700'
					: 'bottom-[11px] left-[11px]',
			]"
			data-test-id="deck-thumbnail-title"
			:title="title"
		>
			{{ title }}
		</figcaption>
	</figure>
</template>

<script setup lang="ts">
import ProgressCircle from '@/components/common/ProgressCircle.vue'
import {
	CheckCircleIcon as CheckCircleIconOutline,
	ExclamationCircleIcon,
} from '@heroicons/vue/24/outline'
import { CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/vue/24/solid'

const props = defineProps({
	imageUrl: String,
	selected: Boolean,
	inactive: Boolean,
	error: Boolean,
	fresh: Boolean,
	downloadProgress: {
		type: Number,
		default: undefined,
	},
	title: String,
})

/** Expose props for unit tests */
defineExpose({
	selected: props.selected,
	inactive: props.inactive,
})
</script>
