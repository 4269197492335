<!-- Layout component for rendering a heading and list with icons -->
<template>
	<div
		class="w-full max-w-4xl"
		v-if="blok"
		v-editable="blok"
	>
		<!-- HeadingSmall field-->
		<HeadingSmall
			v-if="blok.headingSmallOne"
			:headingText="blok.headingSmallOne"
			class="pb-4"
		/>

		<!-- HeadingMedium field-->
		<HeadingMedium
			:richTextHtml="richTextHtml"
			class="pb-12"
		/>

		<!-- HeadingSmall field two-->
		<HeadingSmall
			v-if="blok.headingSmallTwo"
			:headingText="blok.headingSmallTwo"
			class="pb-4"
		/>

		<!-- List with Icons-->
		<ul
			role="list"
			class="divide-y divide-gray-200"
		>
			<li
				v-for="item in blok.listItems"
				:key="item._uid"
				class="py-4"
			>
				<component
					:is="item.component"
					:blok="item"
				/>
			</li>
		</ul>
	</div>
	<div v-else>No heading and list configured</div>
</template>

<script setup lang="ts">
import HeadingMedium from '../presentation/HeadingMedium.vue'
import HeadingSmall from '../presentation/HeadingSmall.vue'
import type { HeadingAndListWithIconsStoryblok } from '@/sbTypes'
import { renderRichText } from '@storyblok/vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
	blok: Object as PropType<HeadingAndListWithIconsStoryblok>,
})

const richTextHtml = computed(() => {
	return renderRichText(props.blok?.headingMedium)
})
</script>
