<!-- Container you can swipe left/right in order to toggle a value -->
<template>
	<div class="relative w-full">
		<div
			ref="swipeTarget"
			class="group relative z-20 flex w-full select-none items-center justify-between gap-5 bg-white p-3 hover:bg-precoa-blue-25"
			:class="{ 'transition-transform duration-200 ease-in-out': !isSwiping }"
			:style="{ transform: `translateX(${swipePosition}px)` }"
			@pointerdown.stop
			@pointermove.stop
			style="touch-action: pan-y"
		>
			<slot></slot>
			<button
				v-if="mouseMode"
				class="hidden group-hover:block"
				type="button"
				@click="value = !value"
			>
				<MinusCircleIcon
					v-if="value"
					class="h-5 w-5 fill-red-500"
				/>
				<PlusCircleIcon
					v-else
					class="h-5 w-5 fill-green-500"
				/>
			</button>
		</div>

		<!-- Indicators behind swipe target -->
		<div
			class="absolute top-1/2 z-10 flex h-full w-full -translate-y-1/2 items-center justify-between bg-precoa-blue-25 p-3"
		>
			<div class="*:h-5 *:w-5">
				<MinusCircleIcon
					v-if="value"
					class="fill-red-500"
				/>

				<PlusCircleIcon
					v-else
					class="fill-green-500"
				/>
			</div>
			<div class="*:h-5 *:w-5">
				<MinusCircleIcon
					v-if="value"
					class="fill-red-500"
				/>

				<PlusCircleIcon
					v-else
					class="fill-green-500"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { usePresentationController } from '@/stores/presentationController'
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/solid'
import { usePointerSwipe } from '@vueuse/core'
import { usePointer } from '@vueuse/core'
import { ref, computed, onMounted } from 'vue'

const { pointerType } = usePointer()

const value = defineModel<boolean>()
const swipeTarget = ref<HTMLElement | null>(null)
const swipePosition = ref(0)

const isSwiping = ref(false) // Tracks whether swiping is happening

/** True if the user is using a mouse */
const mouseMode = computed(() => pointerType.value === 'mouse')

// Handles swiping on a single item and toggle the removed styling
onMounted(() => {
	const { distanceX } = usePointerSwipe(swipeTarget, {
		disableTextSelect: true,
		onSwipe(event: PointerEvent) {
			event.preventDefault()
			event.stopPropagation()

			if (mouseMode.value) {
				return
			} else {
				swipePosition.value = -distanceX.value
				isSwiping.value = true // Disable transition while dragging
			}
		},
		onSwipeEnd(_event: PointerEvent) {
			if (mouseMode.value) {
				return
			}

			if (Math.abs(distanceX.value) >= 100) {
				value.value = !value.value // Toggle removed styling
			}
			isSwiping.value = false // Enable transition after swipe ends

			swipePosition.value = 0 // Reset position
		},
	})
})
</script>
