export enum EventType {
	TRADITIONAL_CEP = 1,
	FH_FOOD = 5,
	RESTAURANT_FOOD = 6,
	VETERANS_BUS = 7,
	MOVIE_NIGHT = 8,
	FUNERAL_HOME = 9,
	RESTAURANT = 10,
	MOVIE_THEATER = 11,
	VETERANS_ORGANIZATION = 12,
	COUNTRY_CLUB = 13,
	OTHER = 14,
}
