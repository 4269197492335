// Asset store for centralizaing fetching assets based on URL
import type {
	SbMediaStoryblok,
	AssetStoryblok,
	ListItemVideoSlideStoryblok,
} from '@/sbTypes'
import type { Cacheable } from '@/types'
import { captureException } from '@sentry/vue'
import localforageLib from 'localforage'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

/** IndexedDB for storing assets offline */
const localAssets = localforageLib.createInstance({
	name: 'content',
	storeName: 'assets',
})

/** IndexedDB for keeping track of asset store version */
const localVersions = localforageLib.createInstance({
	name: 'content',
	storeName: 'versions',
})

export type Asset = {
	arrayBuffer: ArrayBuffer
	type: string
}

export type StoredAsset = Cacheable<Asset>

export type GetAssetOptions = {
	verify?: boolean
	prefetch?: boolean

	/** If not found locally, fail */
	noNetwork?: boolean
}

/** Type representing an object from SB that might have a videoUrl */
type VideoHaver = ListItemVideoSlideStoryblok | SbMediaStoryblok

export const useAssetStore = defineStore('asset', () => {
	let initialized = false

	/** List of asset URLs that are in the cache */
	const cachedAssetUrls = ref<string[]>([])

	/** Map of asset URLs to blob URLs */
	const assetBlobUrls: Map<string, string> = new Map()

	/** Map of asset URLs to promises which are resolved with Blob URLs */
	const assetBlobUrlPromises: Map<
		string,
		Promise<string | undefined>
	> = new Map()

	/** Map of asset URLs to promises which are resolved when the asset is cached */
	const assetPromises: Map<string, Promise<Blob>> = new Map()

	const startedAssetPromiseCount = ref(0)
	const completedAssetPromiseCount = ref(0)

	const assetDownloadPercentage = computed(() => {
		return startedAssetPromiseCount.value === 0
			? undefined
			: completedAssetPromiseCount.value / startedAssetPromiseCount.value
	})

	/** Initializes the asset store. Optional to call. Useful for tests. */
	async function initialize() {
		if (initialized) {
			return
		}

		await processVersionUpgrades()
		cachedAssetUrls.value = await localAssets.keys()
		initialized = true
	}

	/** Get the storage schema version of the asset store */
	async function getVersion(): Promise<number> {
		const version = await localVersions.getItem<string>('assets')
		return version ? parseInt(version) : 0
	}

	/** Set the storage schema version of the asset store */
	async function setVersion(version: number) {
		await localVersions.setItem('assets', version.toString())
	}

	/**
	 * Version 1: Data needs to be stored as ArrayBuffer
	 * Just delete the data and re-download. This has the least
	 * chance of failing.
	 */
	async function upgradeToV1() {
		await localAssets.clear()
	}

	/** Make sure the asset store is in the schema expected by the code */
	async function processVersionUpgrades() {
		const version = await getVersion()

		// Version 0: Store data as blob

		if (version < 1) {
			await upgradeToV1()
			await setVersion(1)
		}
	}

	/** Saves a downloaded asset to the cache */
	async function cacheAsset(url: string, asset: StoredAsset) {
		cachedAssetUrls.value.push(url)
		await localAssets.setItem(url, asset)
	}

	/** Fetch and cache an asset */
	async function fetchAndCacheAsset(url: string) {
		const response = await fetch(url)
		const blob = await response.blob()

		try {
			cacheAsset(url, {
				data: {
					arrayBuffer: await blob.arrayBuffer(),
					type: blob.type,
				},
				etag: response.headers.get('etag') || '',
			})
		} catch (error) {
			console.error(error)
			captureException(error)
		}
		return blob
	}

	/** Returns a Promise that resolves with blob URL for an asset */
	async function getAssetBlobUrl(
		url: string,
		options: GetAssetOptions = {}
	): Promise<string | undefined> {
		initialized || (await initialize())

		// If we have already computed a blob URL for this asset, return it
		if (assetBlobUrls.has(url)) {
			return assetBlobUrls.get(url)
		}

		// If we already have a blob url promise for this URL, return it
		if (assetBlobUrlPromises.has(url)) {
			return assetBlobUrlPromises.get(url)
		}

		// Create a promise for getting the blob url
		const blobUrlPromise = getAsset(url, options).then((blob) => {
			if (!blob) {
				throw new Error(`Asset not found: ${url}`)
			}
			const blobUrl = URL.createObjectURL(blob)
			assetBlobUrls.set(url, blobUrl)
			return blobUrl
		})

		assetBlobUrlPromises.set(url, blobUrlPromise)

		return blobUrlPromise
	}

	/** Returns a Promise that resolves when the asset is cached */
	async function getAsset(
		url: string,
		{ verify }: GetAssetOptions = {}
	): Promise<Blob | undefined> {
		await initialize()

		// If we already have an asset promise for this URL, return it
		if (assetPromises.has(url)) {
			return assetPromises.get(url)
		}

		// If we have already cached this asset, return it
		if (cachedAssetUrls.value.includes(url)) {
			startedAssetPromiseCount.value++
			const storedAssetPromise = getAssetFromCache(url, verify).then(
				(asset) => {
					if (asset) {
						completedAssetPromiseCount.value++
						return new Blob([asset.data.arrayBuffer], { type: asset.data.type })
					}
					throw new Error(`Asset not found: ${url}`)
				}
			)
			assetPromises.set(url, storedAssetPromise)
			return storedAssetPromise
		}

		// Fetch and cache the asset
		const fetchPromise = fetchAndCacheAsset(url)
		startedAssetPromiseCount.value++
		fetchPromise.then(() => completedAssetPromiseCount.value++)
		assetPromises.set(url, fetchPromise)
		return fetchPromise
	}

	/** Gets an asset from the cache */
	async function getAssetFromCache(
		url: string,
		verify = false
	): Promise<StoredAsset | undefined> {
		let asset = await localAssets.getItem<StoredAsset>(url)

		if (!asset) {
			return undefined
		}

		// If the client wants to verify the cache, refetch using ETag
		if (verify) {
			const response = await fetch(url, {
				headers: {
					'If-None-Match': asset.etag,
				},
			})
			if (response.status === 200) {
				const blob = await response.blob()
				asset = {
					data: {
						arrayBuffer: await blob.arrayBuffer(),
						type: blob.type,
					},
					etag: response.headers.get('etag') || '',
				}
				await cacheAsset(url, asset)
			}
		}

		return asset
	}

	/** Returns true if the asset is cached */
	function isAssetCached(url: string) {
		return cachedAssetUrls.value.includes(url)
	}

	/**
	 * Remove all cached items that are not in the given list
	 */
	function deleteUnusedAssets(keys: string[]) {
		return new Promise<void>((resolve) => {
			// Remove items from memory lists
			cachedAssetUrls.value = cachedAssetUrls.value.filter((url) =>
				keys.includes(url)
			)

			for (const url of assetBlobUrlPromises.keys()) {
				if (!keys.includes(url)) {
					assetBlobUrlPromises.delete(url)
				}
			}

			for (const url of assetPromises.keys()) {
				if (!keys.includes(url)) {
					assetPromises.delete(url)
				}
			}

			// Remove items from local storage
			localAssets
				.iterate((_value, key) => {
					if (!keys.includes(key)) {
						localAssets.removeItem(key)
					}
				})
				.then(() => {
					resolve()
				})
		})
	}

	/**
	 * @param asset Storyblok asset
	 * @returns URL to get the asset form the image service
	 */
	function getImageUrl(asset: AssetStoryblok) {
		if (!asset.filename) {
			return undefined
		}

		// Use webp format for best compression unless original is SVG
		const format =
			asset.filename.includes('storyblok.com') &&
			!asset.filename.endsWith('.svg')
				? '/m/filters:format(webp)'
				: ''

		return (
			// Use alt server that supports CORS
			asset.filename.replace(
				'https://a-us.storyblok.com',
				`${import.meta.env.VITE_SALES_STUDIO_API_URL}/api/storyblok-image`
			) + format
		)
	}

	/** Get the URL to get the asset from the video service */
	function getVideoUrl(video: VideoHaver) {
		if (!video.videoUrl || typeof video.videoUrl !== 'string') {
			return undefined
		}

		return (
			// Use alt server that supports CORS
			video.videoUrl.replace(
				'https://a-us.storyblok.com',
				`${import.meta.env.VITE_SALES_STUDIO_API_URL}/api/storyblok-image`
			)
		)
	}

	/** Given an Asset, returns a promise that resolves with a blob URL */
	function getImageBlobUrl(asset: AssetStoryblok) {
		const url = getImageUrl(asset)
		return url ? getAssetBlobUrl(url) : undefined
	}

	/** Given a SbMedia, returns a promise that resolves with a blob URL */
	function getVideoBlobUrl(video: VideoHaver) {
		const formattedUrl = getVideoUrl(video)
		return formattedUrl ? getAssetBlobUrl(formattedUrl) : undefined
	}

	/** Delete everything */
	function purge() {
		initialized = false
		cachedAssetUrls.value = []
		assetBlobUrls.clear()
		assetBlobUrlPromises.clear()
		assetPromises.clear()
		startedAssetPromiseCount.value = 0
		completedAssetPromiseCount.value = 0
		return localAssets.clear()
	}

	return {
		assetDownloadPercentage,
		deleteUnusedAssets,
		getAsset,
		getAssetBlobUrl,
		getImageBlobUrl,
		getImageUrl,
		getVideoBlobUrl,
		getVideoUrl,
		isAssetCached,
		initialize,
		purge,
	}
})
