<template>
	<div
		class="relative inline-block h-fit rounded-full"
		@mouseenter="onMouseEnter"
		@mouseleave="onMouseLeave"
		@click="onClick"
	>
		<!-- Info button -->
		<button
			ref="infoIcon"
			:class="[
				'h-6 w-6 rounded-full font-serif text-white',
				isTooltipVisible ? 'bg-slate-400' : 'bg-slate-300',
				!isTouchDevice ? 'hover:bg-slate-400' : '',
			]"
		>
			i
		</button>

		<!-- Tooltip rendered in body to avoid ancestor overflow restrictions -->
		<Teleport to="body">
			<div
				v-if="isTooltipVisible"
				:style="{
					top: tooltipPosition.top + 'px',
					left: tooltipPosition.left + 'px',
					transform: 'translate(-50%, -100%)',
				}"
				class="pointer-events-none fixed z-50 min-w-[300px] rounded-lg bg-slate-200 p-4 text-sm font-medium text-slate-500 opacity-100 shadow-md [&_li]:ml-6 [&_li]:list-disc"
			>
				<!-- Tooltip content -->
				<div v-html="richTextHtml"></div>
				<!-- Tooltip arrow -->
				<div
					class="absolute bottom-[1px] left-1/2 h-4 w-4 -translate-x-1/2 translate-y-2 rotate-45 transform bg-slate-200"
				></div>
			</div>
		</Teleport>
	</div>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue'

defineProps({
	richTextHtml: {
		type: String,
		required: true,
	},
})

const isTooltipVisible = ref(false)
const infoIcon = ref<HTMLButtonElement | null>(null)
const tooltipPosition = reactive({ top: 0, left: 0 })

// Detect if the device supports touch
const isTouchDevice = ref(
	'ontouchstart' in window || navigator.maxTouchPoints > 0
)

/**
 * Update the isTouchDevice state by checking if the device supports touch.
 */
const updateIsTouchDevice = () => {
	isTouchDevice.value = 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

/**
 * If the tooltip is visible, add event listeners.
 * Otherwise, remove them.
 */
watch(
	() => isTooltipVisible.value,
	(newValue) => {
		if (!newValue) {
			// If tooltip is not visible, remove event listeners
			window.removeEventListener('resize', updateIsTouchDevice)
			document.removeEventListener('click', onClickOutside)
		} else {
			// If tooltip is visible, add event listeners
			window.addEventListener('resize', updateIsTouchDevice)
			document.addEventListener('click', onClickOutside)
		}
	}
)

/**
 * On mouseEnter if the device is not a touch device update the tooltip position
 * and set isTooltipVisible to true
 */
const onMouseEnter = () => {
	if (!isTouchDevice.value) {
		const rect = infoIcon.value?.getBoundingClientRect()
		if (rect) {
			tooltipPosition.top = rect.top - 10
			tooltipPosition.left = rect.left + rect.width / 2
			isTooltipVisible.value = true
		}
	}
}

/**
 * On mouseLeave if the device is not a touch device set isTooltipVisible to false
 */
const onMouseLeave = () => {
	if (!isTouchDevice.value) {
		isTooltipVisible.value = false
	}
}

/**
 * On click if the device is a touch device toggle the isTooltipVisible state
 * and ensure correct tooltip position
 */
const onClick = (event: MouseEvent) => {
	if (isTouchDevice.value) {
		event.stopPropagation()

		isTooltipVisible.value = !isTooltipVisible.value

		if (isTooltipVisible.value) {
			const rect = infoIcon.value?.getBoundingClientRect()
			if (rect) {
				tooltipPosition.top = rect.top - 10
				tooltipPosition.left = rect.left + rect.width / 2
			}
		}
	}
}

/**
 * On click events outside of the component hide the tooltip
 */
const onClickOutside = () => {
	if (isTouchDevice.value) {
		isTooltipVisible.value = false
	}
}
</script>
