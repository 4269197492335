<template>
	<div
		class="flex w-full flex-col gap-10"
		:class="!isSidePanelLayout ? 'md:flex-row' : ''"
	>
		<!--Package Image-->
		<div
			class="w-full"
			:class="!isSidePanelLayout ? 'md:w-1/2' : ''"
		>
			<div
				class="relative aspect-[435/220] w-full overflow-hidden rounded-lg shadow-lg md:aspect-[435/360]"
				:class="isSidePanelLayout ? 'aspect-[435/360]' : ''"
			>
				<div
					class="absolute inset-0 bg-cover bg-center bg-no-repeat"
					:style="`background-image: url(${imageUrl || 'https://placehold.co/600x500'});`"
				></div>
			</div>
		</div>
		<!--Package Details Section-->
		<div
			class="w-full"
			:class="
				!isSidePanelLayout ? 'overflow-hidden md:w-1/2' : 'overflow-x-hidden'
			"
		>
			<div class="flex w-full flex-col gap-7">
				<div class="p-1">
					<div class="flex items-center gap-2">
						<h2
							class="text-slate-900"
							:class="
								isSidePanelLayout
									? 'font-serif text-5xl italic'
									: 'text-2xl font-bold'
							"
						>
							{{ pkg.name }}
						</h2>
						<div v-if="!isSidePanelLayout">
							<ShieldCheckIcon class="size-6 text-primary-green-600" />
						</div>
					</div>

					<div
						class="flex items-center gap-2 py-2"
						v-if="isSidePanelLayout"
					>
						<div class="text-[22px] font-semibold text-precoa-blue-700">
							{{ formatPrice(pkg.price) }}
						</div>
						<ShieldCheckIcon class="size-6 text-primary-green-600" />
					</div>

					<p class="mt-2 text-base text-slate-500">
						{{ pkg.description }}
					</p>

					<template v-if="isSidePanelLayout">
						<InputQuantity
							v-for="plan in plans"
							:key="plan.id"
							class="my-4 w-full"
							:plan="plan"
							:product="pkg"
						/>
					</template>
					<div
						v-else
						class="ml-[1px] flex gap-2"
					>
						<PlanAvatar
							v-for="(plan, index) in sessionStore.activeSession?.plans"
							:key="index"
							:index="index"
							class="mt-2"
							@click="togglePackageInPlan(plan)"
							:filled="isLineItemInPlan(pkg, plan)"
							:plan="plan"
						/>
					</div>
				</div>

				<!-- Package Line Items -->
				<ul
					class="flex w-full flex-col gap-5 *:flex *:w-full *:items-center *:justify-between"
				>
					<li
						v-for="product in pkgLineItems"
						:key="product.id"
					>
						<div class="text-base font-medium text-slate-900">
							{{ product.lineItem?.name }}
						</div>
						<PriceDetailsDisplay
							:price-details="{
								price: product.price ?? 0,
								originalPrice: product.price ?? 0,
								guaranteed: 0,
								nonGuaranteed: 0,
							}"
							:credit="
								product.lineItem && qualifiesForDiscount(product.lineItem)
							"
							size="xs"
						/>
					</li>
				</ul>
				<div v-if="!isSidePanelLayout">
					<template v-if="selectedPlanCount <= 1">
						<!-- Single plan or no plans: Logo and price as the swipable item -->
						<SwipeableToggle v-model="singleOapIncluded">
							<div
								ref="singleSwipeTarget"
								class="flex w-full select-none items-center justify-between gap-5 text-slate-500"
							>
								<div>
									<img
										src="https://a-us.storyblok.com/f/1019754/130x43/b7c48e6e88/inman_logo.png"
										alt="Inman Protection Services Logo"
										class="max-w-[87px] select-none"
										draggable="false"
									/>
									<p
										class="text-base"
										:class="{
											'text-slate-400 line-through': !singleOapIncluded,
										}"
									>
										Out of Area Protection by Inman
									</p>
								</div>
								<div
									class="text-xs font-medium"
									:class="{
										'!text-gray-300 line-through': !singleOapIncluded,
									}"
								>
									{{ formatPrice(499) }}
								</div>
							</div>
						</SwipeableToggle>
					</template>

					<template v-else>
						<!-- Multiple plans: list of plans (names and prices) as swipable items -->
						<img
							src="https://a-us.storyblok.com/f/1019754/130x43/b7c48e6e88/inman_logo.png"
							alt="Inman Protection Services Logo"
							class="max-w-[87px]"
						/>
						<p class="self-stretch text-base text-slate-500">
							Out of Area Protection by Inman
						</p>
						<ul class="mt-3.5 flex w-full flex-col gap-5 font-medium">
							<li
								v-for="(plan, index) in plans"
								:key="index"
								class="flex w-full cursor-pointer select-none items-center justify-between gap-5"
							>
								<template v-if="isSelected[index]">
									<SwipeableToggle v-model="planOapIncluded[index]">
										<div class="flex w-full items-center justify-between">
											<div
												class="text-base font-medium"
												:class="{
													'text-gray-400 line-through': !planOapIncluded[index],
												}"
											>
												{{ plan.firstName }} {{ plan.lastName }}
											</div>
											<div
												class="text-xs font-medium text-slate-500"
												:class="{
													'!text-gray-300 line-through':
														!planOapIncluded[index],
												}"
											>
												{{ formatPrice(499) }}
											</div>
										</div>
									</SwipeableToggle>
								</template>
							</li>
						</ul>
					</template>
				</div>

				<!-- Total(s) -->
				<template v-if="!isSidePanelLayout">
					<template v-if="sessionStore.activeSession?.plans.length === 1">
						<div
							class="w-full text-right text-4xl font-bold text-precoa-blue-700"
						>
							{{ formatPrice(pkg.price + 499) }}
						</div>
					</template>
					<template v-else>
						<div class="divide-gray-200 border-t border-solid pt-4">
							<div
								v-for="(plan, index) in sessionStore.activeSession?.plans"
								:key="index"
								class="flex w-full items-center justify-end gap-4"
							>
								<div class="text-xl font-medium">
									{{ plan.firstName }} {{ plan.lastName }}
								</div>

								<div class="text-4xl font-bold text-precoa-blue-700">
									{{ formatPrice(pkg.price + 499) }}
								</div>
							</div>
						</div>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<!--TODO: Make OAP logo dependent on package-->
<!--TODO: If package is recommended for one of the plans/individuals listed make the avatar be autoselected for that package/individual-->
<!--TODO: Make ShieldCheckIcon dependent on guaranteed status-->

<script setup lang="ts">
import PriceDetailsDisplay from '../merchandise/PriceDetailsDisplay.vue'
import type { PbLineItemPackage } from '@/apiTypes'
import type Plan from '@/classes/Plan'
import SwipeableToggle from '@/components/common/SwipeableToggle.vue'
import InputQuantity from '@/components/merchandise/InputQuantity.vue'
import PlanAvatar from '@/components/presentation/PlanAvatar.vue'
import { usePresentationLocale } from '@/i18n'
import { useMerchandise } from '@/mixins/merchandise'
import type { AssetStoryblok } from '@/sbTypes'
import { useAssetStore } from '@/stores/asset'
import { useSessionStore } from '@/stores/session'
import ShieldCheckIcon from '@heroicons/vue/24/solid/ShieldCheckIcon'
import { computedAsync } from '@vueuse/core'
import { ref, onMounted, computed, type PropType } from 'vue'

const { t } = usePresentationLocale()

const props = defineProps({
	isSidePanelLayout: {
		type: Boolean,
		default: false,
	},
	pkg: {
		type: Object as PropType<PbLineItemPackage>,
		required: true,
	},
})

const { priceBook, formatPrice, isLineItemInPlan, qualifiesForDiscount } =
	useMerchandise()
const sessionStore = useSessionStore()
const asset = useAssetStore()

const plans = computed(() => sessionStore.activeSession?.plans || [])
const pkgLineItems = computed(() =>
	priceBook.value?.getProductsInPackage(props.pkg.id)
)

/** One bool per plan */
const planOapIncluded = ref<boolean[]>(plans.value.map(() => true))

const singleOapIncluded = ref(true)

// Initialize a reactive array to track the selection state of each avatar
const isSelected = ref<boolean[]>([])
const selectedPlanCount = computed(
	() => isSelected.value.filter((v) => v).length
)

// Handles swiping on multiple items and toggle the removed styling
onMounted(() => {
	// Populate the `isSelected` array with false for each plan initially
	if (sessionStore.activeSession?.plans) {
		isSelected.value = sessionStore.activeSession.plans.map(() => false)
	}
})

// Toggle the value of the selection state at a specific index on click
const togglePackageInPlan = (plan: Plan) => {
	if (isLineItemInPlan(props.pkg, plan)) {
		plan.hardDeleteCartItem(props.pkg.id)
	} else {
		if (pkgLineItems.value === undefined)
			throw new Error('Package line items not found')
		plan.addCartPackage(props.pkg.id, pkgLineItems.value)
	}
}

const imageUrl = computedAsync(() =>
	asset.getImageBlobUrl({
		filename: props.pkg.imageUrl,
		name: props.pkg.name,
	} as AssetStoryblok)
)
</script>
